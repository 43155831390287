import './zus-aggregated-profile.scss';

import { intersection } from 'lodash';
import { useContext, useEffect, useMemo } from 'react';
import { PatientConditionsAllGroupedProps } from '../conditions/patient-conditions-all-grouped';
import { PatientDiagnosticReportsProps } from '../diagnostic-reports/patient-diagnostic-reports';
import { PatientEncountersProps } from '../encounters/patient-encounters';
import { PatientMedicationsProps } from '../medications/patient-medications';
import { PatientMedicationsAllProps } from '../medications/patient-medications-all';
import { PatientMedicationsOutsideProps } from '../medications/patient-medications-outside';
import { PatientRecordSearchProps } from '../patient-record-search/patient-record-search';
import { PatientReferralsProps } from '../patient-referrals/patient-referrals';
import { PatientSourcesProps } from '../patient-sources/patient-sources';
import { PatientTimelineProps } from '../timeline/patient-timeline';
import { PatientVitalsProps } from '../vitals/patient-vitals';
import ZusSVG from '@ctw/shared/assets/zus.svg';
import { AlertFeatureFlag } from '@ctw/shared/content/alert-feature-flag';
import { Title } from '@ctw/shared/content/ctw-box';
import { withErrorBoundary } from '@ctw/shared/content/error-boundary';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { PatientAllergiesProps } from '@ctw/shared/content/allergies/patient-allergies';
import { PatientCareTeamProps } from '@ctw/shared/content/care-team/patient-careteam';
import { PatientDocumentsProps } from '@ctw/shared/content/document/patient-documents';
import { PatientImmunizationsProps } from '@ctw/shared/content/immunizations/patient-immunizations';
import { DemographicsProps } from '@ctw/shared/content/demographics/demographics';
import { ZAPTabGroup } from '@ctw/shared/content/zus-aggregated-profile/zap-tab-group/zap-tab-group';
import { zusAggregatedProfileTabs } from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile-tabs';
import { AnalyticsProvider } from '@ctw/shared/context/analytics/analytics-provider';
import { usePatientContext } from '@ctw/shared/context/patient-provider';
import { ZapPropsStateContext } from '@ctw/shared/context/zap-props-state-context';
import { ZapTabStateContext } from '@ctw/shared/context/zap-tab-state-context';
import { useFeatureFlag } from '@ctw/shared/hooks/use-feature-flag';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { Heading } from '@ctw/shared/components/heading';
import { faUserLock } from '@fortawesome/free-solid-svg-icons';
import { ContentError } from '@ctw/shared/components/errors/content-error';
import { SupportFormLink } from '@ctw/shared/content/support-form-link';

export const ZAP_TAB_NAMES = [
  'allergies',
  'care-team',
  'conditions-all',
  'diagnostic-reports',
  'documents',
  'encounters',
  'referrals',
  'immunizations',
  'medications',
  'medications-outside',
  'medications-all',
  'search',
  'timeline',
  'overview',
  'demographics',
  'sources',
  'vitals',
] as const;

export type ZAPTabName = (typeof ZAP_TAB_NAMES)[number];

export const DEFAULT_ZAP_TABS: ZAPTabName[] = [
  'overview',
  'search',
  'demographics',
  'conditions-all',
  'medications-all',
  'diagnostic-reports',
  'vitals',
  'encounters',
  'referrals',
  'documents',
  'allergies',
  'immunizations',
  'care-team',
  'sources',
];

export type ZusAggregatedProfileProps = {
  resources?: ZAPTabName[]; // TODO - would prefer to rename this to tabs
  forceHorizontalTabs?: boolean;
  title?: string;
  hideTitle?: boolean;
  removeBranding?: boolean;
  removeRequestRecords?: boolean;
} & ZusAggregatedProfileSubComponentProps;

type HidableRecords<T> = {
  hideRequestRecords?: boolean;
} & T;

type MapOfHideableTypes<MapType> = {
  [Property in keyof MapType]: HidableRecords<MapType[Property]>;
};

export type ZusAggregatedProfileSubComponentProps = MapOfHideableTypes<
  Partial<{
    allergiesProps: PatientAllergiesProps;
    careTeamProps: PatientCareTeamProps;
    conditionsAllProps: PatientConditionsAllGroupedProps;
    diagnosticReportsProps: PatientDiagnosticReportsProps;
    documentsProps: PatientDocumentsProps;
    immunizationsProps: PatientImmunizationsProps;
    referralsProps: PatientReferralsProps;
    medicationsProps: PatientMedicationsProps;
    medicationsOutsideProps: PatientMedicationsOutsideProps;
    medicationsAllProps: PatientMedicationsAllProps;
    timelineProps: PatientTimelineProps;
    encounterProps: PatientEncountersProps;
    searchProps: PatientRecordSearchProps;
    demographicsProps: DemographicsProps;
    sourcesProps: PatientSourcesProps;
    vitalsProps: PatientVitalsProps;
  }>
>;

export type ZusAggregatedProfileSubComponentPropsMap = Partial<{
  allergies: ZusAggregatedProfileSubComponentProps['allergiesProps'];
  'care-team': ZusAggregatedProfileSubComponentProps['careTeamProps'];
  'conditions-all': ZusAggregatedProfileSubComponentProps['conditionsAllProps'];
  'diagnostic-reports': ZusAggregatedProfileSubComponentProps['diagnosticReportsProps'];
  documents: ZusAggregatedProfileSubComponentProps['documentsProps'];
  immunizations: ZusAggregatedProfileSubComponentProps['immunizationsProps'];
  medications: ZusAggregatedProfileSubComponentProps['medicationsProps'];
  'medications-outside': ZusAggregatedProfileSubComponentProps['medicationsOutsideProps'];
  referrals: ZusAggregatedProfileSubComponentProps['referralsProps'];
  'medications-all': ZusAggregatedProfileSubComponentProps['medicationsAllProps'];
  timeline: ZusAggregatedProfileSubComponentProps['timelineProps'];
  encounters: ZusAggregatedProfileSubComponentProps['encounterProps'];
  search: ZusAggregatedProfileSubComponentProps['searchProps'];
  demographics: ZusAggregatedProfileSubComponentProps['demographicsProps'];
  sources: ZusAggregatedProfileSubComponentProps['sourcesProps'];
  overview: undefined;
  vitals: ZusAggregatedProfileSubComponentProps['vitalsProps'];
}>;

const ZusAggregatedProfileComponent = ({
  forceHorizontalTabs = false,
  allergiesProps,
  careTeamProps,
  conditionsAllProps,
  diagnosticReportsProps,
  documentsProps,
  immunizationsProps,
  referralsProps,
  medicationsProps,
  medicationsOutsideProps,
  medicationsAllProps,
  timelineProps,
  encounterProps,
  searchProps,
  demographicsProps,
  sourcesProps,
  vitalsProps,
  resources = DEFAULT_ZAP_TABS,
  hideTitle = false,
  title = 'Outside Records',
  removeBranding = false,
  removeRequestRecords = false,
}: ZusAggregatedProfileProps) => {
  const hasReferralsFeature = useFeatureFlag('ctw-referrals');
  const hasContactInformationFeature = useFeatureFlag('ctw-contact-information');
  const hasSearchFeature = useFeatureFlag('ctw-patient-record-search');
  const hasChartReviewFeature = useFeatureFlag('ctw-hpi');
  const hasShowRequestRecordsFeature = useFeatureFlag('ctw-patient-history-form');
  const { tabOrder, setTabOrder } = useContext(ZapTabStateContext);
  const { setProps } = useContext(ZapPropsStateContext);
  const { patient } = usePatientContext();

  // Get the configuration for each tab group by resource type
  const subcomponentProps: ZusAggregatedProfileSubComponentPropsMap = useMemo(
    () => ({
      allergies: allergiesProps,
      'care-team': careTeamProps,
      'conditions-all': conditionsAllProps,
      'diagnostic-reports': diagnosticReportsProps,
      documents: documentsProps,
      encounters: encounterProps,
      immunizations: immunizationsProps,
      referrals: referralsProps,
      medications: medicationsProps,
      'medications-outside': medicationsOutsideProps,
      'medications-all': medicationsAllProps,
      timeline: timelineProps,
      overview: undefined,
      search: searchProps,
      demographics: demographicsProps,
      sources: sourcesProps,
      vitals: vitalsProps,
    }),
    [
      allergiesProps,
      careTeamProps,
      conditionsAllProps,
      diagnosticReportsProps,
      documentsProps,
      encounterProps,
      immunizationsProps,
      referralsProps,
      medicationsProps,
      medicationsOutsideProps,
      medicationsAllProps,
      timelineProps,
      searchProps,
      demographicsProps,
      sourcesProps,
      vitalsProps,
    ],
  );

  // Set tab order here because resource tab order can be changed by the user
  useEffect(() => {
    setProps(subcomponentProps);

    // Filter out tabs that are not enabled.
    const tabs = resources.filter(
      (tabName) =>
        (tabName !== 'referrals' || hasReferralsFeature) &&
        (tabName !== 'search' || hasSearchFeature) &&
        (hasContactInformationFeature ?
          tabName === 'demographics' || tabName !== 'sources'
        : tabName === 'sources' || tabName !== 'demographics'),
    );

    setTabOrder(tabs);
  }, [
    resources,
    setTabOrder,
    setProps,
    subcomponentProps,
    hasReferralsFeature,
    hasContactInformationFeature,
    hasSearchFeature,
    hasChartReviewFeature,
  ]);

  const showRequestRecordsButton = useMemo(
    () => hasShowRequestRecordsFeature && !removeRequestRecords,
    [hasShowRequestRecordsFeature, removeRequestRecords],
  );

  const tabbedContent = useMemo(
    () =>
      // Return tab content filtered down to our set of enabled tabs via tabOrder.
      // This also ensures it is in the same order as tabOrder which ensures that
      // setSelectedTab by name works correctly (tabs and content line up!).
      intersection(tabOrder as ZAPTabName[], resources).map((tabName) => {
        const props = subcomponentProps[tabName] ?? {};
        props.hideRequestRecords = !showRequestRecordsButton;
        return zusAggregatedProfileTabs[tabName](props);
      }),
    [tabOrder, resources, subcomponentProps, showRequestRecordsButton],
  );

  if (patient.isLoading) {
    return (
      <LoadingSpinner message="Loading patient. This may take a minute if patient is being synced from EHR for the first time." />
    );
  }

  const notAuthorizedGraphql =
    patient.error &&
    typeof patient.error === 'object' &&
    'statusCode' in patient.error &&
    patient.error.statusCode === 401;

  if (
    (patient.error &&
      typeof patient.error === 'object' &&
      'status' in patient.error &&
      patient.error.status === 401) ||
    notAuthorizedGraphql
  ) {
    return (
      <ContentError
        icon={faUserLock}
        title="Not authorized"
        message="Your account does not have access to this feature."
      >
        <span>If this problem persists </span>
        <SupportFormLink buttonText="contact support" className={tw`link text-base`} />.
      </ContentError>
    );
  }

  if (!patient.data || patient.error) {
    return (
      <ContentError title="Patient not found" message="Try reloading the ZAP.">
        <span>If this problem persists </span>
        <SupportFormLink buttonText="contact support" className={tw`link text-base`} />.
      </ContentError>
    );
  }

  if (patient.data.active === false) {
    return (
      <ContentError
        title="Inactive patient"
        message="Follow your organization's guidelines to activate this patient in Zus."
      >
        <span>If this problem persists </span>
        <SupportFormLink buttonText="contact support" className={tw`link text-base`} />.
      </ContentError>
    );
  }

  return (
    <AnalyticsProvider componentName="ZusAggregatedProfile">
      <div className={tw`space-y-2`}>
        <AlertFeatureFlag featureFlag="zap-alert" />
        <div className={tw`ctw-zus-aggregated-profile scrollable-pass-through-height space-y-4`}>
          <ZAPTabGroup content={tabbedContent} forceHorizontalTabs={forceHorizontalTabs} />
          {!hideTitle && (
            <Title
              className={twx(
                `border-b-0 border-l-0 border-r-0 border-t-2 border-solid border-divider-light bg-zap-headerBg px-4`,
              )}
            >
              <div className={tw`flex items-center space-x-1 py-3`}>
                <Heading level="h3" className={tw`m-0 inline-block p-0`}>
                  {title}
                </Heading>
                {!removeBranding && (
                  <div className={tw`flex items-center space-x-2`}>
                    <span className={tw`text-sm font-light italic text-content-light`}>
                      Powered by
                    </span>
                    <img src={ZusSVG} alt="Zus" />
                  </div>
                )}
              </div>
            </Title>
          )}
        </div>
      </div>
    </AnalyticsProvider>
  );
};

/**
 * ZusAggregatedProfile allows developers to utilize most of the components
 * available in this library as a single customizable component with tabbed
 * navigation. Simply pass an array with the names of the desired resources
 * to include _(see below)_.
 *
 * It's possible to set a custom title for the ZusAggregatedProfile component,
 * making it a single component which can be used to create a handful of
 * different widgets within an app.
 *
 * ```
 * export const ZusMedsWidget = <ZusAggregatedProfile
 *   title="Medications"
 *   resources={["medications", "medications-outside"]}
 * />
 *
 * export const ZusProblemsWidget = <ZusAggregatedProfile
 *   title="Problems"
 *   resources={["allergies", "conditions", "conditions-outside"]}
 * />
 * ```
 * The complete set of available resources in the ZusAggregatedProfile are
 * "allergies",
 * "care-team",
 * "conditions",
 * "conditions-outside",
 * "conditions-all",
 * "diagnostic-reports",
 * "documents",
 * "encounters",
 * "immunizations",
 * "medications",
 * "medications-outside",
 * "medications-all",
 * "timeline"
 */
export const ZusAggregatedProfile = withErrorBoundary(
  ZusAggregatedProfileComponent,
  'ZusAggregatedProfile',
);
