import { compact, uniq } from 'lodash';
import { FHIRModel } from './fhir-model';
import { formatDateISOToLocal, formatISODateStringToDate } from '../formatters';
import { findReference } from '../resource-helper';
import { SYSTEM_LOINC } from '@ctw/shared/api/fhir/system-urls';
import {
  isEmptyClinicalNote,
  isSectionDocument,
} from '@ctw/shared/content/document/helpers/filters';
import { LOINC_SUMMARY_OF_EPISODE_NOTE } from '@ctw/shared/utils/loinc-codes';

// These are public OIDs to identify documents that were generated by Zus for reciprocity to the network.
export const ZUS_DOCUMENT_SYSTEM_IDS = [
  '2.16.840.1.113883.3.3126.2.3.25726.',
  '2.16.840.1.113883.3.9206.2.',
];

export const THIRD_PARTY_DATA_SOURCE_SURESCRIPTS = 'surescripts';

export class DocumentModel extends FHIRModel<fhir4.DocumentReference> {
  kind = 'Document' as const;

  constructor(
    resource: fhir4.DocumentReference,
    private relatedDocuments?: DocumentModel[],
    basics?: fhir4.Basic[],
  ) {
    super(resource, undefined, basics);
  }

  get status(): string {
    return this.resource.status;
  }

  get binaryId(): string | undefined {
    const referenceString = this.resource.content[0].attachment.url;

    if (referenceString) {
      return referenceString.split('/').pop();
    }
    return undefined;
  }

  get contentType(): string | undefined {
    return this.resource.content[0].attachment.contentType;
  }

  get isImage(): boolean {
    return this.contentType?.startsWith('image/') ?? false;
  }

  get isPdf(): boolean {
    return this.contentType === 'application/pdf';
  }

  // Simply checking for XML or HTML is not *really* enough to determine if a document is a CDA.
  // But it's the best we have for now (short of fetching and inspecting the binary).
  // We used to check specifically for Commonwell and Carequality documents, but a better
  // approach for now is to exclude SureScripts XML documents.
  get isCDA(): boolean {
    return (
      (this.contentType === 'application/xml' || this.contentType === 'text/html') &&
      this.externalNetworkSourceName !== THIRD_PARTY_DATA_SOURCE_SURESCRIPTS
    );
  }

  get docStatus(): string | undefined {
    return this.resource.docStatus;
  }

  get category(): fhir4.CodeableConcept[] {
    const categoriesOnRelatedDocs = this.relatedDocuments?.flatMap((d) => d.category) ?? [];
    const documentCategory = this.resource.category ?? ([] as fhir4.CodeableConcept[]);
    return uniq(documentCategory.concat(categoriesOnRelatedDocs));
  }

  get resourceTypeTitle(): string {
    return 'Document';
  }

  get title() {
    if (this.resource.description) {
      return this.resource.description;
    }

    if (this.resource.content.length > 0 && this.resource.content[0].attachment.title) {
      return this.resource.content[0].attachment.title;
    }

    return this.loincDisplay || 'Unknown';
  }

  get dateCreated(): string | undefined {
    return formatISODateStringToDate(
      this.resource.date || this.resource.content[0].attachment.creation,
    );
  }

  // This indicates that the document was originally created by Zus for reciprocity
  // to the network. These *should* generally be filtered out during the DA process,
  // but this is a safety check so that we don't display any in the UI in case they
  // were loaded in error or before the DA process was updated.
  get isZusGeneratedReciprocityDocument(): boolean {
    return ZUS_DOCUMENT_SYSTEM_IDS.some(
      (systemId) => this.resource.masterIdentifier?.system?.includes(systemId),
    );
  }

  get isClinicalSummary(): boolean {
    return (
      this.resource.type?.coding?.some((x) => x.code === LOINC_SUMMARY_OF_EPISODE_NOTE) ?? false
    );
  }

  get contextPeriodStartDate(): string | undefined {
    return formatDateISOToLocal(this.resource.context?.period?.start);
  }

  get contextPeriodEndDate(): string | undefined {
    return formatDateISOToLocal(this.resource.context?.period?.end);
  }

  get encounterDate(): string | undefined {
    if (this.isClinicalSummary) {
      return undefined;
    }

    // This is a bit of a hack so that we don't display the encounter date for non external network documents.
    // Elation documents appear to always put the document generation date in the context period,
    // so progress notes pulled via backfills will show an encounter date that is the same as the
    // date the document was generated and not the actual date of the encounter. This is not accurate
    // and could be misleading.
    // https://zeushealth.atlassian.net/browse/EHR-111
    if (!this.isExternalNetworkData) {
      return undefined;
    }

    const start = this.contextPeriodStartDate;
    const end = this.contextPeriodEndDate;

    if (start && end && start !== end) {
      return `${start} - ${end}`;
    }

    if (start) {
      return start;
    }

    if (end) {
      return end;
    }

    return undefined;
  }

  get custodian(): string | undefined {
    const organizationName = findReference(
      'Organization',
      this.resource.contained,
      this.includedResources,
      this.resource.custodian,
    )?.name;
    return this.resource.custodian?.display || organizationName;
  }

  get loincDisplay(): string | undefined {
    // Look for a LOINC code on the "type" field. It is possible that the LOINC
    // could be found in the "category" list as well (our fhir seeds do this).
    const codings = compact([this.resource.type, ...this.category]);
    const loincCoding = codings.find(
      (codeableConcept) =>
        codeableConcept.coding?.some((coding) => coding.system === SYSTEM_LOINC && coding.code),
    );
    return loincCoding?.coding?.[0]?.display || loincCoding?.text;
  }

  get displayContent(): string | undefined {
    if (this.resource.text?.div) {
      return this.resource.text.div;
    }
    if (
      this.resource.content[0].attachment.data &&
      this.resource.content[0].attachment.contentType === 'text/plain'
    ) {
      return atob(this.resource.content[0].attachment.data);
    }
    return undefined;
  }

  get sectionDocuments() {
    return this.relatedDocuments?.filter((d) => isSectionDocument(d) && !isEmptyClinicalNote(d));
  }
}
