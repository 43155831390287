export const navigateIntoView = (sectionId: string, isOutsideModal: boolean) => {
  // This is the element that gets scrolled.
  const containerElement =
    isOutsideModal ? document.getElementById('ccda-viewer') : document.getElementById('ctw-modal');
  // The top header of the modal, which may be down a bit due to padding or stickied at top.
  const headerOffset = document.getElementById('document-header')?.getBoundingClientRect().y;
  // This is the target position we want to scroll the section to.
  const tocBottom = document.getElementById('table-of-contents')?.getBoundingClientRect().bottom;
  const sectionElement = document.getElementById(sectionId);
  if (
    tocBottom === undefined ||
    headerOffset === undefined ||
    !containerElement ||
    !sectionElement
  ) {
    return;
  }

  const sectionTop = sectionElement.getBoundingClientRect().top;
  // Scroll the modal such that the section's new top is equal to the toc's bottom.
  if (isOutsideModal) {
    const currentPosition = window.scrollY;
    document.documentElement.scrollTop = currentPosition + sectionTop - tocBottom + headerOffset;
    return;
  }

  containerElement.scrollTop += sectionTop - tocBottom + headerOffset;
};
