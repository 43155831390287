import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { useTimingQueryWithPatient } from '@ctw/shared/context/patient-provider';
import { SYSTEM_SUMMARY } from '@ctw/shared/api/fhir/system-urls';
import {
  createGraphqlClient,
  fqsRequest,
  MAX_OBJECTS_PER_REQUEST,
} from '@ctw/shared/api/fqs/client';
import { orderBy, uniqBy } from 'lodash';
import { PatientModel } from './models';
import { ImmunizationModel } from './models/immunization';
import {
  ImmunizationGraphqlResponse,
  immunizationsQuery,
  ImmunizationWithBasics,
} from '@ctw/shared/api/fqs/queries/immunizations';
import { QUERY_KEY_PATIENT_IMMUNIZATIONS } from '@ctw/shared/utils/query-keys';
import { sort } from '@ctw/shared/utils/sort';

export function usePatientImmunizations(enabled = true) {
  return useTimingQueryWithPatient(
    QUERY_KEY_PATIENT_IMMUNIZATIONS,
    [],
    getImmunizationFromFQS,
    enabled,
  );
}

export async function getImmunizationsFromFQS(
  requestContext: CTWRequestContext,
  patient: PatientModel,
  ids: string[],
) {
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequest<ImmunizationGraphqlResponse>(graphClient, immunizationsQuery, {
    upid: patient.UPID,
    cursor: '',
    first: MAX_OBJECTS_PER_REQUEST,
    sort: {
      lastUpdated: 'DESC',
    },
    filter: {
      ids: { anymatch: ids },
      tag: { nonematch: [SYSTEM_SUMMARY] },
    },
  });
  const nodes = data.ImmunizationConnection.edges.map((x) => x.node);
  const results = getFilteredImmunizations(nodes, requestContext.builderId);
  return results;
}

async function getImmunizationFromFQS(requestContext: CTWRequestContext, patient: PatientModel) {
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequest<ImmunizationGraphqlResponse>(graphClient, immunizationsQuery, {
    upid: patient.UPID,
    cursor: '',
    first: 1000,
    sort: {
      lastUpdated: 'DESC',
    },
    filter: {
      tag: { nonematch: [SYSTEM_SUMMARY] },
    },
  });
  const nodes = data.ImmunizationConnection.edges.map((x) => x.node);
  const results = getFilteredImmunizations(nodes, requestContext.builderId);
  return results;
}

export const getFilteredImmunizations = (data: ImmunizationWithBasics[], builderId: string) => {
  const immunizations = data.map(
    (immunization) => new ImmunizationModel(immunization, undefined, immunization.BasicList),
  );

  const sortedByDate = sort(
    immunizations.filter((x) => x.status === 'Completed'),
    'occurrence',
    'desc',
    true,
  );

  // Bump builder owned immunization to the front, so uniqBy favors them!
  const builderOwnedFirst = orderBy(sortedByDate, (a) => a.ownedByBuilder(builderId), 'desc');
  return uniqBy(builderOwnedFirst, 'uniqueKey');
};
