import useResizeObserverTemp from '@react-hook/resize-observer';
import { mapValues } from 'lodash';
import { RefObject, useCallback, useState } from 'react';
import { useIsomorphicLayoutEffect } from './use-isomorphic-layout-effect';
import { defaultBreakpoints } from '../context/theme/default-theme';
import { useTheme } from '@ctw/shared/context/theme/use-theme';

// This is an ugly hack / work around for a SSR/CommonJS/build
// issue where useResizeObserver is getting compiled to
// foo = require("@react-hook/resize-observer")
// instead of
// foo = require("@react-hook/resize-observer").default
// causing a "foo is not a function" error.
// This shouldn't be a problem when using ES Modules or for
// libraries that don't use default exports!
const useResizeObserver: typeof useResizeObserverTemp =
  // @ts-ignore
  useResizeObserverTemp.default || useResizeObserverTemp;

type BreakpointKeys = keyof typeof defaultBreakpoints;
export type Breakpoints = Record<BreakpointKeys, boolean> & { width: number };

export function useBreakpoints<T extends HTMLElement>(target: RefObject<T>) {
  const { theme } = useTheme();
  const [breakpoints, setBreakpoints] = useState<Breakpoints>({
    ...mapValues(defaultBreakpoints, () => false),
    width: 0,
  });

  const updateBreakpoints = useCallback(() => {
    const targetEl = target.current;
    if (!targetEl) return;
    const { width } = targetEl.getBoundingClientRect();

    // Fix bug where display: none / hidden would cause
    // a component to have 0 width. In this case we don't want
    // to update our breakpoints. This fixes an issue with TabGroup
    // where switching tabs in ZAP would cause a flash as
    // the component was initially "stacked" due to 0 width.
    if (width === 0) return;

    const breakpointsTmp = { width } as Breakpoints;

    if (theme.breakpoints) {
      Object.entries(theme.breakpoints).forEach(([key, value]) => {
        breakpointsTmp[key as BreakpointKeys] = width < value;
      });
    }

    setBreakpoints(breakpointsTmp);
  }, [target, theme.breakpoints]);

  // Update on initial render (useIsomorphicLayoutEffect) and
  // on any resizes.
  useIsomorphicLayoutEffect(updateBreakpoints, [target.current, theme]);
  useResizeObserver(target.current, updateBreakpoints);

  return breakpoints;
}
