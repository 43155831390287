import { max } from 'date-fns';
import type { Reference } from 'fhir/r4';
import { capitalize, compact, find, get, toLower } from 'lodash/fp';
import { FHIRModel } from './fhir-model';
import { PatientModel } from './patient';
import {
  codeableConceptLabel,
  findCodingWithEnrichmentValueString,
} from '@ctw/shared/api/fhir/codeable-concept';
import { dateToISO, formatDateISOToLocal } from '@ctw/shared/api/fhir/formatters';
import {
  getIdentifyingRxNormCode,
  getIdentifyingRxNormCoding,
  getMedicationCodeableConcept,
  patientStatus,
} from '@ctw/shared/api/fhir/medication';
import { findReference } from '@ctw/shared/api/fhir/resource-helper';
import {
  CTW_EXTENSION_LENS_AGGREGATED_FROM,
  LENS_EXTENSION_AGGREGATED_FROM,
  LENS_EXTENSION_MEDICATION_DAYS_SUPPLY,
  LENS_EXTENSION_MEDICATION_LAST_FILL_DATE,
  LENS_EXTENSION_MEDICATION_LAST_PRESCRIBED_DATE,
  LENS_EXTENSION_MEDICATION_LAST_PRESCRIBER,
  LENS_EXTENSION_MEDICATION_QUANTITY,
  LENS_EXTENSION_MEDICATION_REFILLS,
  SYSTEM_RXNORM,
} from '@ctw/shared/api/fhir/system-urls';

export class MedicationStatementModel extends FHIRModel<fhir4.MedicationStatement> {
  kind = 'MedicationStatement' as const;

  readonly builderPatientRxNormStatus?: Record<string, string>;

  get activeIngredient(): string | undefined {
    const concept = getMedicationCodeableConcept(this.resource, this.includedResources);
    const coding = findCodingWithEnrichmentValueString('ActiveIngredient', concept);
    return coding?.display;
  }

  get aggregatedFrom(): Reference[] {
    const extension = find(
      (x) =>
        x.url === LENS_EXTENSION_AGGREGATED_FROM || x.url === CTW_EXTENSION_LENS_AGGREGATED_FROM,
      this.resource.extension,
    );
    if (!extension?.extension) {
      return compact(this.resource.derivedFrom);
    }
    return compact(extension.extension.map(get('valueReference')));
  }

  get basedOn(): string | undefined {
    return this.resource.basedOn?.[0]?.type;
  }

  get category(): string {
    return codeableConceptLabel(this.resource.category);
  }

  get context(): string | undefined {
    return this.resource.context?.display;
  }

  get dateAsserted(): string | undefined {
    return formatDateISOToLocal(this.resource.dateAsserted);
  }

  set dateAsserted(dateAsserted: Date | string | undefined) {
    if (dateAsserted instanceof Date) {
      this.resource.dateAsserted = dateToISO(dateAsserted);
    } else {
      this.resource.dateAsserted = dateAsserted;
    }
  }

  get derivedFrom(): string[] {
    return this.resource.derivedFrom?.map(({ display }) => display || '') || [];
  }

  get display() {
    return codeableConceptLabel(
      getMedicationCodeableConcept(this.resource, this.includedResources),
      SYSTEM_RXNORM,
    );
  }

  get sortDisplay() {
    return toLower(this.display);
  }

  get dosage(): string | undefined {
    return this.resource.dosage?.[0]?.text;
  }

  get effectiveStart(): string | undefined {
    return formatDateISOToLocal(this.resource.effectivePeriod?.start);
  }

  get identifier(): string | undefined {
    return this.resource.identifier?.[0]?.value;
  }

  set informationSource(informationSource: Reference | undefined) {
    this.resource.informationSource = informationSource;
  }

  get medicationReference(): string | undefined {
    return this.resource.medicationReference?.display;
  }

  get notesDisplay(): string[] {
    return this.resource.note?.map(({ text }) => text) || [];
  }

  get partOf(): string | undefined {
    return this.resource.partOf?.[0]?.display;
  }

  get patientStatus(): string {
    return patientStatus(this.builderPatientRxNormStatus?.[this.rxNorm ?? '']);
  }

  get resourceTypeTitle(): string {
    return 'Medication';
  }

  get rxNorm() {
    return getIdentifyingRxNormCode(this.resource, this.includedResources);
  }

  /**
   * Get RxNorm coding with "display" defaulting to this Med-Statement label.
   */
  get rxNormCodeableConcept() {
    const coding = getIdentifyingRxNormCoding(this.resource, this.includedResources);

    return {
      ...(coding ?? {}),
      display: coding?.display ?? this.display,
    };
  }

  get reason(): string | undefined {
    return codeableConceptLabel(this.resource.reasonCode?.[0]);
  }

  get reasonReference(): fhir4.MedicationStatement['reasonReference'] | undefined {
    return this.resource.reasonReference;
  }

  get isInactive(): boolean {
    return !['active', 'intended', 'unknown'].includes(this.status);
  }

  get status() {
    return this.resource.status;
  }

  get displayStatus() {
    if (this.isDismissed) {
      return 'Dismissed';
    }
    return capitalize(this.resource.status);
  }

  get statusReason(): string | undefined {
    return codeableConceptLabel(this.resource.statusReason?.[0]);
  }

  get subjectID(): string {
    const [, subjectID] = this.resource.subject.reference?.split('/') || [];
    return subjectID || '';
  }

  get patient(): PatientModel | undefined {
    const reference = findReference(
      'Patient',
      this.resource.contained,
      this.includedResources,
      this.resource.subject,
    );

    if (reference) {
      return new PatientModel(reference, this.includedResources);
    }

    return undefined;
  }

  // lens extensions

  get lastFillDate(): string | undefined {
    return formatDateISOToLocal(
      this.resource.extension?.find((x) => x.url === LENS_EXTENSION_MEDICATION_LAST_FILL_DATE)
        ?.valueDateTime,
    );
  }

  get quantity(): string | undefined {
    const quantity = this.resource.extension?.find(
      (x) => x.url === LENS_EXTENSION_MEDICATION_QUANTITY,
    )?.valueQuantity;

    const { value, unit = 'Unspecified' } = quantity || {};
    return value !== undefined ? `${value} (${unit})` : undefined;
  }

  get daysSupply(): string | undefined {
    return this.resource.extension
      ?.find((x) => x.url === LENS_EXTENSION_MEDICATION_DAYS_SUPPLY)
      ?.valueQuantity?.value?.toString();
  }

  get refills(): string | undefined {
    return this.resource.extension
      ?.find((x) => x.url === LENS_EXTENSION_MEDICATION_REFILLS)
      ?.valueUnsignedInt?.toString();
  }

  get lastPrescriber(): string | undefined {
    const reference = this.resource.extension?.find(
      (x) => x.url === LENS_EXTENSION_MEDICATION_LAST_PRESCRIBER,
    )?.valueReference;

    if (!reference?.reference) {
      return undefined;
    }

    const referenceType = reference.reference.split('/')[0];
    if (!referenceType) {
      return undefined;
    }

    const resource = findReference(
      referenceType as 'Practitioner' | 'Organization',
      this.resource.contained,
      this.includedResources,
      reference,
    );
    if (resource?.name) {
      if (typeof resource.name === 'string') {
        return resource.name;
      }
      const { family, given = [] } = resource.name[0];
      return compact([family, given[0]]).join(', ');
    }
    return reference.display;
  }

  get lastPrescribedDate(): string | undefined {
    return formatDateISOToLocal(
      this.resource.extension?.find((x) => x.url === LENS_EXTENSION_MEDICATION_LAST_PRESCRIBED_DATE)
        ?.valueDateTime,
    );
  }

  get lastActivityDate(): string | undefined {
    const { lastPrescribedDate, lastFillDate } = this;
    const dates = compact([lastPrescribedDate, lastFillDate]).map((x) => new Date(x));
    if (dates.length === 0) {
      return undefined;
    }
    const maxDate = max(dates);
    return formatDateISOToLocal(maxDate.toISOString());
  }

  get title() {
    return this.display;
  }
}
