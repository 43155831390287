import { tw, twx } from '@ctw/shared/utils/tailwind';
import { Button } from '@ctw/shared/components/button';

export type PatientSummaryDrawerFooterProps = {
  feedbackEnabled: boolean;
  onFeedbackClick: () => void;
  onClose: () => void;
};

export const PatientSummaryDrawerFooter = ({
  feedbackEnabled,
  onFeedbackClick,
  onClose,
}: PatientSummaryDrawerFooterProps) => {
  const feedbackBtnClass =
    feedbackEnabled ?
      'rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm btn bg-primary-main text-white hover:bg-primary-dark'
    : 'rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm border border-solid border-divider-main bg-white capitalize text-content-light hover:bg-bg-lighter';

  return (
    <div className={tw`ai-summary-drawer-footer`}>
      <Button type="button" variant="clear" className={tw`hover:underline`} onClick={onClose}>
        Close
      </Button>
      <Button
        type="button"
        variant="unstyled"
        className={twx(feedbackBtnClass)}
        disabled={!feedbackEnabled}
        onClick={onFeedbackClick}
      >
        Submit Feedback
      </Button>
    </div>
  );
};
