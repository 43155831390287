import { Transition } from '@headlessui/react';
import { useContext, useState } from 'react';
import { PatientResourceTable } from '../resource/patient-resource-table';
import { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import { EmptyPatientTable } from '@ctw/shared/components/table/empty-table';
import { Modal, ModalProps } from '@ctw/shared/components/modal';
import { TableProps } from '@ctw/shared/components/table/table';
import { WritebackAction } from '@ctw/shared/components/table/table-rows';
import { notify } from '@ctw/shared/components/toast';
import { BulkActionContext, ZAPSelectedResources } from '@ctw/shared/context/bulk-action-context';
import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { useModal } from '@ctw/shared/context/modal-hooks';
import { PatientState } from '@ctw/shared/context/patient-context';
import { usePatientContext } from '@ctw/shared/context/patient-provider';
import { useCTW } from '@ctw/shared/context/use-ctw';
import { tw } from '@ctw/shared/utils/tailwind';
import { Heading } from '@ctw/shared/components/heading';
import { Button } from '@ctw/shared/components/button';

export type BulkAction<T extends fhir4.Resource, M extends FHIRModel<T>> = {
  type: WritebackAction;
  action: (
    patient: PatientState,
    getRequestContext: () => Promise<CTWRequestContext>,
    selected: M[],
  ) => Promise<void>;
};

export type BulkActionHeaderProps<T extends fhir4.Resource, M extends FHIRModel<T>> = {
  selectedKey: keyof ZAPSelectedResources;
  resourceName: string;
  onWritebackStart: (action: WritebackAction, id: string) => void;
  onWritebackEnd: (
    action: WritebackAction,
    id: string,
    isError: boolean,
    errorMessage?: string,
  ) => void;
  bulkAdd: BulkAction<T, M>;
  bulkDismiss: BulkAction<T, M>;
  columns: TableProps<M>['columns'];
};

export type UseBulkActionModalProps<T extends fhir4.Resource, M extends FHIRModel<T>> = {
  selectedKey: keyof ZAPSelectedResources;
  resourceName: string;
  onWritebackStart: (action: WritebackAction, id: string) => void;
  onWritebackEnd: (
    action: WritebackAction,
    id: string,
    isError: boolean,
    errorMessage?: string,
  ) => void;
  bulkAction: BulkAction<T, M>;
  columns: TableProps<M>['columns'];
};

export type BulkActionModalProps<T extends fhir4.Resource, M extends FHIRModel<T>> = {
  onClose: () => void;
} & UseBulkActionModalProps<T, M> &
  Omit<ModalProps, 'children' | 'onAfterClosed'>;

export function useBulkActionModal<T extends fhir4.Resource, M extends FHIRModel<T>>({
  selectedKey,
  resourceName,
  onWritebackStart,
  onWritebackEnd,
  bulkAction,
  columns,
}: UseBulkActionModalProps<T, M>) {
  const { openModal } = useModal();

  return () => {
    openModal({
      component: (props) => (
        <BulkActionModal
          selectedKey={selectedKey}
          resourceName={resourceName}
          onWritebackStart={onWritebackStart}
          onWritebackEnd={onWritebackEnd}
          bulkAction={bulkAction}
          columns={columns}
          {...props}
        />
      ),
    });
  };
}

export const BulkActionHeaderSelectedState = <T extends fhir4.Resource, M extends FHIRModel<T>>({
  selectedKey,
  resourceName,
  onWritebackStart,
  onWritebackEnd,
  bulkAdd,
  bulkDismiss,
  columns,
}: BulkActionHeaderProps<T, M>) => {
  const { selectedResources, setSelectedResources } = useContext(BulkActionContext);
  const [isSyncing, setIsSyncing] = useState(false);
  const selected = selectedResources[selectedKey];

  const onWriteBackStartTracked = (action: WritebackAction, id: string) => {
    setIsSyncing(true);
    onWritebackStart(action, id);
  };
  const onWriteBackEndTracked = (
    action: WritebackAction,
    id: string,
    isError: boolean,
    errorMessage?: string,
  ) => {
    setIsSyncing(false);
    onWritebackEnd(action, id, isError, errorMessage);
  };

  const openBulkAddModal = useBulkActionModal<T, M>({
    selectedKey,
    resourceName,
    onWritebackStart: onWriteBackStartTracked,
    onWritebackEnd: onWriteBackEndTracked,
    bulkAction: {
      type: 'add',
      action: (patient, getRequestContext, selectedThings) =>
        bulkAdd.action(patient, getRequestContext, selectedThings).catch((e) => {
          notify({
            type: 'error',
            title: 'Error encountered adding to chart.',
            body: 'Try again or add it manually. Dismiss it to no longer see it in this list.',
          });
          throw e;
        }),
    },
    columns,
  });
  const openBulkDismissModal = useBulkActionModal<T, M>({
    selectedKey,
    resourceName,
    onWritebackStart: onWriteBackStartTracked,
    onWritebackEnd: onWriteBackEndTracked,
    bulkAction: {
      type: 'dismiss-bulk',
      action: (patient, getRequestContext, selectedThings) =>
        bulkDismiss.action(patient, getRequestContext, selectedThings).catch((e) => {
          notify({
            type: 'error',
            title: 'Error encountered dismissing.',
            body: 'Try again or dismiss it manually to no longer see it in this list.',
          });
          throw e;
        }),
    },
    columns,
  });

  return (
    <Transition
      show={selected.length > 0} // Conditionally show the banner based on the selected items
      enter="ease-out duration-200"
      enterFrom="opacity-0 max-h-0"
      enterTo="opacity-100 max-h-40"
      leave="ease-out duration-100"
      leaveFrom="opacity-100 max-h-40"
      leaveTo="opacity-0 max-h-0"
    >
      <div className={tw`flex w-full justify-between bg-primary-light p-5`}>
        <div className={tw`flex items-center space-x-2`}>
          <div className={tw`space-x-0.5 font-light`}>
            <span className={tw`font-semibold`}>{selected.length}</span>{' '}
            <span className={tw`lowercase`}>
              {resourceName}
              {selected.length > 1 && 's'} {isSyncing ? 'syncing' : 'selected'}{' '}
            </span>
          </div>
          <Button
            onClick={() => {
              setSelectedResources({ [selectedKey]: [] });
            }}
            type="button"
            variant="clear-link"
            disabled={isSyncing}
          >
            Deselect All
          </Button>
        </div>
        <div className={tw`space-x-2`}>
          <Button
            type="button"
            variant="default"
            onClick={() => openBulkDismissModal()}
            disabled={isSyncing}
          >
            Dismiss
          </Button>
          <Button
            type="button"
            variant="primary"
            onClick={() => openBulkAddModal()}
            disabled={isSyncing}
          >
            Add to Chart
          </Button>
        </div>
      </div>
    </Transition>
  );
};

const bulkActionDisplays: Partial<
  Record<WritebackAction, { title: string; bodyText: string; btnText: string }>
> = {
  add: {
    title: 'Add to Chart',
    bodyText: 'The following data will be imported to the chart:',
    btnText: 'Add to Chart',
  },
  'dismiss-bulk': {
    title: 'Dismiss from Chart',
    bodyText: 'The following data will be dismissed from the chart:',
    btnText: 'Dismiss',
  },
};

const BulkActionModal = <T extends fhir4.Resource, M extends FHIRModel<T>>({
  selectedKey,
  resourceName: resourceType,
  onClose,
  onWritebackStart,
  onWritebackEnd,
  bulkAction,
  columns,
  ...modalProps
}: BulkActionModalProps<T, M>) => {
  const { selectedResources, setSelectedResources } = useContext(BulkActionContext);
  const selectedResourcesOfType = selectedResources[selectedKey] as unknown as M[];
  const patient = usePatientContext();
  const { getRequestContext } = useCTW();
  const displayInfo = bulkActionDisplays[bulkAction.type] ?? {
    title: '',
    bodyText: '',
    btnText: 'Confirm',
  };

  return (
    <Modal className={tw`!w-full !max-w-3xl`} {...modalProps} onClose={onClose} showCloseIcon>
      <div className={tw`space-y-3`}>
        <Heading level="h1">{displayInfo.title}</Heading>
        <div className={tw`space-y-6 text-lg`}>{displayInfo.bodyText}</div>
      </div>

      <div>
        <PatientResourceTable
          columns={columns}
          data={selectedResourcesOfType}
          emptyMessage={
            <EmptyPatientTable
              hasZeroFilteredRecords={selectedResourcesOfType.length === 0}
              resourceName={resourceType}
            />
          }
          isLoading={false}
        />
      </div>
      <div className={tw`space-x-2 text-right`}>
        <Button type="button" variant="default" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          type="button"
          variant="primary"
          onClick={() => {
            selectedResourcesOfType.forEach((resource) => {
              onWritebackStart(bulkAction.type, resource.id);
            });
            onClose();
            bulkAction
              .action(patient, getRequestContext, selectedResourcesOfType)
              .finally(() => {
                // Reset the selected resources after adding to chart
                setSelectedResources({ [selectedKey]: [] });
              })
              .then(() => {
                selectedResourcesOfType.forEach((resource) => {
                  onWritebackEnd(bulkAction.type, resource.id, false);
                });
              })
              .catch((error) => {
                selectedResourcesOfType.forEach((resource) => {
                  onWritebackEnd(
                    bulkAction.type,
                    resource.id,
                    true,
                    error instanceof Error ? error.message : String(error),
                  );
                });
              });
          }}
        >
          {displayInfo.btnText}
        </Button>
      </div>
    </Modal>
  );
};
