import { compact } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import {
  FilterChangeEvent,
  FilterOptionCheckbox,
  FilterOptionTag,
} from '@ctw/shared/components/filter-bar/filter-bar-types';
import { SortOption } from '@ctw/shared/content/resource/resource-table-actions';
import { ViewOption } from '@ctw/shared/content/resource/helpers/view-button';
import { applyFilters, filterHidden } from '@ctw/shared/utils/filters';
import { getDefaultsFromLocalStorage, saveToLocalStorage } from '@ctw/shared/utils/local-storage';
import { applySorts } from '@ctw/shared/utils/sort';
import { AllOrNone } from '@ctw/shared/utils/types';

type PredicateTypes = FilterOptionCheckbox['predicate'] | FilterOptionTag['predicate'];

export type PredicateMap<T extends PredicateTypes> = Record<string, T>;

export type UseFilteredSortedDataProps<T extends object> = {
  cacheKey?: string;
  records?: T[];
  defaultSort: SortOption<T>;
  filterPredicates?: PredicateMap<PredicateTypes>;
  defaultFilters?: FilterChangeEvent;
} & AllOrNone<{
  defaultView: string;
  viewOptions: ViewOption<T>[];
}>;

// Note: This hook all arguments except records are are stable and will not change.
// This allows us to skip some of the dependencies in the useEffects.
export function useFilteredSortedData<T extends object>({
  cacheKey,
  filterPredicates,
  defaultFilters: originalDefaultFilters,
  defaultSort: originalDefaultSort,
  defaultView: originalDefaultView,
  viewOptions,
  records,
}: UseFilteredSortedDataProps<T>) {
  const { defaultView, defaultSort, defaultFilters } = useMemo(
    () => getDefaults(originalDefaultSort, cacheKey, originalDefaultView, originalDefaultFilters),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [viewOption, setViewOption] = useState(defaultView);
  const [filters, setFilters] = useState(defaultFilters ?? {});
  const [sortOption, setSortOption] = useState(defaultSort);
  const [data, setData] = useState(records ?? []);

  function setViewOptionCached(view: ViewOption<T>) {
    setViewOption(view.display);
    setDefaults(cacheKey, view.display, sortOption, filters);
  }

  function setFiltersCached(filters2: FilterChangeEvent) {
    verifyFilter(filters2, filterPredicates);
    setFilters(filters2);
    setDefaults(cacheKey, viewOption, sortOption, filters2);
  }

  function setSortOptionCached(sort: SortOption<T>) {
    setSortOption(sort);
    setDefaults(cacheKey, viewOption, sort, filters);
  }

  useEffect(() => {
    // First we filter out hidden records, then we apply the filters, followed last
    // by filtering out the viewOptions. ViewOptions are last to support "5 most recent"
    // vitals view.
    const filterValues = fixupFilters(filters, filterPredicates);
    let filteredData = filterHidden(records ?? [], filterValues);
    filteredData = applyFilters(filteredData, filterValues);
    const view = viewOptions?.find((option) => option.display === viewOption);
    filteredData = applyFilters(filteredData, view?.filters ?? []);

    const filteredAndSortedData = applySorts(filteredData, sortOption.sorts);
    setData(filteredAndSortedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, sortOption, records, viewOption]);

  return {
    setFilters: setFiltersCached,
    setSort: setSortOptionCached,
    defaultSort,
    viewOption,
    setViewOption: setViewOptionCached,
    defaultView,
    data,
    filters,
    sortOption,
  };
}

function getDefaults<T extends object>(
  defaultSort: SortOption<T>,
  cacheKey?: string,
  defaultView?: string,
  defaultFilters?: FilterChangeEvent,
): {
  defaultView?: string;
  defaultSort: SortOption<T>;
  defaultFilters?: FilterChangeEvent;
} {
  const originals = { defaultView, defaultSort, defaultFilters };
  return getDefaultsFromLocalStorage(cacheKey, originals);
}

function setDefaults<T extends object>(
  cacheKey?: string,
  defaultView?: string,
  defaultSort?: SortOption<T>,
  defaultFilters?: FilterChangeEvent,
) {
  saveToLocalStorage(cacheKey, { defaultView, defaultSort, defaultFilters });
}

// We need to fixup the filters to ensure that the predicate is set correctly.
// Saving to cache drops predicates as they are functions (not serializeable).
// So, we lookup the predicate from the filterPredicates map and set it on the filter.
function fixupFilters(
  filters?: FilterChangeEvent,
  filterPredicates?: PredicateMap<PredicateTypes>,
) {
  const filterValues = compact(Object.values(filters ?? {}));
  if (filters) {
    filterValues.forEach((filter) => {
      if ((filter.type === 'checkbox' || filter.type === 'tag') && filterPredicates?.[filter.key]) {
        // eslint-disable-next-line no-param-reassign
        filter.predicate = filterPredicates[filter.key];
      }
    });
  }

  return filterValues;
}

// Throw a runtime error if we are filtering on a checkbox filter with a predicate
// and that predicate is not in the filterPredicates map.
// This error should help guard developers from setting up predicate based
// filters without passing them in via filterPredicates.
// This is needed to ensure cache works correctly with predicate based filters.
function verifyFilter(
  filters2: FilterChangeEvent,
  filterPredicates?: PredicateMap<PredicateTypes>,
) {
  const values = compact(Object.values(filters2));
  const missingPredicateMap = values
    .filter((filter) => (filter.type === 'checkbox' || filter.type === 'tag') && filter.predicate)
    .filter((filter) => !filterPredicates?.[filter.key]);
  if (missingPredicateMap.length > 0) {
    throw new Error(
      `Missing predicate map for filters: ${missingPredicateMap
        .map((filter) => filter.key)
        .join(',')}`,
    );
  }
}
