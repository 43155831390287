import { ReactNode } from 'react';
import { DocumentHeader } from './ccda-viewer/components/Header/Header';
import { PatientModel } from '@ctw/shared/api/fhir/models';
import { tw } from '@ctw/shared/utils/tailwind';

export const CCDAModalBase = ({
  children,
  onClose,
  actions,
  patient,
  binaryId,
}: {
  children: ReactNode;
  onClose?: () => void;
  actions?: ReactNode;
  patient?: PatientModel;
  binaryId?: string;
}) => (
  <div className={tw`w-full text-lg`}>
    <DocumentHeader
      onClose={onClose}
      actions={actions}
      overridePatient={patient}
      binaryId={binaryId}
    />
    {children}
  </div>
);
