import { Worker } from '@react-pdf-viewer/core';
import { QueryClientProvider } from '@tanstack/react-query';
import { Resource } from 'i18next';
import { PropsWithChildren, useEffect, useMemo } from 'react';
import {
  AuthenticationProvider,
  AuthenticationProviderProps,
} from './authentication/authentication-provider';
import { BulkActionProvider } from './bulk-action-provider';
import { CTWStateContext } from './ctw-context';
import { FeatureFlagProvider } from './feature-flag-provider';
import { TelemetryProvider } from './telemetry/telemetry-provider';
import { ThemeProvider, ThemeProviderProps } from './theme/theme-provider';
import { ToastProvider } from './toast-provider';
import { Env } from './types';
import { ZapPropsProvider } from './zap-props-provider';
import { ZapTabStateProvider } from './zap-tab-provider';
import { OnResourceSaveCallback } from '@ctw/shared/api/fhir/action-helper';
import { queryClient } from '@ctw/shared/utils/request';

export type OnAuthTokenExpiration = (resource: Resource) => void;

export type CTWProviderProps = {
  env: Env;
  builderId?: string;
  enableTelemetry?: boolean;
  ehr?: string;
  featureFlags?: Record<string, boolean>;
  onResourceSave?: OnResourceSaveCallback;
  onAuthTokenExpiration?: OnAuthTokenExpiration;
  resourceSaveHeaders?: Record<string, string>;
  allowSmallBreakpointCCDAViewer?: boolean;
  datadogConfig?: {
    applicationId?: string;
    clientToken?: string;
    service?: string;
    version: string;
  };
} & ThemeProviderProps &
  AuthenticationProviderProps;

declare global {
  interface Window {
    CTWComponentLibrary: {
      version: string;
    };
  }
}

const version = '0.0.0';

/**
 * CTWProvider is required for Zus components to operate and at least one
 * should exist in the app as an ancestor to any component-library React
 * component (we recommend CTWProvider be at the root of your project). In
 * addition to providing a client request context, the CTWProvider also allows
 * for theme configuration and opting into telemetry collection if desired.
 */
export function CTWProvider({
  children,
  env,
  builderId,
  enableTelemetry = false,
  datadogConfig,
  ehr,
  featureFlags,
  locals,
  theme,
  headers,
  authToken,
  authTokenURL,
  onResourceSave,
  onAuthTokenExpiration,
  resourceSaveHeaders,
  allowSmallBreakpointCCDAViewer = false,
}: PropsWithChildren<CTWProviderProps>) {
  useEffect(() => {
    window.CTWComponentLibrary = {
      version,
    };
  }, []);

  const providerState = useMemo(
    () => ({
      env,
      builderId,
      featureFlags,
      onResourceSave,
      resourceSaveHeaders,
      headers,
      allowSmallBreakpointCCDAViewer,
    }),
    [
      env,
      builderId,
      featureFlags,
      onResourceSave,
      resourceSaveHeaders,
      headers,
      allowSmallBreakpointCCDAViewer,
    ],
  );

  return (
    <ThemeProvider theme={theme} locals={locals}>
      <ToastProvider>
        <AuthenticationProvider
          headers={headers}
          authToken={authToken}
          authTokenURL={authTokenURL}
          onAuthTokenExpiration={onAuthTokenExpiration}
        >
          <FeatureFlagProvider env={env}>
            <TelemetryProvider
              env={env}
              builderId={builderId}
              ehr={ehr}
              enableTelemetry={enableTelemetry}
              datadogConfig={datadogConfig}
            >
              <CTWStateContext.Provider value={providerState}>
                <QueryClientProvider client={queryClient}>
                  <ZapTabStateProvider>
                    <BulkActionProvider>
                      {/* https://react-pdf-viewer.dev/docs/basic-usage/ */}
                      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                        <ZapPropsProvider>{children}</ZapPropsProvider>
                      </Worker>
                    </BulkActionProvider>
                  </ZapTabStateProvider>
                </QueryClientProvider>
              </CTWStateContext.Provider>
            </TelemetryProvider>
          </FeatureFlagProvider>
        </AuthenticationProvider>
      </ToastProvider>
    </ThemeProvider>
  );
}
