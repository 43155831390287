import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toggleDismiss } from '@ctw/shared/api/fhir/basic';
import { ConditionModel } from '@ctw/shared/api/fhir/models';
import { TableColumn } from '@ctw/shared/components/table/table-helpers';
import { createOrEditCondition } from '@ctw/shared/content/forms/actions/conditions';
import { ConditionType } from '@ctw/shared/content/forms/types';
import { BulkAction } from '@ctw/shared/content/hooks/use-bulk-action-modal';
import { ZAPSelectedResources } from '@ctw/shared/context/bulk-action-context';
import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { PatientState } from '@ctw/shared/context/patient-context';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { Button } from '@ctw/shared/components/button';

export const bulkDismissConditions: BulkAction<fhir4.Condition, ConditionModel> = {
  type: 'dismiss-bulk',
  action: async (
    patient: PatientState,
    getRequestContext: () => Promise<CTWRequestContext>,
    selectedConditions: ConditionModel[],
  ) => {
    await Promise.all(
      selectedConditions.map(async (condition) => {
        if (condition.preferredCoding && patient.patient.data?.id) {
          await toggleDismiss(condition, await getRequestContext());
        }
      }),
    );
  },
};

export const bulkAddConditions: BulkAction<fhir4.Condition, ConditionModel> = {
  type: 'add',
  action: async (
    patient: PatientState,
    getRequestContext: () => Promise<CTWRequestContext>,
    selected: ConditionModel[],
  ) => {
    await Promise.all(
      selected.map(async (condition) => {
        if (!condition.preferredCoding || !patient.patient.data?.id) {
          return;
        }

        const conditionData = {
          status: condition.displayStatus,
          type: condition.type as ConditionType,
          condition: condition.preferredCoding,
          abatement: condition.abatement ? new Date(condition.abatement) : undefined,
          onset: condition.onset ? new Date(condition.onset) : undefined,
          note: condition.notes.join(''),
        };

        await createOrEditCondition(
          condition,
          patient.patient.data.id,
          conditionData,
          getRequestContext,
        );
      }),
    );
  },
};

export const conditionsBulkActionColumns = (
  selectedResources: ZAPSelectedResources,
  setSelectedResources: (resources: ZAPSelectedResources) => void,
): TableColumn<ConditionModel>[] => [
  {
    title: 'Condition',
    render: (condition) => <div>{condition.display}</div>,
    widthPercent: 35,
  },
  {
    title: 'Onset Date',
    render: (condition) => <div>{condition.onset}</div>,
    widthPercent: 18,
  },
  {
    title: 'Status',
    render: (condition) => <div>{condition.displayStatus}</div>,
    widthPercent: 18,
  },
  {
    title: 'Type',
    render: (condition) => <div>{condition.type}</div>,
    widthPercent: 18,
  },
  {
    title: '',
    render: (condition) => (
      <Button
        type="button"
        variant="link"
        disabled={selectedResources['conditions-all'].length === 1}
        className={twx('di space-x-1', {
          'text-content-lighter hover:text-content-lighter':
            selectedResources['conditions-all'].length === 1,
        })}
        onClick={() => {
          const newSelectedResources = {
            ...selectedResources,
            'conditions-all': selectedResources['conditions-all'].filter(
              (c) => c.id !== condition.id,
            ),
          };
          setSelectedResources(newSelectedResources);
        }}
      >
        <span>
          <FontAwesomeIcon icon={faTrash} className={tw`w-4`} />
        </span>
        <span>Remove</span>
      </Button>
    ),
    widthPercent: 18,
  },
];
