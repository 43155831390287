import { Observation } from 'fhir/r4';
import { gql } from 'graphql-request';
import { fragmentReference } from './fragments';
import { GraphqlConnectionNode, GraphqlPageInfo } from '../client';

export interface VitalConnection {
  pageInfo: GraphqlPageInfo;
  edges: GraphqlConnectionNode<VitalWithProvenance>[];
}

export type VitalWithProvenance = Observation & {
  ProvenanceList: fhir4.Provenance[];
};

export interface VitalGraphqlResponse {
  ObservationConnection: VitalConnection;
}

export const vitalQuery = gql`
  ${fragmentReference}

  query Vitals(
    $upid: ID!
    $cursor: String!
    $sort: ObservationSortParams!
    $filter: ObservationFilterParams!
    $first: Int!
  ) {
    ObservationConnection(
      upid: $upid
      after: $cursor
      filter: $filter
      sort: $sort
      first: $first
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          resourceType
          meta {
            tag {
              system
              code
            }
          }
          encounter {
            reference
          }
          code {
            text
            coding {
              code
              display
              system
              extension {
                url
                valueString
              }
            }
          }
          effectivePeriod {
            start
            end
          }
          effectiveDateTime
          ProvenanceList(_reference: "target") {
            id
            entity {
              what {
                ...Reference
              }
            }
          }
          valueQuantity {
            comparator
            unit
            value
            system
            code
          }
          valueCodeableConcept {
            text
            coding {
              system
              code
              display
            }
          }
          valueString
          valueBoolean
          valueInteger
          valueRange {
            low {
              unit
              value
              system
              code
            }
            high {
              unit
              value
              system
              code
            }
          }
          valueRatio {
            numerator {
              comparator
              unit
              value
              system
              code
            }
            denominator {
              unit
              value
              system
              code
            }
          }
          valueTime
          valueDateTime
          valuePeriod {
            start
            end
          }
        }
      }
    }
  }
`;
