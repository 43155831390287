import { faTrash, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { isString } from 'lodash';
import { isFunction } from 'lodash/fp';
import { ReactNode } from 'react';
import {
  FilterOptionSelect,
  FilterValues,
} from '@ctw/shared/components/filter-bar/filter-bar-types';
import { ListBox, ListBoxOptionStatus } from '@ctw/shared/components/list-box/list-box';
import { MenuItem } from '@ctw/shared/components/_internal/menu-item';
import { tw, twx } from '@ctw/shared/utils/tailwind';

type FilterBarSelectPillProps = {
  filter: FilterOptionSelect;
  filterValues: FilterValues;
  onChange: (key: string, isSelected: boolean) => void;
  onRemove: () => void;
};

const buttonClassName =
  'flex items-center capitalize text-content-black bg-bg-dark text-sm rounded py-2 px-3 relative cursor-pointer border-0 border-transparent whitespace-nowrap mb-2 mr-2';
const renderDisplay = (display: string | ((status: ListBoxOptionStatus) => string | ReactNode)) =>
  isFunction(display) ? display({ active: false }) : display;
const builtInButton = (name: string, key: string, icon: IconDefinition) => ({
  key,
  display: ({ listView, filter }: ListBoxOptionStatus) =>
    listView ? <MenuItem icon={icon}>{name}</MenuItem> : filter && renderDisplay(filter.display),
});
const removeButton = builtInButton('remove filter', '_remove', faTrash);

export function FilterBarSelectPill({
  filter,
  filterValues,
  onChange,
  onRemove,
}: FilterBarSelectPillProps) {
  const filterNames = filter.values.map((value) => (isString(value) ? value : value.name)).sort();
  const items = [...filterNames, removeButton];
  const selectedIndex = typeof filterValues === 'string' ? items.indexOf(filterValues) : -1;

  return (
    <ListBox
      selectedIndex={selectedIndex}
      defaultIndex={items.length - 1} // clear index
      btnClassName={twx(buttonClassName)}
      optionsClassName={tw`capitalize`}
      className={tw`max-h-[17.25rem] overflow-y-auto`}
      items={items.map((item) => ({
        // eslint-disable-next-line react/no-unstable-nested-components
        display: ({ listView }: ListBoxOptionStatus) => {
          if (item === removeButton) {
            return item.display({ listView, filter });
          }
          return listView ?
              <span className={tw`whitespace-nowrap`}>{item as ReactNode}</span>
            : <div className={tw`truncate font-medium text-content-black`}>
                {renderDisplay(filter.display)}
                <span className={tw`font-normal`}>: {item as ReactNode}</span>
              </div>;
        },
        key: isString(item) ? item : item.key,
        className: twx('capitalize', {
          'border border-0 border-t border-solid border-divider-light capitalize':
            item === removeButton,
        }),
      }))}
      onChange={(_index, item) => {
        if (item.key === '_remove') {
          onRemove();
        } else if (item.key === '_reset') {
          onChange('', false);
        } else {
          onChange(item.key, true);
        }
      }}
    />
  );
}
