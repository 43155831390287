import { Resource } from 'fhir/r4';
import { cloneDeep } from 'lodash';
import { fixupFHIR } from './client';
import { isFhirError } from './errors';
import { isFHIRDomainResource } from './types';
import { longPollFQS } from '@ctw/shared/api/fqs/long-poll-fqs';
import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { Telemetry } from '@ctw/shared/utils/telemetry';

type ResourceSaveOperation = 'update' | 'create';
export type OnResourceSaveCallback = (
  resource: Resource,
  action: ResourceSaveOperation,
  error?: Error,
) => void;

export async function createOrEditFhirResource(
  resource: Resource,
  requestContext: CTWRequestContext,
) {
  const updating = !!resource.id;
  const { fhirWriteBackClient } = requestContext;
  const resourceModified = cloneDeep(resource); // Clone to not modify the original resource.
  let action: ResourceSaveOperation = 'create';
  let response: Resource | undefined;

  try {
    if (updating) {
      action = 'update';
      response = await fhirWriteBackClient.update({
        resourceType: resource.resourceType,
        id: resource.id,
        body: fixupFHIR(resource),
        headers: {
          'ctw-write-provenance': 'true',
        },
      });
    } else {
      response = await fhirWriteBackClient.create({
        resourceType: resource.resourceType,
        body: fixupFHIR(resource),
        headers: {
          'ctw-write-provenance': 'true',
        },
      });

      if (!isFhirError(response)) {
        resourceModified.id = response.id;
      }
    }
    requestContext.onResourceSave(resourceModified, action);

    // Read-Your-Writes!
    // Wait for FQS to have the latest version of the resource.
    // This way callers can safely refetch/invalidateQueries
    // and be sure to get fresh data from FQS.
    if (
      resource.resourceType !== 'Basic' &&
      isFHIRDomainResource(response) &&
      response.id &&
      response.meta?.lastUpdated
    ) {
      await longPollFQS(
        requestContext,
        resource.resourceType,
        response.id,
        response.meta.lastUpdated,
      );
    }

    return response;
  } catch (e) {
    Telemetry.logError(e as Error);
    throw e;
  }
}
