import { tw } from '@ctw/shared/utils/tailwind';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';

export interface ContentErrorProps {
  icon?: FontAwesomeIconProps['icon'];
  title: string;
  message?: string;
  children?: ReactNode;
}

export const ContentError = ({ icon, title, message, children }: ContentErrorProps) => (
  <div className={tw`text-xl`}>
    <div className={tw`flex justify-center`}>
      <FontAwesomeIcon icon={icon ?? faExclamationCircle} className={tw`h-16 text-content-light`} />
    </div>
    <div className={tw`space-y-1 text-center text-base`}>
      <div className={tw`text-lg font-medium`}>{title}</div>
      <div>
        {message && <span>{message}</span>}
        <div>{children}</div>
      </div>
    </div>
  </div>
);
