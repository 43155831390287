import { MedicationStatementModel } from '@ctw/shared/api/fhir/models';
import { PatientFormData } from '@ctw/shared/content/forms/actions/patients';
import {
  ZapIFrameReadyMessageType,
  ZapOnAddToRecordMessageType,
  ZapOnConfigReceivedMessageType,
  ZapOnPatientSaveMessageType,
  ZapOnResourceSaveMessageType,
} from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile-iframe';
import { Telemetry } from '@ctw/shared/utils/telemetry';
import type { Resource } from 'fhir/r4';
import { MessageEventPromise } from './message-event-promise';

export function sendOnIFrameReady() {
  window.parent.postMessage(
    {
      type: ZapIFrameReadyMessageType,
    },
    '*',
  );

  Telemetry.logger.debug('sendOnIFrameReady: sent message to parent window');
}

export function sendOnIFrameConfigReceived() {
  window.parent.postMessage(
    {
      type: ZapOnConfigReceivedMessageType,
    },
    '*',
  );
  Telemetry.logger.debug('sendOnIFrameConfigReceived: sent message to parent window');
}

export function sendOnResourceSave(resource: Resource, action: string, error?: Error) {
  const data = {
    error: error?.message,
    resource,
    action,
  };
  window.parent.postMessage(
    {
      type: ZapOnResourceSaveMessageType,
      payload: data,
      // todo: remove deprecated "resource", "action", "error"
      resource,
      action,
      error,
    },
    '*',
  );
  Telemetry.logger.debug('sendOnResourceSave: sent message to parent window', {
    data: {
      resource: {
        resourceType: resource.resourceType,
        id: resource.id,
      },
      action,
      error,
    },
  });
}

export function sendOnPatientSave(patientFormData: PatientFormData) {
  window.parent.postMessage(
    {
      type: ZapOnPatientSaveMessageType,
      payload: patientFormData,
    },
    '*',
  );
  Telemetry.logger.debug('sendOnPatientSave: sent message to parent window');
}

export function sendOnAddToRecord(medStatement: MedicationStatementModel, component: string) {
  const { resource, includedResources } = medStatement;
  // Send an "onResourceSave" message to the parent window and wait for the response.
  const messagePromise = new MessageEventPromise(ZapOnAddToRecordMessageType).sendMessage({
    resource,
    includedResources,
    component,
  });

  Telemetry.logger.debug('sendOnAddToRecord: sent message to parent window', {
    data: {
      resource: {
        resourceType: resource.resourceType,
        id: resource.id,
      },
    },
  });

  return messagePromise;
}
