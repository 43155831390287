import { ZapIFrameConfig } from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile-iframe';
import { createContext } from 'react';

export type ZapConfigContextProps = {
  zapConfig?: ZapIFrameConfig;
  setZapConfig: (config: ZapIFrameConfig) => void;
};

export const ZapConfigContext = createContext<ZapConfigContextProps>({
  setZapConfig: () => {},
});
