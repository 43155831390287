import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';
import { CTWRequestContext } from './ctw-context';
import { OverlayProvider } from './overlay-provider';
import { PatientContext, PatientState } from './patient-context';
import { useCTW } from './use-ctw';
import { PatientModel } from '@ctw/shared/api/fhir/models/patient';
import {
  getBuilderFhirPatientByIdentifier,
  getPatientByID,
} from '@ctw/shared/api/fhir/patient-helper';
import { SYSTEM_ZUS_UNIVERSAL_ID } from '@ctw/shared/api/fhir/system-urls';
import { Tag } from '@ctw/shared/api/fhir/types';
import { PatientFormData } from '@ctw/shared/content/forms/actions/patients';
import { useTimerQueryWithCTW } from '@ctw/shared/context/use-query-with-ctw';
import { useTriggerBackfillRequest } from '@ctw/shared/hooks/use-trigger-backfill-request';
import { useTriggerLensOnDemandJob } from '@ctw/shared/hooks/use-trigger-lens-on-demand-job';
import { QUERY_KEY_PATIENT } from '@ctw/shared/utils/query-keys';
import { retryWithExponentialBackoff } from '@ctw/shared/utils/request';
import { Telemetry, withTimerMetric } from '@ctw/shared/utils/telemetry';

// Cache patient for 5 minutes.
const PATIENT_STALE_TIME = 1000 * 60 * 5;

export type ThirdPartyID = {
  patientResourceID?: never;
  patientUPID?: never;
  patientID: string;
  systemURL: string;
};

export type PatientUPIDSpecified = {
  patientResourceID?: never;
  patientUPID: string;
  patientID?: never;
  systemURL?: never;
};

export type PatientResourceID = {
  patientResourceID: string;
  patientUPID?: never;
  patientID?: never;
  systemURL?: never;
};

export type PatientProviderProps = {
  children: ReactNode;
  tags?: Tag[];
  onPatientSave?: (data: PatientFormData) => void;
  onResourceSave?: (data: fhir4.Resource, action: 'create' | 'update') => void;
} & (ThirdPartyID | PatientUPIDSpecified | PatientResourceID);

export function PatientProvider({
  children,
  patientResourceID,
  patientUPID,
  patientID,
  systemURL,
  tags,
  onPatientSave,
  onResourceSave,
}: PatientProviderProps) {
  const patient = usePatient(
    patientUPID || patientID,
    patientUPID ? SYSTEM_ZUS_UNIVERSAL_ID : systemURL,
    patientResourceID,
    tags,
  );
  useTriggerBackfillRequest(systemURL, patientID);
  useTriggerLensOnDemandJob('encounters', patient.data?.UPID);
  useTriggerLensOnDemandJob('medications', patient.data?.UPID);
  useTriggerLensOnDemandJob('conditions', patient.data?.UPID);

  const providerState = useMemo(
    () => ({
      patientResourceID,
      patientID: patientUPID || patientID,
      systemURL: patientUPID ? SYSTEM_ZUS_UNIVERSAL_ID : systemURL,
      tags,
      onPatientSave,
      onResourceSave,
      patient,
    }),
    [
      patientResourceID,
      patientID,
      patientUPID,
      systemURL,
      tags,
      onPatientSave,
      onResourceSave,
      patient,
    ],
  );

  useEffect(() => {
    if (patientID && systemURL) {
      Telemetry.setPatientID(patientID, systemURL);
    } else if (patientResourceID) {
      Telemetry.setPatientID(patientResourceID);
    }
  }, [patientID, patientResourceID, systemURL]);

  return (
    <PatientContext.Provider value={providerState as PatientState}>
      <OverlayProvider>{children}</OverlayProvider>
    </PatientContext.Provider>
  );
}

export function usePatientContext() {
  const context = useContext(PatientContext);
  if (!context) {
    throw new Error('usePatient must be used within a PatientProvider');
  }

  return context;
}

export function usePatient(
  patientID?: string,
  systemURL?: string,
  patientResourceID?: string,
  tags?: Tag[],
): UseQueryResult<PatientModel> {
  return useTimerQueryWithCTW(
    QUERY_KEY_PATIENT,
    [patientResourceID, patientID, systemURL, tags],
    (requestContext: CTWRequestContext) =>
      handleGetPatient(requestContext, patientResourceID, systemURL, patientID, tags),
    !!patientID || !!patientResourceID,
    PATIENT_STALE_TIME,
  );
}

export async function handleGetPatient(
  requestContext: CTWRequestContext,
  patientResourceID?: string,
  systemURL?: string,
  patientID?: string,
  tags?: Tag[],
) {
  const patientIdUsed = systemURL && patientID ? `${systemURL}|${patientID}` : patientResourceID;
  try {
    let patient: PatientModel;
    if (systemURL && patientID) {
      // retry a few times with exponential backoff
      // in case the patient is not yet available due to being backfilled
      patient = await retryWithExponentialBackoff(
        () => getBuilderFhirPatientByIdentifier(requestContext, patientID, systemURL, tags),
        5,
        2_000,
      );
    } else if (patientResourceID) {
      patient = await getPatientByID(requestContext, patientResourceID);
    } else {
      throw new Error(
        'Must specify a patient ID and system URL or a patient FHIR resource ID to retrieve a patient.',
      );
    }

    if (patient.active === false) {
      void Telemetry.logger.warn(`User accessing inactive patient: ${patientIdUsed}`);
    }

    return patient;
  } catch (e) {
    const msg = `Failed to get patient with ID ${patientIdUsed}`;
    void Telemetry.logError(e instanceof Error ? e : new Error(msg), msg);
    throw e;
  }
}

export function usePatientPromise() {
  const { patientResourceID, patientID, systemURL, tags } = usePatientContext();

  const getPatient = useCallback(
    async (requestContext: CTWRequestContext) => {
      const patientIdUsed =
        systemURL && patientID ? `${systemURL}|${patientID}` : patientResourceID;
      try {
        let patient: PatientModel;
        if (systemURL && patientID) {
          patient = await getBuilderFhirPatientByIdentifier(
            requestContext,
            patientID,
            systemURL,
            tags,
          );
        } else if (patientResourceID) {
          patient = await getPatientByID(requestContext, patientResourceID);
        } else {
          throw new Error(
            'Must specify a patient ID and system URL or a patient FHIR resource ID to retrieve a patient.',
          );
        }

        if (patient.active === false) {
          void Telemetry.logger.warn(`User accessing inactive patient: ${patientIdUsed}`);
        }

        return patient;
      } catch (e) {
        const msg = `Failed to get patient with ID ${patientIdUsed}`;
        void Telemetry.logError(e instanceof Error ? e : new Error(msg), msg);
        throw e;
      }
    },
    [patientResourceID, patientID, systemURL, tags],
  );

  return { patientResourceID, patientID, systemURL, tags, getPatient };
}

function useQueryWithPatient<T, T2>(
  queryKey: string,
  keys: T2[],
  query: (requestContext: CTWRequestContext, patient: PatientModel, keys?: T2[]) => Promise<T>,
  enabled = true,
) {
  const { getRequestContext } = useCTW();
  const { patient } = usePatientContext();

  if (patient.isSuccess && !patient.data.UPID) {
    const patientID = patient.data.resource.identifier;
    Telemetry.logger.error('Patient does not have a UPID', { patientID });
    throw new Error(`Patient with ID ${JSON.stringify(patientID)} does not have a UPID`);
  }

  return useQuery({
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    queryKey: [queryKey, patient.data?.UPID, ...keys],
    queryFn: async () => {
      const requestContext = await getRequestContext();
      // Ignore eslint warning as we should always have a valid
      // patient thanks to the enabled check.
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return query(requestContext, patient.data!, keys);
    },
    enabled: !!patient.data?.UPID && enabled,
  });
}

export function useTimingQueryWithPatient<T, T2>(
  queryKey: string,
  keys: T2[],
  query: (requestContext: CTWRequestContext, patient: PatientModel, keys?: T2[]) => Promise<T>,
  enabled = true,
) {
  return useQueryWithPatient(
    queryKey,
    keys,
    withTimerMetric(query, 'query_request_timing', [`query_key:${queryKey}`]),
    enabled,
  );
}
