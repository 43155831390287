import { fetchAppointments } from '@ctw/shared/api/fhir/appointments';
import { getDocumentsFromFQS } from '@ctw/shared/api/fhir/document';
import { getEncountersWithClinicalNotes } from '@ctw/shared/api/fhir/encounters';
import { PatientModel } from '@ctw/shared/api/fhir/models';
import { ReferralModel } from '@ctw/shared/api/fhir/models/referral';
import {
  createGraphqlClient,
  fqsRequestAll,
  MAX_OBJECTS_PER_REQUEST,
} from '@ctw/shared/api/fqs/client';
import {
  ServiceRequestGraphqlResponse,
  ServiceRequestQuery,
} from '@ctw/shared/api/fqs/queries/service-requests';
import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import {
  findRelatedAppointment,
  findRelatedEncounter,
} from '@ctw/shared/hooks/use-patient-referrals';

export async function fetchReferralsByIdFQS(
  requestContext: CTWRequestContext,
  patient: PatientModel,
  ids: string[],
) {
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequestAll<ServiceRequestGraphqlResponse>(
    graphClient,
    ServiceRequestQuery,
    'ServiceRequestConnection',
    {
      upid: patient.UPID,
      cursor: '',
      first: MAX_OBJECTS_PER_REQUEST,
      sort: {},
      filter: {
        ids: {
          anymatch: ids,
        },
      },
    },
  );

  const appointments = await fetchAppointments(requestContext, patient);
  const encounters = await getEncountersWithClinicalNotes(requestContext, patient);
  const documents = await getDocumentsFromFQS(requestContext, patient, [MAX_OBJECTS_PER_REQUEST]);

  return data.ServiceRequestConnection.edges
    .map((x) => x.node)
    .map((serviceRequest) => {
      const relatedAppointment = findRelatedAppointment(serviceRequest, appointments);
      const relatedEncounter =
        relatedAppointment ?
          findRelatedEncounter(
            relatedAppointment,
            encounters.map((encounterModel) => encounterModel.resource),
          )
        : undefined;

      return new ReferralModel(
        serviceRequest,
        patient,
        relatedAppointment,
        relatedEncounter,
        documents,
      );
    });
}
