import { isEqual, uniqWith } from 'lodash';
import { useEffect, useState } from 'react';
import { createTrendDataMap } from './data-helper/diagnostic-report-trends';
import { SYSTEM_SUMMARY } from './system-urls';
import { DiagnosticReportModel, PatientModel } from '@ctw/shared/api/fhir/models';
import {
  createGraphqlClient,
  fqsRequestAll,
  MAX_OBJECTS_PER_REQUEST,
} from '@ctw/shared/api/fqs/client';
import {
  DiagnosticReportGraphqlResponse,
  getDiagnosticReportQuery,
} from '@ctw/shared/api/fqs/queries/diagnostic-reports-query';
import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { useTimingQueryWithPatient } from '@ctw/shared/context/patient-provider';
import { QUERY_KEY_PATIENT_DIAGNOSTIC_REPORTS } from '@ctw/shared/utils/query-keys';

// Gets diagnostic reports for the patient with an option to include observations, though be aware that
// including observations increases query time.
export function usePatientDiagnosticReports(
  limit = MAX_OBJECTS_PER_REQUEST,
  includeObservations = false,
  enabled = true,
) {
  return useTimingQueryWithPatient(
    QUERY_KEY_PATIENT_DIAGNOSTIC_REPORTS,
    [limit, includeObservations], // Only use the IDs in our key (fixes issue with ciruclar references).
    getDiagnosticReports,
    enabled,
  );
}

// Gets diagnostic reports for the patient with trending data for each observation in the diagnostic report.
// Each observation in the trend also includes a link back to its parent diagnostic report.
// This is an expensive operation that consumes a lot of memory!
export function usePatientDiagnosticReportsWithTrendData(limit = MAX_OBJECTS_PER_REQUEST) {
  const diagnosticReportsQuery = usePatientDiagnosticReports(limit, true);
  const [diagnosticReportsWithTrends, setDiagnosticReportsWithTrends] = useState<
    DiagnosticReportModel[]
  >([]);

  useEffect(() => {
    const diagnosticReports = diagnosticReportsQuery.data;

    if (diagnosticReportsQuery.isLoading) {
      return;
    }

    const valuesToDedupeOn = (dr: DiagnosticReportModel) => [dr.displayName, dr.effectiveStart];
    const uniqueDiagnosticReports = uniqWith(diagnosticReports, (a, b) =>
      isEqual(valuesToDedupeOn(a), valuesToDedupeOn(b)),
    );

    const observations = uniqueDiagnosticReports.flatMap((dr) => dr.observations);
    const trendDataMap = createTrendDataMap(observations);

    observations.forEach((o) => {
      o.setTrends(trendDataMap);
    });

    setDiagnosticReportsWithTrends(uniqueDiagnosticReports);
  }, [diagnosticReportsQuery.data, diagnosticReportsQuery.isLoading]);

  const { isLoading } = diagnosticReportsQuery;
  const { isError } = diagnosticReportsQuery;
  const { isFetching } = diagnosticReportsQuery;

  return {
    isLoading,
    isError,
    isFetching,
    data: diagnosticReportsWithTrends,
  };
}

async function getDiagnosticReports(
  requestContext: CTWRequestContext,
  patient: PatientModel,
  keys: (number | boolean)[] = [],
) {
  const [limit, includeObservations] = keys as [number, boolean];

  const data = await fetchDiagnosticReportsFromFQS(
    requestContext,
    patient,
    limit,
    includeObservations,
  );
  const result = data.DiagnosticReportConnection.edges.map(
    (x) => new DiagnosticReportModel(x.node, undefined, x.node.BasicList),
  );

  return result;
}

async function fetchDiagnosticReportsFromFQS(
  requestContext: CTWRequestContext,
  patient: PatientModel,
  limit: number,
  includeObservations: boolean,
) {
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequestAll<DiagnosticReportGraphqlResponse>(
    graphClient,
    getDiagnosticReportQuery(includeObservations),
    'DiagnosticReportConnection',
    {
      upid: patient.UPID,
      cursor: 'DiagnosticReportConnection',
      filter: {
        tag: { nonematch: [SYSTEM_SUMMARY] },
      },
      first: limit,
    },
  );
  return data;
}

export async function fetchDiagnosticReportsFromFQSById(
  requestContext: CTWRequestContext,
  patient: PatientModel,
  ids: string[] = [],
) {
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequestAll<DiagnosticReportGraphqlResponse>(
    graphClient,
    getDiagnosticReportQuery(true),
    'DiagnosticReportConnection',
    {
      upid: patient.UPID,
      cursor: '',
      first: 1000,
      filter: {
        ids: { anymatch: ids },
        tag: { nonematch: [SYSTEM_SUMMARY] },
      },
    },
  );
  return data.DiagnosticReportConnection.edges.map(
    (x) => new DiagnosticReportModel(x.node, undefined, x.node.BasicList),
  );
}

export async function fetchDiagnosticReportsWithTrendsFromFQSById(
  requestContext: CTWRequestContext,
  patient: PatientModel,
  ids: string[] = [],
) {
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequestAll<DiagnosticReportGraphqlResponse>(
    graphClient,
    getDiagnosticReportQuery(true),
    'DiagnosticReportConnection',
    {
      upid: patient.UPID,
      cursor: '',
      first: 1000,
      filter: {
        ids: { anymatch: ids },
        tag: { nonematch: [SYSTEM_SUMMARY] },
      },
    },
  );
  const diagnosticReports = data.DiagnosticReportConnection.edges.map(
    (x) => new DiagnosticReportModel(x.node, undefined, x.node.BasicList),
  );

  const valuesToDedupeOn = (dr: DiagnosticReportModel) => [dr.displayName, dr.effectiveStart];
  const uniqueDiagnosticReports = uniqWith(diagnosticReports, (a, b) =>
    isEqual(valuesToDedupeOn(a), valuesToDedupeOn(b)),
  );

  const observations = uniqueDiagnosticReports.flatMap((dr) => dr.observations);
  const trendDataMap = createTrendDataMap(observations);

  observations.forEach((o) => {
    o.setTrends(trendDataMap);
  });

  return uniqueDiagnosticReports;
}
