export class RequestError extends Error {
  statusCode: number;

  statusText: string;

  requestInfo: Response;

  stack: string | undefined;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(response: Response, ...params: any[]) {
    super(...params);

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, RequestError);
    }

    this.name = 'RequestError';
    this.statusCode = response.status;
    this.statusText = response.statusText;
    this.requestInfo = response;
  }
}
