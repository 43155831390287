import { useState } from 'react';
import { FeedbackGroup } from './feedback-group';
import { FeedbackRequest } from '@ctw/shared/api/ai/types';
import { ClassName, tw } from '@ctw/shared/utils/tailwind';
import { Heading } from '@ctw/shared/components/heading';
import './feedback.scss';

export interface FeedbackProps {
  className?: ClassName;
  initialFeedback: FeedbackRequest;
  onFeedbackUpdate: (feedback: FeedbackRequest) => void;
}

export const FeedbackComponent = ({ initialFeedback, onFeedbackUpdate }: FeedbackProps) => {
  const [feedback, setFeedback] = useState(initialFeedback);

  const handleFeedbackChange =
    (field: keyof FeedbackRequest) => (event: { target: { value: string } }) => {
      const { value } = event.target;

      // Compute updated feedback object
      const updatedFeedback = {
        ...feedback,
        [field]: field.endsWith('Rating') ? Number(value) : value,
      };

      // Update state and trigger the callback with the updated feedback
      setFeedback(updatedFeedback);
      onFeedbackUpdate(updatedFeedback);
    };

  const usefulnessOptions = [
    'Not useful at all',
    'Somewhat useful',
    'Mostly useful',
    'Very useful',
  ];

  return (
    <div className={tw`ai-feedback-form`}>
      <div>
        <Heading level="h4" className={tw`py-2 text-xs uppercase text-content-light`}>
          Share your feedback
        </Heading>
      </div>

      <FeedbackGroup
        options={usefulnessOptions}
        title="How useful was this summary at getting you up to speed on this patient?"
        radioGroupName="usefulnessRating"
        onChange={(score) =>
          handleFeedbackChange('usefulnessRating')({
            target: { value: score.toString() },
          })
        }
      />
      <div>
        <Heading level="h3">Please provide any details you think might be helpful</Heading>
        <textarea
          className={tw`listbox-textarea w-full resize-none whitespace-pre-wrap`}
          value={feedback.usefulnessFeedback}
          name="accuracy_textarea"
          onChange={handleFeedbackChange('usefulnessFeedback')}
          placeholder="Type your answer here"
        />
      </div>
      <div>
        <Heading level="h3">Do you have any other feedback you like to share?</Heading>
        <textarea
          className={tw`listbox-textarea w-full resize-none whitespace-pre-wrap`}
          value={feedback.otherFeedback}
          onChange={handleFeedbackChange('otherFeedback')}
          placeholder="Type your answer here"
        />
      </div>
    </div>
  );
};
