import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FilterItem } from '@ctw/shared/components/filter-bar/filter-bar-types';

export const dismissFilter: FilterItem = {
  key: 'showHidden', // Special key for filtering. See utils/filters.ts.
  type: 'tag',
  belowDivider: true,
  display: ({ listView }) => (listView ? 'show dismissed records' : 'dismissed records'),
  icon: faEye,
  toggleDisplay: 'hide dismissed records',
  toggleIcon: faEyeSlash,
};
