import DOMPurify from 'dompurify';
import { Interweave } from 'interweave';
import { DocumentModel } from '@ctw/shared/api/fhir/models';
import { tw } from '@ctw/shared/utils/tailwind';

export type NotesProps = {
  entries: DocumentModel[];
};

export function getNoteDisplay(noteText: string | undefined) {
  if (noteText === undefined) {
    return undefined;
  }
  const cleanNote = DOMPurify.sanitize(noteText);

  return (
    <div className={tw`overflow-auto`}>
      <Interweave content={cleanNote} />
    </div>
  );
}
