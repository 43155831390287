import { UseQueryResult } from '@tanstack/react-query';
import { createContext } from 'react';
import { PatientModel } from '@ctw/shared/api/fhir/models';
import { Tag } from '@ctw/shared/api/fhir/types';
import { PatientFormData } from '@ctw/shared/content/forms/actions/patients';

export type PatientState = {
  patientResourceID?: string;
  patientID?: string;
  systemURL?: string;
  tags?: Tag[];
  patient: UseQueryResult<PatientModel>;
  onPatientSave?: (data: PatientFormData) => Promise<void>;
  onResourceSave?: (data: fhir4.Resource, action: 'create' | 'update') => void;
};

export const PatientContext = createContext<PatientState | undefined>(undefined);
