import { ReactNode } from 'react';
import { tw } from '@ctw/shared/utils/tailwind';

export const TableFullLengthRow = ({
  children,
  colSpan,
}: {
  children: ReactNode;
  colSpan: number;
}) => (
  <tr>
    <td className={tw`table-full-length-row`} colSpan={colSpan}>
      {children}
    </td>
  </tr>
);
