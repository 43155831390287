import { createContext } from 'react';
import { ConditionModel } from '@ctw/shared/api/fhir/models';

// TODO: Enforce that the keys of ZAPSelectedResources are ZAPTabName
export type ZAPSelectedResources = {
  'conditions-all': ConditionModel[];
};

export type BulkActionContextType = {
  selectedResources: ZAPSelectedResources;
  setSelectedResources: (resources: ZAPSelectedResources) => void;
};

export const BulkActionContext = createContext<BulkActionContextType>({
  selectedResources: {
    'conditions-all': [],
  },
  setSelectedResources: () => {},
});
