import { memo, ReactNode, useMemo, useState } from 'react';
import { ZapTabStateContext } from './zap-tab-state-context';
import { ZAPTabName } from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile';

interface ZapTabStateProviderProps {
  children: ReactNode;
}

export const ZapTabStateProvider = memo(({ children }: ZapTabStateProviderProps) => {
  const [selectedZapTabIndex, setSelectedZapTabIndex] = useState(0);
  const [tabOrder, setTabOrder] = useState<ZAPTabName[]>([]);

  const value = useMemo(
    () => ({
      selectedZapTabIndex,
      setSelectedTab: (tabName: number | ZAPTabName) => {
        const index = typeof tabName === 'number' ? tabName : tabOrder.indexOf(tabName);
        setSelectedZapTabIndex(index);
      },
      tabOrder,
      setTabOrder,
    }),
    [selectedZapTabIndex, setSelectedZapTabIndex, tabOrder, setTabOrder],
  );
  return <ZapTabStateContext.Provider value={value}>{children}</ZapTabStateContext.Provider>;
});
