import { FileIcon } from '@ctw/shared/components/icons/file';
import { ImageIcon } from '@ctw/shared/components/icons/image';
import { PdfIcon } from '@ctw/shared/components/icons/pdf';
import { twx } from '@ctw/shared/utils/tailwind';

type ContentTypeIconProps = {
  className?: string;
  contentType: string;
};

const IMAGE_RE = /^image\//i;
const PDF_RE = /^application\/.*(pdf|fdf)$/i;

export function ContentTypeIcon({
  contentType,
  className = 'fill-current w-4 h-4',
}: ContentTypeIconProps) {
  if (IMAGE_RE.test(contentType)) {
    return <ImageIcon className={twx(className)} />;
  }
  if (PDF_RE.test(contentType)) {
    return <PdfIcon className={twx(className)} />;
  }
  return <FileIcon className={twx(className)} />;
}
