import { filter, some } from 'lodash';
import { ResourceBuilderInfo } from '@ctw/shared/api/fhir/summary';
import { SYSTEM_ZUS_OWNER } from '@ctw/shared/api/fhir/system-urls';

export const filterResourcesByBuilderId = <T extends ResourceBuilderInfo>(
  resources: T[],
  builderId: string,
) =>
  filter(resources, (record) =>
    some(record.meta?.tag, {
      system: SYSTEM_ZUS_OWNER,
      code: `builder/${builderId}`,
    }),
  );
