import { MinRecordItem, TableColumn } from './table-helpers';
import { twx } from '@ctw/shared/utils/tailwind';

export type TableColGroupProps<T extends MinRecordItem> = {
  columns: TableColumn<T>[];
};

export const TableColGroup = <T extends MinRecordItem>({ columns }: TableColGroupProps<T>) => (
  <colgroup>
    {columns.map((column, index) => (
      <col
        key={column.title ?? index}
        className={twx(column.className)}
        width={column.widthPercent ? `${column.widthPercent}%` : undefined}
        // eslint-disable-next-line no-restricted-syntax
        style={{
          minWidth: column.minWidth,
          width: column.width,
        }}
      />
    ))}
  </colgroup>
);
