import { UseQueryResult } from '@tanstack/react-query';
import { getZusServiceUrl } from '@ctw/shared/api/urls';
import { useTimerQueryWithCTW } from '@ctw/shared/context/use-query-with-ctw';
import { QUERY_KEY_ON_DEMAND_LENS_API } from '@ctw/shared/utils/query-keys';
import { ctwFetch } from '@ctw/shared/utils/request';

type LensType = 'conditions' | 'encounters' | 'medications' | 'transitions';

type LensOnDemandJobApiResponse = {
  data: {
    type: 'job';
    id: string;
    attributes: {
      status: string;
    };
  };
  meta: {
    traceId: string;
  };
};

export function useTriggerLensOnDemandJob(
  type: LensType,
  upid?: string,
): UseQueryResult<LensOnDemandJobApiResponse> {
  const enabledMap: Record<LensType, boolean> = {
    conditions: true,
    encounters: true,
    medications: true,
    transitions: false,
  };

  return useTimerQueryWithCTW(
    QUERY_KEY_ON_DEMAND_LENS_API,
    [type, upid],
    async (requestContext) => {
      const { data } = (await ctwFetch(`${getZusServiceUrl(requestContext.env, 'lens')}/jobs`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${requestContext.authToken}`,
        },
        body: JSON.stringify({
          upid,
          type,
        }),
        mode: 'cors',
      })) as { data: LensOnDemandJobApiResponse };

      return data;
    },
    Boolean(enabledMap[type] && upid),
  );
}
