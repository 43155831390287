import { parseISO } from 'date-fns';
import { orderBy } from 'lodash';
import { usePatientEncounters } from './use-encounters';
import { fetchAppointments } from '@ctw/shared/api/fhir/appointments';
import { usePatientDocuments } from '@ctw/shared/api/fhir/document';
import { ReferralModel } from '@ctw/shared/api/fhir/models/referral';
import { fetchServiceRequests } from '@ctw/shared/api/fhir/service-requests';
import { usePatientContext, useTimingQueryWithPatient } from '@ctw/shared/context/patient-provider';
import {
  QUERY_KEY_PATIENT_APPOINTMENTS,
  QUERY_KEY_PATIENT_SERVICE_REQUESTS,
} from '@ctw/shared/utils/query-keys';
import { UseQueryResultBasic } from '@ctw/shared/utils/request';

const isSameDay = (dateA: Date, dateB: Date): boolean =>
  dateA.getDate() === dateB.getDate() &&
  dateA.getMonth() === dateB.getMonth() &&
  dateA.getFullYear() === dateB.getFullYear();

export const findRelatedAppointment = (
  serviceRequest: fhir4.ServiceRequest,
  appointments: fhir4.Appointment[],
): fhir4.Appointment | undefined =>
  appointments.find(
    (appointment) =>
      appointment.basedOn?.some(
        (basedOn) => basedOn.reference === `ServiceRequest/${serviceRequest.id}`,
      ),
  );

export const findRelatedEncounter = (
  appointment: fhir4.Appointment,
  encounters: fhir4.Encounter[],
): fhir4.Encounter | undefined =>
  encounters.find(
    (encounter) =>
      appointment.start &&
      encounter.period?.start &&
      isSameDay(parseISO(encounter.period.start), parseISO(appointment.start)),
  );

export const usePatientReferrals = (): UseQueryResultBasic<ReferralModel[]> => {
  const { patient } = usePatientContext();
  const serviceRequestsQuery = useTimingQueryWithPatient(
    QUERY_KEY_PATIENT_SERVICE_REQUESTS,
    [],
    fetchServiceRequests,
  );
  const appointmentsQuery = useTimingQueryWithPatient(
    QUERY_KEY_PATIENT_APPOINTMENTS,
    [],
    fetchAppointments,
  );
  const documentsQuery = usePatientDocuments();
  const encountersQuery = usePatientEncounters();

  if (
    serviceRequestsQuery.isLoading ||
    patient.isLoading ||
    appointmentsQuery.isLoading ||
    documentsQuery.isLoading ||
    encountersQuery.isLoading
  ) {
    return {
      isLoading: true,
      data: [],
    };
  }

  const referrals =
    patient.data === undefined ?
      []
    : (serviceRequestsQuery.data || [])
        .filter((serviceRequest) => serviceRequest.status === 'active')
        .map((serviceRequest) => {
          const relatedAppointment = findRelatedAppointment(
            serviceRequest,
            appointmentsQuery.data || [],
          );
          const relatedEncounter =
            relatedAppointment ?
              findRelatedEncounter(
                relatedAppointment,
                encountersQuery.data.map((encounterModel) => encounterModel.resource),
              )
            : undefined;

          return new ReferralModel(
            serviceRequest,
            patient.data,
            relatedAppointment,
            relatedEncounter,
            documentsQuery.data,
          );
        });

  return {
    isLoading: false,
    data: orderBy(
      referrals,
      [(referral) => referral.status, (referral) => referral.referredAt],
      ['desc', 'desc'],
    ),
  };
};
