import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { ctwFetch } from '@ctw/shared/utils/request';
import { Telemetry } from '@ctw/shared/utils/telemetry';
import { getZusApiBaseUrl } from './urls';

export type PatientHistoryResponseError = {
  // TODO: Can code be a list of status codes? Do we have that type defined anywhere.
  code: number;
  title: string;
  details: string;
};

export const schedulePatientHistory = async (
  requestContext: CTWRequestContext,
  resultData: { id: string; npi: string; role: string; name: string },
) => {
  const endpointUrl = `${getZusApiBaseUrl(
    requestContext.env,
    // If patientID is empty, just pass any non-identifying string in url.
  )}/patient-history/jobs`;

  const body = {
    data: {
      type: 'patient-history/jobs',
      attributes: {
        requestConsent: true,
        priority: true,
        practitioner: {
          npi: resultData.npi,
          name: resultData.name,
          role: resultData.role.toLocaleLowerCase(),
        },
      },
      relationships: {
        patient: {
          data: { type: 'fhir/Patient', id: resultData.id },
        },
      },
    },
  };

  try {
    const { data } = await ctwFetch(endpointUrl, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${requestContext.authToken}`,
        'Zus-Account': requestContext.builderId,
      },
      body: JSON.stringify(body),
    });

    return await data;
  } catch (e) {
    const err = e as Error;
    const errorMessage = `Error requesting patient history.`;
    Telemetry.logError(err, errorMessage);
    return new Error(err.message);
  }
};
