import { EncounterModel, PatientModel } from './models';
import {
  SYSTEM_SUMMARY,
  SYSTEM_ZUS_OWNER,
  SYSTEM_ZUS_SUMMARY,
  SYSTEM_ZUS_THIRD_PARTY,
} from './system-urls';
import { filterResourcesByBuilderId } from '../common';
import { getLensBuilderId } from '../urls';
import { getDocumentsFromFQS } from '@ctw/shared/api/fhir/document';
import {
  createGraphqlClient,
  fqsRequestAll,
  MAX_OBJECTS_PER_REQUEST,
} from '@ctw/shared/api/fqs/client';
import { EncounterGraphqlResponse, encountersQuery } from '@ctw/shared/api/fqs/queries/encounters';
import { dedupeAndMergeEncounters } from '@ctw/shared/content/encounters/helpers/filters';
import { CTWRequestContext } from '@ctw/shared/context/ctw-context';

export async function getEncountersWithClinicalNotes(
  requestContext: CTWRequestContext,
  patient: PatientModel,
) {
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequestAll<EncounterGraphqlResponse>(
    graphClient,
    encountersQuery,
    'EncounterConnection',
    {
      upid: patient.UPID,
      cursor: '',
      first: MAX_OBJECTS_PER_REQUEST,
      sort: {},
    },
  );

  const documents = await getDocumentsFromFQS(requestContext, patient, [MAX_OBJECTS_PER_REQUEST]);

  const results = data.EncounterConnection.edges
    .map((x) => x.node)
    .map((c) => new EncounterModel(c, c.ProvenanceList, undefined, c.BasicList));

  results.forEach((e) => e.setClinicalNotesFromDocumentPool(documents));

  return dedupeAndMergeEncounters(results);
}

export async function getEncountersWithClinicalNotesById(
  requestContext: CTWRequestContext,
  patient: PatientModel,
  ids: string[],
) {
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequestAll<EncounterGraphqlResponse>(
    graphClient,
    encountersQuery,
    'EncounterConnection',
    {
      upid: patient.UPID,
      cursor: '',
      first: MAX_OBJECTS_PER_REQUEST,
      sort: {},
      filter: {
        ids: {
          anymatch: ids,
        },
      },
    },
  );

  const documents = await getDocumentsFromFQS(requestContext, patient, [MAX_OBJECTS_PER_REQUEST]);

  const results = data.EncounterConnection.edges
    .map((x) => x.node)
    .map((c) => new EncounterModel(c, c.ProvenanceList, undefined, c.BasicList));

  results.forEach((e) => e.setClinicalNotesFromDocumentPool(documents));

  return dedupeAndMergeEncounters(results);
}

// TODO: Remove this function once the Encounter lens is tested.
export function getNonLensEncountersFQS(limit: number, ids?: string[]) {
  return async (requestContext: CTWRequestContext, patient: PatientModel) => {
    // Skip querying for an empty set of ids.
    // This fixes an issue with FQS where it is an error to do an anymatch with an empty array.
    if (ids !== undefined && ids.length === 0) {
      return [];
    }
    const graphClient = createGraphqlClient(requestContext);
    const { data } = await fqsRequestAll<EncounterGraphqlResponse>(
      graphClient,
      encountersQuery,
      'EncounterConnection',
      {
        upid: patient.UPID,
        cursor: '',
        first: limit,
        // Optionally filter down to a specific set of encounters.
        filter: {
          ...(ids ?
            {
              ids: { anymatch: ids },
            }
          : {}),
          tag: { nonematch: [SYSTEM_SUMMARY] },
        },
      },
    );
    const results = data.EncounterConnection.edges
      .map((x) => x.node)
      .map((c) => new EncounterModel(c, c.ProvenanceList, undefined, c.BasicList));
    return dedupeAndMergeEncounters(results);
  };
}

export function getBuilderEncountersFQS(limit: number) {
  return async (requestContext: CTWRequestContext, patient: PatientModel) => {
    const graphClient = createGraphqlClient(requestContext);
    const { data } = await fqsRequestAll<EncounterGraphqlResponse>(
      graphClient,
      encountersQuery,
      'EncounterConnection',
      {
        upid: patient.UPID,
        cursor: '',
        first: limit,
        filter: {
          tag: {
            nonematch: [SYSTEM_SUMMARY, SYSTEM_ZUS_THIRD_PARTY],
            // TODO: There's a bug in FQS that doesn't allow filtering with nonematch AND allmatch.
            // Uncomment the line below once https://zeushealth.atlassian.net/browse/DRT-249 is resolved.
            // allmatch: [`${SYSTEM_ZUS_OWNER}|builder/${requestContext.builderId}`],
          },
        },
      },
    );
    let nodes = data.EncounterConnection.edges.map((x) => x.node);
    nodes = filterResourcesByBuilderId(nodes, requestContext.builderId);
    const results = nodes.map(
      (c) => new EncounterModel(c, c.ProvenanceList, undefined, c.BasicList),
    );
    return dedupeAndMergeEncounters(results);
  };
}

export function getSummaryEncountersFQS(limit: number) {
  return async (requestContext: CTWRequestContext, patient: PatientModel) => {
    const graphClient = createGraphqlClient(requestContext);
    const { data } = await fqsRequestAll<EncounterGraphqlResponse>(
      graphClient,
      encountersQuery,
      'EncounterConnection',
      {
        upid: patient.UPID,
        cursor: '',
        first: limit,
        filter: {
          tag: {
            allmatch: [
              `${SYSTEM_ZUS_SUMMARY}|Common`,
              `${SYSTEM_ZUS_OWNER}|builder/${getLensBuilderId(requestContext.env)}`,
            ],
          },
        },
      },
    );
    const nodes = data.EncounterConnection.edges.map((x) => x.node);
    const results = nodes.map(
      (c) => new EncounterModel(c, c.ProvenanceList, undefined, c.BasicList),
    );
    return dedupeAndMergeEncounters(results);
  };
}
