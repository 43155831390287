import { createContext } from 'react';
import { ZusAggregatedProfileSubComponentPropsMap } from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile';

type ZapPropsStateContextType = {
  props: ZusAggregatedProfileSubComponentPropsMap;
  setProps: (props: ZusAggregatedProfileSubComponentPropsMap) => void;
};

export const ZapPropsStateContext = createContext<ZapPropsStateContextType>({
  props: {},
  setProps: () => {},
});
