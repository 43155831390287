import { createContext, createRef, RefObject } from 'react';
import { defaultTheme } from './default-theme';
import { IFrameTheme } from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile-iframe';
import { Locals } from '@ctw/shared/utils/i18n';

export interface ThemeContextValue {
  theme: typeof defaultTheme;
  iframeTheme?: IFrameTheme;
  ctwThemeRef: RefObject<HTMLDivElement>;
  locals?: Locals;
}

export const ThemeContext = createContext<ThemeContextValue>({
  theme: defaultTheme,
  ctwThemeRef: createRef<HTMLDivElement>(),
});
