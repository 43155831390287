import { capitalize } from 'lodash';
import { formatPhoneNumber } from '@ctw/shared/api/fhir/formatters';
import { CopyToClipboard } from '@ctw/shared/components/copy-to-clipboard';
import { TableColumn } from '@ctw/shared/components/table/table-helpers';
import {
  CanonicalContact,
  isCanonicalAddress,
  isCanonicalEmail,
  isCanonicalPhone,
} from '@ctw/shared/content/demographics/helpers/canonicalization';
import { RenderSyncedWithRecordIcon } from '@ctw/shared/content/resource/helpers/resource-title-column';
import { tw } from '@ctw/shared/utils/tailwind';

export const canonicalContactColumns = (
  canonicalContactTitle: string,
): TableColumn<CanonicalContact>[] => [
  {
    title: canonicalContactTitle,
    render: (contact) => {
      if (isCanonicalPhone(contact)) {
        return (
          <CopyToClipboard className={tw`text-left font-medium`}>
            {formatPhoneNumber(contact.value)}
          </CopyToClipboard>
        );
      }

      if (isCanonicalEmail(contact) || isCanonicalAddress(contact)) {
        return (
          <CopyToClipboard className={tw`text-left font-medium`}>{contact.value}</CopyToClipboard>
        );
      }

      return <></>;
    },
    widthPercent: 35,
    minWidth: 200,
  },
  {
    render: (contact) => RenderSyncedWithRecordIcon(contact.syncedWithRecord),
    widthPercent: 5,
  },
  {
    title: 'Type',
    render: (contact) => <>{contact.uses.map((use) => capitalize(use)).join(', ')}</>,
    widthPercent: 18,
  },
  {
    title: 'Sources',
    render: (contact) => <>{contact.sources.join(', ')}</>,
  },
];
