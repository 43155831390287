import { MeasureReport } from 'fhir/r4';
import { groupBy, map, mapValues, sortBy } from 'lodash';
import { PatientModel } from './models';
import { fqsRequestContext, searchRecords } from '@ctw/shared/api/fqs-rest/search-helpers';
import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { useTimingQueryWithPatient } from '@ctw/shared/context/patient-provider';
import { QUERY_KEY_PATIENT_CARE_GAPS } from '@ctw/shared/utils/query-keys';
import { UUID_REGEX } from '@ctw/shared/utils/regex';
import { CareGapModel } from '@ctw/shared/api/fhir/models/care-gap';

// TODO: We're using FQS/REST here, while most other resources use FQS/GraphQL.  This results in an
// extra network request.  We should consider converting this to use FQS/GraphQL, or decide for sure
// that we're OK with the extra calls.

export function usePatientCareGaps() {
  return useTimingQueryWithPatient(QUERY_KEY_PATIENT_CARE_GAPS, [], fetchCareGaps);
}

// Fetches MeasureReports for the patient and returns them as CareGap models.  The results are sorted
// by title and then by report date.
async function fetchCareGaps(requestContext: CTWRequestContext, patient: PatientModel) {
  const searchByUpidParams = {
    upid: patient.UPID ?? '',
  };
  return searchCareGaps(requestContext, searchByUpidParams);
}

export async function getCareGapsByIdFromFQS(
  requestContext: CTWRequestContext,
  ids: string[] = [],
) {
  const searchByIDParams = {
    _id: ids.join(','),
  };

  return searchCareGaps(requestContext, searchByIDParams);
}

// This is a local function to unify all the various "here's a search string, go get a list of
// MeasureReports" actions
async function searchCareGaps(
  requestContext: CTWRequestContext,
  searchParams: Record<string, string>,
) {
  const measureReports = await searchRecords(
    fqsRequestContext(requestContext),
    'MeasureReport',
    searchParams,
  );

  const measures = await fetchMeasures(requestContext, measureReports);

  const careGaps: CareGapModel[] = measureReports
    .map(
      (measureReport) =>
        new CareGapModel(
          measureReport,
          measures.find((m) => m.id === measureReport.measure.split('/').pop()),
        ),
    )
    .sort(
      // Sort by title first, then by report date
      (a: CareGapModel, b: CareGapModel) =>
        a.title.localeCompare(b.title) || Date.parse(b.updatedDate) - Date.parse(a.updatedDate),
    );
  return careGaps;
}

// Note: This logic expects that the `MeasureReport` resource has a `measure` field that is a
// full URL pointing to a specific `Measure` resource.  This is *not* a Canonical URL as described
// in the FHIR spec (our ODS service doesn't support searching for Measures by the url field, so
// we use this as a workaround.)
async function fetchMeasures(requestContext: CTWRequestContext, measureReports: MeasureReport[]) {
  const measureIDs = measureReports
    .map((measureReport) => measureReport.measure.split('/').pop() || '')
    .filter((measureID) => UUID_REGEX.test(measureID));
  const uniqueMeasureIDs = [...new Set(measureIDs)]; // Remove duplicates

  const measureSearchParams = {
    _id: uniqueMeasureIDs.join(','),
  };
  return searchRecords(fqsRequestContext(requestContext), 'Measure', measureSearchParams);
}

// Returns the most recent report for each unique gap title.
export function mostRecentGaps(careGaps: CareGapModel[]) {
  // Group the gaps by their titles
  let groupedCareGaps = groupBy(careGaps, (gap) => gap.title);
  // Sort each list of gaps by report date.
  groupedCareGaps = mapValues(groupedCareGaps, (gaps) =>
    gaps.sort((a, b) => Date.parse(b.updatedDate) - Date.parse(a.updatedDate)),
  );
  // Grab the first gap from each group.
  const newestGaps = map(groupedCareGaps, (gaps) => gaps[0]);
  return sortBy(newestGaps, (gap) => gap.title);
}
