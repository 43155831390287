import '@ctw/shared/styles/main.scss';
import { MedicationStatementModel } from '@ctw/shared/api/fhir/models';
import { Alert } from '@ctw/shared/components/alert';
import { PatientMedicationsAllProps } from '@ctw/shared/content/medications/patient-medications-all';
import { PatientMedicationsOutsideProps } from '@ctw/shared/content/medications/patient-medications-outside';
import { ZusAggregatedProfile } from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile';
import { ZapIFrameConfig } from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile-iframe';
import { CTWProvider } from '@ctw/shared/context/ctw-provider';
import { PatientProvider } from '@ctw/shared/context/patient-provider';
import { tw } from '@ctw/shared/utils/tailwind';
import { Telemetry } from '@ctw/shared/utils/telemetry';
import { debounce } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFont } from '../use-font';
import {
  sendOnAddToRecord,
  sendOnPatientSave,
  sendOnResourceSave,
} from '../utils/send-zap-message-event';

const { VITE_GIT_SHA } = import.meta.env;

export function ZapV2() {
  const [accessToken, setAccessToken] = useState<string>('');
  const [searchParams] = useSearchParams();

  useEffect(() => {
    Telemetry.logger.info('Mounting zap in child frame');
    return () => {
      Telemetry.logger.info('Unmounting zap from child frame');
    };
  }, []);

  const zapConfig = useMemo(() => {
    try {
      return JSON.parse(searchParams.get('config') ?? 'null') as ZapIFrameConfig;
    } catch (e) {
      return null;
    }
  }, [searchParams]);

  useFont(zapConfig?.iframeTheme);

  // zapProps is a memoized version of zapConfig.ZusAggregatedProfileProps. It creates local handlers where callback
  // functions may normally be used. This is so the local running ZAP can transparently run callback in the parent window.
  const zapProps = useMemo(() => {
    const baseProps = zapConfig?.ZusAggregatedProfileProps ?? {};
    const medOutsideProps = baseProps.medicationsOutsideProps;
    const medAllProps = baseProps.medicationsAllProps;

    function propsReplaceOnAddToRecord(
      props: PatientMedicationsOutsideProps | PatientMedicationsAllProps | undefined,
      component: 'medications-outside' | 'medications-all',
    ) {
      if (!props) return undefined;
      // If readonly then we have no onAddRecord to replace
      if (props.readOnly) return props;

      return {
        ...props,
        // Replace onAddToRecord with a function that sends a message to the parent window and waits for the callback
        onAddToRecord: async (medication: MedicationStatementModel) => {
          await sendOnAddToRecord(medication, component);
        },
      };
    }

    return {
      ...baseProps,
      medicationsOutsideProps: propsReplaceOnAddToRecord(medOutsideProps, 'medications-outside'),
      medicationsAllProps: propsReplaceOnAddToRecord(medAllProps, 'medications-all'),
    };
  }, [zapConfig]);

  useEffect(() => {
    const handleTokenReceivedMessage = (event: {
      data: { type: string; payload: { accessToken: string } };
    }) => {
      if (event.data.type === 'ZusToken') {
        setAccessToken(event.data.payload.accessToken);
      }
    };

    window.addEventListener('message', handleTokenReceivedMessage);
    return () => {
      window.removeEventListener('message', handleTokenReceivedMessage);
    };
  }, [setAccessToken]);

  const postRefreshMessage = () => {
    window.parent.postMessage(
      {
        type: 'ZusTokenRequest',
      },
      '*',
    );
  };

  const debouncedPostRefreshMessage = debounce(postRefreshMessage, 10_000, {
    leading: true,
  });

  if (zapConfig) {
    return (
      <CTWProvider
        onAuthTokenExpiration={() => {
          debouncedPostRefreshMessage();
        }}
        {...zapConfig.CTWProviderProps}
        authToken={accessToken}
        onResourceSave={sendOnResourceSave}
        featureFlags={{
          ...zapConfig.CTWProviderProps.featureFlags,
          openCCDAInNewTab: false,
        }}
        enableTelemetry
        datadogConfig={{ version: VITE_GIT_SHA }}
      >
        <PatientProvider {...zapConfig.PatientProviderProps} onPatientSave={sendOnPatientSave}>
          <ZusAggregatedProfile {...zapProps} />
        </PatientProvider>
      </CTWProvider>
    );
  }

  return (
    <div className={tw`w-full`}>
      <Alert type="error" header="Missing Zus Aggregated Profile Configuration" />
    </div>
  );
}
