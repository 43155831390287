import { gql } from 'graphql-request';

export const fragmentEpisodeOfCareWithoutPatient = gql`
  fragment EpisodeOfCare on EpisodeOfCare {
    id
    resourceType
    extension {
      url
      extension {
        url
        valueReference {
          id
          type
          reference
          display
          extension {
            url
            valueString
            valueBoolean
          }
        }
        valueString
      }
      valueString
      valueReference {
        id
        type
        reference
        display
      }
      valueCodeableConcept {
        coding {
          system
          code
          display
        }
        text
      }
      valueCoding {
        code
        display
        system
        userSelected
        version
      }
    }
    meta {
      extension {
        url
      }
      security {
        system
        code
      }
      tag {
        system
        code
      }
      lastUpdated
    }
    period {
      start
      end
    }
    identifier {
      system
      value
      type {
        text
        coding {
          system
          code
          display
        }
      }
    }
    status
    diagnosis {
      role {
        coding {
          system
          code
          display
        }
      }
      condition {
        id
        type
        reference
        resource {
          ... on Condition {
            id
            resourceType
            code {
              text
              coding {
                display
                code
                system
                extension {
                  url
                  valueString
                }
              }
            }
          }
        }
      }
    }
  }
`;
