import { ResponsiveSourceDocumentLink } from '@ctw/shared/content/CCDA/responsive-source-document-link';
import { DetailsCard, DetailsProps } from '@ctw/shared/content/resource/helpers/details-card';
import { useAdditionalResourceActions } from '@ctw/shared/content/resource/use-additional-resource-actions';
import { Drawer } from '@ctw/shared/components/drawer';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { TrackingMetadata } from '@ctw/shared/context/analytics/tracking-metadata';
import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { useDrawer } from '@ctw/shared/context/drawer-hooks';
import { useTimingQueryWithPatient } from '@ctw/shared/context/patient-provider';
import { useCtwThemeRef } from '@ctw/shared/context/theme/use-ctw-theme-ref';
import { RowActionsConfigProp } from '@ctw/shared/components/table/table-rows';
import { getAllergyIntolerancesById } from '@ctw/shared/api/fhir/allergies';
import { useBinaryId } from '@ctw/shared/api/fhir/binaries';
import { getCareTeamMembersByIdFromFQS } from '@ctw/shared/api/fhir/care-team';
import { fetchConditionsByIdFQS } from '@ctw/shared/api/fhir/conditions';
import { getEncountersWithClinicalNotesById } from '@ctw/shared/api/fhir/encounters';
import { getImmunizationsFromFQS } from '@ctw/shared/api/fhir/immunizations';
import { getMedicationStatementsByIdFQS } from '@ctw/shared/api/fhir/medications';
import { DocumentModel, PatientModel } from '@ctw/shared/api/fhir/models';
import { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import { fetchReferralsByIdFQS } from '@ctw/shared/api/fhir/referrals';
import { ReactNode, useEffect } from 'react';
import { useBreakpoints } from '@ctw/shared/hooks/use-breakpoints';
import { QUERY_KEY_BINARY_ID } from '@ctw/shared/utils/query-keys';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { fetchDiagnosticReportsWithTrendsFromFQSById } from '@ctw/shared/api/fhir/diagnostic-report';
import { getDocumentsByIdFromFQS } from '@ctw/shared/api/fhir/document';
import { Button } from '@ctw/shared/components/button';
import { faInbox } from '@fortawesome/free-solid-svg-icons';
import { ContentError } from '@ctw/shared/components/errors/content-error';
import { SupportFormLink } from '@ctw/shared/content/support-form-link';
import { getCareGapsByIdFromFQS } from '@ctw/shared/api/fhir/care-gaps';
import { useAnalytics } from '@ctw/shared/context/analytics/use-analytics';

type DeepLinkResourceType =
  | 'Condition'
  | 'Immunization'
  | 'AllergyIntolerance'
  | 'Encounter'
  | 'DiagnosticReport'
  | 'ServiceRequest'
  | 'MedicationStatement'
  | 'Practitioner'
  | 'DocumentReference'
  | 'MeasureReport';

export type UseResourceDetailsDrawerProps<T extends fhir4.Resource, M extends FHIRModel<T>> = Pick<
  ResourceDetailsDrawerProps<T, M>,
  | 'header'
  | 'subHeader'
  | 'getSourceDocument'
  | 'details'
  | 'rowActions'
  | 'enableDismissAndReadActions'
  | 'deepLinkResourceType'
  | 'RenderChild'
  | 'renderResourceActions'
  | 'onOpen'
  | 'onAfterClosed'
>;

export function useResourceDetailsDrawer<T extends fhir4.Resource, M extends FHIRModel<T>>(
  props: UseResourceDetailsDrawerProps<T, M>,
) {
  const { openDrawer } = useDrawer();

  const openDrawerWithResource = (
    model?: M,
    trackingMetadata?: TrackingMetadata,
    resourceId?: string,
    resourceType?: DeepLinkResourceType,
  ) => {
    const canonicalResourceType = resourceType ?? model?.resourceType;
    const canonicalResourceId = resourceId ?? model?.id;

    const uniqueKey = resourceId ? `${resourceId}-${Date.now()}` : Date.now().toString();

    openDrawer({
      component: (drawerProps) => (
        <ResourceDetailsDrawer
          key={uniqueKey}
          model={model}
          resourceId={resourceId}
          resourceType={resourceType}
          {...props}
          {...drawerProps}
          onOpen={(openModel) => {
            if (
              canonicalResourceType &&
              canonicalResourceType === props.deepLinkResourceType &&
              canonicalResourceId
            ) {
              const onOpenUrl = new URL(window.location.href);
              onOpenUrl.searchParams.set('drawerResourceType', canonicalResourceType);
              onOpenUrl.searchParams.set('drawerResourceId', canonicalResourceId);
              window.history.pushState({}, '', onOpenUrl);
            }

            props.onOpen?.(openModel);
          }}
          onClose={() => {
            if (props.deepLinkResourceType) {
              const onCloseUrl = new URL(window.location.href);
              onCloseUrl.searchParams.delete('drawerResourceType');
              onCloseUrl.searchParams.delete('drawerResourceId');
              window.history.pushState({}, '', onCloseUrl);
            }

            drawerProps.onClose();
            props.onAfterClosed?.();
          }}
        />
      ),
      trackingMetadata: {
        resourceType: model?.resourceType,
        ...trackingMetadata,
      },
    });
  };

  useEffect(() => {
    if (props.deepLinkResourceType) {
      const initialUrl = new URL(window.location.href);
      const initialResourceType = initialUrl.searchParams.get(
        'drawerResourceType',
      ) as DeepLinkResourceType | null;
      const initialResourceId = initialUrl.searchParams.get('drawerResourceId');

      if (initialResourceType === props.deepLinkResourceType && initialResourceId !== null) {
        openDrawerWithResource(undefined, undefined, initialResourceId, initialResourceType);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return openDrawerWithResource;
}

const fetchSingularResource = async (
  requestContext: CTWRequestContext,
  patient: PatientModel,
  resourceId?: string,
  resourceType?: string,
) => {
  if (!resourceId) {
    return null;
  }
  switch (resourceType) {
    case 'Condition':
      return fetchConditionsByIdFQS(requestContext, patient, [resourceId]);
    case 'Immunization':
      return getImmunizationsFromFQS(requestContext, patient, [resourceId]);
    case 'AllergyIntolerance':
      return getAllergyIntolerancesById(requestContext, patient, [resourceId]);
    case 'Encounter':
      return getEncountersWithClinicalNotesById(requestContext, patient, [resourceId]);
    case 'DiagnosticReport':
      return fetchDiagnosticReportsWithTrendsFromFQSById(requestContext, patient, [resourceId]);
    case 'ServiceRequest':
      return fetchReferralsByIdFQS(requestContext, patient, [resourceId]);
    case 'MedicationStatement':
      return getMedicationStatementsByIdFQS(requestContext, patient, [resourceId]);
    case 'Practitioner':
      return getCareTeamMembersByIdFromFQS(requestContext, patient, [resourceId]);
    case 'DocumentReference':
      return getDocumentsByIdFromFQS(requestContext, patient, [resourceId]);
    case 'MeasureReport':
      return getCareGapsByIdFromFQS(requestContext, [resourceId]);
    default:
      return null;
  }
};

export function useFetchResource<T extends fhir4.Resource, M extends FHIRModel<T>>(
  resourceId?: string,
  resourceType?: string,
) {
  const { data, isLoading, isError } = useTimingQueryWithPatient(
    QUERY_KEY_BINARY_ID,
    [resourceId, resourceType],
    (requestContext: CTWRequestContext, patient: PatientModel) =>
      fetchSingularResource(requestContext, patient, resourceId, resourceType),
    !!resourceId && !!resourceType,
  );

  const resource = data?.[0] as M | undefined | null;

  return { data: resource, isLoading, isError };
}

type ResourceDetailsDrawerProps<T extends fhir4.Resource, M extends FHIRModel<T>> = {
  className?: string;
  details: (model: M) => DetailsProps['details'];
  getSourceDocument?: boolean;
  header: (model: M) => ReactNode;
  isOpen: boolean;
  onOpen?: (model: M) => void;
  model?: M;
  onClose: () => void;
  onAfterClosed?: () => void;
  rowActions?: (model: M) => RowActionsConfigProp<M>;
  enableDismissAndReadActions?: boolean;
  deepLinkResourceType?: DeepLinkResourceType;
  subHeader?: (model: M) => ReactNode;
  RenderChild?: (props: { model: M }) => ReactNode;
  renderResourceActions?: (model: M) => ReactNode;
  resourceType?: DeepLinkResourceType;
  resourceId?: string;
};

function ResourceDetailsDrawer<T extends fhir4.Resource, M extends FHIRModel<T>>({
  className,
  details,
  getSourceDocument,
  header,
  isOpen,
  model,
  onClose,
  onAfterClosed,
  rowActions,
  enableDismissAndReadActions,
  subHeader,
  RenderChild,
  onOpen,
  resourceId,
  resourceType,
  renderResourceActions,
}: ResourceDetailsDrawerProps<T, M>) {
  const ctwThemeRef = useCtwThemeRef();
  const { trackInteraction } = useAnalytics();
  const breakpoints = useBreakpoints(ctwThemeRef);
  const { data, isLoading, isError } = useFetchResource(resourceId, resourceType);
  // If we pass in a model, we don't want to show the loading state.

  const shouldUseLoadingState = model ? false : isLoading;
  const resource = (model || data) as M | undefined;
  let actions = null;

  useEffect(() => {
    if (resource) {
      onOpen?.(resource);
    }
  }, [onOpen, resource]);

  const rowActionsWithAdditions = useAdditionalResourceActions({
    rowActions,
    enableDismissAndReadActions,
    isInFooter: true,
  });

  // We call rowActions right away so we'll know if it returns null and thus we should
  // hide our footer.
  if (resource) {
    actions = rowActionsWithAdditions({
      record: resource,
      onSuccess: onClose,
      stacked: breakpoints.xs,
    });
  }
  return (
    <Drawer
      className={twx(className)}
      isOpen={isOpen}
      onClose={onClose}
      onAfterClosed={onAfterClosed}
      renderHeader={() => (resource?.resourceTypeTitle || resourceType) ?? ''}
      renderFooter={() => (
        <div className={tw`flex justify-between gap-4`}>
          <Button
            type="button"
            variant="clear"
            className={tw`!px-4 !py-2`}
            onClick={() => {
              onClose();
              trackInteraction('close_drawer', {
                target: 'footer_close_icon',
              });
            }}
          >
            Close
          </Button>
          {resource && renderResourceActions && (
            <div className={tw`flex flex-1 items-center justify-end`}>
              {renderResourceActions(resource)}
            </div>
          )}
          {actions}
        </div>
      )}
    >
      <ResourceDetailsDrawerBody
        model={resource}
        isError={isError}
        getSourceDocument={getSourceDocument}
        isLoading={shouldUseLoadingState}
        details={details}
        header={header}
        subHeader={subHeader}
        RenderChild={RenderChild}
        onClose={onClose}
      />
    </Drawer>
  );
}

type ResourceDetailsDrawerBodyProps<T extends fhir4.Resource, M extends FHIRModel<T>> = {
  model?: M;
  getSourceDocument?: boolean;
  isLoading: boolean;
  isError: boolean;
  details: (model: M) => DetailsProps['details'];
  header: (model: M) => ReactNode;
  subHeader?: (model: M) => ReactNode;
  RenderChild?: (props: { model: M }) => ReactNode;
  onClose: () => void;
};

function ResourceDetailsDrawerBody<T extends fhir4.Resource, M extends FHIRModel<T>>({
  model,
  getSourceDocument,
  isLoading,
  isError,
  details,
  header,
  subHeader,
  RenderChild,
  onClose,
}: ResourceDetailsDrawerBodyProps<T, M>) {
  const ctwThemeRef = useCtwThemeRef();
  const breakpoints = useBreakpoints(ctwThemeRef);
  const shouldFetchBinaryDocument = !!getSourceDocument || !!model;
  const binaryFetch = useBinaryId(model, getSourceDocument, shouldFetchBinaryDocument);

  if (isError) {
    return (
      <div className={tw`flex flex-col items-center space-y-6`}>
        <ContentError title="An unexpected error occurred">
          <span>If this problem persists </span>
          <SupportFormLink buttonText="contact support" className={tw`link text-base`} />.
        </ContentError>
        <Button type="button" variant="primary" className={tw`w-fit`} onClick={() => onClose()}>
          Close
        </Button>
      </div>
    );
  }

  if (isLoading) {
    return (
      <LoadingSpinner
        message="Loading content..."
        className={tw`!m-0 flex-col space-y-2 pt-3 text-content-light`}
        iconClass="w-5 h-5"
      />
    );
  }

  if (!model) {
    return (
      <div className={tw`flex flex-col items-center space-y-6`}>
        <ContentError
          icon={faInbox}
          title="No record found"
          message="Check the link and try again."
        >
          <span>If this problem persists </span>
          <SupportFormLink buttonText="contact support" className={tw`link text-base`} />.
        </ContentError>
        <Button type="button" variant="primary" className={tw`w-fit`} onClick={() => onClose()}>
          Close
        </Button>
      </div>
    );
  }

  return (
    <div className={tw`space-y-6`}>
      <div className={tw`space-y-2`}>
        <div
          data-testid="details-drawer-header"
          className={twx(breakpoints.xs ? 'text-2xl' : 'text-3xl')}
        >
          {header(model)}
        </div>
        {subHeader && <div>{subHeader(model)}</div>}
      </div>

      {binaryFetch.isLoading && !shouldFetchBinaryDocument ?
        <LoadingSpinner message="Loading data..." />
      : <DetailsCard
          details={details(model)}
          documentButton={
            !(model instanceof DocumentModel) || !binaryFetch.data ?
              undefined
            : <ResponsiveSourceDocumentLink
                contentType={model instanceof DocumentModel ? model.contentType : ''}
                binaryId={binaryFetch.data}
                forceInModal={model instanceof DocumentModel && !model.contentType?.includes('xml')}
                documentTitle={model.resourceTypeTitle}
                telemetryTarget="resource_details_drawer"
              />
          }
        />
      }
      {RenderChild && <RenderChild model={model} />}
    </div>
  );
}
