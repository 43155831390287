import { createContext } from 'react';
import { ZAPTabName } from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile';

export type ZapTabStateContextType = {
  selectedZapTabIndex: number;
  setSelectedTab: (tabName: ZAPTabName | number) => void;
  tabOrder: ZAPTabName[];
  setTabOrder: (tabOrder: ZAPTabName[]) => void;
};

export const ZapTabStateContext = createContext<ZapTabStateContextType>({
  selectedZapTabIndex: 0,
  setSelectedTab: () => {},
  tabOrder: [],
  setTabOrder: () => [],
});
