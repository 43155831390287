import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { AuthenticationContext } from './context';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { hasValidClaimsAuthEmail, sendInvalidTokenNotification } from '@ctw/shared/utils/auth';

// We use an expiry padding to provide a buffer to prevent race conditions.
// A race condition could happen in that we check if the token is expired,
// it isn't, but by time we do our request(s) it has expired.
// We track the id of the toast that we open to avoid stacking.
const AUTH_ERROR_TOAST_ID = 'invalid-token';

export type AuthenticationProviderProps = {
  headers?: Record<string, string>;
  authToken?: string;
  authTokenURL?: string;
  onAuthTokenExpiration?: () => void;
};

export function AuthenticationProvider({
  children,
  authToken,
  authTokenURL,
  onAuthTokenExpiration,
}: PropsWithChildren<AuthenticationProviderProps>) {
  const [showingErrorNotice, setShowingErrorNotice] = useState(false);
  if (!authToken && !authTokenURL && !onAuthTokenExpiration) {
    throw new Error('Either authToken, authTokenURL or onAuthTokenExpiration is required');
  }
  useEffect(() => {
    if (!authToken && !authTokenURL) {
      onAuthTokenExpiration?.();
    }
  }, [authToken, authTokenURL, onAuthTokenExpiration]);

  useEffect(() => {
    // We don't want to stack toast notifications. So we track if there is one already open with auth errors
    const unsubscribe = toast.onChange((t) => {
      if (t.id === AUTH_ERROR_TOAST_ID && t.status === 'removed') {
        setShowingErrorNotice(false);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const getAuthToken = useCallback(async () => {
    if (authToken) {
      if (!hasValidClaimsAuthEmail(authToken)) {
        onAuthTokenExpiration?.();

        if (!showingErrorNotice) {
          setShowingErrorNotice(true);
          sendInvalidTokenNotification(authToken, AUTH_ERROR_TOAST_ID);
        }
        return '';
      }
      return authToken;
    }

    return authToken || '';
  }, [authToken, showingErrorNotice, onAuthTokenExpiration]);

  const contextValue = useMemo(
    () => ({
      isExpired: !hasValidClaimsAuthEmail(authToken),
      token: authToken,
      getAuthToken,
    }),
    [authToken, getAuthToken],
  );

  return (
    <AuthenticationContext.Provider value={contextValue}>
      {authToken ? children : <LoadingSpinner message="Loading..." />}
    </AuthenticationContext.Provider>
  );
}
