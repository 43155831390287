import { compact } from 'lodash';
import { useEffect, useState } from 'react';
import { getBinaryId } from '@ctw/shared/api/fhir/binaries';
import { DiagnosticReportModel, ObservationModel } from '@ctw/shared/api/fhir/models';
import { searchProvenances } from '@ctw/shared/api/fhir/provenance';
import { withErrorBoundary } from '@ctw/shared/content/error-boundary';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { ResponsiveSourceDocumentLink } from '@ctw/shared/content/CCDA/responsive-source-document-link';
import { ObservationsTable } from '@ctw/shared/content/observations/helpers/observations-table';
import { DetailsCard } from '@ctw/shared/content/resource/helpers/details-card';
import { useCTW } from '@ctw/shared/context/use-ctw';
import { tw } from '@ctw/shared/utils/tailwind';

export type ObservationDetailsProps = {
  diagnosticReport: DiagnosticReportModel;
};

export const diagnosticReportData = (diagnosticReport: DiagnosticReportModel) => [
  {
    label: 'Effective Date',
    value: diagnosticReport.effectiveStart,
  },
  { label: 'Organization', value: diagnosticReport.performer },
];

export const ObservationDetailsComponent = ({ diagnosticReport }: ObservationDetailsProps) => {
  const [observationEntries, setObservationsEntries] = useState<ObservationModel[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [binaryId, setBinaryId] = useState<string>();
  const { getRequestContext } = useCTW();

  // We optionally look for any associated binary CCDAs
  // if getSourceDocument is true.
  useEffect(() => {
    async function load() {
      setIsLoading(true);
      const requestContext = await getRequestContext();
      const provenances = await searchProvenances(requestContext, [diagnosticReport]);
      setBinaryId(getBinaryId(provenances, diagnosticReport.id));
      setIsLoading(false);
    }

    void load();
  }, [diagnosticReport, getRequestContext]);

  useEffect(() => {
    setObservationsEntries(compact(diagnosticReport.observations));
  }, [diagnosticReport]);

  return (
    <div className={tw`space-y-6`}>
      <div className={tw`text-2xl`}>{diagnosticReport.displayName}</div>

      {isLoading ?
        <LoadingSpinner message="Loading data..." />
      : <>
          <DetailsCard
            details={diagnosticReportData(diagnosticReport)}
            documentButton={
              <ResponsiveSourceDocumentLink
                binaryId={binaryId}
                documentTitle={diagnosticReport.resourceTypeTitle}
                telemetryTarget="observation_details"
              />
            }
          />
          <ObservationsTable data={observationEntries} />
        </>
      }
    </div>
  );
};

export const ObservationDetails = withErrorBoundary(ObservationDetailsComponent, 'Observations');
