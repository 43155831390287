import { ReactNode } from 'react';
import { twx } from '@ctw/shared/utils/tailwind';

export interface ButtonGroupProps {
  className?: string;
  children: ReactNode;
}

export const ButtonGroup = ({ className, children }: ButtonGroupProps) => (
  <div
    className={twx(
      'rounded-md pb-5 [&>button]:-ml-[1px] [&>button]:rounded-none first:[&>button]:ml-0 first:[&>button]:rounded-l-md last:[&>button]:rounded-r-md',
      className,
    )}
  >
    {children}
  </div>
);
