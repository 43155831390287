import { faFileLines } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { upperCase } from 'lodash';
import { ReferralModel } from '@ctw/shared/api/fhir/models/referral';
import { Badge } from '@ctw/shared/components/badge';
import { TableColumn } from '@ctw/shared/components/table/table-helpers';
import { tw } from '@ctw/shared/utils/tailwind';

export const patientReferralsColumns = (_builderId: string) => {
  const referralColumns: TableColumn<ReferralModel>[] = [
    {
      title: 'Ordered',
      render: (referral) => (
        <div className={tw`leading-tight`}>
          <span>{referral.referredAt?.toLocaleDateString() || 'Unknown'}</span>
          {referral.referringPractitionerName && (
            <>
              <br />
              <span className={tw`text-xs`}>by {referral.referringPractitionerName}</span>
            </>
          )}
        </div>
      ),
    },
    {
      title: 'Referred Provider',
      render: (referral) => (
        <div className={tw`leading-tight`}>
          <span>{referral.referredPractitionerName || 'Unknown'}</span>
          {referral.referredPracticeName && (
            <>
              <br />
              <span>{referral.referredPracticeName}</span>
            </>
          )}
          {referral.referredPracticePhoneNumber && (
            <>
              <br />
              <span>{referral.referredPracticePhoneNumber}</span>
            </>
          )}
          {referral.referredPractitioners.length > 1 && (
            <>
              <br />
              <span className={tw`text-xs`}>
                + {referral.referredPractitioners.length - 1} More
              </span>
            </>
          )}
        </div>
      ),
    },
    {
      title: 'Status',
      render: (referral) => (
        <div className={tw`flex flex-col gap-1`}>
          <div className={tw`flex w-full items-center justify-between`}>
            <Badge
              color={referral.status === 'acknowledged' ? 'muted' : 'primary'}
              content={upperCase(referral.status === 'acknowledged' ? 'closed' : 'open')}
            />
            {referral.encounter?.docsAndNotes && referral.encounter.docsAndNotes.length === 0 && (
              <FontAwesomeIcon className={tw`w-4 text-content-lighter`} icon={faFileLines} />
            )}
          </div>
          {['pending-acknowledgement', 'acknowledged'].includes(referral.status) &&
            referral.completedAt && <div>{referral.completedAt.toLocaleDateString()}</div>}
        </div>
      ),
    },
    {
      title: 'Appointment Details',
      render: (referral) => (
        <div className={tw`flex flex-col gap-1`}>
          {referral.status === 'scheduled' && (
            <div className={tw`leading-tight`}>
              {referral.scheduledAt && (
                <>
                  <span className={tw`whitespace-nowrap`}>
                    Scheduled {referral.scheduledAt.toLocaleDateString()}
                  </span>
                  {referral.referredPracticeName && (
                    <>
                      <br />
                      <span className={tw`text-xs`}>by {referral.referredPracticeName}</span>
                    </>
                  )}
                </>
              )}
            </div>
          )}
          {['pending-acknowledgement', 'acknowledged'].includes(referral.status) && (
            <div className={tw`leading-tight`}>
              {referral.completedAt && (
                <>
                  <span className={tw`whitespace-nowrap`}>
                    Completed {referral.completedAt.toLocaleDateString()}
                  </span>
                  {referral.referredPracticeName && (
                    <>
                      <br />
                      <span className={tw`text-xs`}>by {referral.referredPracticeName}</span>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      ),
    },
  ];

  return referralColumns;
};
