import { Dialog, Transition } from '@headlessui/react';
import type { ReactNode } from 'react';
import { Fragment } from 'react';
import { APP_TOAST_CONTAINER_DRAWER_ID } from './toast';
import { ToastProvider } from '../context/toast-provider';
import { tw, twx } from '@ctw/shared/utils/tailwind';

const transitionClasses = 'transform transition ease-in-out duration-300' as const;

export type DrawerProps = {
  className?: string;
  children: ReactNode;
  isOpen: boolean;
  onOpen?: () => void;
  onAfterOpened?: () => void;
  onClose: () => void;
  onAfterClosed?: () => void;
  renderHeader?: () => ReactNode;
  renderFooter?: () => ReactNode;
  disableCloseOnBlur?: boolean;
};

export function Drawer({
  isOpen,
  onOpen,
  onAfterOpened,
  onClose,
  onAfterClosed,
  children,
  className,
  renderHeader,
  renderFooter,
  disableCloseOnBlur,
}: DrawerProps) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={twx('drawer relative z-[10000]', className)}
        onClose={() => {
          if (!disableCloseOnBlur) {
            onClose();
          }
        }}
        data-testid="drawer"
      >
        <Transition.Child
          as={Fragment}
          enter={transitionClasses}
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave={transitionClasses}
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          afterLeave={onAfterClosed}
          beforeEnter={onOpen}
          afterEnter={onAfterOpened}
        >
          <div className={tw`fixed inset-0 transition-opacity`}>
            <div className={tw`h-full w-full bg-content-light opacity-75`} />
          </div>
        </Transition.Child>
        <div className={tw`fixed inset-0 overflow-hidden`}>
          <ToastProvider containerId={APP_TOAST_CONTAINER_DRAWER_ID}>
            <div className={tw`absolute inset-0 overflow-hidden`}>
              <div
                className={tw`@sm/drawer:pl-10 pointer-events-none fixed inset-y-0 right-0 flex max-w-full`}
              >
                <Transition.Child
                  as={Fragment}
                  enter={transitionClasses}
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave={transitionClasses}
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className={tw`pointer-events-auto w-screen max-w-xl`}>
                    <div
                      data-testid="drawer-content"
                      className={tw`@container/drawer flex h-full flex-col bg-white shadow-xl`}
                    >
                      {renderHeader && (
                        <div
                          data-testid="drawer-title"
                          className={tw`header border-default @sm/drawer:p-3 @lg/drawer:p-6 border-b`}
                        >
                          {renderHeader()}
                        </div>
                      )}
                      <div
                        className={tw`@sm/drawer:py-3 @lg/drawer:py-6 flex h-full flex-col overflow-y-auto px-6`}
                      >
                        {children}
                      </div>
                      {renderFooter && (
                        <div
                          className={tw`border-default @sm/drawer:p-3 @lg/drawer:p-6 flex items-center justify-between border-t`}
                        >
                          {renderFooter()}
                        </div>
                      )}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </ToastProvider>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
