import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { Button } from '@ctw/shared/components/button';

export type SaveButtonProps = {
  submitting: boolean;
};

export const SaveButton = ({ submitting }: SaveButtonProps) => (
  <Button
    type="submit"
    variant="primary"
    disabled={submitting}
    className={twx('save-button w-28 whitespace-nowrap')}
  >
    {submitting ? 'Saving...' : 'Save'}
    {submitting && <LoadingSpinner iconClass={tw`ml-2 text-white`} />}
  </Button>
);
