import { orderBy, uniqBy } from 'lodash';
import { AllergyModel } from '@ctw/shared/api/fhir/models/allergies';
import { sort } from '@ctw/shared/utils/sort';

export const applyAllergyFilters = (allergyModel: AllergyModel[], builderId: string) => {
  const sortedByDate = sort(allergyModel, 'recordedDate', 'desc', true);
  // Bump builder owned allergies to the front, so uniqBy favors them!
  const builderOwnedFirst = orderBy(sortedByDate, (a) => a.ownedByBuilder(builderId), 'desc');
  return uniqBy(builderOwnedFirst, 'lowercaseDisplay');
};
