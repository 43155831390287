import { compact, mergeWith, uniq } from 'lodash';
import { toLower } from 'lodash/fp';
import { EncounterModel } from '@ctw/shared/api/fhir/models/encounter';
import { SYSTEM_LOINC } from '@ctw/shared/api/fhir/system-urls';
import {
  FilterItem,
  FilterOptionCheckbox,
} from '@ctw/shared/components/filter-bar/filter-bar-types';
import { dismissFilter } from '@ctw/shared/content/resource/filters';
import { PredicateMap } from '@ctw/shared/hooks/use-filtered-sorted-data';

export const noteTypeValues = [
  {
    name: 'Assessments / Plans',
    key: ['51847-2', '18776-5'].join(','),
  },
  {
    name: 'Diagnostic Narratives',
    key: ['34109-9', '30954-2'].join(','),
  },
  {
    name: 'Discharge Summary',
    key: '18842-5',
  },
  {
    name: 'History of Present Illness',
    key: '10164-2',
  },
  {
    name: 'Reason for Visit',
    key: '29299-5',
  },
];

export const encounterFilterPredicates: PredicateMap<FilterOptionCheckbox['predicate']> = {
  class: (values, item) => encounterClassPredicate(values, item as EncounterModel),
  location: (values, item) => encounterLocationPredicate(values, item as EncounterModel),
  locationType: (values, item) => encounterSpecialtyPredicate(values, item as EncounterModel),
  noteType: noteTypePredicate,
  provider: (values, item) => encounterProviderPredicate(values, item as EncounterModel),
};

export function encounterFilters(encounters: EncounterModel[] = []): FilterItem[] {
  const filters: FilterItem[] = [
    dismissFilter,
    {
      key: 'class',
      type: 'checkbox',
      predicate: encounterFilterPredicates.class,
      display: 'Class',
      values: compact(uniq(encounters.map((e) => e.classDisplay))),
    },
  ];

  const availableNoteTypeValues = noteTypeValues.filter((value) =>
    encounters.some((encounter) => noteTypePredicate([value.key], encounter)),
  );
  const hasOtherEncounterNoteTypes =
    encounters.filter(
      (encounter) => !noteTypeValues.some((value) => noteTypePredicate([value.key], encounter)),
    ).length > 0;

  if (availableNoteTypeValues.length > 0 || hasOtherEncounterNoteTypes) {
    filters.push({
      key: 'noteType',
      type: 'checkbox',
      display: 'Note Type',
      predicate: encounterFilterPredicates.noteType,
      values: compact([
        ...availableNoteTypeValues,
        hasOtherEncounterNoteTypes ?
          {
            name: 'Other',
            key: 'other',
          }
        : undefined,
      ]),
    });
  }

  const locations = compact(encounters.flatMap((encounter) => encounter.location));
  if (locations.length > 0) {
    filters.push({
      key: 'location',
      type: 'checkbox',
      display: 'Location',
      predicate: encounterFilterPredicates.location,
      values: uniq(locations.map(toLower).toSorted((a, b) => a.localeCompare(b))),
    });
  }

  const providers = encounters.flatMap((encounter) => encounter.practitioners);
  if (providers.length > 0) {
    filters.push({
      key: 'provider',
      type: 'checkbox',
      display: 'Provider',
      predicate: encounterFilterPredicates.provider,
      values: uniq(providers.map(toLower).toSorted((a, b) => a.localeCompare(b))),
    });
  }

  // Filter out "unknown" from specialty values. We will add it in to the end of the list but don't want to show the
  // Specialty filter if the only value is "unknown".
  const locationType = encounters
    .flatMap((encounter) => encounter.locationType)
    .map(toLower)
    .filter((value) => value !== 'unknown')
    .toSorted((a, b) => a.localeCompare(b));

  if (locationType.length > 0) {
    filters.push({
      key: 'locationType',
      type: 'checkbox',
      display: 'Location Type',
      predicate: encounterFilterPredicates.locationType,
      values: uniq(locationType.concat('unknown')),
    });
  }

  return filters;
}

// Dedupes encounters by patient, periodStart, class, type, and location.
// Merges their properties to maximize information.
export function dedupeAndMergeEncounters(encounters: EncounterModel[]): EncounterModel[] {
  // Group up the encounters that need to be merged
  const dupeGroups = new Map<string, EncounterModel[]>();

  encounters.forEach((encounter, i) => {
    const key: string = JSON.stringify({
      upid: encounter.patientUPID,
      periodStart: encounter.periodStart || '',
      class: encounter.resource.class,
      type: encounter.resource.type,
      location: encounter.location.join(', '),
    });
    const val = dupeGroups.get(key);

    if (
      !(
        encounter.patientUPID &&
        encounter.periodStart &&
        encounter.resource.type &&
        encounter.location.length > 0
      )
    ) {
      dupeGroups.set(key + i, [encounter]); // If it's missing values, put it in a lone group.
    } else if (val) {
      val.push(encounter);
    } else {
      dupeGroups.set(key, [encounter]);
    }
  });

  return mergeEncounters(dupeGroups);
}

function mergeEncounters(dupeGroups: Map<string, EncounterModel[]>): EncounterModel[] {
  const dedupedEncounters: EncounterModel[] = [];

  dupeGroups.forEach((encs) => {
    // If there's only one encounter in the group, no need to merge
    if (encs.length === 1) {
      dedupedEncounters.push(encs[0]);
      return;
    }
    // Sort them to prioritize the most recently updated encounter
    encs.sort((a, b) => {
      const aVal = a.lastUpdated ? new Date(a.lastUpdated).valueOf() : 0;
      const bVal = b.lastUpdated ? new Date(b.lastUpdated).valueOf() : 0;
      return aVal - bVal;
    });
    const mergedEncounter: EncounterModel = encs[0];

    if (encs.length > 1) {
      const sortedEncResources = encs.map((enc) => enc.resource);
      // Merge the encounters
      mergedEncounter.resource = sortedEncResources.reduce((merged, curr) =>
        mergeWith(merged, curr),
      );
    }
    dedupedEncounters.push(mergedEncounter);
  });

  return dedupedEncounters;
}

export function noteTypePredicate(values: string[], item: object): boolean {
  if (values.length === 0) return true;

  // Values can contain comma separated values, so we need to split them.
  const parsedValues = values.flatMap((value) => value.split(','));
  const parsedAllValues = noteTypeValues.flatMap((v) => v.key.split(','));

  const encounter = item as EncounterModel;
  const hasValueMatch = encounter.clinicalNotes.some((note) =>
    note.category.some(
      (category) =>
        category.coding?.some(
          (coding) =>
            coding.system === SYSTEM_LOINC && coding.code && parsedValues.includes(coding.code),
        ),
    ),
  );
  const hasOtherMatch =
    parsedValues.includes('other') &&
    !encounter.clinicalNotes.some((note) =>
      note.category.some(
        (category) =>
          category.coding?.some(
            (coding) =>
              coding.system === SYSTEM_LOINC &&
              coding.code &&
              parsedAllValues.includes(coding.code),
          ),
      ),
    );
  return hasValueMatch || hasOtherMatch;
}

export function encounterClassPredicate(values: string[], encounter: EncounterModel): boolean {
  if (values.length === 0) return true;
  return values.includes(encounter.classDisplay);
}

export function encounterLocationPredicate(values: string[], encounter: EncounterModel): boolean {
  if (values.length === 0) return true;
  return encounter.location.some((location) => values.includes(location.toLowerCase()));
}

export function encounterProviderPredicate(values: string[], encounter: EncounterModel): boolean {
  if (values.length === 0) return true;
  return encounter.practitioners.some((practitioner) =>
    values.includes(practitioner.toLowerCase()),
  );
}

export function encounterSpecialtyPredicate(values: string[], encounter: EncounterModel): boolean {
  if (values.length === 0) return true;
  // If there is no specialty, return true if we're filtering for unknown
  if (values.includes('unknown') && encounter.locationType.length === 0) {
    return true;
  }
  return encounter.locationType.some((specialty) => values.includes(specialty.toLowerCase()));
}
