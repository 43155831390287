import { twx } from '@ctw/shared/utils/tailwind';
import { ReactNode } from 'react';

type HeadingLevel = 'h1' | 'h2' | 'h3' | 'h4';

export type HeadingProps = {
  className?: string;
  children: ReactNode;
  level: HeadingLevel;
};

export const Heading = ({ className, children, level }: HeadingProps) => {
  const HeadingElement = level;

  return (
    <HeadingElement
      className={twx(
        'capitalize leading-9',
        {
          'text-2xl font-bold': level === 'h1',
          'text-xl font-medium': level === 'h2',
          'text-lg font-medium': level === 'h3',
          'text-sm font-medium': level === 'h4',
        },
        className,
      )}
    >
      {children}
    </HeadingElement>
  );
};
