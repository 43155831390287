import { FHIRModel } from './fhir-model';

export class AppointmentModel extends FHIRModel<fhir4.Appointment> {
  kind = 'Appointment' as const;

  get title() {
    return (
      this.resource.appointmentType?.text ||
      this.resource.serviceType?.[0].text ||
      this.resource.serviceCategory?.[0].text ||
      this.resource.specialty?.[0].text ||
      this.resource.reasonCode?.[0].text ||
      'Appointment'
    );
  }
}
