import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import {
  faCircleCheck as faCircleCheckSolid,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from '@ctw/shared/components/badge';
import { Tooltip } from '@ctw/shared/components/tooltip';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';

export type ResourceTitleColumnProps = {
  title?: string | JSX.Element;
  subTitle?: string | JSX.Element;
  capitalizeTitle?: boolean;
  className?: string;
  renderIcon?: React.ReactNode; // Function returning a React node
};

export const ResourceTitleColumn = ({
  title,
  subTitle,
  className,
  renderIcon, // Add the renderIcon prop to be used within the component
}: ResourceTitleColumnProps) => (
  <div className={twx(className)}>
    <div className={tw`flex justify-between space-x-1.5`}>
      <div className={tw`font-medium group-hover:underline`}>{title}</div>
      <div className={tw`flex flex-nowrap space-x-2`}>{renderIcon}</div>
    </div>
    {subTitle ?
      <div>{subTitle}</div>
    : null}
  </div>
);

// The function to render the FontAwesome icon used for ownedByBuilder
export const RenderSyncedWithRecordIcon = (enable = true, isNew = false) =>
  enable && (
    <Tooltip content="Synced with record">
      <FontAwesomeIcon
        // eslint-disable-next-line tailwindcss/no-custom-classname
        className={tw`${isNew ? 'text-success-main' : 'text-content-light'} size-[14px]`}
        icon={isNew ? faCircleCheckSolid : faCircleCheck}
      />
    </Tooltip>
  );

export type WritebackStatus = {
  state: WritebackState;
  message?: string;
};

export type WritebackState = (typeof writebackStatuses)[number];

const writebackStatuses = [
  'unknown',
  'not-known-to-builder',
  'in-progress',
  'error',
  'known-to-builder-new',
  'known-to-builder',
  'bulk-dismissed-new',
  'dismissed',
] as const;

export function isSelectableWritebackStatus(status: WritebackStatus | undefined): boolean {
  const includedStatuses: WritebackState[] = ['not-known-to-builder', 'unknown'];
  return !status || includedStatuses.includes(status.state);
}

export type Selection = {
  isSelected: boolean;
  changeState: (isBeingSelected: boolean) => void;
};

// The icon on the left of the record. Has several options indicating different things relating to the record's ownership by this builder.
export const RenderLeftIconOfRow = (
  writebackStatus: WritebackStatus,
  selectState?: Selection,
  readOnly = false,
) => {
  const { state: writebackState, message = 'There was an error adding this record to the chart.' } =
    writebackStatus;

  switch (writebackState) {
    case 'known-to-builder-new':
      return RenderSyncedWithRecordIcon(true, true);

    case 'in-progress':
      return <LoadingSpinner iconClass={tw`h-[14px] w-[14px]`} />;

    case 'error':
      return (
        <Tooltip content={message}>
          <FontAwesomeIcon
            className={tw`h-[14px] w-[14px] text-error-main`}
            icon={faExclamationCircle}
          />
        </Tooltip>
      );

    case 'bulk-dismissed-new':
      return (
        <Tooltip content="Successfully dismissed">
          <FontAwesomeIcon
            className={tw`h-[14px] w-[14px] text-content-light`}
            icon={faCircleCheckSolid}
          />
        </Tooltip>
      );

    case 'dismissed':
      return readOnly ? undefined : (
          <input type="checkbox" className={tw`w-4`} checked={false} disabled />
        );

    case 'known-to-builder':
      return RenderSyncedWithRecordIcon(true);

    default:
      if (selectState && !readOnly) {
        const { isSelected, changeState } = selectState;
        return <BulkAddSelectBox isSelected={isSelected} changeState={changeState} />;
      }
  }
  return undefined;
};

export const BulkAddSelectBox = ({ isSelected, changeState }: Selection) => (
  <input
    type="checkbox"
    className={tw`w-4`}
    onClick={(event) => {
      event.stopPropagation();
      changeState(!isSelected);
    }}
    checked={isSelected}
    onChange={(e) => {
      e.stopPropagation();
    }}
  />
);

// The function to render the FontAwesome icon used for info badge
export const RenderInfoBadge = (
  infoBadge?: string,
  infoBadgeClassName?: string,
  toolTipText?: string,
) =>
  infoBadge && (
    <div data-testid="indicator" className={twx(infoBadgeClassName)}>
      {toolTipText ?
        <div className={tw`self-start`}>
          <Tooltip content={toolTipText}>
            <Badge color="info" content={infoBadge} size="xs" noFlex />
          </Tooltip>
        </div>
      : <Badge color="info" content={infoBadge} size="xs" noFlex />}
    </div>
  );
