import { DiagnosticReportModel } from '@ctw/shared/api/fhir/models';
import { Button } from '@ctw/shared/components/button';
import { Drawer } from '@ctw/shared/components/drawer';
import { ObservationDetails } from '@ctw/shared/content/observations/helpers/details';
import { useFetchResource } from '@ctw/shared/content/resource/resource-details-drawer';
import { useAdditionalResourceActions } from '@ctw/shared/content/resource/use-additional-resource-actions';
import { useAnalytics } from '@ctw/shared/context/analytics/use-analytics';
import { useDrawer } from '@ctw/shared/context/drawer-hooks';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { useEffect, useState } from 'react';

export function useObservationsDetailsDrawer(enableDismissAndReadActions = true) {
  const { openDrawer } = useDrawer();

  const openDrawerWithResource = (
    diagnosticReport?: DiagnosticReportModel,
    resourceId?: string,
  ) => {
    const canonicalResourceId = resourceId ?? diagnosticReport?.id;

    openDrawer({
      component: (props) => (
        <ObservationsDrawer
          diagnosticReport={diagnosticReport}
          resourceId={resourceId}
          {...props}
          enableDismissAndReadActions={enableDismissAndReadActions}
          onOpen={() => {
            if (canonicalResourceId) {
              const onOpenUrl = new URL(window.location.href);
              onOpenUrl.searchParams.set('drawerResourceType', 'DiagnosticReport');
              onOpenUrl.searchParams.set('drawerResourceId', canonicalResourceId);
              window.history.pushState({}, '', onOpenUrl);
            }

            props.onOpen?.();
          }}
          onClose={() => {
            const onCloseUrl = new URL(window.location.href);
            onCloseUrl.searchParams.delete('drawerResourceType');
            onCloseUrl.searchParams.delete('drawerResourceId');
            window.history.pushState({}, '', onCloseUrl);

            props.onClose();
          }}
        />
      ),
      trackingMetadata: { action: 'observations_details' },
    });
  };

  useEffect(() => {
    const initialUrl = new URL(window.location.href);
    const initialResourceType = initialUrl.searchParams.get('drawerResourceType');
    const initialResourceId = initialUrl.searchParams.get('drawerResourceId');

    if (initialResourceType === 'DiagnosticReport' && initialResourceId !== null) {
      openDrawerWithResource(undefined, initialResourceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return openDrawerWithResource;
}

export type ObservationsDrawerProps = {
  className?: string;
  diagnosticReport?: DiagnosticReportModel;
  resourceId?: string;
  enableDismissAndReadActions: boolean;
  isOpen: boolean;
  onOpen?: () => void;
  onClose: () => void;
};

function ObservationsDrawer({
  className,
  diagnosticReport,
  resourceId,
  enableDismissAndReadActions,
  isOpen,
  onOpen,
  onClose,
}: ObservationsDrawerProps) {
  const { data } = useFetchResource(resourceId, 'DiagnosticReport');
  const [resource, setResource] = useState<DiagnosticReportModel | undefined>(diagnosticReport);
  const { trackInteraction } = useAnalytics();

  const ResourceActions = useAdditionalResourceActions({
    enableDismissAndReadActions,
  });

  useEffect(() => {
    const canonicalResource = (diagnosticReport || data) as DiagnosticReportModel | undefined;

    if (!resource && canonicalResource) {
      setResource(canonicalResource);
      onOpen?.();
    }
  }, [onOpen, data, resource, diagnosticReport]);

  return (
    <Drawer
      className={twx(className)}
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      renderHeader={() => 'Diagnostic Report'}
      renderFooter={() =>
        resource && (
          <div className={tw`flex justify-between`}>
            <Button
              type="button"
              variant="clear"
              className={tw`!px-4 !py-2`}
              onClick={() => {
                onClose();
                trackInteraction('close_drawer', {
                  target: 'footer_close_icon',
                });
              }}
            >
              Close
            </Button>
            <ResourceActions record={resource} onSuccess={onClose} />
          </div>
        )
      }
    >
      {resource && <ObservationDetails diagnosticReport={resource} />}
    </Drawer>
  );
}
