import { twx } from '@ctw/shared/utils/tailwind';

export function FileIcon({ className }: { className?: string }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="3.75 1.5 12.5 17.5"
      xmlns="http://www.w3.org/2000/svg"
      className={twx(className)}
    >
      <path d="M6 2H11V6C11 6.5625 11.4375 7 12 7H16V16C16 17.125 15.0938 18 14 18H6C4.875 18 4 17.125 4 16V4C4 2.90625 4.875 2 6 2ZM12 2L16 6H12V2ZM7.5 10C7.21875 10 7 10.25 7 10.5C7 10.7812 7.21875 11 7.5 11H12.5C12.75 11 13 10.7812 13 10.5C13 10.25 12.75 10 12.5 10H7.5ZM7.5 12C7.21875 12 7 12.25 7 12.5C7 12.7812 7.21875 13 7.5 13H12.5C12.75 13 13 12.7812 13 12.5C13 12.25 12.75 12 12.5 12H7.5ZM7.5 14C7.21875 14 7 14.25 7 14.5C7 14.7812 7.21875 15 7.5 15H12.5C12.75 15 13 14.7812 13 14.5C13 14.25 12.75 14 12.5 14H7.5Z" />
    </svg>
  );
}
