import { ReactNode } from 'react';
import { TimelineEventModel } from '@ctw/shared/api/fhir/models/timeline-event';
import { SimpleMoreList } from '@ctw/shared/components/simple-more-list';
import { TableColumn } from '@ctw/shared/components/table/table-helpers';
import { tw } from '@ctw/shared/utils/tailwind';

export const patientTimelineColumns: TableColumn<TimelineEventModel>[] = [
  {
    title: 'Date',
    widthPercent: 10,
    minWidth: 120,
    dataIndex: 'date',
  },
  {
    title: 'Type',
    render: (timelineEvent) => (
      <div>
        <div className={tw`font-medium`}>{timelineEvent.type}</div>
        <div>{timelineEvent.subtype as unknown as ReactNode}</div>
      </div>
    ),
  },
  {
    title: 'Actor',
    render: (timelineEvent) => (
      <>
        {timelineEvent.actorDetails.map((detail) => (
          <div className={tw`capitalize`} key={detail}>
            {detail.toLocaleLowerCase()}
          </div>
        ))}
      </>
    ),
  },
  {
    title: 'Modifiers',
    render: (timelineEvent) => (
      <SimpleMoreList
        items={timelineEvent.modifiers}
        limit={3}
        total={timelineEvent.modifiers.length}
      />
    ),
  },
];
