import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Menu } from '@headlessui/react';
import * as RadixDropdownMenu from '@radix-ui/react-dropdown-menu';
import { ReactNode, useState } from 'react';
import { ClassName, tw, twx } from '@ctw/shared/utils/tailwind';

import './action-dropdown.scss';

export type MenuItem = {
  action: () => void;
  className?: string;
  icon?: IconDefinition;
  name: string;
};

export type OptionsItem = {
  key: string;
  name: string;
  display?: ReactNode;
  disabled?: boolean;
  isSelected?: boolean;
};

export type DropdownMenuSimpleProps = {
  buttonClassName?: ClassName;
  children: ReactNode;
  items: OptionsItem[];
  onItemSelect: (clickedItem: { key: string; name: string; value: boolean }) => void;
  isOpen?: boolean;
} & Partial<Pick<RadixDropdownMenu.MenuContentProps, 'align' | 'side'>>;

export function DropdownMenuSimple({
  children,
  items,
  onItemSelect,
  buttonClassName,
  isOpen,
  side,
  align = 'start',
}: DropdownMenuSimpleProps) {
  const [isMenuOpen, setIsMenuOpen] = useState(isOpen);
  return (
    <Menu>
      <RadixDropdownMenu.Root
        modal={false}
        open={isMenuOpen}
        onOpenChange={(e) => setIsMenuOpen(e)}
      >
        <RadixDropdownMenu.Trigger className={twx(buttonClassName)} aria-label="dropdown">
          {children}
        </RadixDropdownMenu.Trigger>
        <RadixDropdownMenu.Content
          align={align}
          side={side}
          // Prevent focus from closing menu, this fixes
          // an issue with interactive testing where a "click"
          // would fire twice, once for the mousedown and
          // again for focus on the button being clicked.
          onFocusOutside={(event) => event.preventDefault()}
          className={tw`action-dropdown bg-bg-white`}
          collisionPadding={10}
        >
          {items.map((menuItem) => (
            <RadixDropdownMenu.Item
              key={menuItem.key}
              className={twx('action-dropdown-item bg-bg-white')}
              onClick={() => {
                if (!menuItem.disabled) {
                  onItemSelect({
                    key: menuItem.key,
                    name: menuItem.name,
                    value: !menuItem.isSelected,
                  });
                }
              }}
            >
              <div
                className={twx('flex w-full justify-between', {
                  'text-content-lighter': menuItem.disabled,
                  '!text-content-black': !menuItem.disabled,
                })}
              >
                {menuItem.display ? menuItem.display : menuItem.name}
              </div>
            </RadixDropdownMenu.Item>
          ))}
        </RadixDropdownMenu.Content>
      </RadixDropdownMenu.Root>
    </Menu>
  );
}
