import { CareGapModel } from '@ctw/shared/api/fhir/models/care-gap';
import { CareGapStatusBadge } from '@ctw/shared/content/care-gaps/status-badge';
import { useResourceDetailsDrawer } from '@ctw/shared/content/resource/resource-details-drawer';
import { tw } from '@ctw/shared/utils/tailwind';

export const useCareGapDetailsDrawer = () => {
  const createHeader = (gap: CareGapModel) => (
    <div>
      <div className={tw`flex pb-2`}>
        <CareGapStatusBadge gap={gap} />
      </div>
      <div>{gap.title}</div>
    </div>
  );

  return useResourceDetailsDrawer({
    header: createHeader,
    details: (gap: CareGapModel) => [{ label: 'Last Updated', value: gap.updatedDate }],
    deepLinkResourceType: 'MeasureReport',
  });
};
