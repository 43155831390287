import { DiagnosticReportModel } from '@ctw/shared/api/fhir/models';
import { SortOption } from '@ctw/shared/content/resource/resource-table-actions';

export const diagnosticReportSortOptions: SortOption<DiagnosticReportModel>[] = [
  {
    display: 'Date (New to Old)',
    sorts: [{ key: 'effectiveStart', dir: 'desc', isDate: true }],
  },
  {
    display: 'Date (Old to New)',
    sorts: [{ key: 'effectiveStart', dir: 'asc', isDate: true }],
  },
  {
    display: 'Name (A-Z)',
    sorts: [{ key: 'displayName', dir: 'asc' }],
  },
  {
    display: 'Name (Z-A)',
    sorts: [{ key: 'displayName', dir: 'desc' }],
  },
];

export const defaultDiagnosticReportSort = diagnosticReportSortOptions[0];
