import { isFunction } from 'lodash';
import { OverviewCard } from '../../components/overview-card';
import { useToggleRead } from '../hooks/use-toggle-read';
import { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import { MinRecordItem } from '@ctw/shared/components/table/table-helpers';
import { useGroupedBy } from '@ctw/shared/content/hooks/use-grouped-by';
import { ResourceOverviewCardProps } from '@ctw/shared/content/overview/resource-overview-card';
import { useAnalytics } from '@ctw/shared/context/analytics/use-analytics';
import { useUserBuilderId } from '@ctw/shared/context/user-builder-id';
import { tw, twx } from '@ctw/shared/utils/tailwind';

export interface ResourceOverviewCardGroupedProps<T extends MinRecordItem>
  extends ResourceOverviewCardProps<T> {
  groupBy: (record: T) => string;
  groupByKeyOrder?: string[];
}

export const ResourceOverviewCardGrouped = <T extends fhir4.Resource, M extends FHIRModel<T>>({
  renderResource,
  data = [],
  onRowClick,
  telemetryTargetName,
  rowClassName,
  groupBy,
  groupByKeyOrder,
  footerCTA,
  ...overviewCardProps
}: ResourceOverviewCardGroupedProps<M>) => {
  const { trackInteraction } = useAnalytics();
  const userBuilderId = useUserBuilderId();
  const { toggleRead } = useToggleRead();
  // Group the records using the groupBy function
  const { groupedRecords, sortedKeys } = useGroupedBy(groupBy, data, groupByKeyOrder);

  function handleRowClick(record: M) {
    if (onRowClick) {
      if (!record.isRead && !record.ownedByBuilder(userBuilderId)) {
        void toggleRead(record);
      }
      onRowClick(record);
      trackInteraction('click_row', { target: telemetryTargetName });
    }
  }

  return (
    <OverviewCard
      {...overviewCardProps}
      empty={data.length === 0}
      fullbleed
      footerCTA={
        footerCTA ?
          {
            label: footerCTA.label,
            onClick: () => {
              trackInteraction('see_all_of_resource', { target: telemetryTargetName });
              footerCTA.onClick();
            },
          }
        : undefined
      }
    >
      {sortedKeys.map((titleKey) => (
        <div data-testid="overview-group-row" key={titleKey}>
          <div className={tw`bg-bg-light pb-1 pl-8 pt-2 text-xs uppercase text-content-light`}>
            {titleKey}
          </div>
          {(groupedRecords[titleKey] ?? []).map((record) => (
            <div
              className={twx('px-2 py-1.5', rowClassName, {
                'cursor-pointer hover:bg-bg-lighter': isFunction(onRowClick),
                'last:rounded-b-lg': !footerCTA,
              })}
              data-testid="resource-overview-row"
              key={record.key}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleRowClick(record);
                }
              }}
              onClick={() => handleRowClick(record)}
            >
              {renderResource(record)}
            </div>
          ))}
        </div>
      ))}
    </OverviewCard>
  );
};
