import '@ctw/shared/styles/main.scss';
import { MedicationStatementModel } from '@ctw/shared/api/fhir/models';
import { PatientMedicationsAllProps } from '@ctw/shared/content/medications/patient-medications-all';
import { PatientMedicationsOutsideProps } from '@ctw/shared/content/medications/patient-medications-outside';
import { ZusAggregatedProfile } from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile';
import {
  ZapIFrameConfig,
  ZapIFrameConfigMessageType,
} from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile-iframe';
import { CTWProvider } from '@ctw/shared/context/ctw-provider';
import { PatientProvider } from '@ctw/shared/context/patient-provider';
import { tw } from '@ctw/shared/utils/tailwind';
import { Telemetry } from '@ctw/shared/utils/telemetry';
import omit from 'lodash/omit';
import { useEffect, useMemo } from 'react';
import { useFont } from '../use-font';
import {
  sendOnAddToRecord,
  sendOnIFrameConfigReceived,
  sendOnIFrameReady,
  sendOnPatientSave,
  sendOnResourceSave,
} from '../utils/send-zap-message-event';
import { useZapConfig } from '../zap-config/use-zap-config';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';

const { VITE_GIT_SHA } = import.meta.env;

// todo: replace this with the imported ZapMessageEvent from @ctw/shared once v1.63.0 is deployed to all EHRs
type ZapIFrameConfigMessage = {
  type: string;
  config?: ZapIFrameConfig; // deprecated
  payload: ZapIFrameConfig;
};

export function ZapV1() {
  const { zapConfig, setZapConfig } = useZapConfig();

  useEffect(() => {
    Telemetry.logger.info('Mounting zap in child frame');
    return () => {
      Telemetry.logger.info('Unmounting zap from child frame');
    };
  }, []);

  // zapProps is a memoized version of zapConfig.ZusAggregatedProfileProps. It creates local handlers where callback
  // functions may normally be used. This is so the local running ZAP can transparently run callback in the parent window.
  const zapProps = useMemo(() => {
    const baseProps = zapConfig?.ZusAggregatedProfileProps ?? {};
    const medOutsideProps = baseProps.medicationsOutsideProps;
    const medAllProps = baseProps.medicationsAllProps;

    function propsReplaceOnAddToRecord(
      props: PatientMedicationsOutsideProps | PatientMedicationsAllProps | undefined,
      component: 'medications-outside' | 'medications-all',
    ) {
      if (!props) return undefined;
      // If readonly then we have no onAddRecord to replace
      if (props.readOnly) return props;

      return {
        ...props,
        // Replace onAddToRecord with a function that sends a message to the parent window and waits for the callback
        onAddToRecord: async (medication: MedicationStatementModel) => {
          await sendOnAddToRecord(medication, component);
        },
      };
    }

    return {
      ...baseProps,
      medicationsOutsideProps: propsReplaceOnAddToRecord(medOutsideProps, 'medications-outside'),
      medicationsAllProps: propsReplaceOnAddToRecord(medAllProps, 'medications-all'),
    };
  }, [zapConfig]);

  useEffect(() => {
    const handler = ({ data }: { data: ZapIFrameConfigMessage }) => {
      if (data.type === ZapIFrameConfigMessageType) {
        const payload = data.config ?? data.payload;
        Telemetry.logger.debug('received ZapIFrameConfig message in child frame', {
          ...payload,
          CTWProviderProps: omit(payload.CTWProviderProps, ['authToken', 'headers', 'theme']),
        });
        // todo: remove deprecated config
        setZapConfig(data.config ?? data.payload);
        sendOnIFrameConfigReceived();
      }
    };

    Telemetry.logger.debug('adding ZapIFrameConfig event listener in child frame');
    window.addEventListener('message', handler);
    sendOnIFrameReady();

    // clean up
    return () => {
      Telemetry.logger.debug('cleaning up ZapIFrameConfig event listener in child frame');
      window.removeEventListener('message', handler);
    };
    // eslint-disable-next-line
  }, [setZapConfig]);

  useFont(zapConfig?.iframeTheme);
  if (zapConfig) {
    return (
      <CTWProvider
        {...zapConfig.CTWProviderProps}
        onResourceSave={sendOnResourceSave}
        featureFlags={{
          ...zapConfig.CTWProviderProps.featureFlags,
          openCCDAInNewTab: false,
        }}
        enableTelemetry
        datadogConfig={{ version: VITE_GIT_SHA }}
      >
        <PatientProvider {...zapConfig.PatientProviderProps} onPatientSave={sendOnPatientSave}>
          <ZusAggregatedProfile {...zapProps} />
        </PatientProvider>
      </CTWProvider>
    );
  }

  return (
    <div className={tw`w-full`}>
      <LoadingSpinner message="Loading..." />
    </div>
  );
}
