import baseTheme from '@ctw/config/tailwind.config';
import { Theme } from './theme-provider';

export const defaultBreakpoints = {
  xs: 400,
  sm: 640,
  md: 768,
  lg: 1024,
} as const;

export const defaultTheme: Theme = {
  ...baseTheme,
  breakpoints: defaultBreakpoints,
};
