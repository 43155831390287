import { tw } from '@ctw/shared/utils/tailwind';
import { faFileCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface PageErrorProps {
  title: string;
  message?: string;
  errorCode?: string;
  supportEmailSubject?: string;
}

export function PageError({ title, message, errorCode, supportEmailSubject }: PageErrorProps) {
  const supportEmailLink =
    supportEmailSubject ?
      `mailto:support@zushealth.com?subject=${encodeURIComponent(supportEmailSubject)}`
    : 'mailto:support@zushealth.com';

  return (
    <div className={tw`ml-10 mt-20 flex flex-col flex-wrap space-y-12 sm:ml-20 lg:ml-80`}>
      <FontAwesomeIcon icon={faFileCircleXmark} className={tw`h-20 w-20 text-content-light`} />
      <div className={tw`space-y-6`}>
        <div className={tw`text-2xl font-medium`}>{title}</div>
        {message && <div className={tw`text-base font-medium`}>{message}</div>}
        {errorCode && <div className={tw`text-xs font-medium text-gray-500`}>{errorCode}</div>}
        <div>
          <a
            className={tw`btn zap-case-normal w-fit rounded-md border border-transparent bg-primary-main px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-dark`}
            href={supportEmailLink}
          >
            Contact support@zushealth.com
          </a>
        </div>
      </div>
    </div>
  );
}
