import { createContext } from 'react';
import { TrackingMetadata } from './analytics/tracking-metadata';
import { DrawerProps } from '@ctw/shared/components/drawer';

export type OpenDrawerProps = {
  trackingMetadata?: TrackingMetadata;
  component: ({
    isOpen,
    onClose,
    onOpen,
    onAfterOpened,
  }: Pick<DrawerProps, 'isOpen' | 'onClose' | 'onOpen' | 'onAfterOpened'>) =>
    | JSX.Element
    | undefined;
};

export type DrawerState = {
  closeDrawer: () => void;
  openDrawer: (props: OpenDrawerProps) => void;
};

export const DrawerContext = createContext<DrawerState | undefined>(undefined);
