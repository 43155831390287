import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { ActionDropdown } from '@ctw/shared/components/dropdown/action-dropdown';
import { useAnalytics } from '@ctw/shared/context/analytics/use-analytics';
import { FilterEntry } from '@ctw/shared/utils/filters';
import { ClassName, tw, twx } from '@ctw/shared/utils/tailwind';

export type ViewOption<T extends object> = {
  display: string;
  filters: FilterEntry<T>[];
};

export type ViewButtonProps<T extends object> = {
  className?: ClassName;
  defaultView?: string;
  onChange: (view: ViewOption<T>) => void;
  options: ViewOption<T>[];
};

export const ViewButton = <T extends object>({
  className,
  defaultView,
  onChange,
  options,
}: ViewButtonProps<T>) => {
  const defaultViewOption = options.find((o) => o.display === defaultView) ?? options[0];
  const [selected, setSelected] = useState<ViewOption<T>>(defaultViewOption);
  const { trackInteraction } = useAnalytics();

  return (
    <ActionDropdown
      type="select"
      buttonClassName={twx(className, 'border-none bg-transparent p-0')}
      onItemSelect={(item) => {
        const selectedOption = options.filter((option) => option.display === item.key)[0];
        onChange(selectedOption);
        setSelected(selectedOption);
        trackInteraction('change_view', { value: selectedOption.display });
      }}
      items={options.map((option) => ({
        key: option.display,
        name: option.display,
        isSelected: selected.display === option.display,
      }))}
    >
      <div
        className={tw`flex items-center space-x-2 rounded-md border border-solid border-divider-main border-transparent bg-white px-4 py-2 text-sm font-medium capitalize text-content-light shadow-sm hover:bg-bg-lighter`}
      >
        <span className={tw`mr-1.5 w-full font-normal`}>{selected.display}</span>
        <FontAwesomeIcon icon={faChevronDown} className={tw`ml-auto w-2`} />
      </div>
    </ActionDropdown>
  );
};
