import './zap-tab-group.scss';

import { Tab } from '@headlessui/react';
import { ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { PatientHeader } from './patient-header';
import { withErrorBoundary } from '@ctw/shared/content/error-boundary';
import { ListBox } from '@ctw/shared/components/list-box/list-box';
import { usePatientSummaryDrawer } from '@ctw/shared/content/chart-review/patient-summary-drawer';
import { useAnalytics } from '@ctw/shared/context/analytics/use-analytics';
import { usePatientContext } from '@ctw/shared/context/patient-provider';
import {
  ZapTabStateContext,
  ZapTabStateContextType,
} from '@ctw/shared/context/zap-tab-state-context';
import { useBreakpoints } from '@ctw/shared/hooks/use-breakpoints';
import { useFeatureFlag } from '@ctw/shared/hooks/use-feature-flag';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { Button } from '@ctw/shared/components/button';
import { faMagicWandSparkles } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PrototypeLabel } from '@ctw/shared/components/prototype-label';

export type TabGroupProps = {
  children?: ReactNode;
  className?: string;
  content: TabGroupItem[];
  forceHorizontalTabs?: boolean;
  topRightContent?: ReactNode;
};

export type TabGroupItem = {
  display: () => string | ReactNode;
  key: string;
  render: (sm: boolean) => string | ReactNode;
};

/**
 * When rendered in a small breakpoint the component will change from horizontal
 * tabs to a vertical dropdown menu. If this is undesired, you may set the
 * property `forceHorizontalTabs` to true and the tabs will remain visible and
 * horizontal.
 */
function ZAPTabGroupComponent({
  children,
  className,
  content,
  forceHorizontalTabs = false,
  selectedZapTabIndex,
  setSelectedTab,
  tabOrder,
  topRightContent,
}: TabGroupProps & Omit<ZapTabStateContextType, 'setTabOrder'>) {
  // State used for responsive tab dropdown menu.
  const topRightContentRef = useRef<HTMLDivElement>(null);
  const topLeftContentRef = useRef<HTMLDivElement>(null);
  const moreMenuRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const breakpoints = useBreakpoints(containerRef);
  const [tabOverflowCutoff, setTabOverflowCutoff] = useState(content.length);
  const hasHpiInteractiveFeature = useFeatureFlag('ctw-hpi-interactive');

  const openDrawer = usePatientSummaryDrawer();

  const { trackInteraction } = useAnalytics();
  // Work around for not wanting to pre-mount all tabs.
  // https://github.com/tailwindlabs/headlessui/issues/2276#issuecomment-1456537475
  const [shown, setShown] = useState<Record<number, boolean>>({ 0: true });

  // Calculate how many tabs can fit in the container,
  useEffect(() => {
    // Force everything into the more menu if we are in a small breakpoint,
    // and we are not forcing horizontal tabs.
    if (breakpoints.sm && !forceHorizontalTabs) {
      setTabOverflowCutoff(0);
      return;
    }

    const container = containerRef.current;
    if (!container) return;

    // Start with the width of the container.
    let { width } = container.getBoundingClientRect();

    // Subtract the corner content if there is any.
    if (topRightContentRef.current) {
      width -= topRightContentRef.current.getBoundingClientRect().width;
    }
    if (topLeftContentRef.current) {
      width -= topLeftContentRef.current.getBoundingClientRect().width;
    }

    // Get width of the more menu and its spacing but don't subtract it yet.
    const moreWidth = moreMenuRef.current?.getBoundingClientRect().width ?? 0;

    // Grab our tabs and keep subtracting their widths until we run out of space.
    const tabs = Array.from(container.querySelectorAll('button.tab')) as HTMLButtonElement[];
    setTabOverflowCutoff(
      tabs.filter((tab, index) => {
        // Subtract the tabs width and spacing for all but the first tab.
        width -= tab.getBoundingClientRect().width;
        // All but the last tab must reserve space for the more menu.
        if (index < tabs.length - 1) {
          return width >= moreWidth;
        }

        // The last tab can use the remaining space and ignore more menu
        // as we'll not show more menu if we are displaying all tabs.
        return width >= 0;
      }).length,
    );
  }, [breakpoints, forceHorizontalTabs]); // Recalculate when the breakpoint changes (resize observer).

  useEffect(() => {
    trackInteraction('open_tab', {
      value: tabOrder[0],
      customMetadata: { default: true },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!shown[selectedZapTabIndex]) {
      setShown({ ...shown, [selectedZapTabIndex]: true });
    }
  }, [selectedZapTabIndex, shown]);

  const handleOnChange = useCallback(
    (index: number) => {
      setSelectedTab(index);
      onClickBlur();
      trackInteraction('open_tab', { value: tabOrder[index] });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [trackInteraction, tabOrder],
  );

  const showTopRightContent = !!topRightContent;
  const shownContent = content.slice(tabOverflowCutoff);

  return (
    <div
      ref={containerRef}
      className={twx(className, 'zap-tab-group scrollable-pass-through-height w-full')}
    >
      <Tab.Group selectedIndex={selectedZapTabIndex} onChange={handleOnChange}>
        <div
          className={twx(
            'border-divider-main, sticky top-0 z-50 flex border-b bg-white',
            tabOverflowCutoff === 0 ? 'flex-col' : 'items-end',
          )}
        >
          <PatientHeader collapse={tabOverflowCutoff === 0} ref={topLeftContentRef} />
          <div
            className={twx(
              'flex w-full content-between',
              'border-default border-divider-light bg-white',
              tabOverflowCutoff === 0 &&
                'underline-light border-default justify-between border-t border-divider-light bg-white',
            )}
          >
            <Tab.List
              className={twx(
                'flex',
                tabOverflowCutoff === 0 && 'after:bg-content-black',
                tabOverflowCutoff > 0 && 'rounded-t-md border-x border-t border-divider-main',
              )}
            >
              {/* Renders button for each tab using "display | display()" */}
              {content.map(({ key, display }, index) => (
                <Tab
                  key={key}
                  // eslint-disable-next-line no-restricted-syntax
                  className={({ selected }) =>
                    twx(
                      [
                        'tab whitespace-nowrap text-sm capitalize',
                        'hover:after:bg-content-black',
                        'focus-visible:outline-primary-dark focus-visible:after:bg-transparent',
                      ],
                      {
                        'after:bg-content-black': selected,
                        'text-content-light': !selected,
                      },
                      { 'invisible !absolute': index >= tabOverflowCutoff }, // Still render tab so we can derive width.
                    )
                  }
                >
                  <div className={tw`divider-wrapper`}>{display()}</div>
                </Tab>
              ))}

              {/* More Button */}
              {tabOverflowCutoff < content.length && (
                <ListBox
                  ref={moreMenuRef}
                  selectedIndex={selectedZapTabIndex - tabOverflowCutoff}
                  btnClassName={twx(
                    'more-tab h-full flex-shrink-0 whitespace-nowrap text-sm capitalize',
                    'hover:after:bg-content-black',
                    {
                      'after:bg-content-black': selectedZapTabIndex - tabOverflowCutoff >= 0,
                    },
                  )}
                  optionsClassName={twx(
                    'tab-list capitalize',
                    !showTopRightContent && tabOverflowCutoff > 2 && 'right-0', // Right-align dropdown if it is rightmost (and not leftmost)
                  )}
                  onChange={(index) => handleOnChange(index + tabOverflowCutoff)}
                  items={shownContent}
                >
                  {tabOverflowCutoff !== 0 ?
                    <span>More</span>
                  : undefined}
                </ListBox>
              )}
            </Tab.List>
            {hasHpiInteractiveFeature && (
              <div className={tw`flex py-1 pl-4`} ref={topRightContentRef}>
                <Button
                  type="button"
                  variant="unstyled"
                  className={tw`flex flex-shrink-0 items-center space-x-1 whitespace-nowrap px-4 font-medium hover:rounded-md hover:bg-bg-lighter`}
                  onClick={() => {
                    openDrawer();
                    trackInteraction('open_gps_summary');
                  }}
                >
                  <span className={tw`inline-block`}>
                    <FontAwesomeIcon
                      icon={faMagicWandSparkles}
                      className={tw`h-5 w-5 text-primary-main`}
                    />
                  </span>
                  <span className={tw`text-sm text-content-black`}>GPS</span>
                  <PrototypeLabel deemphasized stage="pilot" />
                </Button>
              </div>
            )}
          </div>
        </div>

        {/* Children are always rendered and appear above the active panel */}
        {children}

        {/* Renders body of each tab using "render()" */}
        <Tab.Panels className={tw`scrollable-content bg-white px-4`}>
          {content.map((item, index) => (
            <Tab.Panel
              key={item.key}
              className={twx('scrollable -pass-through-height')}
              // Don't unmount our tabs. Th is fixes an issue
              // where ZAP filters/sort selections would get reset
              // when switching to a new tab and back again.
              unmount={false}
            >
              {shown[index] && item.render(breakpoints.sm)}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}

function ZAPTabGroupContainerComponent(props: TabGroupProps) {
  // Setting up the context for the ZAP tab group outside the component so that we don't track a view before the patient is loaded and tabOrder is defined
  const { selectedZapTabIndex, setSelectedTab, tabOrder } = useContext(ZapTabStateContext);
  const { patient } = usePatientContext();

  if (!patient.data?.UPID || tabOrder.length === 0) {
    return null;
  }

  return (
    <ZAPTabGroupComponent
      {...props}
      selectedZapTabIndex={selectedZapTabIndex}
      setSelectedTab={setSelectedTab}
      tabOrder={tabOrder}
    />
  );
}

export const ZAPTabGroup = withErrorBoundary(ZAPTabGroupContainerComponent, 'TabGroup');

function onClickBlur() {
  if (typeof document !== 'undefined') {
    requestAnimationFrame(() => {
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    });
  }
}
