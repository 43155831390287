import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import {
  createGraphqlClient,
  fqsRequestAll,
  MAX_OBJECTS_PER_REQUEST,
} from '@ctw/shared/api/fqs/client';
import { PatientModel } from './models';
import {
  AppointmentGraphqlResponse,
  AppointmentQuery,
} from '@ctw/shared/api/fqs/queries/appointments';

export async function fetchAppointments(requestContext: CTWRequestContext, patient: PatientModel) {
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequestAll<AppointmentGraphqlResponse>(
    graphClient,
    AppointmentQuery,
    'AppointmentConnection',
    {
      upid: patient.UPID,
      cursor: '',
      first: MAX_OBJECTS_PER_REQUEST,
    },
  );

  return data.AppointmentConnection.edges.map(({ node }) => node);
}
