import { forwardRef } from 'react';
import { usePatientContext } from '@ctw/shared/context/patient-provider';
import { tw, twx } from '@ctw/shared/utils/tailwind';

export const PatientHeader = forwardRef<HTMLDivElement, { collapse: boolean }>(
  ({ collapse }, ref) => {
    const { patient } = usePatientContext();

    return (
      <div
        className={twx(
          'flex h-full w-fit text-content-black',
          collapse ? 'items-end space-x-1 px-4 py-3' : 'flex-col whitespace-nowrap px-5 py-2',
        )}
        ref={ref}
        data-testid="ctw-zap-patient-header"
      >
        <div className={tw`flex font-medium`}>
          <div className={tw`min-w-fit space-x-1`}>
            <span>
              {patient.data?.givenName} {patient.data?.lastName}
            </span>
            {patient.data?.gender && <span>({patient.data.gender[0].toUpperCase()})</span>}
          </div>
        </div>
        <div className={tw`whitespace-nowrap text-sm`}>
          {patient.data?.dob &&
            patient.data.age !== undefined &&
            `${patient.data.dob} (${patient.data.age})`}
        </div>
      </div>
    );
  },
);
