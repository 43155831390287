import { faCircleExclamation, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PatientHistoryDetail } from './patient-history-detail';
import { usePatientHistoryStatus } from './use-patient-history-status';
import { ClassName, tw, twx } from '@ctw/shared/utils/tailwind';

type PatientHistoryLastCompletedProps = {
  className?: ClassName;
};

export const PatientHistoryLastCompleted = ({ className }: PatientHistoryLastCompletedProps) => {
  const { isLoading, lastCompletedDate, derivedStatus } = usePatientHistoryStatus();

  if (isLoading) {
    return null;
  }

  if (derivedStatus === 'complete') {
    return (
      <PatientHistoryDetail
        text="Last updated"
        date={lastCompletedDate}
        className={twx(className)}
      />
    );
  }

  if (derivedStatus === 'completeWithErrors') {
    return (
      <PatientHistoryDetail
        text="Last updated"
        date={lastCompletedDate}
        className={twx(className)}
        icon={<FontAwesomeIcon icon={faWarning} className={tw`text-caution-icon`} />}
        tooltip="There was an error fetching some records for this patient"
      />
    );
  }

  if (derivedStatus === 'failed') {
    return (
      <PatientHistoryDetail
        text="Last request failed"
        date={lastCompletedDate}
        className={twx(className, 'text-error-main')}
        icon={faCircleExclamation}
        tooltip="There was an error fetching records for this patient"
      />
    );
  }

  return <PatientHistoryDetail text="Records never requested" />;
};
