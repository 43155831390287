import { getFormsConditionsUrl } from './urls';
import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { ctwFetch } from '@ctw/shared/utils/request';

export const getAutoCompleteConditions = async (
  requestContext: CTWRequestContext,
  searchTerm: string,
) => {
  const { authToken, builderId } = requestContext;
  const { data } = await ctwFetch(
    `${getFormsConditionsUrl(requestContext.env)}?display=${searchTerm}`,
    {
      headers: {
        Authorization: `Bearer ${authToken}`,
        ...(builderId && { 'Zus-Account': builderId }),
      },
    },
  );
  return data.conditionsList;
};
