import xpath from 'xpath';
import { DocumentDetails } from './components/DocumentDetails';
import { Header } from './components/Header/Header';
import { Section, SectionContainer } from './components/Section/Section';
import { getNarrativeForSection } from './helpers/sectionNarrative/get-section-narrative';
import { SectionType } from './types';
import { TABLE_OF_CONTENT_ID } from '../constants';
import { PatientModel } from '@ctw/shared/api/fhir/models';
import { withErrorBoundary } from '@ctw/shared/content/error-boundary';
import { EmptyNotes } from '@ctw/shared/content/document/helpers/empty-notes';
import { tw } from '@ctw/shared/utils/tailwind';

type CcdaViewerComponentProps = {
  document: Document;
  overridePatient: PatientModel | undefined;
};

const sectionToUseSectionNarrative = [
  'Progress Notes',
  'Patient Instructions',
  'ED Notes',
  'Discharge Instructions',
  'H&P Notes',
  'Consult Notes',
  'Miscellaneous Notes',
];

export const CcdaViewerComponent = ({
  document,
  overridePatient,
}: CcdaViewerComponentProps): JSX.Element => {
  const sections = xpath.select(
    "//*[name()='component']/*[name()='section']",
    document,
  ) as Document[];

  const texts: SectionType[] = sections.map((section, index) => {
    const title = String(xpath.select1("string(*[name()='title'])", section));
    let isEmpty = false;
    const id = `${title}-${index}`; // This way we can handle multiple sections with the same title.
    let humanReadable = xpath.select1(`*[name()='text']`, section) as Element | null;

    const textContent = humanReadable?.textContent;

    // Checks if the textContent is empty or contains a substring from EmptyNotes
    if (textContent && textContent.length < 500) {
      isEmpty = EmptyNotes.some(
        (emptyNoteStr) =>
          textContent.length < 500 && textContent.toLowerCase().includes(emptyNoteStr),
      );
    }

    if (sectionToUseSectionNarrative.includes(title)) {
      const sectionNarrative = getNarrativeForSection(section);
      if (!sectionNarrative) {
        return { title, humanReadable, id, isEmpty };
      }

      humanReadable = sectionNarrative.div as unknown as Element;
    }

    return { title, humanReadable, id, isEmpty };
  });

  return (
    <div className={tw`print:min-h-full print:overflow-auto`} id="ccda-viewer">
      <Header document={document} sections={texts} overridePatient={overridePatient} />
      <div className={tw`flex flex-col`}>
        {texts.map(({ title, humanReadable, id, isEmpty }) => (
          <Section
            key={`el-text-${id}`}
            title={title}
            id={id}
            humanReadable={humanReadable}
            isEmpty={isEmpty}
          />
        ))}
      </div>
      <SectionContainer
        title="Demographic Details"
        id="demographic-details"
        tableOfContentsId={TABLE_OF_CONTENT_ID}
      >
        <DocumentDetails document={document} />
      </SectionContainer>
    </div>
  );
};

export const CcdaViewer = withErrorBoundary(CcdaViewerComponent);
