import { canonicalizeContactInfo } from './helpers/canonicalization';
import { useMatchedPatients } from '@ctw/shared/api/fhir/patient-helper';
import { withErrorBoundary } from '@ctw/shared/content/error-boundary';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { Table } from '@ctw/shared/components/table/table';
import { canonicalContactColumns } from '@ctw/shared/content/demographics/helpers/columns';
import { AnalyticsProvider } from '@ctw/shared/context/analytics/analytics-provider';
import { useUserBuilderId } from '@ctw/shared/context/user-builder-id';
import { tw, twx } from '@ctw/shared/utils/tailwind';

export type ContactInformationProps = {
  className?: string;
};

const ContactInformationComponent = ({ className }: ContactInformationProps) => {
  const builderId = useUserBuilderId();
  const matchedPatients = useMatchedPatients({
    includeCurrentBuilder: true,
  });

  if (!matchedPatients.data) {
    return <LoadingSpinner message="Loading..." />;
  }

  const canonicalContactInfo = canonicalizeContactInfo(builderId, matchedPatients.data);

  return (
    <AnalyticsProvider componentName="ContactInformation">
      <div className={twx(className, 'scrollable-pass-through-height pt-5')}>
        <div className={tw`flex flex-col justify-between gap-5`}>
          <div data-testid="phone-numbers">
            <Table
              className={tw`rounded-md !border border-gray-200`}
              records={canonicalContactInfo.phones}
              columns={canonicalContactColumns('Phone Number')}
              hidePagination
              emptyMessage="No phone numbers found for this patient."
            />
          </div>
          <div data-testid="email-addresses">
            <Table
              className={tw`rounded-md !border border-gray-200`}
              records={canonicalContactInfo.emails}
              columns={canonicalContactColumns('Email Address')}
              hidePagination
              emptyMessage="No email addresses found for this patient."
            />
          </div>
          <div data-testid="addresses">
            <Table
              className={tw`rounded-md !border border-gray-200`}
              records={canonicalContactInfo.addresses}
              columns={canonicalContactColumns('Address')}
              hidePagination
              emptyMessage="No addresses found for this patient."
            />
          </div>
        </div>
      </div>
    </AnalyticsProvider>
  );
};

export const ContactInformation = withErrorBoundary(
  ContactInformationComponent,
  'ContactInformation',
);
