import { ConditionModel, ConditionStatuses } from '@ctw/shared/api/fhir/models/condition';
import { ViewOption } from '@ctw/shared/content/resource/helpers/view-button';

// Filter for clinicalStatus and/or verificationStatus
function statusFilter(statuses: ConditionStatuses[]) {
  return (data: ConditionModel[]) => data.filter((c) => statuses.includes(c.displayStatus));
}

export type ConditionViewOptions = 'Current' | 'Past' | 'All';

const viewOptions: ViewOption<ConditionModel>[] = [
  {
    display: 'Current',
    filters: [statusFilter(['Active', 'Pending', 'Unknown'])],
  },
  {
    display: 'Past',
    filters: [statusFilter(['Inactive', 'Refuted'])],
  },
  {
    display: 'All',
    filters: [(data: ConditionModel[]) => data],
  },
];

export const statusView = {
  viewOptions,
  current: viewOptions[0],
  past: viewOptions[1],
  all: viewOptions[2],
};
