import { useQuery } from '@tanstack/react-query';
import { CTWRequestContext } from './ctw-context';
import { useCTW } from './use-ctw';
import { withTimerMetric } from '@ctw/shared/utils/telemetry';

function useQueryWithCTW<T, T2>(
  queryKey: string,
  keys: T2[],
  query: (requestContext: CTWRequestContext, keys?: T2[], odsMirrorHeader?: boolean) => Promise<T>,
  enabled = true,
  staleTime: number | undefined = undefined,
) {
  const { getRequestContext } = useCTW();
  return useQuery({
    queryKey: [queryKey, ...keys],
    queryFn: async () => {
      const requestContext = await getRequestContext();
      return query(requestContext, keys);
    },
    staleTime,
    enabled,
  });
}

export function useTimerQueryWithCTW<T, T2>(
  queryKey: string,
  keys: T2[],
  query: (requestContext: CTWRequestContext, keys?: T2[]) => Promise<T>,
  enabled = true,
  staleTime: number | undefined = undefined,
) {
  return useQueryWithCTW(
    queryKey,
    keys,
    withTimerMetric(query, 'query_request_timing', [`query_key:${queryKey}`]),
    enabled,
    staleTime,
  );
}
