import { compact, groupBy, orderBy, uniq } from 'lodash';
import { getNonLensEncountersFQS } from './encounters';
import { EncounterModel, ObservationModel, PatientModel } from './models';
import { VitalsBucket } from './models/vitals-bucket';
import { referenceToId } from './resource-helper';
import { SYSTEM_LOINC } from './system-urls';
import {
  createGraphqlClient,
  fqsRequest,
  MAX_OBJECTS_PER_REQUEST,
} from '@ctw/shared/api/fqs/client';
import { VitalGraphqlResponse, vitalQuery } from '@ctw/shared/api/fqs/queries/vitals';
import { vitalLoincCodes } from '@ctw/shared/content/vitals/vital-loinc-codes';
import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { useTimingQueryWithPatient } from '@ctw/shared/context/patient-provider';
import { QUERY_KEY_PATIENT_VITALS } from '@ctw/shared/utils/query-keys';

// Returns an array of EncounterModelWithVitals, each with an array of vitals.
export function usePatientVitals() {
  return useTimingQueryWithPatient(QUERY_KEY_PATIENT_VITALS, [], fetchVitals);
}

// First fetches observations for vital loinc codes.
// Then fetches all encounters referenced by those observations.
// Returns an array of EncounterModelWithVitals, each with an array of vitals.
async function fetchVitals(requestContext: CTWRequestContext, patient: PatientModel) {
  const vitals = await fetchVitalObservations(requestContext, patient);
  if (vitals.length === 0) {
    return [];
  }

  const encounters = await fetchVitalEncounters(vitals, requestContext, patient);

  return createVitalBuckets(vitals, requestContext.builderId, encounters);
}

async function fetchVitalObservations(requestContext: CTWRequestContext, patient: PatientModel) {
  const codes = vitalLoincCodes.map((loincCode) => `${SYSTEM_LOINC}|${loincCode}`); // turn loincCodes into system|code format expected by FQS
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequest<VitalGraphqlResponse>(graphClient, vitalQuery, {
    upid: patient.UPID,
    cursor: '',
    first: MAX_OBJECTS_PER_REQUEST,
    sort: {
      lastUpdated: 'DESC',
    },
    filter: {
      code: {
        anymatch: codes,
      },
    },
  });

  const vitalModels = data.ObservationConnection.edges.map(
    ({ node }) => new ObservationModel(node, node.ProvenanceList),
  );

  // Remove vitals that don't have any value.
  // This can help prevent blank columns appearing in our vitals table.
  return vitalModels.filter((vital) => vital.value !== '');
}

function fetchVitalEncounters(
  vitals: ObservationModel[],
  requestContext: CTWRequestContext,
  patient: PatientModel,
) {
  const encounterRefs = uniq(
    vitals.map((observation) => observation.resource.encounter?.reference),
  );
  const encounterIds = compact(encounterRefs.map(referenceToId));
  return getNonLensEncountersFQS(MAX_OBJECTS_PER_REQUEST, encounterIds)(requestContext, patient);
}

// Group all vitals by date and grab the most recent encounter for each date.
// This way we handle a few different cases:
// 1. Duplicate encounters for the same date. We'll add all of their vitals to a single bucket
//    and take the average of the matching vitals thus cancelling out the duplications.
// 2. Vitals without encounters will still be grouped by date and displayed in the UI.
// 3. Different encounters on the same date will be displayed together, using the most recent
//    encounter for that date.
function createVitalBuckets(
  vitals: ObservationModel[],
  builderId: string,
  encounters: EncounterModel[],
) {
  const vitalsByDate = groupBy(vitals, (observation) => observation.effectiveStart);
  const encountersByDate = groupBy(encounters, 'periodStart');

  return Object.entries(vitalsByDate).map(([date, dateVitals]) => {
    // Grab the most recent encounter that has a type.
    const orderedEncounters = orderBy(
      encountersByDate[date],
      (e) => e.resource.period?.start,
      'desc',
    );
    const encounter =
      orderedEncounters.find((e) => e.typeDisplay !== 'Unknown') ?? orderedEncounters[0];
    return new VitalsBucket(dateVitals, builderId, encounter);
  });
}
