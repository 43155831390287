import { ImmunizationModel } from '@ctw/shared/api/fhir/models/immunization';
import { SortOption } from '@ctw/shared/content/resource/resource-table-actions';

export const defaultImmunizationSort: SortOption<ImmunizationModel> = {
  display: 'Name (A-Z)',
  sorts: [
    { key: 'description', dir: 'asc' },
    { key: 'occurrence', dir: 'desc', isDate: true },
  ],
};

export const immunizationSortOptions: SortOption<ImmunizationModel>[] = [
  defaultImmunizationSort,
  {
    display: 'Name (Z-A)',
    sorts: [
      { key: 'description', dir: 'desc' },
      { key: 'occurrence', dir: 'desc', isDate: true },
    ],
  },
  {
    display: 'Date (New to Old)',
    sorts: [{ key: 'occurrence', dir: 'desc', isDate: true }],
  },
  {
    display: 'Date (Old to New)',
    sorts: [{ key: 'occurrence', dir: 'asc', isDate: true }],
  },
];
