import { useRequestRecordsModal } from './request-records-modal';
import { usePatientHistory } from './use-patient-history';
import { usePatientSubscription } from '@ctw/shared/api/subscriptions/subscriptions';
import { useAnalytics } from '@ctw/shared/context/analytics/use-analytics';
import { ClassName, twx } from '@ctw/shared/utils/tailwind';
import { Button } from '@ctw/shared/components/button';

type RequestRecordsButtonProps = {
  className?: ClassName;
  displayText?: string;
  // Ideally we want to skip the modal when there's been zero requests
  // or when patient history needs to be requested manually (custom package).
  skipModal?: boolean;
};

export const RequestRecordsButton = ({
  className,
  displayText = 'Request records',
  skipModal = false,
}: RequestRecordsButtonProps) => {
  const openRequestRecordsModal = useRequestRecordsModal();
  const { openHistoryRequestDrawer } = usePatientHistory();
  const patientSubscription = usePatientSubscription();
  const { trackInteraction } = useAnalytics();

  return (
    <Button
      type="button"
      variant="clear-link"
      // Add line-height (leading) to increase the clickable area.
      className={twx('text-sm !leading-5', className)}
      onClick={() => {
        // Skip modal if explicitly requested or if the package requires manual requests.
        if (skipModal || patientSubscription.data?.package?.requiresManualRequests) {
          void openHistoryRequestDrawer();
        } else {
          openRequestRecordsModal();
        }
        trackInteraction('request_records');
      }}
    >
      {displayText}
    </Button>
  );
};
