import { EpisodeOfCare } from 'fhir/r4';
import { gql } from 'graphql-request';
import { GraphqlConnectionNode, GraphqlPageInfo } from '../client';
import { fragmentEpisodeOfCareWithoutPatient } from './fragments/episode-of-care';

export interface EpisodeOfCareConnection {
  pageInfo: GraphqlPageInfo;
  edges: GraphqlConnectionNode<EpisodeOfCare>[];
}

export interface EpisodeOfCareGraphqlResponse {
  EpisodeOfCareConnection: EpisodeOfCareConnection;
}

export const episodeOfCareQuery = gql`
  ${fragmentEpisodeOfCareWithoutPatient}

  query EpisodeOfCare(
    $upid: ID!
    $cursor: String!
    $first: Int!
    $filter: EpisodeOfCareFilterParams!
  ) {
    EpisodeOfCareConnection(upid: $upid, after: $cursor, first: $first, filter: $filter) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...EpisodeOfCare
        }
      }
    }
  }
`;
