import { capitalize, compact, upperCase } from 'lodash';
import { RelatedDocumentEntryProps } from './related-document-entry';
import { RelatedDocuments } from './related-documents';
import { DocumentModel } from '@ctw/shared/api/fhir/models';
import { ReferralModel } from '@ctw/shared/api/fhir/models/referral';
import { Badge } from '@ctw/shared/components/badge';
import { DetailEntry } from '@ctw/shared/content/resource/helpers/details-card';
import { useResourceDetailsDrawer } from '@ctw/shared/content/resource/resource-details-drawer';
import { UseQueryResultBasic } from '@ctw/shared/utils/request';
import { tw } from '@ctw/shared/utils/tailwind';

function createRelatedDocumentEntry(document: DocumentModel): RelatedDocumentEntryProps {
  return {
    date: document.encounterDate,
    id: document.id,
    title: document.title,
    hideEmpty: false,
    subtitle: document.custodian,
    details: [
      {
        label: 'Status',
        value: capitalize(document.status),
      },
      {
        label: 'Category',
        value:
          document.category.length > 0 ?
            document.category.map((category) => capitalize(category.text)).join(', ')
          : 'Unknown',
      },
    ],
  };
}

export function useReferralRelatedDocuments(
  referral: ReferralModel,
): UseQueryResultBasic<RelatedDocumentEntryProps[]> {
  return {
    data: referral.encounter?.docsAndNotes.map(createRelatedDocumentEntry) || [],
    isLoading: false,
  };
}

const relatedDocuments = (referral: ReferralModel) => (
  <RelatedDocuments
    limit={10}
    getRelatedDocuments={useReferralRelatedDocuments}
    referral={referral}
  />
);

export const useReferralDetailsDrawer = () =>
  useResourceDetailsDrawer({
    deepLinkResourceType: 'ServiceRequest',
    header: (referral) => referral.referredPractitionerName,
    subHeader: (referral) =>
      `${referral.referredPracticeName} ${referral.referredPracticePhoneNumber || ''}`,
    details: (referral: ReferralModel) =>
      compact<DetailEntry>([
        {
          label: 'Status',
          value: (
            <Badge
              color={referral.status === 'acknowledged' ? 'muted' : 'primary'}
              content={upperCase(referral.status === 'acknowledged' ? 'closed' : 'open')}
            />
          ),
        },
        ...referral.referredPractitioners.map((referredPractitioner, index) => ({
          label:
            referral.referredPractitioners.length === 1 ?
              'Referred Provider'
            : `Referred Provider ${index + 1}`,
          value: (
            <div className={tw`leading-tight`}>
              {referredPractitioner.name || 'Unknown'}
              {referredPractitioner.practiceName && (
                <>
                  <br />
                  {referredPractitioner.practiceName}
                </>
              )}
              {referredPractitioner.phoneNumber && (
                <>
                  <br />
                  {referredPractitioner.phoneNumber}
                </>
              )}
            </div>
          ),
        })),
        {
          beginsNewSection: true,
          label: 'Ordered',
          value: referral.referredAt?.toLocaleDateString(),
        },
        referral.status === 'scheduled' && {
          label: 'Scheduled By',
          value: (
            <div className={tw`leading-tight`}>
              {referral.referredPracticeName}
              {referral.referredPracticePhoneNumber && (
                <>
                  <br />
                  {referral.referredPracticePhoneNumber}
                </>
              )}
            </div>
          ),
        },
        {
          label: 'Ordered By',
          value: (
            <div className={tw`leading-tight`}>
              {referral.referringPractitionerName || 'Unknown'}
              {referral.referringPracticeName && (
                <>
                  <br />
                  {referral.referringPracticeName}
                </>
              )}
              {referral.referringPracticePhoneNumber && (
                <>
                  <br />
                  {referral.referringPracticePhoneNumber}
                </>
              )}
            </div>
          ),
        },
        referral.status === 'scheduled' && {
          label: 'Scheduled',
          value: referral.scheduledAt?.toLocaleDateString(),
        },
        referral.status === 'scheduled' && {
          label: 'Scheduled by',
          value: (
            <div className={tw`leading-tight`}>
              {referral.referredPracticeName && (
                <>
                  <br />
                  {referral.referringPracticeName}
                </>
              )}
              {referral.referringPracticePhoneNumber && (
                <>
                  <br />
                  {referral.referringPracticePhoneNumber}
                </>
              )}
            </div>
          ),
        },
        ['pending-acknowledgement', 'acknowledged'].includes(referral.status) && {
          label: 'Completed',
          value: referral.completedAt?.toLocaleDateString(),
        },
        ['pending-acknowledgement', 'acknowledged'].includes(referral.status) && {
          label: 'Completed By',
          value: referral.referredPractitionerName || 'Unknown',
        },

        { label: 'Reason', value: referral.reason },
      ]),
    RenderChild: ({ model }) => relatedDocuments(model),
    getSourceDocument: false,
    enableDismissAndReadActions: false,
  });
