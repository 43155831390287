import { useMedicationHistoryEntries } from './history';
import { History } from '../../resource/helpers/history';
import { MedicationStatementModel } from '@ctw/shared/api/fhir/models';
import { entryFromArray } from '@ctw/shared/utils/entry-from-array';
import { RowActionsConfigProp } from '@ctw/shared/components/table/table-rows';
import { useResourceDetailsDrawer } from '@ctw/shared/content/resource/resource-details-drawer';

const medicationHistory = ({ model }: { model: MedicationStatementModel }) => (
  <History getHistory={useMedicationHistoryEntries} model={model} />
);

export const useMedicationDetailsDrawer = ({
  rowActions,
  enableDismissAndReadActions,
}: {
  rowActions?: (m: MedicationStatementModel) => RowActionsConfigProp<MedicationStatementModel>;
  enableDismissAndReadActions?: boolean;
}) =>
  useResourceDetailsDrawer({
    header: (medication: MedicationStatementModel) => medication.display,
    RenderChild: medicationHistory,
    details: (medication: MedicationStatementModel) => [
      { label: 'Status', value: medication.displayStatus },
      { label: 'Last Fill Date', value: medication.lastFillDate },
      { label: 'Quantity', value: medication.quantity },
      { label: 'Days Supply', value: medication.daysSupply },
      { label: 'Refills', value: medication.refills },
      { label: 'Instructions', value: medication.dosage },
      { label: 'Prescriber', value: medication.lastPrescriber ?? 'n/a' },
      { label: 'Last Prescribed Date', value: medication.lastPrescribedDate },
      ...entryFromArray('Note', medication.notesDisplay),
    ],
    rowActions,
    enableDismissAndReadActions,
    deepLinkResourceType: 'MedicationStatement',
  });
