import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { cloneElement, isValidElement, ReactElement } from 'react';
import { formatISODateStringToDate } from '@ctw/shared/api/fhir/formatters';
import { Tooltip } from '@ctw/shared/components/tooltip';
import { ClassName, tw, twx } from '@ctw/shared/utils/tailwind';

interface PatientHistoryDetailProps {
  text: string;
  date?: Date;
  className?: ClassName;
}

interface PatientHistoryDetailWithTooltipProps extends PatientHistoryDetailProps {
  icon: IconDefinition | ReactElement<{ className?: string }>;
  tooltip?: string;
}

export function PatientHistoryDetail(props: PatientHistoryDetailProps): React.ReactElement;
export function PatientHistoryDetail(
  props: PatientHistoryDetailWithTooltipProps,
): React.ReactElement;
export function PatientHistoryDetail(
  props: PatientHistoryDetailProps | PatientHistoryDetailWithTooltipProps,
) {
  const { text, date, className } = props;
  let tooltipElement = null;
  if (isPropsWithIcon(props)) {
    const { icon, tooltip } = props;
    const iconElement =
      isValidElement(icon) ?
        cloneElement(icon, {
          className: twx('history-request-form-icon h-4', icon.props.className),
        })
      : <FontAwesomeIcon
          icon={icon as IconDefinition}
          className={tw`history-request-form-icon h-4`}
        />;

    if (tooltip) {
      tooltipElement = <Tooltip content={tooltip}>{iconElement}</Tooltip>;
    }
  }

  return (
    <div className={twx('flex items-center space-x-1', className)}>
      <span>{text}</span>
      {date && <span>{formatISODateStringToDate(date.toISOString())}</span>}
      {tooltipElement}
    </div>
  );
}

function isPropsWithIcon(
  props: PatientHistoryDetailProps | PatientHistoryDetailWithTooltipProps,
): props is PatientHistoryDetailWithTooltipProps {
  return 'icon' in props;
}
