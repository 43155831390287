import { MedicationStatementModel } from '@ctw/shared/api/fhir/models';
import { SortOption } from '@ctw/shared/content/resource/resource-table-actions';

export const medicationSortOptions: SortOption<MedicationStatementModel>[] = [
  {
    display: 'Name (A-Z)',
    sorts: [{ key: 'sortDisplay', dir: 'asc' }],
  },
  {
    display: 'Name (Z-A)',
    sorts: [{ key: 'sortDisplay', dir: 'desc' }],
  },
  {
    display: 'Last Fill Date (Old to New)',
    sorts: [
      { key: 'lastFillDate', dir: 'asc', isDate: true },
      { key: 'lastPrescribedDate', isDate: true, dir: 'asc' },
      { key: 'sortDisplay', dir: 'asc' },
    ],
  },
  {
    display: 'Last Fill Date (New to Old)',
    sorts: [
      { key: 'lastFillDate', dir: 'desc', isDate: true },
      { key: 'lastPrescribedDate', isDate: true, dir: 'desc' },
      { key: 'sortDisplay', dir: 'asc' },
    ],
  },
  {
    display: 'Last Prescribed (Old to New)',
    sorts: [
      { key: 'lastPrescribedDate', isDate: true, dir: 'asc' },
      { key: 'lastPrescriber', dir: 'asc' },
    ],
  },
  {
    display: 'Last Prescribed (New to Old)',
    sorts: [
      { key: 'lastPrescribedDate', isDate: true, dir: 'desc' },
      { key: 'lastPrescriber', dir: 'asc' },
    ],
  },
];

export const defaultMedicationSort = medicationSortOptions[3];
export const defaultMedicationSortOverview = medicationSortOptions[0];
