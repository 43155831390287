import { ReactNode, useState } from 'react';
import { ViewButton, ViewButtonProps } from './helpers/view-button';
import { FilterBar } from '@ctw/shared/components/filter-bar/filter-bar';
import { FilterBarProps } from '@ctw/shared/components/filter-bar/filter-bar-types';
import { MinRecordItem } from '@ctw/shared/components/table/table-helpers';
import { tw, twx, ClassName } from '@ctw/shared/utils/tailwind';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionDropdown } from '@ctw/shared/components/dropdown/action-dropdown';
import { useAnalytics } from '@ctw/shared/context/analytics/use-analytics';
import { Sort } from '@ctw/shared/utils/sort';

export type SortOption<T extends object> = {
  display: string;
  sorts: Sort<T>[];
};

type SortButtonProps<T extends object> = {
  className?: ClassName;
  defaultSort: SortOption<T>;
  onChange: (sort: SortOption<T>) => void;
  options: SortOption<T>[];
};

const SortButton = <T extends object>({
  className,
  defaultSort,
  onChange,
  options,
}: SortButtonProps<T>) => {
  const [selected, setSelected] = useState<SortOption<T>>(defaultSort);
  const { trackInteraction } = useAnalytics();

  return (
    <ActionDropdown
      className={tw`max-h-[17.25rem] overflow-y-auto`}
      type="select"
      buttonClassName={twx(className, 'border-none bg-transparent p-0')}
      onItemSelect={(item) => {
        const selectedOption = options.filter((option) => option.display === item.key)[0];
        onChange(selectedOption);
        trackInteraction('sort', {
          value: item.key,
          dir: selectedOption.sorts[0].dir,
        });
        setSelected(selectedOption);
      }}
      items={options.map((option) => ({
        key: option.display,
        name: option.display,
        isSelected: selected.display === option.display,
      }))}
    >
      <div
        className={tw`flex items-center space-x-2 rounded-md border border-solid border-divider-main border-transparent bg-white px-4 py-2 text-sm font-medium capitalize text-content-light shadow-sm hover:bg-bg-lighter`}
      >
        <span className={tw`mr-1.5 w-full`}>
          Sort: <span className={tw`font-normal`}>{selected.display}</span>
        </span>
        <FontAwesomeIcon icon={faChevronDown} className={tw`ml-auto w-2`} />
      </div>
    </ActionDropdown>
  );
};

export type ResourceTableActionsProps<T extends MinRecordItem> = {
  action?: ReactNode;
  className?: string;
  filterOptions?: FilterBarProps;
  sortOptions?: SortButtonProps<T>;
  viewOptions?: ViewButtonProps<T>;
};

export const ResourceTableActions = <T extends MinRecordItem>({
  action,
  className,
  filterOptions,
  sortOptions,
  viewOptions,
}: ResourceTableActionsProps<T>) => {
  const isEmpty = !viewOptions && !sortOptions && !filterOptions && !action;
  if (isEmpty) {
    return null;
  }

  return (
    <div
      className={twx(
        className,
        'flex flex-col-reverse items-start justify-items-end pt-2 sm:flex-row sm:pt-1.5',
      )}
    >
      <div className={tw`flex w-full flex-col flex-wrap gap-x-2 sm:flex-row`}>
        {viewOptions && (
          <ViewButton
            className={twx(viewOptions.className, 'mb-2')}
            defaultView={viewOptions.defaultView}
            onChange={viewOptions.onChange}
            options={viewOptions.options}
          />
        )}
        {sortOptions && (
          <SortButton
            className={twx(sortOptions.className, 'mb-2')}
            defaultSort={sortOptions.defaultSort}
            onChange={sortOptions.onChange}
            options={sortOptions.options}
          />
        )}
        {filterOptions && filterOptions.filters.length > 0 && <FilterBar {...filterOptions} />}
      </div>
      <div className={tw`mb-2 ml-0 w-full whitespace-nowrap px-2 sm:mb-0 sm:ml-auto sm:w-auto`}>
        {action}
      </div>
    </div>
  );
};
