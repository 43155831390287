export const QUERY_KEY_AI_FEEDBACK = 'ai_feedback';
export const QUERY_KEY_AI_CHART_REVIEW_INTERACTIVE = 'ai_chart_review_interactive';
export const QUERY_KEY_AI_CHART_REVIEW_SUMMARY = 'ai_chart_review_summary';
export const QUERY_KEY_AI_SEARCH = 'ai_search';
export const QUERY_KEY_ALLERGY_HISTORY = 'allergy_history';
export const QUERY_KEY_AUTH_TOKEN = 'auth_token';
export const QUERY_KEY_BINARIES_BY_ID = 'binaris_by_id';
export const QUERY_KEY_BINARY = 'binary';
export const QUERY_KEY_BUILDER_CONFIG = 'builder_config';
export const QUERY_KEY_BUILDER_PATIENTS_BY_UPID = 'builder_patients_by_upid';
export const QUERY_KEY_CARETEAM = 'patient_care_team';
export const QUERY_KEY_CCDA_PATIENT = 'ccda_patient';
export const QUERY_KEY_CONDITION_HISTORY = 'condition_history';
export const QUERY_KEY_DATA_INDICATORS = 'patient_data_indicators';
export const QUERY_KEY_EHR_BACKFILL_REQUEST = 'ehr_backfill_request';
export const QUERY_KEY_MATCHED_PATIENTS = 'matched_patients';
export const QUERY_KEY_MATCHED_PATIENTS_INCLUDING_CURRENT_BUILDER =
  'matched_patients_including_current_builder';
export const QUERY_KEY_MEDICATION_HISTORY = 'medication_history';
export const QUERY_KEY_ON_DEMAND_LENS_API = 'on_demand_lens_api';
export const QUERY_KEY_PATIENT = 'patient';
export const QUERY_KEY_PATIENT_CARE_GAPS = 'patient_care_gaps';
export const QUERY_KEY_PATIENTS_LIST_FQS = 'patients_list_fqs';
export const QUERY_KEY_PATIENT_SEARCH = 'patient_search';
export const QUERY_KEY_PATIENT_ALLERGIES = 'patient_allergies';
export const QUERY_KEY_BINARY_ID = 'patient_binary_id';
export const QUERY_KEY_PATIENT_BUILDER_ENCOUNTERS = 'patient_builder_encounters';
export const QUERY_KEY_PATIENT_BUILDER_MEDICATIONS = 'patient_builder_medications';
export const QUERY_KEY_PATIENT_SUMMARY_CONDITIONS = 'patient_summary_conditions';
export const QUERY_KEY_PATIENT_BUILDER_CONDITIONS = 'patient_builder_conditions';
export const QUERY_KEY_PATIENT_DIAGNOSTIC_REPORTS = 'patient_diagnostic_reports';
export const QUERY_KEY_PATIENT_DOCUMENTS = 'patient_documents';
export const QUERY_KEY_PATIENT_ENCOUNTERS = 'patient_encounters';
export const QUERY_KEY_PATIENT_EPISODES_OF_CARE = 'patient_episodes_of_care';
export const QUERY_KEY_PATIENT_HISTORY_DETAILS = 'patient_history_details';
export const QUERY_KEY_PATIENT_HISTORY_LIST = 'patient_history_list';
export const QUERY_KEY_PATIENT_IMMUNIZATIONS = 'patient_immunizations';
export const QUERY_KEY_PATIENT_MEDICATION_DISPENSE = 'patient_medication_dispense';
export const QUERY_KEY_PATIENT_SUBSCRIPTION = 'patient_subscription';
export const QUERY_KEY_PATIENT_SUMMARY_ENCOUNTERS = 'patient_summary_encounters';
export const QUERY_KEY_PATIENT_SERVICE_REQUESTS = 'patient_service_requests';
export const QUERY_KEY_PATIENT_APPOINTMENTS = 'patient_appointments';
export const QUERY_KEY_PATIENT_SUMMARY_MEDICATIONS = 'patient_summary_medications';
export const QUERY_KEY_PATIENT_VITALS = 'patient_vitals';
export const QUERY_KEY_PROVENANCE = 'provenance';
