import { useContext } from 'react';
import { TelemetryContext } from './context';
import { Telemetry } from '@ctw/shared/utils/telemetry';

export function useTelemetry() {
  return {
    Telemetry,
    context: useContext(TelemetryContext),
  };
}
