// All of the possible resourceType strings.
export type ResourceTypeString = fhir4.FhirResource['resourceType'];

// Usage ResourceType<"MedicationStatement"> to return the fhir4.MedicationStatement type.
// This is useful in combination with ResourceTypeString, allowing us to go
// from the resourceType string to the actual fhir4 resource type.
export type ResourceType<T extends ResourceTypeString> = Extract<
  fhir4.FhirResource,
  { resourceType: T }
>;

export type ResourceMap = { [key: string]: fhir4.Resource | undefined };

export type Tag = { system: string; code: string };

export function isFHIRDomainResource(resource: unknown): resource is fhir4.DomainResource {
  return (
    isFHIRResource(resource) &&
    // Exclude resource types that extend "Resource" and not "DomainResource".
    resource.resourceType !== 'Binary' &&
    resource.resourceType !== 'Bundle'
  );
}

export function isFHIRResource(resource: unknown): resource is fhir4.FhirResource {
  return typeof resource === 'object' && resource !== null && 'resourceType' in resource;
}
