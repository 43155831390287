import { faNoteSticky } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { EncounterModel } from '@ctw/shared/api/fhir/models/encounter';
import { SimpleMoreList } from '@ctw/shared/components/simple-more-list';
import { TableColumn } from '@ctw/shared/components/table/table-helpers';
import {
  RenderSyncedWithRecordIcon,
  ResourceTitleColumn,
} from '@ctw/shared/content/resource/helpers/resource-title-column';
import { tw } from '@ctw/shared/utils/tailwind';

export const patientEncounterColumns = (builderId: string): TableColumn<EncounterModel>[] => [
  {
    render: (encounter) => (
      <div className={tw`flex flex-nowrap space-x-2`}>
        {RenderSyncedWithRecordIcon(
          encounter.ownedByBuilder(builderId) || encounter.syncedWithRecord,
        )}
      </div>
    ),
    width: '25px',
  },
  {
    title: 'Date',
    widthPercent: 10,
    minWidth: 120,
    dataIndex: 'dateDisplay',
  },
  {
    title: 'Class',
    render: (encounter) => <ResourceTitleColumn title={encounter.classDisplay} />,
  },
  {
    title: 'Location',
    render: (encounter) => {
      // We need to filter by "unknown", but we don't want to display it
      const specialties = encounter.locationType.filter((l) => l.toLocaleLowerCase() !== 'unknown');
      return (
        <>
          <SimpleMoreList items={encounter.location} limit={3} total={encounter.location.length} />
          {specialties.length > 0 && (
            <div className={tw`pt-2`}>Speciality: {specialties.join(', ')}</div>
          )}
        </>
      );
    },
  },
  {
    title: 'Provider',
    render: (encounter) => (
      <SimpleMoreList
        items={encounter.practitioners}
        limit={2}
        total={encounter.practitioners.length}
        commaDelimit
      />
    ),
  },
  {
    width: '28px',
    render: (encounter) => {
      const notes = encounter.clinicalNotes.map((note) => note.title).sort();
      return notes.length > 0 ?
          <FontAwesomeIcon className={tw`w-4 text-content-lighter`} icon={faNoteSticky} />
        : null;
    },
  },
  {
    title: 'Details',
    render: (encounter) => {
      const { dischargeDisposition } = encounter;
      const { diagnoses } = encounter;
      return (
        <>
          {diagnoses.length > 0 && (
            <SimpleMoreList items={diagnoses} limit={3} total={diagnoses.length} commaDelimit />
          )}
          {dischargeDisposition && (
            <div className={tw`pt-2`}>Discharge: {dischargeDisposition}</div>
          )}
        </>
      );
    },
  },
];
