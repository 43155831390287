import './diagnostic-reports.scss';
import { patientDiagnosticReportsColumns } from './helpers/columns';
import { diagnosticReportFilter } from './helpers/filters';
import { defaultDiagnosticReportSort, diagnosticReportSortOptions } from './helpers/sorts';
import { useObservationsDetailsDrawer } from '../observations/helpers/drawer';
import { PatientResourceTable } from '../resource/patient-resource-table';
import { ResourceTableActions } from '../resource/resource-table-actions';
import { usePatientDiagnosticReportsWithTrendData } from '@ctw/shared/api/fhir/diagnostic-report';
import { EmptyPatientTable } from '@ctw/shared/components/table/empty-table';
import { withErrorBoundary } from '@ctw/shared/content/error-boundary';
import { AnalyticsProvider } from '@ctw/shared/context/analytics/analytics-provider';
import { useUserBuilderId } from '@ctw/shared/context/user-builder-id';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { twx } from '@ctw/shared/utils/tailwind';

export type PatientDiagnosticReportsProps = {
  className?: string;
};

function PatientDiagnosticReportsComponent({ className }: PatientDiagnosticReportsProps) {
  const query = usePatientDiagnosticReportsWithTrendData();
  const { data, filters, setFilters, setSort, defaultSort } = useFilteredSortedData({
    cacheKey: 'patient-diagnostic-reports',
    defaultSort: defaultDiagnosticReportSort,
    records: query.data,
  });
  const userBuilderId = useUserBuilderId();
  const isEmptyQuery = query.data.length === 0;
  const hasZeroFilteredRecords = !isEmptyQuery && data.length === 0;

  const openDetails = useObservationsDetailsDrawer();

  return (
    <AnalyticsProvider componentName="PatientDiagnosticReports">
      <div className={twx(className, 'scrollable-pass-through-height', 'diagnostic-reports')}>
        <ResourceTableActions
          filterOptions={{
            onChange: setFilters,
            filters: diagnosticReportFilter(),
            selected: filters,
          }}
          sortOptions={{
            defaultSort,
            options: diagnosticReportSortOptions,
            onChange: setSort,
          }}
        />
        <PatientResourceTable
          showTableHead
          isLoading={query.isLoading}
          data={data}
          columns={patientDiagnosticReportsColumns(userBuilderId)}
          onRowClick={openDetails}
          enableDismissAndReadActions
          emptyMessage={
            <EmptyPatientTable
              hasZeroFilteredRecords={hasZeroFilteredRecords}
              resourceName="diagnostics"
            />
          }
        />
      </div>
    </AnalyticsProvider>
  );
}

export const PatientDiagnosticReports = withErrorBoundary(
  PatientDiagnosticReportsComponent,
  'PatientDiagnosticReports',
);
