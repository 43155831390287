import { ReactNode } from 'react';
import { MinRecordItem, TableColumn } from './table-helpers';
import { twx } from '@ctw/shared/utils/tailwind';

type TableColumnProps<T extends MinRecordItem> = {
  column: TableColumn<T>;
  record: T;
};

export const TableDataCell = <T extends MinRecordItem>({
  column,
  record,
}: TableColumnProps<T>): JSX.Element => {
  const value = column.dataIndex ? (record[column.dataIndex] as unknown as ReactNode) : undefined;

  return (
    <td
      className={twx(
        'text-content-black',
        column.className,
        // Added break-words because AbdominalReallyLongWordThatShouldBReakButDoeskadjlkajflkajsf doesn't wrap correclty without it.
        'hyphens-auto break-words',
      )}
    >
      {column.render ? column.render(record) : value}
    </td>
  );
};
