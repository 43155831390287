import { ReactNode, useMemo, useState } from 'react';
import { ZapPropsStateContext } from './zap-props-state-context';

interface ZapTabStateProviderProps {
  children: ReactNode;
}

export function ZapPropsProvider({ children }: ZapTabStateProviderProps) {
  const [props, setProps] = useState({});

  const value = useMemo(
    () => ({
      props,
      setProps,
    }),
    [props, setProps],
  );
  return <ZapPropsStateContext.Provider value={value}>{children}</ZapPropsStateContext.Provider>;
}
