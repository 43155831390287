import { ReactNode } from 'react';
import { twx } from '@ctw/shared/utils/tailwind';

export type BadgeColor = 'primary' | 'info' | 'notification' | 'muted' | 'caution';

export type BadgeProps = {
  className?: string;
  color: BadgeColor;
  content: string | ReactNode;
  size?: 'sm' | 'xs';
  noFlex?: boolean;
};

export const Badge = ({
  className,
  color,
  content: text,
  noFlex = false,
  size = 'sm',
}: BadgeProps) => {
  const colorClasses = {
    primary: 'bg-primary-light text-primary-main', // green
    info: 'bg-info-light !text-info-text', // blue
    notification: 'bg-notification-light text-notification-main', // purple
    muted: 'bg-bg-light text-content-black', // grey
    caution: 'bg-caution-light text-caution-text', // yellow
  }[color];

  const sizeClasses = {
    xs: 'text-xs px-1.5 py-0.5',
    sm: 'text-xs px-2 leading-5',
  }[size];

  return (
    <span
      className={twx(
        className,
        colorClasses,
        sizeClasses,
        'whitespace-nowrap rounded-full !font-medium hover:!no-underline',
        !noFlex && 'inline-flex',
      )}
    >
      {text}
    </span>
  );
};
