import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { PatientHistoryDetail } from './patient-history-detail';
import { PatientHistoryLastCompleted } from './patient-history-last-completed';
import { RequestRecordsButton } from './request-records-button';
import { usePatientHistory } from './use-patient-history';
import { PatientHistoryRequestModel } from '@ctw/shared/api/fhir/models/patient-history';
import { usePatientSubscription } from '@ctw/shared/api/subscriptions/subscriptions';
import { RefreshIcon } from '@ctw/shared/components/icons/refresh';
import { tw } from '@ctw/shared/utils/tailwind';

export type PatientHistoryWithActionProps = {
  hideRequestRecords?: boolean;
};

export const PatientHistoryWithAction = ({
  hideRequestRecords = false,
}: PatientHistoryWithActionProps) => {
  const { isError, isLoading, details } = usePatientHistory();
  const patientSubscription = usePatientSubscription();
  const patientPackage = patientSubscription.data?.package;
  const shouldSkipModal = patientPackage ? patientPackage.requiresManualRequests : true;

  if (isError) {
    return <div className={tw`text-center text-sm`}>Unable to load patient history</div>;
  }

  if (isLoading) {
    return <LoadingSpinner message="Loading..." className={tw`w-full`} />;
  }

  const { latestInProgress, latestCompleted, nextScheduledAt } = details;
  const latestInProgressStatus =
    latestInProgress &&
    PatientHistoryRequestModel.getDerivedProviderStatus(latestInProgress.job.attributes.providers);
  const latestInProgressText =
    latestInProgressStatus === 'partiallyComplete' ?
      'Current request partially complete'
    : 'Request in-progress';

  // Only show last completed if there is one or if there isn't a latest in progress or next scheduled
  const showLastCompleted = !!latestCompleted || (!latestInProgress && !nextScheduledAt);

  return (
    <div className={tw`@container`}>
      {/* Use flex-col until we hit the 365px breakpoint and then use row.
          This is to accomodate the 'Current request partially complete' text and tooltip. */}
      <div
        className={tw`@[365px]:flex-row @[365px]:space-y-0 flex flex-col justify-between space-y-1.5 text-sm font-medium`}
      >
        <div className={tw`flex flex-col space-y-1.5`}>
          {latestInProgress && (
            <PatientHistoryDetail
              text={latestInProgressText}
              icon={<RefreshIcon className={tw`w-auto flex-none`} />}
              tooltip="Patient records are being retrieved"
            />
          )}
          {!latestInProgress && nextScheduledAt && (
            <PatientHistoryDetail text="Request scheduled" date={nextScheduledAt} />
          )}
          {showLastCompleted && (
            <PatientHistoryLastCompleted key="patient-history-last-completed" />
          )}
        </div>
        {!hideRequestRecords && (
          <div>
            <RequestRecordsButton skipModal={shouldSkipModal} />
          </div>
        )}
      </div>
    </div>
  );
};
