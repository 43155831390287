import Client from 'fhir-kit-client';
import { createContext } from 'react';
import { Env } from './types';
import { OnResourceSaveCallback } from '@ctw/shared/api/fhir/action-helper';

export type CTWState = {
  env: Env;
  builderId?: string;
  headers?: HeadersInit;
  featureFlags?: Record<string, boolean>;
  onResourceSave?: OnResourceSaveCallback;
  resourceSaveHeaders?: Record<string, string>;
  allowSmallBreakpointCCDAViewer?: boolean;
};

export type CTWRequestContext = {
  env: Env;
  headers?: HeadersInit;
  authToken: string;
  builderId: string;
  userType: string | undefined;
  fhirClient: Client;
  fhirWriteBackClient: Client;
  fetchFromFqs: (
    url: string,
    options: RequestInit,
  ) => Promise<{ data: unknown; headers: Response['headers'] }>;
  onResourceSave: OnResourceSaveCallback;
  resourceSaveHeaders?: Record<string, string>;
};

export const CTWStateContext = createContext<CTWState | undefined>(undefined);
