import { Env } from '@ctw/shared/context/types';

export function getPageEnv(): Env {
  switch (window.location.hostname) {
    case 'zap.zushealth.com':
    case 'zap.zusapi.com':
      return 'prod';
    case 'zap.sandbox.zushealth.com':
    case 'zap.sandbox.zusapi.com':
      return 'sandbox';
    case 'zap.phitest.zushealth.com':
    case 'zap.phitest.zusapi.com':
      return 'phitest';
    case 'zap.dev.zushealth.com':
    case 'zap.dev.zusapi.com':
      return 'dev';
    case 'localhost':
      return 'sandbox';
    default:
      return 'unknown';
  }
}
