import { isFunction } from 'lodash';
import { FunctionComponent, ReactElement } from 'react';
import { TableDataCell } from './table-data-cell';
import { TableFullLengthRow } from './table-full-length-row';
import { MinRecordItem, TableColumn } from './table-helpers';
import { LoadingTable } from './loading-table';
import { RowActionsProps } from '@ctw/shared/components/table/table';
import { WritebackStatus } from '@ctw/shared/content/resource/helpers/resource-title-column';
import { useAnalytics } from '@ctw/shared/context/analytics/use-analytics';
import { ClassName, tw, twx } from '@ctw/shared/utils/tailwind';
import { VoidValue } from '@ctw/shared/utils/types';

export type WritebackStatuses = Record<string, WritebackStatus | undefined>;

export type WritebackAction = 'add' | 'dismiss-bulk' | 'dismiss-single' | 'restore';

export type RowActionsProp<T extends MinRecordItem> = FunctionComponent<RowActionsProps<T>>;

export type RowActionsConfigProp<T extends MinRecordItem> =
  | {
      text: string;
      onClick: (record: T, onSuccess?: () => void) => VoidValue;
      className: ClassName;
      disabled?: boolean;
      testId?: string;
      render?: RowActionsProp<T>;
    }[]
  | undefined;

export type TableRowsProps<T extends MinRecordItem> = {
  RowActions?: RowActionsProp<T>;
  rowActions?: RowActionsConfigProp<T>;
  columns: TableColumn<T>[];
  emptyMessage: string | ReactElement;
  getRowClassName?: (record: T) => ClassName;
  handleRowClick?: (record: T) => void;
  canClickRow?: (record: T) => boolean;
  onRightClick?: (record: T) => void;
  isLoading: boolean;
  records: T[];
  stacked: boolean;
};

export const TableRows = <T extends MinRecordItem>({
  records,
  columns,
  isLoading,
  emptyMessage,
  handleRowClick,
  canClickRow = () => true,
  onRightClick,
  RowActions,
  getRowClassName,
  stacked,
}: TableRowsProps<T>) => {
  const { trackInteraction } = useAnalytics();

  if (isLoading) {
    return (
      <TableFullLengthRow colSpan={columns.length}>
        <LoadingTable />
      </TableFullLengthRow>
    );
  }

  if (records.length === 0) {
    return (
      <TableFullLengthRow colSpan={columns.length}>
        <span className={tw`empty-message -mt-3.5 sm:mt-0`}>{emptyMessage}</span>
      </TableFullLengthRow>
    );
  }

  return (
    <>
      {records.map((record) => (
        <tr
          // mx-px fixes bug where side borders disappear on hover when stacked.
          className={twx(
            'group relative mx-px table-row',
            isFunction(getRowClassName) ? getRowClassName(record) : '',
            {
              'cursor-pointer hover:bg-bg-lighter':
                isFunction(handleRowClick) && canClickRow(record),
            },
          )}
          key={record.key}
          onClick={({ target, metaKey, ctrlKey }) => {
            // This is for the case where a user clicks area near the button (but not on), we do not want have the onRowClick handler trigger as that will cause confusion to the user.
            if (target instanceof HTMLElement && target.querySelectorAll('button').length) {
              return;
            }

            // Case for cmd/ctrl + click
            if (isFunction(onRightClick) && (metaKey || ctrlKey)) {
              onRightClick(record);
              return;
            }

            if (handleRowClick) {
              handleRowClick(record);
              trackInteraction('click_row');
            }
          }}
        >
          {columns.map((column, index) => (
            <TableDataCell
              key={`${record.key}_${column.title ?? index}`}
              column={column}
              record={record}
            />
          ))}
          {RowActions && (
            // Add onClick here to prevent clicks from propagating to
            // the to the tr's onClick for onRowClick.
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
            <td
              aria-label="row-actions"
              className={tw`table-row-actions group-hover:visible`}
              onClick={(event) => event.stopPropagation()}
            >
              <RowActions record={record} stacked={stacked} />
            </td>
          )}
        </tr>
      ))}
    </>
  );
};
