import { uniqBy } from 'lodash';
import { useRef } from 'react';
import { patientSourcesColumns } from './helpers/columns';
import { PatientResourceTable } from '../resource/patient-resource-table';
import { PatientModel } from '@ctw/shared/api/fhir/models';
import { useMatchedPatients } from '@ctw/shared/api/fhir/patient-helper';
import { EmptyPatientTable } from '@ctw/shared/components/table/empty-table';
import { withErrorBoundary } from '@ctw/shared/content/error-boundary';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { AnalyticsProvider } from '@ctw/shared/context/analytics/analytics-provider';
import { usePatientContext } from '@ctw/shared/context/patient-provider';
import { sort } from '@ctw/shared/utils/sort';
import { twx } from '@ctw/shared/utils/tailwind';

export type PatientSourcesProps = {
  className?: string;
};

function PatientSourcesComponent({ className }: PatientSourcesProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const { patient } = usePatientContext();
  const patientSourcesQuery = useMatchedPatients();

  const hasNoData = patientSourcesQuery.data?.length === 0;

  const dedupedData = uniqBy(
    patientSourcesQuery.data ?? [],
    (p: PatientModel) =>
      [
        p.createdAtDisplay,
        p.organizationDisplayName,
        p.display,
        p.dob,
        p.age,
        p.gender,
        p.phoneNumber,
        p.email,
        p.homeAddress,
      ].join('|'), // Stringify the array to compare its contents
  );
  const sortedData = sort(dedupedData, 'createdAt', 'desc');

  if (!patient.data) {
    return <LoadingSpinner message="Loading..." />;
  }

  return (
    <AnalyticsProvider componentName="MatchedPatients">
      <div ref={containerRef} className={twx(className, 'scrollable-pass-through-height')}>
        <PatientResourceTable
          isLoading={patientSourcesQuery.isLoading}
          data={sortedData}
          emptyMessage={
            <EmptyPatientTable hasZeroFilteredRecords={hasNoData} resourceName="patients" />
          }
          columns={patientSourcesColumns}
        />
      </div>
    </AnalyticsProvider>
  );
}

export const PatientSources = withErrorBoundary(PatientSourcesComponent, 'MatchedPatients');
