import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import {
  createGraphqlClient,
  fqsRequestAll,
  MAX_OBJECTS_PER_REQUEST,
} from '@ctw/shared/api/fqs/client';
import { ObservationModel, PatientModel } from './models';
import { SYSTEM_SUMMARY } from './system-urls';
import {
  ObservationGraphqlResponse,
  observationQuery,
} from '@ctw/shared/api/fqs/queries/observations';

export async function fetchObservationsById(
  requestContext: CTWRequestContext,
  patient: PatientModel,
  ids: string[],
) {
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequestAll<ObservationGraphqlResponse>(
    graphClient,
    observationQuery,
    'ObservationConnection',
    {
      upid: patient.UPID,
      cursor: '',
      first: MAX_OBJECTS_PER_REQUEST,
      filter: {
        ids: {
          anymatch: ids,
        },
        tag: { nonematch: [SYSTEM_SUMMARY] },
      },
    },
  );

  return data.ObservationConnection.edges.map(({ node }) => new ObservationModel(node));
}
