import { useEffect, useState } from 'react';

const useHeaderOffset = (elementId: string) => {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const updateOffset = () => {
      const element = document.getElementById(elementId);
      const newOffset = element ? element.getBoundingClientRect().height : 0;
      setOffset(newOffset);
    };

    // Delay setting up the ResizeObserver to give the element time to reach its full size
    const timeoutId = setTimeout(() => {
      const element = document.getElementById(elementId);
      if (element) {
        const resizeObserver = new ResizeObserver(updateOffset);
        resizeObserver.observe(element);

        return () => {
          resizeObserver.unobserve(element);
        };
      }

      return undefined;
    }, 500);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [elementId]);

  return offset;
};

export default useHeaderOffset;
