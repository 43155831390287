import { Dispatch, SetStateAction } from 'react';
import { getRequestData, requestHistorySchema } from './forms/schemas/request-history-schema';
import {
  DrawerFormWithFields,
  DrawerFormWithFieldsProps,
} from '../components/form/drawer-form-with-fields';
import { CTWRequestContext } from '../context/ctw-context';
import { PatientModel } from '@ctw/shared/api/fhir/models';
import {
  PatientHistoryResponseError,
  schedulePatientHistory,
} from '@ctw/shared/api/patient-history';
import { QUERY_KEY_PATIENT_HISTORY_DETAILS } from '@ctw/shared/utils/query-keys';
import { queryClient } from '@ctw/shared/utils/request';

type PatientHistoryRequestDrawer<T> = Pick<
  DrawerFormWithFieldsProps<T>,
  'isOpen' | 'onClose' | 'header'
> & {
  patient?: PatientModel;
  setClinicalHistoryExists: Dispatch<SetStateAction<boolean | undefined>>;
};

export type ScheduleHistoryFormData = {
  npi: string;
  name: string;
  role: string;
  id: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const PatientHistoryRequestDrawer = <T,>({
  patient,
  header,
  isOpen,
  onClose,
  setClinicalHistoryExists,
}: PatientHistoryRequestDrawer<T>) => {
  const onScheduleHistory = async (
    data: ScheduleHistoryFormData,
    getRequestContext: () => Promise<CTWRequestContext>,
  ) => {
    const requestContext = await getRequestContext();
    const patientHistoryResponse = await schedulePatientHistory(requestContext, data);

    if ('errors' in patientHistoryResponse) {
      const requestErrors = [
        patientHistoryResponse.errors.map((err: PatientHistoryResponseError) => err.details),
      ];
      return new Error(requestErrors.join(', '));
    }

    await queryClient.invalidateQueries([QUERY_KEY_PATIENT_HISTORY_DETAILS]);

    // patientHistoryResponse has succeeded at this point and should remove empty request history state.
    setClinicalHistoryExists(true);

    return patientHistoryResponse;
  };

  return (
    <DrawerFormWithFields
      header={header}
      title="Request Records"
      errorHeader="Unable to request records"
      action={onScheduleHistory}
      data={getRequestData(patient, false)}
      schema={requestHistorySchema}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};
