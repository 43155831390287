import { isEmpty } from 'lodash';
import { PatientModel } from '@ctw/shared/api/fhir/models';

enum CanonicalizationTag {
  INTERNAL = 'internal',
  EXTERNAL = 'external',
}

export interface CanonicalContact {
  type: string;
  key: string;
  rank: number;
  value: string;
  uses: string[];
  tags: CanonicalizationTag[];
  sources: string[];
  syncedWithRecord: boolean;
}

export interface CanonicalPhone extends CanonicalContact {
  type: 'phone';
}

export interface CanonicalEmail extends CanonicalContact {
  type: 'email';
}

export interface CanonicalAddress extends CanonicalContact {
  type: 'address';
  city: string;
  country: string;
  line: string[];
  postalCode: string;
  state: string;
}

export const isCanonicalPhone = (contact: CanonicalContact): contact is CanonicalPhone =>
  contact.type === 'phone';

export const isCanonicalEmail = (contact: CanonicalContact): contact is CanonicalEmail =>
  contact.type === 'email';

export const isCanonicalAddress = (contact: CanonicalContact): contact is CanonicalEmail =>
  contact.type === 'address';

interface CanonicalContactInfo {
  phones: CanonicalPhone[];
  emails: CanonicalEmail[];
  addresses: CanonicalAddress[];
}

const canonicalizationSourceTag = (
  builderId: string,
  patient: PatientModel,
): CanonicalizationTag =>
  patient.resource.meta?.tag?.find((tag) => tag.code === `builder/${builderId}`) ?
    CanonicalizationTag.INTERNAL
  : CanonicalizationTag.EXTERNAL;

/**
 * Given the builder ID of the current builder and an array of patient records for a single patient, canonicalizes the related `Address` and `ContactPoint` resources. This process involves deduplication, normalization, and ranking of the unique pieces of contact information. Contact information is ranked based on how many occurences exist in the patient records.
 *
 * @param builderId The builder ID of the current builder.
 * @param patients The array of patients from which to canonicalize contact points.
 * @returns The canonicalized phone numbers, email addresses, and physical addresses.
 */
export const canonicalizeContactInfo = (
  builderId: string,
  patients: PatientModel[],
): CanonicalContactInfo => ({
  // Reduce an array of `CanonicalPhone` records from the given patient records.
  phones: Object.values(
    patients
      // Reduce all non-empty `ContactPoint`s with a system of `phone` into an array of `CanonicalPhone` records.
      .reduce<CanonicalPhone[]>(
        (phones, patient) => [
          ...phones,
          ...(patient.resource.telecom || [])
            .filter((telecom) => telecom.system === 'phone' && !isEmpty(telecom.value))
            .map((contactPoint) => ({
              type: 'phone' as const,
              key: (contactPoint.value || '').replace(/\D/g, '').trim(),
              rank: contactPoint.period?.end ? 0 : 1,
              tags: [canonicalizationSourceTag(builderId, patient)],
              uses: [contactPoint.use || 'unknown'],
              value: (contactPoint.value || '').replace(/\D/g, '').trim(),
              sources: [patient.organizationDisplayName || 'Unknown'],
              syncedWithRecord: patient.ownedByBuilder(builderId),
            })),
        ],
        [],
      )
      // De-duplicate the array of `CanonicalPhone` records by combining records with the same `value`.
      .reduce<Record<string, CanonicalPhone>>((phones, phone) => {
        const newPhones = { ...phones };

        if (Object.hasOwn(phones, phone.value)) {
          newPhones[phone.value] = {
            type: 'phone' as const,
            key: phone.value,
            rank: phones[phone.value].rank + phone.rank,
            tags: [...new Set([...phones[phone.value].tags, ...phone.tags])],
            value: phone.value,
            uses: [...new Set([...phones[phone.value].uses, ...phone.uses])],
            sources: [...new Set([...phones[phone.value].sources, ...phone.sources])].sort(),
            syncedWithRecord: phone.syncedWithRecord || phones[phone.value].syncedWithRecord,
          };
        } else {
          newPhones[phone.value] = phone;
        }

        return newPhones;
      }, {}),
    // Sort records descending by `rank`
  ).sort((a, b) => b.rank - a.rank),

  // Reduce an array of `CanonicalEmail` records from the given patient records.
  emails: Object.values(
    patients
      // Reduce all non-empty `ContactPoint`s with a system of `email` into an array of `CanonicalPhone` records.
      .reduce<CanonicalEmail[]>(
        (emails, patient) => [
          ...emails,
          ...(patient.resource.telecom || [])
            .filter((telecom) => telecom.system === 'email' && !isEmpty(telecom.value))
            .map((contactPoint) => ({
              type: 'email' as const,
              key: (contactPoint.value || '').trim(),
              rank: contactPoint.period?.end ? 0 : 1,
              tags: [canonicalizationSourceTag(builderId, patient)],
              uses: [contactPoint.use || 'unknown'],
              value: (contactPoint.value || '').trim(),
              sources: [patient.organizationDisplayName || 'Unknown'],
              syncedWithRecord: patient.ownedByBuilder(builderId),
            })),
        ],
        [],
      )
      // De-duplicate the array of `CanonicalEmail` records by combining records with the same `value`.
      .reduce<Record<string, CanonicalEmail>>((emails, email) => {
        const newEmails = { ...emails };

        if (Object.hasOwn(emails, email.value)) {
          newEmails[email.value] = {
            type: 'email' as const,
            key: email.value,
            rank: emails[email.value].rank + email.rank,
            tags: [...new Set([...emails[email.value].tags, ...email.tags])],
            uses: [...new Set([...emails[email.value].uses, ...email.uses])],
            value: email.value,
            sources: [...new Set([...emails[email.value].sources, ...email.sources])].sort(),
            syncedWithRecord: email.syncedWithRecord || emails[email.value].syncedWithRecord,
          };
        } else {
          newEmails[email.value] = email;
        }

        return newEmails;
      }, {}),
    // Sort records descending by `rank`
  ).sort((a, b) => b.rank - a.rank),

  // Reduce an array of `CanonicalAddress` records from the given patient records.
  addresses: Object.values(
    patients
      // Reduce all non-empty `Address`es into an array of `CanonicalAddress` records.
      .reduce<CanonicalAddress[]>(
        (addresses, patient) => [
          ...addresses,
          ...(patient.resource.address || [])
            .filter(
              (address) =>
                !isEmpty(address.city) &&
                !isEmpty(address.postalCode) &&
                !isEmpty(address.state) &&
                !isEmpty(address.line),
            )
            .map((address) => ({
              type: 'address' as const,
              key: JSON.stringify(address, Object.keys(address).sort()),
              rank: address.period?.end ? 0 : 1,
              tags: [canonicalizationSourceTag(builderId, patient)],
              value: `${address.line?.join(' ')}, ${address.city}, ${address.state} ${
                address.postalCode
              }, ${address.country || 'USA'}`,
              uses: [address.use || 'unknown'],
              city: address.city || 'Unknown',
              country: address.country || 'USA',
              line: Array.isArray(address.line) ? address.line : ['Unknown'],
              postalCode: address.postalCode || 'Unknown',
              state: address.state || 'Unknown',
              sources: [patient.organizationDisplayName || 'Unknown'],
              syncedWithRecord: patient.ownedByBuilder(builderId),
            })),
        ],
        [],
      )
      // De-duplicate the array of `CanonicalAddress` records by combining records with the same address value.
      .reduce<Record<string, CanonicalAddress>>((addresses, address) => {
        const newAddresses = { ...addresses };

        if (Object.hasOwn(addresses, address.value)) {
          newAddresses[address.value] = {
            ...address,
            rank: addresses[address.value].rank + address.rank,
            uses: [...new Set([...addresses[address.value].uses, ...address.uses])],
            tags: [...new Set([...addresses[address.value].tags, ...address.tags])],
            sources: [...new Set([...addresses[address.value].sources, ...address.sources])].sort(),
            syncedWithRecord: address.syncedWithRecord || addresses[address.value].syncedWithRecord,
          };
        } else {
          newAddresses[address.value] = address;
        }

        return newAddresses;
      }, {}),
    // Sort records descending by `rank`
  ).sort((a, b) => b.rank - a.rank),
});
