import { useCallback, useState } from 'react';
import { ConditionModel } from '@ctw/shared/api/fhir/models';
import { RowActionsConfigProp, WritebackAction } from '@ctw/shared/components/table/table-rows';
import { useAddConditionForm } from '@ctw/shared/content/conditions/helpers/modal-hooks';
import { useToggleRead } from '@ctw/shared/content/hooks/use-toggle-read';
import { useAnalytics } from '@ctw/shared/context/analytics/use-analytics';
import { useBaseTranslations } from '@ctw/shared/utils/i18n';

export function useConditionRowActions(
  readOnly: boolean,
  onWritebackStart: (action: WritebackAction, id: string) => void,
  onWritebackEnd: (
    action: WritebackAction,
    id: string,
    isError: boolean,
    errorMessage?: string,
  ) => void,
): (r: ConditionModel) => RowActionsConfigProp<ConditionModel> {
  const { t } = useBaseTranslations();
  const { toggleRead } = useToggleRead();
  const { trackInteraction } = useAnalytics();

  const [writebackInProgress, setWritebackInProgress] = useState<Record<string, boolean>>({});

  const showAddConditionForm = useAddConditionForm(
    (id: string) => {
      onWritebackStart('add', id);
      setWritebackInProgress((prev) => ({ ...prev, [id]: true }));
    },
    (id: string, isError: boolean, errorMessage?: string) => {
      onWritebackEnd('add', id, isError, errorMessage);
      setWritebackInProgress((prev) => ({ ...prev, [id]: false }));
    },
  );

  return useCallback(
    (record: ConditionModel): RowActionsConfigProp<ConditionModel> => {
      if (readOnly) {
        return [];
      }

      if (!record.syncedWithRecord && !writebackInProgress[record.id]) {
        return [
          {
            className:
              'rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm btn bg-primary-main text-white hover:bg-primary-dark',
            text: t('resourceTable.add'),
            onClick: () => {
              if (!record.isRead) {
                void toggleRead(record);
              }
              showAddConditionForm(record);
              trackInteraction('add_to_record');
            },
          },
        ];
      }

      return [];
    },
    [readOnly, showAddConditionForm, t, toggleRead, trackInteraction, writebackInProgress],
  );
}
