import { faArrowUpRightFromSquare, faBars, faX, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useRef, useState } from 'react';
import { getAuthor } from './data/getAuthor';
import { getEncounter } from './data/getEncounter';
import { getTitle } from './data/getTitle';
import '@ctw/shared/content/CCDA/styles.scss';
import { PatientModel } from '@ctw/shared/api/fhir/models';
import ZusSVG from '@ctw/shared/assets/zus-logo.svg';
import { navigateIntoView } from '@ctw/shared/content/CCDA/ccda-viewer/helpers/navigate-into-view';
import { DocumentOnlyProps, SectionType } from '@ctw/shared/content/CCDA/ccda-viewer/types';
import { DOCUMENT_HEADER_ID, TABLE_OF_CONTENT_ID } from '@ctw/shared/content/CCDA/constants';
import { useAnalytics } from '@ctw/shared/context/analytics/use-analytics';
import { usePatientContext } from '@ctw/shared/context/patient-provider';
import { useCTW } from '@ctw/shared/context/use-ctw';
import { Breakpoints, useBreakpoints } from '@ctw/shared/hooks/use-breakpoints';
import useHeaderOffset from '@ctw/shared/hooks/use-header-offset';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { Heading } from '@ctw/shared/components/heading';
import { Button } from '@ctw/shared/components/button';

type HeaderProps = DocumentOnlyProps & {
  children?: ReactNode;
  sections: SectionType[];
  onClose?: () => void;
  overridePatient: PatientModel | undefined;
};

export const Header = ({
  document,
  children,
  sections,
  overridePatient,
}: HeaderProps): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);
  const breakpoints = useBreakpoints(containerRef);
  const headerOffset = useHeaderOffset(DOCUMENT_HEADER_ID);

  const tableOfContents = [
    ...sections.map(({ title, id, isEmpty }) => ({ title, id, isEmpty })),
    { title: 'Demographic Details', id: 'demographic-details', isEmpty: false },
  ];

  return (
    <>
      <div
        className={twx('top-0 z-50 text-lg', {
          sticky: !breakpoints.md,
        })}
        id={TABLE_OF_CONTENT_ID}
        ref={containerRef}
        // eslint-disable-next-line no-restricted-syntax
        style={{ top: headerOffset }}
      >
        <TableOfContents
          document={document}
          sections={tableOfContents}
          breakpoints={breakpoints}
          overridePatient={overridePatient}
        />
      </div>
      <div className={tw`ccda-header-container space-y-4`}>
        <div className={tw`flex w-full`}>
          <div className={tw`w-fit basis-1/2`}>{children}</div>
        </div>
      </div>
    </>
  );
};

export const DocumentHeader = ({
  onClose,
  actions,
  overridePatient,
  binaryId,
}: {
  onClose?: () => void;
  actions?: ReactNode;
  overridePatient?: PatientModel | undefined;
  binaryId?: string;
}) => {
  const patientContext = usePatientContext();
  const containerRef = useRef<HTMLDivElement>(null);
  const breakpoints = useBreakpoints(containerRef);
  const patient = overridePatient || patientContext.patient.data;
  const { featureFlags } = useCTW();
  const newTabLink = `/ccda/${binaryId}`;
  // We don't want to show the open in new tab button if we are already in the new tab.
  const showOpenInNewTab =
    typeof window !== 'undefined' && new URL(window.location.href).pathname === newTabLink;

  return (
    <div
      className={tw`sticky top-0 z-50 flex justify-between bg-bg-black p-3 text-white`}
      ref={containerRef}
      id={DOCUMENT_HEADER_ID}
    >
      <div className={tw`flex space-x-4 overflow-hidden`}>
        <img src={ZusSVG} alt="Zus" />
        {patient && (
          <div
            className={twx('space-x-1', {
              'overflow-hidden text-ellipsis whitespace-nowrap text-sm': breakpoints.sm,
            })}
          >
            <span>{patient.firstName}</span>
            <span>{patient.lastName}</span>
            <span className={tw`uppercase`}>{`(${patient.gender?.[0]}),`}</span>
            <span>{patient.dob}</span>
            <span>{`(${patient.age}yr)`}</span>
          </div>
        )}
      </div>
      <div className={tw`flex items-center space-x-2`}>
        {featureFlags?.openCCDAInNewTab && !showOpenInNewTab && (
          <a
            aria-label="Open in new tab"
            href={newTabLink}
            className={tw`flex items-center space-x-2`}
            rel="noopener noreferrer"
            target="_blank"
          >
            <FontAwesomeIcon className={tw`cursor-pointer`} icon={faArrowUpRightFromSquare} />
          </a>
        )}
        {actions && <div className={tw`flex`}>{actions}</div>}
        {!showOpenInNewTab && (
          <CloseDocument onClose={onClose}>
            <span className={tw`text-sm text-white`}>Close</span>
            <FontAwesomeIcon icon={faX} className={tw`h-4 w-4 text-white`} aria-hidden="true" />
          </CloseDocument>
        )}
      </div>
    </div>
  );
};

const TableOfContents = ({
  document,
  sections,
  breakpoints,
  overridePatient,
}: {
  document: Document;
  sections: SectionType[];
  breakpoints: Breakpoints | undefined;
  overridePatient: PatientModel | undefined;
}) => {
  const [isHidden, setIsHidden] = useState(true);
  const { trackInteraction } = useAnalytics();

  const handleClick = () => {
    setIsHidden(!isHidden);
    trackInteraction('toggle_document_section', {
      action: isHidden ? 'collapse_document_section' : 'expand_document_section',
    });
  };

  return (
    <div className={tw`flex w-full min-w-[300px] flex-col bg-bg-lighter`}>
      <div className={tw`bg-bg-zusLogoBlue px-4 py-2 text-sm uppercase text-white`}>
        <Button
          type="button"
          variant="unstyled"
          className={tw`ccda-section-arrow section`}
          onClick={handleClick}
        >
          <span className={tw`hidden`}>hide</span>
          <FontAwesomeIcon icon={isHidden ? faBars : faXmark} className={twx('ml-auto w-4')} />
        </Button>
        in this document
      </div>
      {!isHidden && (
        <div>
          <div
            className={twx('in-this-document-section items-baseline space-y-2.5 px-4 py-2', {
              '!flex flex-col': breakpoints?.lg,
            })}
          >
            <TableOfContentsItems sections={sections} breakpoints={breakpoints} />
            <PatientDetails breakpoints={breakpoints} overridePatient={overridePatient} />
            <DocumentDetails breakpoints={breakpoints} document={document} />
          </div>
        </div>
      )}
    </div>
  );
};

const TableOfContentsItems = ({
  sections,
  breakpoints,
}: {
  sections: SectionType[];
  breakpoints: Breakpoints | undefined;
}) => {
  const isOutsideModal =
    typeof window !== 'undefined' && new URL(window.location.href).pathname.includes('/ccda/');
  // We use the section length to determine whether we should override the default number of columns
  const betterWithTwoCols = sections.length > 7 && sections.length < 15;
  const betterWithOneCol = sections.length < 8;
  const divisor = () => {
    if (betterWithOneCol) return 1;
    if (breakpoints?.md || betterWithTwoCols) return 2;
    return 3;
  };

  const gridTemplateRows = Math.ceil(sections.length / divisor());
  const gridTemplateColumns = divisor();

  return (
    <div className={tw`w-full space-y-1`}>
      <Heading level="h4" className={tw`uppercase text-content-light`}>
        Contents Menu
      </Heading>
      <ol
        // eslint-disable-next-line no-restricted-syntax
        style={
          {
            '--number-of-columns': gridTemplateColumns,
            '--number-of-rows': gridTemplateRows,
          } as React.CSSProperties
        }
        className={twx('table-of-contents list-inside list-decimal items-baseline text-sm', {
          '!gap-1': breakpoints?.sm,
        })}
      >
        {sections.map(({ title, id, isEmpty }) => (
          <Button
            key={`el-text-${id}`}
            variant="unstyled"
            className={twx(
              'button-list-item',
              { '!p-1': breakpoints?.sm },
              { 'text-content-light': isEmpty },
            )}
            type="button"
            onClick={() => {
              navigateIntoView(id, isOutsideModal);
            }}
          >
            <li className={tw`list-item`}>
              {title} {isEmpty && ' (empty)'}
            </li>
          </Button>
        ))}
      </ol>
    </div>
  );
};

const PatientDetails = ({
  breakpoints,
  overridePatient,
}: {
  breakpoints: Breakpoints | undefined;
  overridePatient: PatientModel | undefined;
}) => {
  const patientContext = usePatientContext();
  const patient = overridePatient || patientContext.patient.data;

  return (
    <div className={twx('space-y-1 text-base', { 'p-1': breakpoints?.md })}>
      <div className={tw`text-sm font-medium uppercase text-content-light`}>Details</div>
      <div className={tw`patient-details`}>
        <div className={tw`item`}>
          <div className={tw`font-medium capitalize`}>patient name:</div>
          <div className={tw`space-x-1`}>
            <span>{patient?.firstName}</span>
            <span>{patient?.lastName}</span>
            <span className={tw`uppercase`}>{`(${patient?.gender?.[0]})`}</span>
          </div>
        </div>
        <div className={tw`item`}>
          <div className={tw`font-medium capitalize`}>dob:</div>
          <div>{patient?.dob}</div>
        </div>
        <div className={tw`item`}>
          <div className={tw`font-medium capitalize`}>phone:</div>
          <div>{patient?.phoneNumber}</div>
        </div>
        <div className={tw`item`}>
          <div className={tw`font-medium capitalize`}>email:</div>
          <div>{patient?.email}</div>
        </div>
      </div>
    </div>
  );
};

const DocumentDetails = ({
  breakpoints,
  document,
}: {
  breakpoints: Breakpoints | undefined;
  document: Document;
}) => {
  const title = getTitle(document);
  const encounter = getEncounter(document);
  const encounterType = encounter?.find((item) => item.label === 'Type')?.value;
  const encounterDateTime = encounter?.find((item) => item.label === 'Date/Time')?.value;
  const author = getAuthor(document);
  const authorName = author?.author1?.find((item) => item.label === 'Organization:')?.value;

  return (
    <div className={twx('space-y-1 text-base', { 'p-1': breakpoints?.md })}>
      <div className={tw`document-details`}>
        {title && (
          <div className={tw`item`}>
            <div className={tw`font-medium capitalize`}>title:</div>
            <div>{title}</div>
          </div>
        )}
        {encounterType && (
          <div className={tw`item`}>
            <div className={tw`font-medium capitalize`}>encounter type:</div>
            <div>{encounterType}</div>
          </div>
        )}
        {encounterDateTime && (
          <div className={tw`item`}>
            <div className={tw`font-medium capitalize`}>date:</div>
            <div>{encounterDateTime}</div>
          </div>
        )}
        {authorName && (
          <div className={tw`item`}>
            <div className={tw`font-medium capitalize`}>author:</div>
            <div>{authorName}</div>
          </div>
        )}
      </div>
    </div>
  );
};

const CloseDocument = ({ onClose, children }: { onClose?: () => void; children?: ReactNode }) => {
  const { trackInteraction } = useAnalytics();

  return (
    <Button
      type="button"
      ariaLabel="close"
      variant="clear"
      onClick={() => {
        if (onClose) {
          onClose();
        }

        trackInteraction('close_document');
      }}
      className={tw`flex items-center space-x-1`}
    >
      {children}
    </Button>
  );
};
