import { useContext, useRef } from 'react';
import { CareGapsCard } from '../care-gaps/care-gaps-overview';
import { useHasDataRecordsByResource } from '../data-indicator';
import { ZAPTabName } from '../zus-aggregated-profile/zus-aggregated-profile';
import { ConditionModel } from '@ctw/shared/api/fhir/models';
import { EmptyPatientTable } from '@ctw/shared/components/table/empty-table';
import { withErrorBoundary } from '@ctw/shared/content/error-boundary';
import { ZapTabStateContext } from '@ctw/shared/context/zap-tab-state-context';
import { useBuilderConfig } from '@ctw/shared/hooks/use-builder-config';
import { useFeatureFlag } from '@ctw/shared/hooks/use-feature-flag';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { PatientConditionOverview } from '@ctw/shared/content/conditions/patient-conditions-overview';
import { PatientDiagnosticReportsOverview } from '@ctw/shared/content/diagnostic-reports/patient-diagnostic-reports-overview';
import { EncountersOverviewCard } from '@ctw/shared/content/encounters/encounters-overview-card';
import { EpisodeOfCareOverviewCard } from '@ctw/shared/content/episode-of-care/episode-of-care-overview-card';
import { PatientMedicationOverview } from '@ctw/shared/content/medications/patient-medications-overview';
import { ZusInformationCard } from '@ctw/shared/content/overview/zus-information-card';
import { PatientReferralsOverview } from '@ctw/shared/content/patient-referrals/patient-referrals-overview';
import { PatientVitalsOverview } from '@ctw/shared/content/vitals/patient-vitals-overview';
import { Masonry } from '@ctw/shared/components/masonry';

export type OverviewResources =
  | 'conditions'
  | 'referrals'
  | 'medications'
  | 'diagnostics'
  | 'encounters'
  | 'vitals';

export type PatientOverviewProps = {
  className?: string;
  hideRequestRecords?: boolean;
  onSeeAllResources: (resource: OverviewResources) => void;
  tabsToUseToDetermineIfDataExists?: ZAPTabName[];
  onAfterDetailsDrawerClosed?: () => void;
  onOpenDetailsDrawer?: (c: ConditionModel) => void;
  conditionIdForDetailsDrawer?: string;
};

export const PatientOverviewComponent = ({
  className,
  hideRequestRecords = false,
  tabsToUseToDetermineIfDataExists,
  onSeeAllResources,
  onAfterDetailsDrawerClosed,
  onOpenDetailsDrawer,
  conditionIdForDetailsDrawer,
}: PatientOverviewProps) => {
  const ref = useRef(null);
  const { tabOrder } = useContext(ZapTabStateContext);
  const dataIndicatorsMap = useHasDataRecordsByResource(
    tabsToUseToDetermineIfDataExists || tabOrder,
  );
  const builderConfig = useBuilderConfig();

  const hasReferralsFeature = useFeatureFlag('ctw-referrals');
  const hasCareGapsFeature = useFeatureFlag('ctw-care-gaps-overview');

  const noData = Array.from(dataIndicatorsMap.values()).some((hasData) => hasData);

  if (!noData) {
    return (
      <div className={twx(className, 'scrollable-pass-through-height pt-8')}>
        <div className={tw`text-center`}>
          <EmptyPatientTable resourceName="overview" hasZeroFilteredRecords />
        </div>
      </div>
    );
  }

  if (builderConfig.isLoading) {
    return (
      <div className={twx(className, 'scrollable-pass-through-height pt-8')}>
        <div className={tw`text-center`}>
          <span className={tw`font-medium`}>Loading...</span>
        </div>
      </div>
    );
  }

  const { overviewCards = {} } = builderConfig.data || {};
  const cardIsEnabled = (cardName: string) => overviewCards[cardName]?.enabled !== false;
  const enabledCards = [
    cardIsEnabled('conditions') && (
      <PatientConditionOverview
        key="conditions"
        onSeeAllResources={() => onSeeAllResources('conditions')}
        onAfterDetailsDrawerClosed={onAfterDetailsDrawerClosed}
        onOpenDetailsDrawer={onOpenDetailsDrawer}
        conditionIdForDetailsDrawer={conditionIdForDetailsDrawer}
      />
    ),
    cardIsEnabled('referrals') && hasReferralsFeature && (
      <PatientReferralsOverview
        key="referrals"
        onSeeAllResources={() => onSeeAllResources('referrals')}
      />
    ),
    cardIsEnabled('medications') && (
      <PatientMedicationOverview
        key="medications"
        onSeeAllResources={() => onSeeAllResources('medications')}
      />
    ),
    cardIsEnabled('diagnostics') && (
      <PatientDiagnosticReportsOverview
        key="diagnostics"
        onSeeAllResources={() => onSeeAllResources('diagnostics')}
      />
    ),
    cardIsEnabled('encounters') && (
      <EncountersOverviewCard
        key="encounters"
        onSeeAllResources={() => onSeeAllResources('encounters')}
      />
    ),
    cardIsEnabled('episodesOfCare') && (
      <EpisodeOfCareOverviewCard
        key="episodeofcare"
        onSeeAllResources={() => onSeeAllResources('encounters')}
      />
    ),
    cardIsEnabled('vitals') && (
      <PatientVitalsOverview key="vitals" onSeeAllResources={() => onSeeAllResources('vitals')} />
    ),
    cardIsEnabled('zusInformation') && (
      <ZusInformationCard key="zusinformation" hideRequestRecords={hideRequestRecords} />
    ),
    cardIsEnabled('careGaps') && hasCareGapsFeature && <CareGapsCard key="caregaps" />,
  ].filter(Boolean);

  if (enabledCards.length === 0) {
    return <></>;
  }

  return (
    <div className={twx(className)} ref={ref}>
      <Masonry
        items={enabledCards}
        config={{
          columns: [1, 2, 3, 4],
          gap: [12, 12, 12, 12],
          media: [320 * 2 + 12 * 4, 320 * 3 + 12 * 5, 320 * 4 + 12 * 6, 320 * 5 + 12 * 7],
        }}
        render={(item) => item}
      />
    </div>
  );
};

export const PatientOverview = withErrorBoundary(PatientOverviewComponent, 'PatientOverview');
