import { faPills } from '@fortawesome/free-solid-svg-icons';
import { groupBy, orderBy } from 'lodash';
import { values } from 'lodash/fp';
import { useContext } from 'react';
import { useMedicationDetailsDrawer } from './helpers/details';
import { defaultMedicationSortOverview } from './helpers/sorts';
import { useMedicationRowActions } from './patient-medications-all';
import { ResourceOverviewCard } from '../overview/resource-overview-card';
import {
  RenderSyncedWithRecordIcon,
  ResourceTitleColumn,
} from '../resource/helpers/resource-title-column';
import { getDateRangeView } from '../resource/helpers/view-date-range';
import { MedicationStatementModel } from '@ctw/shared/api/fhir/models';
import { withErrorBoundaryResourceCard } from '@ctw/shared/components/overview-error-boundary';
import { useUserBuilderId } from '@ctw/shared/context/user-builder-id';
import { ZapPropsStateContext } from '@ctw/shared/context/zap-props-state-context';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { useQueryAllPatientMedications } from '@ctw/shared/hooks/use-medications';

const TITLE = 'Medications';
const FOOTER_TITLE = 'All Medications';

export type PatientMedicationOverviewProps = {
  onSeeAllResources: () => void;
};

export const PatientMedicationOverviewComponent = ({
  onSeeAllResources,
}: PatientMedicationOverviewProps) => {
  const userBuilderId = useUserBuilderId();
  const query = useQueryAllPatientMedications();
  const { props } = useContext(ZapPropsStateContext);
  const rowActions = useMedicationRowActions((record) => {
    if (props['medications-all']?.onAddToRecord) {
      void props['medications-all'].onAddToRecord(record);
    }
  });
  const readOnly = props['medications-all']?.readOnly ?? false;

  const openDetails = useMedicationDetailsDrawer({
    rowActions: readOnly ? undefined : rowActions,
    enableDismissAndReadActions: false,
  });

  const { past6Months } = getDateRangeView<MedicationStatementModel>('lastActivityDate', false);

  const { data } = useFilteredSortedData({
    defaultSort: defaultMedicationSortOverview, // Alphabetical
    viewOptions: [past6Months],
    defaultView: past6Months.display,
    records: query.allMedications,
  });

  // Dedupe medications by active ingredient, taking the entry
  // with the most recent lastActivityDate.
  const dedupedData = values(
    groupBy(data, (med: MedicationStatementModel) => med.activeIngredient),
  ).map((meds: MedicationStatementModel[]) => orderBy(meds, 'lastActivityDate', 'desc')[0]);

  return (
    <ResourceOverviewCard
      onRowClick={openDetails}
      data={dedupedData}
      emptyStateMessage={
        query.allMedications.length === 0 ?
          "We didn't find any medication records for this patient."
        : 'There are no medications with prescriptions or fills within the past 6 months for this patient.'
      }
      footerCTA={{
        label: FOOTER_TITLE,
        onClick: onSeeAllResources,
      }}
      title={TITLE}
      helpText="Medications with prescriptions or fills within the last 6 months"
      loading={query.isLoading}
      headerIcon={faPills}
      telemetryTargetName="medications_overview"
      testId="medications-overview"
      renderResource={(record: MedicationStatementModel) => (
        <ResourceTitleColumn
          title={record.display}
          renderIcon={RenderSyncedWithRecordIcon(record.ownedByBuilder(userBuilderId))}
        />
      )}
    />
  );
};

export const PatientMedicationOverview = withErrorBoundaryResourceCard({
  component: PatientMedicationOverviewComponent,
  name: 'PatientMedicationOverview',
  title: TITLE,
  footerTitle: FOOTER_TITLE,
  tabToNavigateTo: 'medications-all',
});
