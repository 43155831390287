import { useState } from 'react';
import { withErrorBoundary } from '@ctw/shared/content/error-boundary';
import { ContactInformation } from '@ctw/shared/content/demographics/contact-information';
import {
  DemographicsTabName,
  PatientInformationTabs,
} from '@ctw/shared/content/demographics/demographics-tabs';
import { PatientSources } from '@ctw/shared/content/patient-sources/patient-sources';
import { AnalyticsProvider } from '@ctw/shared/context/analytics/analytics-provider';
import { twx } from '@ctw/shared/utils/tailwind';

export type DemographicsProps = {
  className?: string;
};

const DemographicsComponent = ({ className }: DemographicsProps) => {
  const [selectedTab, setSelectedTab] = useState<DemographicsTabName>('Contact Information');

  return (
    <AnalyticsProvider componentName="PatientInformation">
      <div className={twx(className, 'flex flex-col gap-3 py-3')}>
        <PatientInformationTabs selectedTab={selectedTab} onTabSelected={setSelectedTab} />
        {selectedTab === 'Contact Information' && <ContactInformation />}
        {selectedTab === 'Sources' && <PatientSources />}
      </div>
    </AnalyticsProvider>
  );
};

export const Demographics = withErrorBoundary(DemographicsComponent, 'PatientInformation');
