import { orderBy } from 'lodash';
import { useState } from 'react';
import { HistoryEntry, HistoryEntryProps } from './history-entry';
import { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { UseQueryResultBasic } from '@ctw/shared/utils/request';
import { tw } from '@ctw/shared/utils/tailwind';
import { Button } from '@ctw/shared/components/button';

export type HistoryEntries = HistoryEntryProps[];

export type HistoryProps<M> = {
  limit?: number;
  getHistory?: (model: M) => UseQueryResultBasic<HistoryEntries | undefined>;
  model: M;
};

const DEFAULT_HISTORY_PAGE_LIMIT = 20;

export function History<T extends fhir4.Resource, M extends FHIRModel<T>>({
  limit = DEFAULT_HISTORY_PAGE_LIMIT,
  getHistory,
  model,
}: HistoryProps<M>) {
  const history = getHistory && getHistory(model);
  const entries: HistoryEntries = history?.data ?? [];
  const [showAll, setShowAll] = useState(!limit || entries.length <= limit);
  const { resourceTypeTitle } = model;

  // Sort entries by:
  //  1. date descending
  //  2. has a source document (binaryId present or not)
  //  3. title ascending
  //  4. subtitle ascending
  //  5. versionId descending (this is to ensure consistent ordering)
  const sortedEntries = orderBy(
    entries,
    [
      // Convert the date string to a Date object so that it sorts correctly.
      (e) => (e.date ? new Date(e.date) : ''), // desc
      // We want to promote entries with a source document to the top.
      (e) => !!e.binaryId, // desc
      'title', // asc
      'subtitle', // asc
      'versionId', // desc
    ],
    ['desc', 'desc', 'asc', 'asc', 'desc'],
  );

  const displayedEntries = showAll || !limit ? sortedEntries : sortedEntries.slice(0, limit);

  return history && history.isLoading ?
      <LoadingSpinner message="Loading history..." />
    : <div className={tw`space-y-4`}>
        <div className={tw`text-lg font-semibold`}>History</div>
        {displayedEntries.map((entry, idx) => (
          // We can have multiple items with the same condition id
          // eslint-disable-next-line react/no-array-index-key
          <div key={`${entry.id}-${idx}`}>
            <HistoryEntry
              id={entry.id}
              date={entry.date}
              title={entry.title}
              subtitle={entry.subtitle}
              details={entry.details}
              hideEmpty={entry.hideEmpty}
              binaryId={entry.binaryId}
              resourceTypeTitle={resourceTypeTitle}
            />
          </div>
        ))}
        {!showAll && (
          <div className={tw`text-center`}>
            <Button type="button" variant="primary" onClick={() => setShowAll(true)}>
              {/* We know limit must be set if showAll is false. */}
              {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
              Load {sortedEntries.length - limit!} More
            </Button>
          </div>
        )}
      </div>;
}
