import { useContext } from 'react';
import { OverviewResources, PatientOverview } from './patient-overview';
import { withErrorBoundary } from '@ctw/shared/content/error-boundary';
import { ZapTabStateContext } from '@ctw/shared/context/zap-tab-state-context';
import { tw } from '@ctw/shared/utils/tailwind';

export type PatientOverviewZapProps = {
  hideRequestRecords?: boolean;
};

export const PatientOverviewZapComponent = ({
  hideRequestRecords = false,
}: PatientOverviewZapProps) => {
  const { setSelectedTab } = useContext(ZapTabStateContext);

  function handleSeeAllResources(resource: OverviewResources) {
    switch (resource) {
      case 'conditions':
        setSelectedTab('conditions-all');
        break;
      case 'referrals':
        setSelectedTab('referrals');
        break;
      case 'medications':
        setSelectedTab('medications-all');
        break;
      case 'diagnostics':
        setSelectedTab('diagnostic-reports');
        break;
      case 'encounters':
        setSelectedTab('encounters');
        break;
      case 'vitals':
        setSelectedTab('vitals');
        break;
      default:
    }
  }

  return (
    <PatientOverview
      className={tw`pt-5`}
      onSeeAllResources={handleSeeAllResources}
      hideRequestRecords={hideRequestRecords}
    />
  );
};

export const PatientOverviewZap = withErrorBoundary(PatientOverviewZapComponent, 'PatientOverview');
