import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { tw } from '@ctw/shared/utils/tailwind';

// Shows centered loading message and spinner with some padding.
export function LoadingTable() {
  return (
    <div className={tw`flex items-center justify-center space-x-2 px-4 py-9`}>
      <LoadingSpinner message="Loading..." />
    </div>
  );
}
