import { GraphQLClient, Variables } from 'graphql-request';
import { get, isEmpty } from 'lodash';
import { graphQLToFHIR } from './graphql-to-fhir';
import { ResourceType, ResourceTypeString } from '@ctw/shared/api/fhir/types';
import { getZusServiceUrl } from '@ctw/shared/api/urls';
import { Env } from '@ctw/shared/context/types';
import { CTW_REQUEST_HEADER, ctwFetch } from '@ctw/shared/utils/request';
import { Telemetry } from '@ctw/shared/utils/telemetry';
import { isEmptyValue } from '@ctw/shared/utils/types';

export interface GraphqlPageInfo {
  hasNextPage: boolean;
  startCursor?: string;
  endCursor?: string;
}

export type FQSFilter = {
  ids?: FQSFilterMatchLogic;
  tag?: FQSFilterMatchLogic;
};

type FQSFilterMatchLogic = {
  allmatch?: string[];
  nonematch?: string[];
};

export interface GraphqlConnectionNode<T> {
  node: T;
}

export interface GenericConnection<T extends ResourceTypeString> {
  pageInfo: GraphqlPageInfo;
  edges: GraphqlConnectionNode<ResourceType<T>>[];
}

// FQS has hard limit of 500 objects per request
export const MAX_OBJECTS_PER_REQUEST = 500;
// We have found that 7 pages of 500 objects should almost cover all cases. The
// reason we have this limit is basically to cap off runaway requests if fqs
// pagination breaks or to limit time spent for some extreme patient cases
const MAX_PAGES_PER_REQUEST = 7;

export const createGraphqlClient = (requestContext: { env: string; authToken: string }) => {
  const endpoint = `${getZusServiceUrl(requestContext.env, 'fqs')}/query`;

  return new GraphQLClient(endpoint, {
    errorPolicy: 'all',
    headers: {
      ...CTW_REQUEST_HEADER,
      authorization: `Bearer ${requestContext.authToken}`,
    },
  });
};

export async function fqsRequest<T>(
  client: GraphQLClient,
  query: string,
  variables: object,
): Promise<{ data: T }> {
  const updatedVariables = { ...variables };
  if (
    'first' in updatedVariables &&
    typeof updatedVariables.first === 'number' &&
    updatedVariables.first > MAX_OBJECTS_PER_REQUEST
  ) {
    updatedVariables.first = MAX_OBJECTS_PER_REQUEST;
  }
  const { data, errors } = await client.rawRequest<T>(query, updatedVariables as Variables);

  const fhirData = graphQLToFHIR(data);
  if (errors) {
    const requestCompletelyFailed = isEmptyValue(data);

    Telemetry.logger.error(
      requestCompletelyFailed ? 'FQS request failed' : (
        'FQS request partially failed, proceeding with returned data'
      ),
      {
        errors: errors.slice(0, 10),
        errorsTruncated: errors.length > 10,
      },
    );
    if (requestCompletelyFailed) {
      throw errors;
    }
  }
  return { data: fhirData };
}

export async function fqsRequestAll<T>(
  client: GraphQLClient,
  query: string,
  namespace: string,
  variables: object,
  pageLimit = MAX_PAGES_PER_REQUEST,
): Promise<{ data: T }> {
  if (!isEmpty(get(variables, 'sort'))) {
    throw new Error(
      "'sort' variable not allowed in fqsRequestAll; FQS does not support sorting with pagination",
    );
  }
  let pageCount = 0;
  let fqsHasNextPageClaim = true;
  const responses = [];
  let lastEndCursor = '';

  do {
    const updatedVariables = {
      ...variables,
      // Some queries require 'sort', but if it is set to anything besides an empty object, fqs pagination breaks!
      sort: {},
      cursor: lastEndCursor,
    };
    // eslint-disable-next-line no-await-in-loop
    const response = await fqsRequest<T>(client, query, updatedVariables);
    responses.push(response);
    const endCursor = get(response, ['data', namespace, 'pageInfo', 'endCursor'], '');
    fqsHasNextPageClaim =
      lastEndCursor !== endCursor &&
      get(response, ['data', namespace, 'pageInfo', 'hasNextPage'], false);

    lastEndCursor = endCursor;
    pageCount += 1;
  } while (pageLimit > pageCount && fqsHasNextPageClaim);

  // This metric informs how many pages we've fetched and whether we finished early with still more pages to fetch.
  void Telemetry.countMetric('fqs_request_all', 1, [
    `pages_fetched:${pageCount}`,
    `has_next_page:${fqsHasNextPageClaim}`,
    `query_type:${namespace}`,
  ]);

  return responses.reduce(
    (acc, next) => {
      const edges = get(acc, ['data', namespace, 'edges'], []);
      const nextEdges = get(next, ['data', namespace, 'edges'], []);

      return {
        ...acc,
        data: {
          [namespace]: {
            edges: edges.concat(nextEdges),
          },
        } as T,
      };
    },
    { data: { [namespace]: { edges: [] } } } as { data: T },
  );
}

export function getFetchFromFqs(env: Env, accessToken: string) {
  return (url: string, options: RequestInit) =>
    ctwFetch(`${getZusServiceUrl(env, 'fqs')}/${url}`, {
      ...options,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ...options.headers,
      } as Record<string, string>,
    });
}

export function getResourceNodes<T extends ResourceTypeString>(
  response: object,
): ResourceType<T>[] {
  const values = Object.values(response) as GenericConnection<T>[];

  return values.map((x) => x.edges.map((y) => y.node)).flat();
}
