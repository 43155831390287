import { twx } from '@ctw/shared/utils/tailwind';

export function ImageIcon({ className }: { className?: string }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="3.75 1.5 12.5 17.5"
      xmlns="http://www.w3.org/2000/svg"
      className={twx(className)}
    >
      <path d="M6 2H11V6C11 6.5625 11.4375 7 12 7H16V16C16 17.125 15.0938 18 14 18H6C4.875 18 4 17.125 4 16V4C4 2.90625 4.875 2 6 2ZM12 2L16 6H12V2ZM8 10C8 9.46875 7.53125 9 7 9C6.4375 9 6 9.46875 6 10C6 10.5625 6.4375 11 7 11C7.53125 11 8 10.5625 8 10ZM10.75 11V11.0312C10.5625 11.0312 10.4062 11.0938 10.3125 11.2188L8.8125 13.4375L8.40625 12.75C8.3125 12.5938 8.15625 12.5 8 12.5C7.8125 12.5 7.65625 12.5938 7.5625 12.75L6.0625 15.25C5.96875 15.4062 5.96875 15.5938 6.0625 15.75C6.125 15.9062 6.3125 16 6.5 16H8H9.5H10.75H13.5C13.6875 16 13.8438 15.9062 13.9375 15.75C14 15.5938 14 15.375 13.9062 15.2188L11.1562 11.2188C11.0625 11.0938 10.9062 11 10.75 11Z" />
    </svg>
  );
}
