import { Resource } from 'fhir/r4';
import { omit } from 'lodash/fp';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ZusAggregatedProfileProps } from './zus-aggregated-profile';
import { MedicationStatementModel } from '@ctw/shared/api/fhir/models';
import { getZapIFrameUrl } from '@ctw/shared/api/urls';
import { withErrorBoundary } from '@ctw/shared/content/error-boundary';
import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { CTWProviderProps } from '@ctw/shared/context/ctw-provider';
import {
  PatientResourceID,
  ThirdPartyID,
  usePatientContext,
} from '@ctw/shared/context/patient-provider';
import { useTelemetry } from '@ctw/shared/context/telemetry/use-telemetry';
import { useTheme } from '@ctw/shared/context/theme/use-theme';
import { useCTW } from '@ctw/shared/context/use-ctw';
import { useIsomorphicLayoutEffect } from '@ctw/shared/hooks/use-isomorphic-layout-effect';
import { tw } from '@ctw/shared/utils/tailwind';

// Theming for iframe which can't be handled via global tailwind theme
export type IFrameTheme = {
  fontFamily?: 'Avenir' | 'Roboto';
  headerSize?: string;
  lineHeight?: string;
  letterSpacing?: string;
  fontWeight?: string;
};
export type ZapIFrameConfig = {
  CTWProviderProps: CTWProviderProps;
  PatientProviderProps: ThirdPartyID | PatientResourceID;
  ZusAggregatedProfileProps: ZusAggregatedProfileIFrameProps;
  iframeTheme: IFrameTheme | undefined;
};
export const ZapIFrameConfigMessageType = 'ZusAggregatedProfileIFrameConfig';
export const ZapIFrameReadyMessageType = 'ZusAggregatedProfileIFrameReady';
export const ZapOnResourceSaveMessageType = 'ZusAggregatedProfileOnResourceSave';
export const ZapOnPatientSaveMessageType = 'ZusAggregatedProfileOnPatientSave';
export const ZapOnAddToRecordMessageType = 'ZusAggregatedProfileOnAddToRecord';
export const ZapOnConfigReceivedMessageType = 'ZusAggregatedProfileConfigReceived';

export type ZapMessageEventData<EventType, EventPayload = undefined> = {
  type: EventType;
  id?: string;
} & ({ payload: EventPayload; error?: never } | { payload?: never; error: string });

export type ZapIFrameConfigMessageEvent = ZapMessageEventData<typeof ZapIFrameConfigMessageType>;
export type ZapOnConfigReceivedMessageEvent = ZapMessageEventData<
  typeof ZapOnConfigReceivedMessageType
>;
export type ZapIFrameReadyMessageEvent = ZapMessageEventData<typeof ZapIFrameReadyMessageType>;
export type ZapOnResourceSaveMessageEvent = ZapMessageEventData<
  typeof ZapOnResourceSaveMessageType,
  {
    resource: Resource;
    action: 'create' | 'update';
    error?: string;
  }
>;
export type ZapOnAddToRecordMessageEvent = ZapMessageEventData<
  typeof ZapOnAddToRecordMessageType,
  {
    resource: fhir4.MedicationStatement;
    includedResources?: Record<string, fhir4.Resource>;
    component: 'medications-outside' | 'medications-all';
  }
>;

export type ZapMessageEvent = MessageEvent<
  | ZapOnAddToRecordMessageEvent
  | ZapOnConfigReceivedMessageEvent
  | ZapOnResourceSaveMessageEvent
  | ZapIFrameConfigMessageEvent
  | ZapIFrameReadyMessageEvent
>;

type ZusAggregatedProfileIFrameProps = ZusAggregatedProfileProps & {
  overrideIframeUrl?: string;
  iframeId?: string;
};

const IFRAME_MAX_LOAD_TIME_MS = 5000; // 5 seconds is a very reasonable amount of time for the iframe to load

const ZusAggregatedProfileIFrameComponent = (props: ZusAggregatedProfileIFrameProps) => {
  const { Telemetry, context: telemetryContext } = useTelemetry();
  const { overrideIframeUrl } = props;
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [hostedZapReady, setHostedZapReady] = useState(false);
  const [preparedOnReadyListener, setPreparedOnReadyListener] = useState(false);
  const [iframeShouldBeLoaded, setIframeShouldBeLoaded] = useState(false);
  const [sentZapConfig, setSentZapConfig] = useState(false);
  const [zapURL, setZapUrl] = useState<string | undefined>(overrideIframeUrl);
  const [onAddToRecord, setOnAddToRecord] = useState<(e: ZapMessageEvent) => void>();
  const { getRequestContext, featureFlags } = useCTW();
  const patient = usePatientContext();
  const theme = useTheme();

  const { medicationsOutsideProps, medicationsAllProps } = props;

  if (iframeShouldBeLoaded && !hostedZapReady) {
    // It has been a reasonable amount of time since this component mounted and set the onPreparedReadyListener and we
    // still have not heard back from the embedded iframe. This could indicate that there is either an issue downloading
    // the embedded ZAP static files or some wonkiness in our window message event listeners. In either case, we should
    // error here to prevent the user from waiting indefinitely and let the error boundary send component failure metrics.
    throw new Error(
      'The ZAP should have connected by now. Please try to refresh the ZAP. If this error persists please contact support.',
    );
  }

  useEffect(() => {
    const onAddToRecordHandler = async ({ data }: ZapMessageEvent) => {
      if (data.type === ZapOnAddToRecordMessageType && typeof data.error !== 'string') {
        const medicationStatement = new MedicationStatementModel(
          data.payload.resource,
          data.payload.includedResources,
        );
        const response: Partial<ZapOnAddToRecordMessageEvent> = {
          id: data.id,
          type: data.type,
        };
        try {
          switch (data.payload.component) {
            case 'medications-outside':
              await medicationsOutsideProps?.onAddToRecord?.(medicationStatement);
              break;
            case 'medications-all':
              await medicationsAllProps?.onAddToRecord?.(medicationStatement);
              break;
            default:
              response.error = `Unhandled onAddToRecord for component: ${data.payload.component}`;
          }
        } catch (e) {
          response.error = e instanceof Error ? e.message : 'Unknown error';
        }
        iframeRef.current?.contentWindow?.postMessage(response, String(zapURL));
        Telemetry.logger.debug(
          'ZusAggregatedProfileIframe - Sent onAddToRecord message from parent frame',
        );
      }
    };

    // Add this listener
    const updatedOnAddToRecordHandler = (event: ZapMessageEvent) => {
      Telemetry.logger.debug(
        'ZusAggregatedProfileIframe - Receiving onAddToRecord message in parent frame',
      );
      void onAddToRecordHandler(event as ZapMessageEvent);
    };
    // Remove previous listener if exists
    if (onAddToRecord) {
      Telemetry.logger.debug(
        'ZusAggregatedProfileIframe - Removed previous onAddToRecord listener from parent frame',
      );
      window.removeEventListener('message', onAddToRecord);
    }
    window.addEventListener('message', updatedOnAddToRecordHandler);
    Telemetry.logger.debug(
      'ZusAggregatedProfileIframe - Added onAddToRecord listener to parent frame',
    );

    // Set the current listener and remove it later if this effect is called again.
    setOnAddToRecord(() => updatedOnAddToRecordHandler);
    return () => {
      Telemetry.logger.debug(
        'ZusAggregatedProfileIframe - Unmounting, removing onAddToRecord listener from parent frame',
      );
      window.removeEventListener('message', updatedOnAddToRecordHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    medicationsOutsideProps?.readOnly,
    medicationsOutsideProps?.onAddToRecord,
    medicationsAllProps?.readOnly,
    medicationsAllProps?.onAddToRecord,
    zapURL,
  ]);

  useIsomorphicLayoutEffect(() => {
    // listener to know when iframe ZAP is ready
    const onMessageReady = ({ data }: ZapMessageEvent) => {
      // Handle message that iframe is ready to receive ctw config
      if (data.type === ZapIFrameReadyMessageType) {
        Telemetry.logger.debug(
          'ZusAggregatedProfileIframe - Receiving IFrameReady message in parent frame, setting hostedZapReady to true',
        );
        setHostedZapReady(true);
      }
      // Handle message that iframe successfully received ctw config. This simply for debugging purposes.
      if (data.type === ZapOnConfigReceivedMessageType) {
        Telemetry.logger.debug(
          'ZusAggregatedProfileIframe - Receiving onConfigReceived message in parent frame',
        );
      }
    };
    window.addEventListener('message', onMessageReady);
    Telemetry.logger.debug(
      'ZusAggregatedProfileIframe - Added onMessageReady listener to parent frame',
    );
    setPreparedOnReadyListener(true);

    const timeoutId = setTimeout(() => {
      setIframeShouldBeLoaded(true);
    }, IFRAME_MAX_LOAD_TIME_MS);

    return () => {
      clearTimeout(timeoutId);
      Telemetry.logger.debug(
        'ZusAggregatedProfileIframe - Unmounting, removing onMessageReady listener from parent frame',
      );
      window.removeEventListener('message', onMessageReady);
    };
  }, []);

  useEffect(() => {
    // set up ZAP URL and listener for resource save
    let requestContext: CTWRequestContext | undefined;
    void (async () => {
      requestContext = await getRequestContext();
      setZapUrl(overrideIframeUrl ?? getZapIFrameUrl(requestContext.env));
    })();

    const onMessageSave = ({ data }: ZapMessageEvent) => {
      if (data.type === ZapOnResourceSaveMessageType && typeof data.error !== 'string') {
        Telemetry.logger.debug(
          'ZusAggregatedProfileIframe - Receiving onMessageSave message in parent frame',
        );
        const { resource, action, error } = data.payload;
        requestContext?.onResourceSave(resource, action, new Error(error));
      }
    };

    window.addEventListener('message', onMessageSave);
    Telemetry.logger.debug(
      'ZusAggregatedProfileIframe - Added onMessageSave listener to parent frame',
    );
    return () => {
      Telemetry.logger.debug(
        'ZusAggregatedProfileIframe - Unmounting, removing onMessageSave listener from parent frame',
      );
      window.removeEventListener('message', onMessageSave);
    };
  }, [Telemetry.logger, getRequestContext, overrideIframeUrl]);

  useEffect(() => {
    // Pass config to iframe
    async function load() {
      const requestContext = await getRequestContext();

      //  & { locals?: Locals, enableTelemetry: boolean, ehr: string }
      const ctwProviderProps: CTWProviderProps = {
        authToken: requestContext.authToken,
        env: requestContext.env,
        builderId: requestContext.builderId,
        featureFlags,
        ehr: telemetryContext.ehr,
        enableTelemetry: telemetryContext.enableTelemetry,
        headers: requestContext.headers as Record<string, string> | undefined,
        locals: theme.locals,
        theme: {
          ...theme.theme,
          iframe: theme.iframeTheme,
        },
      };

      const payload: ZapIFrameConfig = {
        CTWProviderProps: ctwProviderProps,
        PatientProviderProps:
          typeof patient.patientResourceID === 'string' ?
            {
              patientResourceID: patient.patientResourceID,
            }
          : {
              patientID: patient.patientID ?? '',
              systemURL: patient.systemURL ?? '',
            },
        ZusAggregatedProfileProps: {
          ...props,
          medicationsOutsideProps: omit('onAddToRecord', medicationsOutsideProps),
          medicationsAllProps: omit('onAddToRecord', medicationsAllProps),
        },
        // todo: refactor this out. It is redundant with CTWProviderProps.theme.iframe but it's expected by the standalone ZAP.
        iframeTheme: theme.iframeTheme,
      };

      iframeRef.current?.contentWindow?.postMessage(
        { type: ZapIFrameConfigMessageType, payload },
        zapURL as string,
      );
      Telemetry.logger.debug(
        'ZusAggregatedProfileIframe - Sent ZapIFrameConfig message from parent frame',
      );
      setSentZapConfig(true);
    }

    if (hostedZapReady) {
      Telemetry.logger.debug(
        'ZusAggregatedProfileIframe - Hosted ZAP is ready, preparing to send config from parent frame',
      );
      void load();
    }
  }, [
    getRequestContext,
    hostedZapReady,
    iframeRef,
    patient.patientResourceID,
    patient.patientID,
    patient.systemURL,
    props,
    medicationsAllProps,
    medicationsOutsideProps,
    zapURL,
    sentZapConfig,
    featureFlags,
    theme.theme,
    theme.locals,
    telemetryContext.enableTelemetry,
    telemetryContext.ehr,
    theme.iframeTheme,
    Telemetry.logger,
  ]);

  const configSearchParams = useMemo(() => {
    const params: { builderId?: string; ehr?: string } = { ehr: undefined };
    if (telemetryContext.builderId) {
      params.builderId = telemetryContext.builderId;
    }
    if (telemetryContext.ehr) {
      params.ehr = telemetryContext.ehr;
    }
    return new URLSearchParams(params);
  }, [telemetryContext.ehr, telemetryContext.builderId]);

  if (!zapURL || !preparedOnReadyListener) {
    return null;
  }

  return (
    <iframe
      ref={iframeRef}
      data-testid="iframe-zap"
      title="zus-aggregated-profile"
      height="100%"
      width="100%"
      src={`${zapURL}/v1?${configSearchParams}`}
      className={tw`border-0`}
    />
  );
};

export const ZusAggregatedProfileIFrame = withErrorBoundary(
  ZusAggregatedProfileIFrameComponent,
  'ZusAggregatedProfileIFrame',
);
