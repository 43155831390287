import { isEmpty } from 'lodash';
import { ReactNode, useState } from 'react';
import { Drawer, DrawerProps } from '../drawer';
import { SaveButton } from '@ctw/shared/content/forms/save-button';
import { useAnalytics } from '@ctw/shared/context/analytics/use-analytics';
import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { useCTW } from '@ctw/shared/context/use-ctw';
import { getFormResponseErrors } from '@ctw/shared/utils/errors';
import { AnyZodSchema, getFormData } from '@ctw/shared/utils/form-helper';
import { tw } from '@ctw/shared/utils/tailwind';
import { Telemetry } from '@ctw/shared/utils/telemetry';
import { Alert } from '@ctw/shared/components/alert';
import { Button } from '@ctw/shared/components/button';
import { TrackingMetadata } from '@ctw/shared/context/analytics/tracking-metadata';

export type FormErrors = Record<string, string[]>;
type InputError = Record<string, string[]>;

export type DrawerFormProps<T> = {
  action: (data: T, getRequestContext: () => Promise<CTWRequestContext>) => Promise<unknown>;
  closeOnSubmit?: boolean;
  onSubmit?: () => void;
  onSubmitSuccess?: () => void;
  onSubmitError?: (errorMessage: string) => void;
  schema: AnyZodSchema;
  errorHeader?: string;
  children: (submitting: boolean, errors?: FormErrors) => ReactNode;
  trackingMetadata?: TrackingMetadata;
} & Omit<DrawerProps, 'children'>;

export const DrawerForm = <T,>({
  action,
  closeOnSubmit = false,
  onSubmit,
  onSubmitSuccess,
  onSubmitError,
  onClose,
  children,
  schema,
  errorHeader = 'There was an error with your submission',
  trackingMetadata,
  ...drawerProps
}: DrawerFormProps<T>) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<{
    formErrors?: FormErrors;
    requestErrors?: string[];
  }>();
  const { getRequestContext } = useCTW();
  const { trackInteraction } = useAnalytics();

  const reset = () => {
    setErrors({});
    setIsSubmitting(false);
  };

  const onFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setErrors({});
    setIsSubmitting(true);
    const form = event.target;

    const inputs = Array.from((event.target as HTMLElement).querySelectorAll('input'));

    const inputErrors: InputError = {};

    inputs.forEach((input) => {
      if (!input.checkValidity()) {
        inputErrors[input.name] = [`The value is not valid for ${input.name}`];
      }
    });

    if (!isEmpty(inputErrors)) {
      setErrors({ formErrors: inputErrors });
      setIsSubmitting(false);
      return;
    }

    const data = new FormData(form as HTMLFormElement);

    // Validation that occurs before the request.
    const formResult = await getFormData(data, schema);
    if (!formResult.success) {
      // eslint-disable-next-line no-console
      console.error('There was an error processing form data. formResult:', formResult);
      setErrors({
        formErrors: formResult.errors,
        requestErrors: undefined,
      });
      setIsSubmitting(false);
      return;
    }

    let response;
    let responseIsSuccess = true;
    let requestErrors;
    try {
      if (closeOnSubmit) onClose();
      if (onSubmit) onSubmit();
      response = await action(formResult.data, getRequestContext);
    } catch (e) {
      Telemetry.logError(e as Error);
      responseIsSuccess = false;
      requestErrors = getFormResponseErrors(e).requestErrors;
    }

    if (response) {
      const formResponseErrors = getFormResponseErrors(response);
      responseIsSuccess = formResponseErrors.responseIsSuccess;
      requestErrors = formResponseErrors.requestErrors;
    }

    // Setting any errors from the response to the form.
    if (!responseIsSuccess) {
      setErrors({
        formErrors: undefined,
        requestErrors,
      });
      setIsSubmitting(false);
      if (onSubmitError) {
        onSubmitError(requestErrors?.join(', ') || 'An error occurred');
      }
    } else {
      setIsSubmitting(false);
      if (!closeOnSubmit) onClose();
      if (onSubmitSuccess) onSubmitSuccess();
    }
  };

  return (
    <Drawer {...drawerProps} onClose={onClose} onAfterClosed={reset} disableCloseOnBlur>
      <form
        className={tw`flex h-full flex-col overflow-y-auto`}
        onSubmit={(event) => {
          void onFormSubmit(event);
          trackInteraction('submit_form', trackingMetadata);
        }}
        noValidate // Removes the browser tooltip functionality.
      >
        <div className={tw`space-y-4 px-2`}>
          {errors?.requestErrors && (
            <Alert type="error" header={errorHeader}>
              {errors.requestErrors.length === 1 ?
                errors.requestErrors[0]
              : <ul className={tw`m-0 list-disc px-4`}>
                  {errors.requestErrors.map((error) => (
                    <li>{error}</li>
                  ))}
                </ul>
              }
            </Alert>
          )}
          {children(isSubmitting, errors?.formErrors)}
        </div>
        <div className={tw`mt-5 flex gap-3 border-t pt-3`}>
          <Button
            type="button"
            variant="clear"
            className={tw`!px-4 !py-2`}
            onClick={() => {
              onClose();
              trackInteraction('close_drawer', {
                target: 'footer_close_icon',
                ...trackingMetadata,
              });
            }}
          >
            Cancel
          </Button>
          <div className={tw`flex items-center space-x-3`}>
            {(errors?.requestErrors || errors?.formErrors) && (
              <div className={tw`text-sm font-medium text-error-text`}>
                There was an error with your submission
              </div>
            )}
            <SaveButton submitting={isSubmitting} />
          </div>
        </div>
      </form>
    </Drawer>
  );
};
