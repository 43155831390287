import { useRef } from 'react';
import { patientTimelineColumns } from './helpers/columns';
import { defaultTimelineFilters, timelineFilters } from './helpers/filters';
import { usePatientEncounterDetailsDrawer } from './helpers/modal-hooks';
import { defaultTimelineSort, timelineSortOptions } from './helpers/sorts';
import { useObservationsDetailsDrawer } from '../observations/helpers/drawer';
import { getDateRangeView } from '../resource/helpers/view-date-range';
import { ResourceTableActions } from '../resource/resource-table-actions';
import { TimelineEventModel } from '@ctw/shared/api/fhir/models/timeline-event';
import { useTimelineEvents } from '@ctw/shared/api/fhir/timeline-event';
import { EmptyPatientTable } from '@ctw/shared/components/table/empty-table';
import { ResourceTable } from '@ctw/shared/content/resource/resource-table';
import { AnalyticsProvider } from '@ctw/shared/context/analytics/analytics-provider';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { ClassName, twx } from '@ctw/shared/utils/tailwind';

export type PatientTimelineProps = {
  className?: ClassName;
};

export function PatientTimeline({ className }: PatientTimelineProps) {
  const timelineEventsQuery = useTimelineEvents();
  const containerRef = useRef<HTMLDivElement>(null);
  const { viewOptions, past6Months } = getDateRangeView<TimelineEventModel>('date');

  const { data, filters, setFilters, setSort, setViewOption, defaultSort, defaultView } =
    useFilteredSortedData({
      cacheKey: 'patient-timeline',
      viewOptions,
      defaultView: past6Months.display,
      defaultFilters: defaultTimelineFilters,
      defaultSort: defaultTimelineSort,
      records: timelineEventsQuery.data,
    });

  const isEmptyQuery = timelineEventsQuery.data.length === 0;
  const hasZeroFilteredRecords = !isEmptyQuery && data.length === 0;

  const openEncounterDetails = usePatientEncounterDetailsDrawer();
  const openDiagnosticReportDetails = useObservationsDetailsDrawer();

  return (
    <AnalyticsProvider componentName="PatientTimeline">
      <div className={twx(className, 'scrollable-pass-through-height')} ref={containerRef}>
        <ResourceTableActions
          viewOptions={{
            onChange: setViewOption,
            options: viewOptions,
            defaultView,
          }}
          filterOptions={{
            onChange: setFilters,
            defaultFilters: defaultTimelineFilters,
            filters: timelineFilters(timelineEventsQuery.data),
            selected: filters,
          }}
          sortOptions={{
            defaultSort,
            options: timelineSortOptions,
            onChange: setSort,
          }}
        />
        <ResourceTable
          showTableHead={false}
          isLoading={timelineEventsQuery.isLoading}
          data={data}
          emptyMessage={
            <EmptyPatientTable
              hasZeroFilteredRecords={hasZeroFilteredRecords}
              resourceName="timeline records"
            />
          }
          columns={patientTimelineColumns}
          onRowClick={(record) => {
            switch (record.model.kind) {
              case 'Encounter':
                openEncounterDetails(record.model);
                break;
              case 'DiagnosticReport':
                openDiagnosticReportDetails(record.model);
                break;
              default:
            }
          }}
        />
      </div>
    </AnalyticsProvider>
  );
}
