import { faFlask } from '@fortawesome/free-solid-svg-icons';
import { compact } from 'lodash';
import { useEffect, useState } from 'react';
import { useObservationsDetailsDrawer } from '../observations/helpers/drawer';
import { OverviewFilter } from '../overview/filters';
import { ResourceOverviewCard } from '../overview/resource-overview-card';
import {
  RenderSyncedWithRecordIcon,
  ResourceTitleColumn,
} from '../resource/helpers/resource-title-column';
import { usePatientDiagnosticReportsWithTrendData } from '@ctw/shared/api/fhir/diagnostic-report';
import { DiagnosticReportModel } from '@ctw/shared/api/fhir/models';
import { getCTWBaseUrl } from '@ctw/shared/api/urls';
import { withErrorBoundaryResourceCard } from '@ctw/shared/components/overview-error-boundary';
import { useTimerQueryWithCTW } from '@ctw/shared/context/use-query-with-ctw';
import { useUserBuilderId } from '@ctw/shared/context/user-builder-id';
import { ctwFetch } from '@ctw/shared/utils/request';
import { tw } from '@ctw/shared/utils/tailwind';

const TITLE = 'Select Labs';
const FOOTER_TITLE = 'All Labs';

const hasA1CCode = (A1CCodes: string[], r: DiagnosticReportModel) =>
  r.coding?.code && A1CCodes.includes(r.coding.code);

const containsText = (r: DiagnosticReportModel, text: string) =>
  r.displayName.toLowerCase().includes(text);

const cleanupA1CDisplay = (A1CCodes: string[], r: DiagnosticReportModel) => {
  // First check that it is a 1AC display
  if (hasA1CCode(A1CCodes, r) || containsText(r, 'a1c')) {
    if (hasA1CCode(A1CCodes, r)) {
      return r.displayName;
    }
    return 'A1c';
  }

  // If not an a1c display don't manipulate text
  return r.displayName;
};

export const getOverviewFilters = (A1CCodes: string[]): OverviewFilter<DiagnosticReportModel>[] => [
  {
    key: 'a1c',
    label: 'Filter by A1C',
    description: 'Contains a1c in text or if it includes an a1c code',
    predicate: (r: DiagnosticReportModel) => hasA1CCode(A1CCodes, r) || containsText(r, 'a1c'),
  },
  {
    key: 'cbc',
    label: 'Filter by CBC',
    description: 'Match lowercase “cbc” or “complete blood count” but not containing “cd4/cd8”',
    predicate: (r: DiagnosticReportModel) =>
      /cbc(?!.*cd4\/cd8)|complete blood count/.test(r.displayName.toLowerCase()),
  },
  {
    key: 'cmp',
    label: 'Filter by CMP',
    description: 'Match lowercase “cmp” or “bmp” or “metabolic panel”',
    predicate: (r: DiagnosticReportModel) =>
      ['cmp', 'bmp', 'metabolic panel'].includes(r.displayName.toLowerCase()),
  },
];

const useA1CCodes = () =>
  useTimerQueryWithCTW('a1cCodes', [], async (requestContext, _) => {
    const { data } = await ctwFetch(
      `${getCTWBaseUrl(requestContext.env)}/api/terminology/valuesets/a1c`,
      {
        headers: {
          Authorization: `Bearer ${requestContext.authToken}`,
          'content-type': 'application/json',
        },
      },
    );
    return data;
  });

type ValueSet = {
  code: string;
  display: string;
};

export type PatientDiagnosticReportsOverviewProps = {
  onSeeAllResources: () => void;
};

export const PatientDiagnosticReportsOverviewComponent = ({
  onSeeAllResources,
}: PatientDiagnosticReportsOverviewProps) => {
  const [A1CCodes, setA1CCodes] = useState([]);
  const [mergedData, setMergedData] = useState<DiagnosticReportModel[]>([]);
  const openDetails = useObservationsDetailsDrawer(false);
  const { data, isLoading } = usePatientDiagnosticReportsWithTrendData();
  const userBuilderId = useUserBuilderId();
  const useA1CCodesResponse = useA1CCodes();

  useEffect(() => {
    if (useA1CCodesResponse.data && useA1CCodesResponse.data.content) {
      setA1CCodes(useA1CCodesResponse.data.content.map((item: ValueSet) => item.code));
    }
  }, [useA1CCodesResponse.data]);

  // Wait for the A1C codes to load before rendering the data
  useEffect(() => {
    if (A1CCodes.length > 0) {
      const filteredData = compact(
        getOverviewFilters(A1CCodes).flatMap((filter) => data.filter(filter.predicate)[0]),
      );
      setMergedData(filteredData);
    }
  }, [A1CCodes, data]);

  return (
    <ResourceOverviewCard
      onRowClick={openDetails}
      data={mergedData}
      emptyStateMessage={
        data.length === 0 ?
          "We didn't find any lab records for this patient."
        : 'There are no metabolic or CBC panel and A1C labs for this patient.'
      }
      footerCTA={{
        label: FOOTER_TITLE,
        onClick: onSeeAllResources,
      }}
      title={TITLE}
      helpText="Most recent Metabolic or CBC Panel and A1C"
      loading={isLoading || useA1CCodesResponse.isLoading}
      headerIcon={faFlask}
      telemetryTargetName="labs_overview"
      testId="labs-overview"
      renderResource={(record: DiagnosticReportModel) => (
        <div>
          <ResourceTitleColumn
            title={cleanupA1CDisplay(A1CCodes, record)}
            renderIcon={RenderSyncedWithRecordIcon(record.ownedByBuilder(userBuilderId))}
          />
          <div className={tw`font-normal`}>Recorded: {record.effectiveStart}</div>
        </div>
      )}
    />
  );
};

export const PatientDiagnosticReportsOverview = withErrorBoundaryResourceCard({
  component: PatientDiagnosticReportsOverviewComponent,
  name: 'PatientDiagnosticReportsOverview',
  title: TITLE,
  footerTitle: FOOTER_TITLE,
  tabToNavigateTo: 'diagnostic-reports',
});
