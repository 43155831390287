import { useRef } from 'react';
import { patientEncounterColumns } from './helpers/columns';
import { encounterFilterPredicates, encounterFilters } from './helpers/filters';
import { usePatientEncounterDetailsDrawer } from './helpers/modal-hooks';
import { defaultEncounterSort, encounterSortOptions } from './helpers/sorts';
import { getDateRangeView } from '../resource/helpers/view-date-range';
import { PatientResourceTable } from '../resource/patient-resource-table';
import { ResourceTableActions } from '../resource/resource-table-actions';
import { EncounterModel } from '@ctw/shared/api/fhir/models/encounter';
import { EmptyPatientTable } from '@ctw/shared/components/table/empty-table';
import { withErrorBoundary } from '@ctw/shared/content/error-boundary';
import { AnalyticsProvider } from '@ctw/shared/context/analytics/analytics-provider';
import { useUserBuilderId } from '@ctw/shared/context/user-builder-id';
import { usePatientEncountersWithClinicalNotes } from '@ctw/shared/hooks/use-encounters';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { ClassName, twx } from '@ctw/shared/utils/tailwind';

export type PatientEncountersProps = {
  className?: ClassName;
};

function PatientEncountersComponent({ className }: PatientEncountersProps) {
  const userBuilderId = useUserBuilderId();
  const encountersQuery = usePatientEncountersWithClinicalNotes();
  const containerRef = useRef<HTMLDivElement>(null);
  const { viewOptions, allTime } = getDateRangeView<EncounterModel>('periodStart');
  const { data, filters, setFilters, setSort, setViewOption, defaultSort, defaultView } =
    useFilteredSortedData({
      cacheKey: 'patient-encounters',
      viewOptions,
      defaultView: allTime.display,
      filterPredicates: encounterFilterPredicates,
      defaultSort: defaultEncounterSort,
      records: encountersQuery.data,
    });

  const isEmptyQuery = encountersQuery.data.length === 0;
  const hasZeroFilteredRecords = !isEmptyQuery && data.length === 0;

  const openEncounterDetails = usePatientEncounterDetailsDrawer();

  return (
    <AnalyticsProvider componentName="PatientEncounters">
      <div className={twx(className, 'scrollable-pass-through-height')} ref={containerRef}>
        <ResourceTableActions
          viewOptions={{
            onChange: setViewOption,
            options: viewOptions,
            defaultView,
          }}
          filterOptions={{
            onChange: setFilters,
            filters: encounterFilters(encountersQuery.data),
            selected: filters,
          }}
          sortOptions={{
            defaultSort,
            options: encounterSortOptions,
            onChange: setSort,
          }}
        />
        <PatientResourceTable
          showTableHead
          isLoading={encountersQuery.isLoading}
          columns={patientEncounterColumns(userBuilderId)}
          data={data}
          emptyMessage={
            <EmptyPatientTable
              hasZeroFilteredRecords={hasZeroFilteredRecords}
              resourceName="encounters"
            />
          }
          enableDismissAndReadActions
          onRowClick={(e) => {
            openEncounterDetails(e, { target: 'encounters' });
          }}
        />
      </div>
    </AnalyticsProvider>
  );
}

export const PatientEncounters = withErrorBoundary(PatientEncountersComponent, 'PatientEncounters');
