import { isEmpty } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import {
  bulkAddConditions,
  bulkDismissConditions,
  conditionsBulkActionColumns,
} from './helpers/bulk-actions';
import { useConditionDetailsDrawer } from './helpers/details';
import { ConditionViewOptions, statusView } from './helpers/views';
import { BulkActionHeaderSelectedState } from '../hooks/use-bulk-action-modal';
import {
  RenderInfoBadge,
  RenderLeftIconOfRow,
  ResourceTitleColumn,
  WritebackState,
} from '../resource/helpers/resource-title-column';
import { ResourceTableGrouped } from '../resource/resource-table-grouped';
import { usePatientConditionsAll } from '@ctw/shared/api/fhir/conditions';
import { ConditionModel } from '@ctw/shared/api/fhir/models';
import { EmptyPatientTable } from '@ctw/shared/components/table/empty-table';
import { withErrorBoundary } from '@ctw/shared/content/error-boundary';
import { WritebackAction, WritebackStatuses } from '@ctw/shared/components/table/table-rows';
import {
  conditionsColumnGroupedClassNames,
  patientConditionsColumnsGrouped,
} from '@ctw/shared/content/conditions/helpers/columns';
import {
  conditionFilterPredicates,
  conditionFilters,
} from '@ctw/shared/content/conditions/helpers/filters';
import { useConditionRowActions } from '@ctw/shared/content/conditions/helpers/row-actions';
import {
  groupedConditionSortOptions,
  lastUpdatedMostRecentConditionSort,
} from '@ctw/shared/content/conditions/helpers/sorts';
import { ResourceTableActions } from '@ctw/shared/content/resource/resource-table-actions';
import { AnalyticsProvider } from '@ctw/shared/context/analytics/analytics-provider';
import { BulkActionContext } from '@ctw/shared/context/bulk-action-context';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { ClassName, tw, twx } from '@ctw/shared/utils/tailwind';

export type PatientConditionsAllGroupedProps = {
  readOnly?: boolean;
  className?: ClassName;
};

const writebackStateOnLoad = (condition: ConditionModel): WritebackState => {
  let status: WritebackState = 'not-known-to-builder';
  if (condition.isDismissed) {
    status = 'dismissed';
  } else if (condition.syncedWithRecord) {
    status = 'known-to-builder';
  }
  return status;
};

const PatientConditionsAllGroupedComponent = ({
  className,
  readOnly = false,
}: PatientConditionsAllGroupedProps) => {
  const query = usePatientConditionsAll();
  const { selectedResources, setSelectedResources } = useContext(BulkActionContext);
  const selectedConditions = selectedResources['conditions-all'];
  const [writebackStatuses, setWritebackStatuses] = useState<WritebackStatuses>(
    Object.fromEntries(
      query.data.map((condition) => [
        condition.id,
        {
          state: writebackStateOnLoad(condition),
        },
      ]),
    ),
  );

  useEffect(() => {
    setWritebackStatuses((prev) => {
      const newStatuses = { ...prev };

      query.data.forEach((condition) => {
        if (!newStatuses[condition.id]) {
          newStatuses[condition.id] = {
            state: writebackStateOnLoad(condition),
          };
        }
      });
      return newStatuses;
    });
  }, [query.data]);

  const onWritebackStart = (action: WritebackAction, id: string) => {
    if (action === 'dismiss-single' || action === 'restore') {
      return; // We don't want a loading icon for this as the row button is already loading
    }
    setWritebackStatuses((prev) => ({
      ...prev,
      [id]: { state: 'in-progress' },
    }));
  };

  const onWritebackEnd = (
    action: WritebackAction,
    id: string,
    isError: boolean,
    errorMessage?: string,
  ) => {
    setWritebackStatuses((prev) => {
      if (isError) {
        return { ...prev, [id]: { state: 'error', message: errorMessage } };
      }

      const condition = query.data.find((c) => c.id === id);
      switch (action) {
        case 'add':
          return { ...prev, [id]: { state: 'known-to-builder-new' } };
        case 'dismiss-bulk':
          return { ...prev, [id]: { state: 'bulk-dismissed-new' } };
        case 'dismiss-single':
          return { ...prev, [id]: { state: 'dismissed' } };
        case 'restore':
        default:
          return condition ? { ...prev, [id]: { state: writebackStateOnLoad(condition) } } : prev;
      }
    });
  };

  const rowActions = useConditionRowActions(readOnly, onWritebackStart, onWritebackEnd);

  const { viewOptions, current } = statusView;
  const {
    data,
    filters,
    setFilters,
    setSort,
    viewOption,
    setViewOption,
    defaultSort,
    defaultView,
  } = useFilteredSortedData({
    cacheKey: 'patient-conditions-all-grouped',
    defaultSort: lastUpdatedMostRecentConditionSort,
    viewOptions,
    filterPredicates: conditionFilterPredicates,
    records: query.data,
    defaultView: current.display,
  });

  const isEmptyQuery = query.data.length === 0;
  const hasZeroFilteredRecords = !isEmptyQuery && data.length === 0;
  const showEmptyTable = isEmptyQuery || hasZeroFilteredRecords;

  const openDetails = useConditionDetailsDrawer({
    rowActions,
    enableDismissAndReadActions: true,
  });

  const select = (record: ConditionModel) => {
    setSelectedResources({
      ...selectedResources,
      'conditions-all': [...selectedConditions, record],
    });
  };
  const deselect = (record: ConditionModel) => {
    setSelectedResources({
      ...selectedResources,
      'conditions-all': selectedConditions.filter((r) => r.id !== record.id),
    });
  };

  return (
    <AnalyticsProvider componentName="PatientConditionsAll">
      <div className={twx(className, 'scrollable-pass-through-height')}>
        {!readOnly && (
          <BulkActionHeaderSelectedState
            selectedKey="conditions-all"
            resourceName="condition"
            onWritebackStart={onWritebackStart}
            onWritebackEnd={onWritebackEnd}
            bulkAdd={bulkAddConditions}
            bulkDismiss={bulkDismissConditions}
            columns={conditionsBulkActionColumns(selectedResources, setSelectedResources)}
          />
        )}
        <ResourceTableActions
          viewOptions={{
            onChange: setViewOption,
            options: viewOptions,
            defaultView,
          }}
          sortOptions={{
            defaultSort,
            options: groupedConditionSortOptions,
            onChange: setSort,
          }}
          filterOptions={{
            onChange: setFilters,
            filters: conditionFilters(query.data, true, viewOption as ConditionViewOptions),
            selected: filters,
          }}
        />

        {showEmptyTable && (
          <div className={tw`my-16`}>
            <EmptyPatientTable
              resourceName="conditions"
              hasZeroFilteredRecords={hasZeroFilteredRecords}
            />
          </div>
        )}

        {!showEmptyTable && (
          <ResourceTableGrouped
            data={data}
            onRowClick={openDetails}
            loading={query.isLoading}
            telemetryTargetName="condition"
            emptyStateMessage="We didn't find any condition records for this patient."
            rowClassName={tw`border-border-lighter border-b last:border-none`}
            groupBy={(record: ConditionModel) => record.ccsChapter || 'Uncategorized'}
            rowActions={rowActions}
            columnHeaders={patientConditionsColumnsGrouped}
            enableDismissAndReadActions
            testId="patient-conditions-all-grouped"
            selectedResources={selectedConditions}
            setSelectedResources={
              readOnly ? undefined : (
                (resources) => setSelectedResources({ 'conditions-all': resources })
              )
            }
            writebackStatuses={writebackStatuses}
            onWritebackStart={onWritebackStart}
            onWritebackEnd={onWritebackEnd}
            renderResource={(record: ConditionModel) => {
              const writebackState = writebackStatuses[record.id] ?? {
                state: 'unknown',
              };
              return (
                <div
                  className={tw`group relative flex justify-between space-x-1.5 text-left text-base`}
                  data-testid="patient-conditions-all-row"
                >
                  <div className={twx(conditionsColumnGroupedClassNames.builderOwned)} role="cell">
                    {RenderLeftIconOfRow(
                      writebackState,
                      {
                        isSelected: selectedConditions.includes(record),
                        changeState: (isBeingSelected: boolean) => {
                          if (isBeingSelected) {
                            select(record);
                          } else {
                            deselect(record);
                          }
                        },
                      },
                      readOnly,
                    )}
                  </div>
                  <div className={twx(conditionsColumnGroupedClassNames.displayName)} role="cell">
                    <ResourceTitleColumn
                      title={record.display}
                      renderIcon={RenderInfoBadge(
                        !isEmpty(record.hccEnrichment) ? 'HCC' : undefined,
                        '',
                        !isEmpty(record.hccEnrichment) ? 'Risk-adjusting condition' : undefined,
                      )}
                    />
                  </div>
                  <div
                    className={twx(conditionsColumnGroupedClassNames.latestDiagnosis)}
                    role="cell"
                  >
                    <div>{record.recordedDate}</div>
                    <div>{record.recorder}</div>
                  </div>
                  <div className={twx(conditionsColumnGroupedClassNames.onset)} role="cell">
                    {record.onset}
                  </div>
                  <div className={twx(conditionsColumnGroupedClassNames.type)} role="cell">
                    {record.typeDisplay}
                  </div>
                </div>
              );
            }}
          />
        )}
      </div>
    </AnalyticsProvider>
  );
};

export const PatientConditionsAllGrouped = withErrorBoundary(
  PatientConditionsAllGroupedComponent,
  'PatientConditionsAllGrouped',
);
