import UTIF from '@ctw/shared/vendor/utif';

export function tiffToPNG(
  arrayBuffer: ArrayBuffer,
  page = 0,
): {
  dataUrl: string;
  description: string;
  pageCount: number;
} {
  const imageFileDirectory = UTIF.decode(arrayBuffer);
  if (imageFileDirectory.length <= page) {
    throw new Error('Tiff page number requested is out of bounds');
  }
  const imageFileDirectoryPage = imageFileDirectory[page];
  // t270 is where an IFD indexes any description of the image
  if (!('t270' in imageFileDirectoryPage && Array.isArray(imageFileDirectoryPage.t270))) {
    imageFileDirectoryPage.t270 = ['']; // Set a default description
  }
  UTIF.decodeImage(arrayBuffer, imageFileDirectoryPage);
  const rgba = UTIF.toRGBA8(imageFileDirectory[0]); // Uint8Array with RGBA pixels

  const canvas = document.createElement('canvas');
  canvas.width = imageFileDirectoryPage.width;
  canvas.height = imageFileDirectoryPage.height;
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    throw new Error('Could not get 2d context');
  }

  const img = ctx.createImageData(canvas.width, canvas.height);
  img.data.set(rgba);
  ctx.putImageData(img, 0, 0);

  return {
    dataUrl: canvas.toDataURL(),
    description: `${imageFileDirectoryPage.t270[0]}`,
    pageCount: imageFileDirectory.length,
  };
}
