/* eslint-disable no-restricted-imports */

import clsx, { ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export type ClassName = ClassValue;

const identity = (strings: TemplateStringsArray, ...values: unknown[]): string =>
  String.raw({ raw: strings }, ...values);

export const tw = (strings: TemplateStringsArray, ...values: unknown[]): string =>
  twMerge(clsx(identity(strings, ...values)));

export const twx = (...classLists: ClassValue[]): string => twMerge(clsx(...classLists));
