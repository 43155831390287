import { CareGapModel } from '@ctw/shared/api/fhir/models/care-gap';
import { useCareGapDetailsDrawer } from '@ctw/shared/content/care-gaps/use-details-panel';
import { CareGapStatusBadge } from '@ctw/shared/content/care-gaps/status-badge';
import { tw } from '@ctw/shared/utils/tailwind';

type CareGapProps = {
  gap: CareGapModel;
  testId?: string;
};

export const CareGap = ({ gap, testId }: CareGapProps) => {
  const drawer = useCareGapDetailsDrawer();

  const handleClick = (g: CareGapModel) => {
    drawer(g);
  };

  return (
    <div
      data-testid={testId}
      className={tw`flex cursor-pointer flex-col space-y-2 text-sm hover:bg-bg-lighter`}
      role="button"
      tabIndex={0}
      onClick={() => handleClick(gap)}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          handleClick(gap);
        }
      }}
    >
      <div className={tw`flex items-center gap-1 font-normal`}>
        <CareGapStatusBadge gap={gap} />
      </div>
      <div className={tw`font-medium`}>{gap.title}</div>
      <div className={tw`flex items-center gap-1 font-normal`}>Updated: {gap.updatedDate}</div>
    </div>
  );
};
