import { useAddMedicationForm } from './helpers/add-new-med-drawer';
import { patientMedicationsAllColumns } from './helpers/columns';
import { useMedicationDetailsDrawer } from './helpers/details';
import { medicationFilters } from './helpers/filters';
import { defaultMedicationSort, medicationSortOptions } from './helpers/sorts';
import { useToggleRead } from '../hooks/use-toggle-read';
import { getDateRangeView } from '../resource/helpers/view-date-range';
import { PatientResourceTable } from '../resource/patient-resource-table';
import { ResourceTableActions } from '../resource/resource-table-actions';
import { MedicationStatementModel } from '@ctw/shared/api/fhir/models';
import { EmptyPatientTable } from '@ctw/shared/components/table/empty-table';
import { withErrorBoundary } from '@ctw/shared/content/error-boundary';
import { RowActionsConfigProp } from '@ctw/shared/components/table/table-rows';
import { AnalyticsProvider } from '@ctw/shared/context/analytics/analytics-provider';
import { useUserBuilderId } from '@ctw/shared/context/user-builder-id';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { useQueryAllPatientMedications } from '@ctw/shared/hooks/use-medications';
import { useBaseTranslations } from '@ctw/shared/utils/i18n';
import { twx } from '@ctw/shared/utils/tailwind';

export type PatientMedicationsAllProps = {
  className?: string;
  readOnly?: boolean;
  onAddToRecord?: (record: MedicationStatementModel) => Promise<void> | void;
};

function PatientMedicationsAllComponent({
  className,
  readOnly,
  onAddToRecord,
}: PatientMedicationsAllProps) {
  const userBuilderId = useUserBuilderId();
  const query = useQueryAllPatientMedications();

  const { viewOptions, past6Months } =
    getDateRangeView<MedicationStatementModel>('lastActivityDate');

  const { data, filters, setFilters, setSort, setViewOption, defaultSort, defaultView } =
    useFilteredSortedData({
      cacheKey: 'patient-medications-all',
      defaultSort: defaultMedicationSort,
      viewOptions,
      defaultView: past6Months.display,
      records: query.allMedications,
    });

  const isEmptyQuery = query.allMedications.length === 0;
  const hasZeroFilteredRecords = !isEmptyQuery && data.length === 0;
  const empty = (
    <EmptyPatientTable hasZeroFilteredRecords={hasZeroFilteredRecords} resourceName="medications" />
  );

  const rowActions = useMedicationRowActions((record) => {
    if (onAddToRecord) {
      void onAddToRecord(record);
    }
  });

  const openDetails = useMedicationDetailsDrawer({
    rowActions: readOnly ? undefined : rowActions,
    enableDismissAndReadActions: true,
  });

  return (
    <AnalyticsProvider componentName="PatientMedicationsAll">
      <div className={twx(className, 'scrollable-pass-through-height')}>
        <ResourceTableActions
          filterOptions={{
            onChange: setFilters,
            filters: medicationFilters(query.allMedications, true),
            selected: filters,
          }}
          sortOptions={{
            defaultSort,
            options: medicationSortOptions,
            onChange: setSort,
          }}
          viewOptions={{
            onChange: setViewOption,
            options: viewOptions,
            defaultView,
          }}
        />
        <PatientResourceTable
          showTableHead
          isLoading={query.isLoading}
          data={data}
          columns={patientMedicationsAllColumns(userBuilderId)}
          onRowClick={openDetails}
          rowActions={readOnly ? undefined : rowActions}
          enableDismissAndReadActions
          emptyMessage={empty}
        />
      </div>
    </AnalyticsProvider>
  );
}

export const PatientMedicationsAll = withErrorBoundary(
  PatientMedicationsAllComponent,
  'PatientMedicationsAll',
);

export function useMedicationRowActions(
  onAddToRecord?: (record: MedicationStatementModel) => void,
) {
  const { t } = useBaseTranslations();
  const userBuilderId = useUserBuilderId();
  const showAddMedicationForm = useAddMedicationForm();
  const { toggleRead } = useToggleRead();

  return (record: MedicationStatementModel): RowActionsConfigProp<MedicationStatementModel> =>
    record.ownedByBuilder(userBuilderId) ?
      []
    : [
        {
          text: t('resourceTable.add'),
          className:
            'rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm btn bg-primary-main text-white hover:bg-primary-dark',
          onClick: () => {
            if (!record.isRead) {
              void toggleRead(record);
            }

            if (onAddToRecord) {
              onAddToRecord(record);
            } else {
              showAddMedicationForm(record);
            }
          },
        },
      ];
}
