import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment, ReactNode } from 'react';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { Button } from '@ctw/shared/components/button';

type BaseModalProps = {
  isOpen: boolean;
  onAfterClosed?: () => void;
  children: ReactNode;
  className?: string;
  id?: string;
  showCloseIcon?: boolean;
};

type ModalWithCloseOnEscapeOrBackdrop = BaseModalProps & {
  closeOnEscapeOrBackdrop: true;
  onClose: () => void;
};

type ModalWithoutCloseOnEscapeOrBackdrop = BaseModalProps & {
  closeOnEscapeOrBackdrop?: false;
  onClose?: () => void;
};

export type ModalProps = ModalWithCloseOnEscapeOrBackdrop | ModalWithoutCloseOnEscapeOrBackdrop;

export const Modal = ({
  isOpen,
  onAfterClosed,
  children,
  className,
  id,
  closeOnEscapeOrBackdrop = false,
  onClose,
  showCloseIcon = false,
}: ModalProps) => (
  <Transition appear show={isOpen} as={Fragment}>
    <Dialog
      as="div"
      // change to z-modal and z-drawer in main.scss (and make sure the value of z is modal > drawer)
      className={tw`relative z-[10100]`}
      onClose={() => {
        if (closeOnEscapeOrBackdrop) {
          onClose?.();
        }
      }}
    >
      <Transition.Child
        as={Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        afterLeave={onAfterClosed}
      >
        <div className={tw`fixed inset-0 transition-opacity`}>
          <div className={tw`h-full w-full bg-content-light opacity-75`} />
        </div>
      </Transition.Child>

      <div className={tw`fixed inset-0 overflow-auto`} id={id}>
        <div className={tw`min-h-full items-center p-10 max-lg:p-4 max-sm:p-0`}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel
              className={twx(
                'ml-auto mr-auto w-fit max-w-lg transform rounded-lg bg-white p-6 shadow-xl',
                className,
              )}
            >
              {showCloseIcon && (
                <Button
                  type="button"
                  ariaLabel="Close"
                  variant="unstyled"
                  className={twx(
                    'h-5 w-5',
                    'text-content-lighter hover:text-content-light',
                    'flex items-center justify-center',
                    'absolute right-3 top-3',
                  )}
                  onClick={onClose}
                >
                  <FontAwesomeIcon icon={faXmark} className={tw``} />
                </Button>
              )}
              <div className={tw`flex h-full flex-col space-y-6`}>{children}</div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
);
