import { createContext } from 'react';

type TelemetryContextValue = {
  builderId?: string;
  enableTelemetry?: boolean;
  ehr?: string;
  datadogConfig?: {
    applicationId?: string;
    clientToken?: string;
    service?: string;
    version: string;
  };
};

export const TelemetryContext = createContext<TelemetryContextValue>({});
