import { parseISO } from 'date-fns';
import { AppointmentModel } from './appointment';
import { DocumentModel } from './document';
import { EncounterModel } from './encounter';
import { FHIRModel } from './fhir-model';
import { PatientModel } from './patient';
import { bestName, formatName } from '@ctw/shared/api/fhir/formatters/human-name';

type ReferralStatus = 'ordered' | 'scheduled' | 'pending-acknowledgement' | 'acknowledged';

interface ReferredPractitioner {
  name: string | undefined;
  practiceName: string | undefined;
  phoneNumber: string | undefined;
}

export class ReferralModel extends FHIRModel<fhir4.ServiceRequest> {
  kind = 'Referral' as const;

  private patientModel: PatientModel;

  private appointmentResource: fhir4.Appointment | undefined;

  private encounterResource: fhir4.Encounter | undefined;

  private documentPool: DocumentModel[] = [];

  constructor(
    serviceRequestResource: fhir4.ServiceRequest,
    patientModel: PatientModel,
    appointmentResource?: fhir4.Appointment,
    encounterResource?: fhir4.Encounter,
    documentPool?: DocumentModel[],
  ) {
    super(serviceRequestResource);

    this.documentPool = documentPool || [];
    this.patientModel = patientModel;
    this.appointmentResource = appointmentResource;

    if (this.appointmentResource) {
      this.encounterResource = encounterResource;
    }
  }

  get patient(): PatientModel {
    return this.patientModel;
  }

  get appointment(): AppointmentModel | undefined {
    return this.appointmentResource ? new AppointmentModel(this.appointmentResource) : undefined;
  }

  get encounter(): EncounterModel | undefined {
    const encounterModel =
      this.encounterResource ? new EncounterModel(this.encounterResource, []) : undefined;

    if (encounterModel) {
      encounterModel.setClinicalNotesFromDocumentPool(this.documentPool);
    }

    return encounterModel;
  }

  get resourceTypeTitle() {
    return 'Referral';
  }

  get title() {
    return this.resource.category?.[0].text || 'Referral';
  }

  get referringPracticeName(): string | undefined {
    return this.patient.organizationDisplayName;
  }

  get referringPractitionerName(): string | undefined {
    const practitioner = (this.resource.requester as Record<string, unknown>).resource as
      | fhir4.Practitioner
      | undefined;

    if (this.resource.requester?.display) {
      return this.resource.requester.display;
    }

    if (practitioner?.name !== undefined) {
      const name = bestName(practitioner.name);

      if (name) {
        return formatName(name);
      }
    }

    return undefined;
  }

  get referringPracticePhoneNumber(): string | undefined {
    return this.patient.organization?.phone;
  }

  get referredAt() {
    const time = this.resource.occurrenceDateTime || this.resource.authoredOn;
    return time ? parseISO(time) : undefined;
  }

  get scheduledAt() {
    return this.appointmentResource?.start ? parseISO(this.appointmentResource.start) : undefined;
  }

  get referredPractitioners(): ReferredPractitioner[] {
    return (this.resource.performer || []).map((performer, index) => ({
      name: performer.display,
      practiceName: this.resource.locationReference?.[index]?.display,
      // TODO: fetch from location reference?
      phoneNumber: undefined,
    }));
  }

  get referredPractitionerName() {
    return this.referredPractitioners[0]?.name;
  }

  get referredPracticeName() {
    return this.referredPractitioners[0]?.practiceName;
  }

  get referredPracticePhoneNumber() {
    return this.referredPractitioners[0]?.phoneNumber;
  }

  get completedAt() {
    const time = this.appointmentResource?.end ?? this.appointmentResource?.start;
    return time ? parseISO(time) : undefined;
  }

  get acknowledged(): boolean {
    return false;
  }

  get status(): ReferralStatus {
    if (!this.appointment) {
      return 'ordered';
    }

    if (!this.encounter) {
      return 'scheduled';
    }

    if (this.acknowledged) {
      return 'acknowledged';
    }

    return 'pending-acknowledgement';
  }

  get reason() {
    return this.resource.reasonCode?.[0].text || 'Unknown';
  }
}
