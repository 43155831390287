import { twx } from '@ctw/shared/utils/tailwind';

type SimpleMoreListProps = {
  className?: string;
  commaDelimit?: boolean;
  items: string[];
  limit: number;
  total: number;
  prefix?: string;
};

export const SimpleMoreList = ({
  className,
  commaDelimit = false,
  items,
  limit,
  total,
  prefix,
}: SimpleMoreListProps) => {
  const displayItems = [...items];
  if (prefix) {
    displayItems[0] = `${prefix} ${displayItems[0]}`;
  }
  const displayCount = Math.min(limit, displayItems.length);
  return (
    <div className={twx(className)}>
      {displayItems.slice(0, displayCount).map((item, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key
          key={item + index}
          data-after={index + 1 < displayCount ? ', ' : ''}
          className={twx(commaDelimit && 'relative inline-block after:content-[attr(data-after)]')}
        >
          {item}
        </div>
      ))}
      {total > limit && (
        <div className={twx('font-medium', commaDelimit && 'has-more inline-block')}>
          + {total - displayCount} more
        </div>
      )}
    </div>
  );
};
