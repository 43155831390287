// Codes taken from
// https://docs.google.com/spreadsheets/d/18HfnrBAdwJhrZf1KS4Bb1LDpMlsXqJLGXPRZIB_pbnM

export const bpSystolicCodes = ['8480-6', '8479-8', '8459-0', '8460-8'];

export const bpDiastolicCodes = ['8462-4', '8453-3', '8454-1'];

export const heartRateCodes = ['8867-4', '8893-0'];

export const tempCodes = ['8310-5', '8331-1'];

export const respiratoryRateCodes = ['9279-1'];

export const o2Codes = [
  '59408-5',
  '2710-2',
  '2711-0',
  '20564-1',
  '59418-4',
  '2709-4',
  '59416-8',
  '59408-5',
  '19224-5',
];

export const heightCodes = ['8302-2', '8306-3', '8301-4', '3138-5'];

export const weightCodes = ['29463-7', '3141-9', '79348-9'];

export const bmiCodes = ['39156-5', '41909-3'];

export const headCircumferenceCodes = ['9843-4', '8287-5', '1554118'];

export const lmpCodes = ['8665-2', '3145-0'];

export const painCodes = ['95627-6', '52500-6', '72514-3'];

export const vitalLoincCodes = [
  ...bpSystolicCodes,
  ...bpDiastolicCodes,
  ...heartRateCodes,
  ...tempCodes,
  ...respiratoryRateCodes,
  ...o2Codes,
  ...heightCodes,
  ...weightCodes,
  ...bmiCodes,
  ...headCircumferenceCodes,
  ...lmpCodes,
  ...painCodes,
];
