import { useRef } from 'react';
import { patientDocumentColumns } from './helpers/columns';
import { useDocumentDetailsDrawer } from './helpers/details';
import { documentsFilter } from './helpers/filters';
import { defaultDocumentSort, documentSortOptions } from './helpers/sorts';
import { getDateRangeView } from '../resource/helpers/view-date-range';
import { PatientResourceTable } from '../resource/patient-resource-table';
import { ResourceTableActions } from '../resource/resource-table-actions';
import { getBinaryDocument } from '@ctw/shared/api/fhir/binaries';
import { usePatientRenderableDocuments } from '@ctw/shared/api/fhir/document';
import { DocumentModel } from '@ctw/shared/api/fhir/models/document';
import { EmptyPatientTable } from '@ctw/shared/components/table/empty-table';
import { withErrorBoundary } from '@ctw/shared/content/error-boundary';
import { ContentTypeIcon } from '@ctw/shared/components/icons/content-type-icon';
import { RowActionsConfigProp } from '@ctw/shared/components/table/table-rows';
import { useCCDAModal } from '@ctw/shared/content/CCDA/modal-ccda';
import { AnalyticsProvider } from '@ctw/shared/context/analytics/analytics-provider';
import { useAnalytics } from '@ctw/shared/context/analytics/use-analytics';
import { useCTW } from '@ctw/shared/context/use-ctw';
import { useUserBuilderId } from '@ctw/shared/context/user-builder-id';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { useBaseTranslations } from '@ctw/shared/utils/i18n';
import { tw, twx } from '@ctw/shared/utils/tailwind';

export type PatientDocumentsProps = {
  className?: string;
  onAddToRecord?: (document: DocumentModel, binary: fhir4.Binary) => void;
};

function PatientDocumentsComponent({ className, onAddToRecord }: PatientDocumentsProps) {
  const userBuilderId = useUserBuilderId();
  const containerRef = useRef<HTMLDivElement>(null);
  const { featureFlags } = useCTW();

  const patientDocumentQuery = usePatientRenderableDocuments();
  const rowActions = useRowActions(onAddToRecord);
  const { viewOptions, allTime } = getDateRangeView<DocumentModel>('dateCreated');

  const { data, filters, setFilters, setSort, setViewOption, defaultSort, defaultView } =
    useFilteredSortedData({
      cacheKey: 'patient-documents',
      defaultSort: defaultDocumentSort,
      viewOptions,
      defaultView: allTime.display,
      records: patientDocumentQuery.data,
    });

  const isEmptyQuery = patientDocumentQuery.data.length === 0;
  const hasZeroFilteredRecords = !isEmptyQuery && data.length === 0;
  const openCCDA = useCCDAModal();

  return (
    <AnalyticsProvider componentName="PatientDocuments">
      <div ref={containerRef} className={twx(className, 'scrollable-pass-through-height')}>
        <ResourceTableActions
          filterOptions={{
            onChange: setFilters,
            filters: documentsFilter(),
            selected: filters,
          }}
          sortOptions={{
            defaultSort,
            options: documentSortOptions,
            onChange: setSort,
          }}
          viewOptions={{
            onChange: setViewOption,
            options: viewOptions,
            defaultView,
          }}
        />
        <PatientResourceTable
          isLoading={patientDocumentQuery.isLoading}
          data={data}
          emptyMessage={
            <EmptyPatientTable
              hasZeroFilteredRecords={hasZeroFilteredRecords}
              resourceName="documents"
            />
          }
          columns={patientDocumentColumns(userBuilderId)}
          onRightClick={(document) => {
            if (!document.binaryId || !featureFlags?.openCCDAInNewTab) {
              return;
            }

            const url = `/ccda/${document.binaryId}`;
            window.open(url, '_blank');
          }}
          onRowClick={(document) => {
            if (!document.binaryId) {
              return;
            }
            openCCDA(document.binaryId, document.resourceTypeTitle);
          }}
          rowActions={rowActions}
          enableDismissAndReadActions
        />
      </div>
    </AnalyticsProvider>
  );
}

const useDocumentDrawerActions = () => {
  const { t } = useBaseTranslations();
  const openCCDA = useCCDAModal();

  return (record: DocumentModel): RowActionsConfigProp<DocumentModel> => {
    const actions: RowActionsConfigProp<DocumentModel> = [];

    if (record.binaryId) {
      actions.push({
        className:
          'rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm btn bg-primary-main text-white hover:bg-primary-dark fill-white ml-1 capitalize',
        testId: 'preview-document',
        text: t('resourceTable.preview'),
        render: () => (
          <span className={tw`space-x-1.5`}>
            <span className={tw`relative inline-block w-4 pr-4 align-middle`}>
              <ContentTypeIcon
                contentType={record.contentType ?? ''}
                className={tw`absolute -top-2.5 left-0 h-4 w-4`}
              />
            </span>
            <span>{t('resourceTable.preview')}</span>
          </span>
        ),
        onClick: async () => {
          openCCDA(record.binaryId as string, record.resourceTypeTitle);
        },
      });
    }

    return actions;
  };
};

function useRowActions(onAddToRecord?: (document: DocumentModel, binary: fhir4.Binary) => void) {
  const { t } = useBaseTranslations();
  const documentActions = useDocumentDrawerActions();
  const { trackInteraction } = useAnalytics();
  const { getRequestContext } = useCTW();
  const openDetails = useDocumentDetailsDrawer({ rowActions: documentActions });

  return (record: DocumentModel): RowActionsConfigProp<DocumentModel> => {
    const { binaryId } = record;
    const actions: RowActionsConfigProp<DocumentModel> = [];

    actions.push({
      className:
        'rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm border border-solid border-divider-main bg-white capitalize text-content-light hover:bg-bg-lighter ml-1 capitalize',
      testId: 'view-details',
      text: 'View Details',
      onClick: () => {
        openDetails(record);
      },
    });

    if (binaryId && onAddToRecord) {
      actions.push({
        className:
          'rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm btn bg-primary-main text-white hover:bg-primary-dark ml-1 capitalize',
        testId: 'add-to-record',
        text: t('resourceTable.add'),
        onClick: async () => {
          const { binary } = await getBinaryDocument(await getRequestContext(), binaryId);
          onAddToRecord(record, binary);
          trackInteraction('add_to_record');
        },
      });
    }

    return actions;
  };
}

export const PatientDocuments = withErrorBoundary(PatientDocumentsComponent, 'PatientDocuments');
