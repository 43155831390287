import { useEffect, useState } from 'react';
import {
  getBuilderMedicationsFQS,
  getSummaryMedicationsFQS,
  splitMedications,
} from '@ctw/shared/api/fhir/medications';
import { MedicationStatementModel } from '@ctw/shared/api/fhir/models/medication-statement';
import { useTimingQueryWithPatient } from '@ctw/shared/context/patient-provider';
import {
  QUERY_KEY_PATIENT_BUILDER_MEDICATIONS,
  QUERY_KEY_PATIENT_SUMMARY_MEDICATIONS,
} from '@ctw/shared/utils/query-keys';

// Gets patient medications for the builder, excluding meds where the information source is patient.
export function usePatientBuilderMedications(enabled = true) {
  return useTimingQueryWithPatient(
    QUERY_KEY_PATIENT_BUILDER_MEDICATIONS,
    [],
    getBuilderMedicationsFQS,
    enabled,
  );
}

export function usePatientSummaryMedications(enabled = true) {
  return useTimingQueryWithPatient(
    QUERY_KEY_PATIENT_SUMMARY_MEDICATIONS,
    [],
    getSummaryMedicationsFQS,
    enabled,
  );
}

/**
 * This hook provides all patient medication statements reconciled into two
 * categories ("Builder Medications" and "Other Provider Medications"). This is
 * useful when creating content such as the <PatientMedications /> component.
 */
export function useQueryAllPatientMedications(enabled = true) {
  const [expandedBuilderMedications, setExpandedBuilderMedications] = useState<
    MedicationStatementModel[]
  >([]);
  const [otherProviderMedications, setOtherProviderMedications] = useState<
    MedicationStatementModel[]
  >([]);
  const [allMedications, setAllMedications] = useState<MedicationStatementModel[]>([]);

  const summaryMedicationsQuery = usePatientSummaryMedications(enabled);
  const builderMedicationsQuery = usePatientBuilderMedications(enabled);

  useEffect(() => {
    const builderMedications = builderMedicationsQuery.data ?? [];
    let summaryMedications = (summaryMedicationsQuery.data ?? []) as MedicationStatementModel[];

    // Filter out summary medications that are only aggregattedFrom MedicationAdministration
    summaryMedications = summaryMedications.filter((medi) =>
      medi.aggregatedFrom.some((agg) => agg.type !== 'MedicationAdministration'),
    );

    // Split the summarized medications into those known/unknown to the builder
    const splitData = splitMedications(summaryMedications, builderMedications);
    setExpandedBuilderMedications(splitData.builderMedications);
    setOtherProviderMedications(splitData.otherProviderMedications);
    setAllMedications([...splitData.builderMedications, ...splitData.otherProviderMedications]);
  }, [builderMedicationsQuery.data, summaryMedicationsQuery.data]);

  const isLoading = builderMedicationsQuery.isLoading || summaryMedicationsQuery.isLoading;
  const isError = builderMedicationsQuery.isError || summaryMedicationsQuery.isError;
  const isFetching = builderMedicationsQuery.isFetching || summaryMedicationsQuery.isFetching;

  return {
    isLoading,
    isError,
    isFetching,
    builderMedications: expandedBuilderMedications,
    otherProviderMedications,
    allMedications,
  };
}
