import { ReactJason } from 'react-jason';
import github from 'react-jason/themes/github';
import { Drawer } from '../components/drawer';
import { useDrawer } from '../context/drawer-hooks';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { Button } from '@ctw/shared/components/button';
import './view-fhir.scss';
import { faFire } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type ViewFHIRProps = {
  name?: string;
  resource: fhir4.Resource;
};

export function useFHIRDrawer() {
  const { openDrawer } = useDrawer();

  return (name: string, resource: fhir4.Resource) => {
    openDrawer({
      component: (props) => <FHIRDrawer name={name} resource={resource} {...props} />,
      trackingMetadata: { action: 'view_fhir' },
    });
  };
}

export function ViewFHIR({ name = 'View FHIR', resource }: ViewFHIRProps) {
  const openDrawer = useFHIRDrawer();

  return (
    <Button
      type="button"
      ariaLabel={name}
      variant="default"
      onClick={() => {
        openDrawer(name, resource);
      }}
    >
      <span className={tw`relative inline-block w-4 pr-4 align-middle`}>
        <FontAwesomeIcon icon={faFire} className={tw`absolute -top-2.5 left-0 h-4`} />
      </span>
    </Button>
  );
}

type FHIRDrawerProps = {
  name: string;
  className?: string;
  resource: fhir4.Resource;
  isOpen: boolean;
  onClose: () => void;
};

function FHIRDrawer({ resource, isOpen, onClose, className, name }: FHIRDrawerProps) {
  return (
    <Drawer
      className={twx(className, 'view-fhir')}
      isOpen={isOpen}
      onClose={onClose}
      renderHeader={() => name}
    >
      <div className={tw`react-json-pre-wrapper text-sm`}>
        <ReactJason value={resource} theme={github} />
      </div>
    </Drawer>
  );
}
