import { mapValues, uniqWith } from 'lodash';
import { ObservationModel } from '../models';
import { LOINC_ANALYTES } from '../models/observation';
import { SYSTEM_LOINC } from '../system-urls';

export type TrendDataMap = Record<string, ObservationModel[] | undefined>;
/**
 * Create a map from analyte to trends. This allows us to go from
 * diagnosticReport.result observations to their trends data by
 * looking up the observation's analytes.
 */
export const createTrendDataMap = (observations: ObservationModel[]): TrendDataMap => {
  const trendData = observations
    .filter((o) => {
      if (!o.diagnosticReport) {
        return true;
      }

      return !o.isIncorrectlyCodedGlucose;
    })
    // Sort here because the observations should be sorted in the drawer
    .sort((a, b) => {
      if (!a.effectiveStartRaw) {
        return !b.effectiveStartRaw ? 0 : 1;
      }
      if (!b.effectiveStartRaw || a.effectiveStartRaw > b.effectiveStartRaw) {
        return -1;
      }
      if (a.effectiveStartRaw < b.effectiveStartRaw) {
        return 1;
      }
      return 0;
    });

  // Compute a map from analyte to trends
  const trendDataMap: TrendDataMap = {};
  trendData.forEach((o) => {
    o.resource.code.coding?.forEach((coding) => {
      if (coding.code && coding.system === SYSTEM_LOINC) {
        const analyte = LOINC_ANALYTES[coding.code];
        // Check if the analyte is in our list of analytes
        if (analyte) {
          if (!trendDataMap[analyte]) {
            trendDataMap[analyte] = [];
          }
          trendDataMap[analyte].push(o);
        }
      }
    });
  });

  return mapValues(trendDataMap, (v) => uniqWith(v, (a, b) => a.id === b.id));
};
