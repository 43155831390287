import { Coding } from 'fhir/r4';
import { capitalize, compact, find, intersectionWith, uniqWith } from 'lodash';
import { FHIRModel } from './fhir-model';
import { PatientModel } from './patient';
import { PractitionerModel } from './practitioner';
import { formatDateISOToLocal, formatStringToDate } from '../formatters';
import { CCSChapterName } from '../mappings/ccs-chapter-names';
import { findReference } from '../resource-helper';
import {
  SYSTEM_CCI,
  SYSTEM_CCS,
  SYSTEM_CMS_HCC,
  SYSTEM_CONDITION_CLINICAL,
  SYSTEM_CONDITION_VERIFICATION_STATUS,
  SYSTEM_ICD10,
  SYSTEM_ICD10_CM,
  SYSTEM_ICD9,
  SYSTEM_ICD9_CM,
  SYSTEM_SNOMED,
} from '../system-urls';
import {
  codeableConceptLabel,
  codeableConceptTextOrDisplay,
  CodePreference,
  findAllCodings,
  findAllCodingsWithEnrichment,
  findCoding,
  findCodingByOrderOfPreference,
  findCodingWithEnrichment,
} from '@ctw/shared/api/fhir/codeable-concept';
import {
  getAddConditionWithDefaults,
  getClincalAndVerificationStatus,
} from '@ctw/shared/content/forms/actions/conditions';

type VerificationStatus =
  | 'unconfirmed'
  | 'provisional'
  | 'differential'
  | 'confirmed'
  | 'refuted'
  | 'entered-in-error';

type ClinicalStatus = 'active' | 'recurrence' | 'relapse' | 'inactive' | 'remission' | 'resolved';

const CONDITION_CODE_PREFERENCE_ORDER: CodePreference[] = [
  { system: SYSTEM_SNOMED, checkForEnrichment: true },
  { system: SYSTEM_ICD10, checkForEnrichment: true },
  { system: SYSTEM_SNOMED },
  { system: SYSTEM_ICD10 },
  { system: SYSTEM_ICD10_CM },
  { system: SYSTEM_ICD9 },
  { system: SYSTEM_ICD9_CM },
];

export function getNewCondition(patientId: string) {
  const newCondition: fhir4.Condition = {
    resourceType: 'Condition',
    subject: {
      type: 'Patient',
      reference: `Patient/${patientId}`,
    },
    ...getClincalAndVerificationStatus('Active'),
  };
  return getAddConditionWithDefaults(newCondition);
}

export class ConditionModel extends FHIRModel<fhir4.Condition> {
  kind = 'Condition' as const;

  // Used by summary conditions to indicate that there's a matching
  // builder condition for this summary condition.
  public syncedWithRecord = false;

  get abatement(): string | undefined {
    if (this.resource.abatementAge) {
      return this.resource.abatementAge.value?.toString();
    }

    if (this.resource.abatementDateTime) {
      return formatDateISOToLocal(this.resource.abatementDateTime);
    }

    if (this.resource.abatementPeriod) {
      return formatDateISOToLocal(this.resource.abatementPeriod.start);
    }

    if (this.resource.abatementRange) {
      return formatDateISOToLocal(this.resource.abatementRange.low?.value?.toString());
    }

    return this.resource.abatementString;
  }

  get active(): boolean {
    const coding = find(this.resource.clinicalStatus?.coding, {
      system: SYSTEM_CONDITION_CLINICAL,
    });

    return coding?.code ? ['active', 'recurrence', 'relapse'].includes(coding.code) : false;
  }

  get asserter(): string | undefined {
    return this.resource.asserter?.display;
  }

  get bodySites(): string[] {
    return this.resource.bodySite?.map((bodySite) => codeableConceptLabel(bodySite)) || [];
  }

  // Returns the first category text or display.
  get category(): string {
    return (
      compact(
        this.resource.category?.map((category) => codeableConceptTextOrDisplay(category)),
      )[0] ?? ''
    );
  }

  get ccsChapter(): string | undefined {
    const code = this.ccsChapterCode;
    if (!code) return 'Uncategorized';
    return CCSChapterName[code]?.shortName;
  }

  get ccsChapterCode(): string | undefined {
    return findCoding(SYSTEM_CCS, this.resource.code)?.code?.slice(0, 3);
  }

  get ccsGrouping(): string | undefined {
    return findCoding(SYSTEM_CCS, this.resource.code)?.display;
  }

  get clinicalStatus(): string {
    return codeableConceptLabel(this.resource.clinicalStatus);
  }

  get clinicalStatusCode(): ClinicalStatus | undefined {
    return find(this.resource.clinicalStatus?.coding, {
      system: SYSTEM_CONDITION_CLINICAL,
    })?.code as ClinicalStatus | undefined;
  }

  get codings(): fhir4.CodeableConcept | undefined {
    return this.resource.code;
  }

  get chronic(): Coding | undefined {
    if (this.isSummaryResource) {
      return findCoding(SYSTEM_CCI, this.resource.code);
    }
    return findCodingWithEnrichment(SYSTEM_CCI, this.resource.code);
  }

  get display(): string | undefined {
    return capitalize(
      findCodingByOrderOfPreference(CONDITION_CODE_PREFERENCE_ORDER, this.resource.code)?.display ??
        codeableConceptLabel(this.resource.code),
    );
  }

  get title() {
    return this.display ?? 'Condition';
  }

  get type() {
    const type = findCoding(SYSTEM_CCI, this.resource.code)?.display || 'Unknown';
    return type.toLocaleLowerCase() === 'both' ? 'Chronic and acute' : type;
  }

  // Standardize the type display as to not end up with so many useless type categories to filter on
  get typeDisplay() {
    const type = findCoding(SYSTEM_CCI, this.resource.code);
    const display = this.type;

    if (
      ['UNK', 'U', 'NULL', 'N'].includes(type?.code ?? '') ||
      /^(not applicable|unknown)/i.test(display)
    ) {
      return 'Unknown, not applicable';
    }

    return display;
  }

  get encounter(): string | undefined {
    return this.resource.encounter?.display;
  }

  get hccEnrichment(): Coding | undefined {
    if (this.isSummaryResource) {
      return findCoding(SYSTEM_CMS_HCC, this.resource.code);
    }
    return findCodingWithEnrichment(SYSTEM_CMS_HCC, this.resource.code);
  }

  get hccEnrichments(): Coding[] {
    if (this.isSummaryResource) {
      return findAllCodings(SYSTEM_CMS_HCC, this.resource.code);
    }
    return findAllCodingsWithEnrichment(SYSTEM_CMS_HCC, this.resource.code);
  }

  get icd10CMEnrichments(): Coding[] {
    if (this.isSummaryResource) {
      return findAllCodings(SYSTEM_ICD10_CM, this.resource.code);
    }
    return findAllCodingsWithEnrichment(SYSTEM_ICD10_CM, this.resource.code);
  }

  get knownCodings(): fhir4.Coding[] {
    return knownCodings(this.resource.code);
  }

  // Returns true if any of the known codings match between
  // this condition and the passed in condition or object with select fields from condition.
  knownCodingsMatch(code?: fhir4.CodeableConcept): boolean {
    return (
      intersectionWith(
        this.knownCodings,
        knownCodings(code),
        (a, b) => a.code === b.code && a.system === b.system,
      ).length > 0
    );
  }

  get notes(): string[] {
    return this.resource.note?.map((note) => note.text) || [];
  }

  get onset(): string | undefined {
    if (this.resource.onsetAge) {
      return this.resource.onsetAge.value?.toString();
    }

    if (this.resource.onsetDateTime) {
      return formatDateISOToLocal(this.resource.onsetDateTime);
    }

    if (this.resource.onsetPeriod) {
      return formatDateISOToLocal(this.resource.onsetPeriod.start);
    }

    if (this.resource.onsetRange) {
      return formatDateISOToLocal(this.resource.onsetRange.low?.value?.toString());
    }

    return formatStringToDate(this.resource.onsetString);
  }

  get patientOrganizationName(): string | undefined {
    const reference = findReference(
      'Patient',
      this.resource.contained,
      this.includedResources,
      this.resource.subject,
    );

    if (reference) {
      return new PatientModel(reference, this.includedResources).organizationDisplayName;
    }

    return undefined;
  }

  get preferredCoding(): fhir4.Coding | undefined {
    return findCodingByOrderOfPreference(CONDITION_CODE_PREFERENCE_ORDER, this.resource.code);
  }

  get recordedDate(): string | undefined {
    return formatDateISOToLocal(this.resource.recordedDate);
  }

  get recorded(): string | undefined {
    return formatDateISOToLocal(this.resource.recordedDate);
  }

  get recorder(): string | undefined {
    const reference = this.resource.recorder;

    const practitioner = findReference(
      'Practitioner',
      this.resource.contained,
      this.includedResources,
      reference,
    );

    if (practitioner) {
      return new PractitionerModel(practitioner).fullName;
    }

    return this.resource.recorder?.display;
  }

  get severity(): string {
    return codeableConceptLabel(this.resource.severity);
  }

  get stages(): string[] {
    return (
      this.resource.stage?.map((stage) => {
        const summary = codeableConceptLabel(stage.summary);
        const type = codeableConceptLabel(stage.type);
        return `Summary: ${summary}, Type: ${type}`;
      }) || []
    );
  }

  get displayStatus(): ConditionStatuses {
    function clinicalStatusMap(code: ClinicalStatus | undefined) {
      switch (code) {
        case 'active':
        case 'recurrence':
        case 'relapse':
          return 'Active';
        case 'inactive':
        case 'remission':
        case 'resolved':
          return 'Inactive';
        default:
          return '';
      }
    }

    function verificationStatusMap(code: VerificationStatus | undefined) {
      switch (code) {
        case 'confirmed':
          return 'confirmed';
        case 'unconfirmed':
        case 'provisional':
        case 'differential':
          return 'unconfirmed';
        case 'refuted':
          return 'refuted';
        case 'entered-in-error':
          return 'entered-in-error';
        default:
          return '';
      }
    }

    // What to show if lens or summary resource.
    if (this.isSummaryResource) {
      return clinicalStatusMap(this.clinicalStatusCode) || 'Unknown';
    }

    const concatenation =
      verificationStatusMap(this.verificationStatusCode) +
      clinicalStatusMap(this.clinicalStatusCode).toLowerCase();

    // What to show if patient record resource.
    switch (concatenation) {
      case 'unconfirmedactive':
        return 'Pending';
      case 'unconfirmedinactive':
      case 'refutedactive':
        return 'Unknown';
      case 'confirmedinactive':
        return 'Inactive';
      case 'confirmedactive':
        return 'Active';
      case 'refutedinactive':
        return 'Refuted';
      case 'entered-in-error':
        return 'Entered in Error';
      default:
        return 'Unknown';
    }
  }

  get subjectID(): string {
    const [, subjectID] = this.resource.subject.reference?.split('/') || [];
    return subjectID || '';
  }

  get verificationStatus(): string {
    return codeableConceptLabel(this.resource.verificationStatus);
  }

  get verificationStatusCode(): VerificationStatus | undefined {
    return find(this.resource.verificationStatus?.coding, {
      system: SYSTEM_CONDITION_VERIFICATION_STATUS,
    })?.code as VerificationStatus | undefined;
  }
}

export const conditionStatuses = [
  'Active',
  'Inactive',
  'Entered in Error',
  'Pending',
  'Refuted',
  'Unknown',
] as const;

export const outsideConditionStatuses = ['Active', 'Inactive', 'Unknown'] as const;

export function knownCodings(code?: fhir4.CodeableConcept) {
  const codings = compact(
    CONDITION_CODE_PREFERENCE_ORDER.map((codePreference) => {
      if (codePreference.checkForEnrichment) {
        return findCodingWithEnrichment(codePreference.system, code);
      }
      return findCoding(codePreference.system, code);
    }),
  );

  // The order of the array matters here because that is how it determines which record to keep when dupes are found.
  return uniqWith(codings, (prev, next) => prev.system === next.system);
}

export type ConditionStatuses =
  | (typeof conditionStatuses)[number]
  | (typeof outsideConditionStatuses)[number];
