import { pickBy } from 'lodash';
import {
  Component,
  ComponentType,
  createElement,
  ErrorInfo,
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  PropsWithoutRef,
  ReactNode,
  RefAttributes,
} from 'react';
import { Optional } from 'utility-types';
import { ZapTabStateContext, ZapTabStateContextType } from '../context/zap-tab-state-context';
import { tw } from '@ctw/shared/utils/tailwind';
import { Telemetry } from '@ctw/shared/utils/telemetry';
import { ContentError } from '@ctw/shared/components/errors/content-error';
import { SupportFormLink } from '@ctw/shared/content/support-form-link';
import { OverviewCard } from '@ctw/shared/components/overview-card';
import { ZAPTabName } from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile';
import { faFlask } from '@fortawesome/free-solid-svg-icons';

export interface Props {
  children?: ReactNode;
  title: string;
  footerTitle: string;
  name?: string;
  trackView?: boolean;
  tabToNavigateTo?: string;
}

export interface State {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
}

export class ResourceCardErrorBoundary extends Component<Props, State> {
  static contextType = ZapTabStateContext;

  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  name?: string;

  state: State = {
    hasError: false,
  };

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { props } = this;
    void Telemetry.countMetric(`component.${props.title}.failure`);
    Telemetry.logger.error(
      error.message,
      pickBy({
        ...errorInfo,
        componentName: props.name,
        error: {
          stack: error.stack,
        },
      }),
    );
  }

  setTab(tabName: string) {
    const { name, context } = this as {
      name?: string | undefined;
      context: Optional<ZapTabStateContextType, 'setSelectedTab'> | undefined;
    };

    if (context && context.setSelectedTab && tabName) {
      context.setSelectedTab(tabName as ZAPTabName);
    } else {
      Telemetry.logger.error(
        'Unable to navigate to tab in ResourceCardErrorBoundary',
        pickBy({
          componentName: name,
        }),
      );
    }
  }

  public render() {
    const { state } = this;
    const { title, footerTitle, children } = this.props;

    const footerOnClick = () => {
      const { tabToNavigateTo } = this.props;
      this.setTab(tabToNavigateTo || '');
    };

    if (state.hasError) {
      return (
        <OverviewCard
          headerIcon={faFlask}
          title={title}
          footerCTA={{
            label: footerTitle,
            onClick: footerOnClick,
          }}
        >
          <div className={tw`py-4`}>
            <ContentError title="An unexpected error occurred" message="Try reloading the ZAP.">
              <span>If this problem persists </span>
              <SupportFormLink buttonText="contact support" className={tw`link text-base`} />.
            </ContentError>
          </div>
        </OverviewCard>
      );
    }

    return children;
  }
}

export type ErrorBoundaryResourceCardProps<CProps> = {
  title: string;
  footerTitle: string;
  name?: string;
  trackView?: boolean;
  tabToNavigateTo?: string;
  component: ComponentType<CProps>;
};

export function withErrorBoundaryResourceCard<CProps extends object>({
  component,
  name,
  trackView = true,
  title = '',
  footerTitle = '',
  tabToNavigateTo,
}: ErrorBoundaryResourceCardProps<CProps>): ForwardRefExoticComponent<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PropsWithoutRef<CProps> & RefAttributes<any>
> {
  const Wrapped = forwardRef<ComponentType<CProps>, CProps>(
    (props, ref: ForwardedRef<ComponentType<CProps>>) =>
      createElement(
        ResourceCardErrorBoundary,
        {
          name,
          trackView,
          title,
          footerTitle,
          tabToNavigateTo: tabToNavigateTo || '',
        },
        createElement(component, { ...props, ref } as CProps),
      ),
  );

  // Format for display in DevTools
  Wrapped.displayName = `withErrorBoundary(${name})`;

  return Wrapped;
}
