import { merge } from 'lodash';
import { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { ThemeContext } from './context';
import '@ctw/shared/styles/main.scss';
import { defaultBreakpoints, defaultTheme } from './default-theme';
import { IFrameTheme } from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile-iframe';
import i18next, { Locals } from '@ctw/shared/utils/i18n';
import { tw } from '@ctw/shared/utils/tailwind';

type Subset<K> = {
  [attr in keyof K]?: K[attr] extends object ? Subset<K[attr]> : K[attr];
};

export type Theme = {
  colors?: Record<string, string>;
  breakpoints?: Subset<typeof defaultBreakpoints>;
};

// Helps name a CSS variable based on the class prefix.
export function nameCSSVar(name: string): string {
  return `--${name}`;
}

// Takes a theme and turns it into a CSSProperties that sets CSS Variables.
export function mapToCSSVar(colorConfig: Record<string, string>): Record<string, string> {
  const properties: { [variable: string]: string } = {};
  Object.entries(colorConfig).forEach(([colorTitle, colorValueOrObj]) => {
    if (typeof colorValueOrObj === 'string') {
      properties[nameCSSVar(colorTitle)] = hexToRGB(colorValueOrObj);
    } else {
      Object.entries(colorValueOrObj).forEach(([colorName, value]) => {
        properties[nameCSSVar(`${colorTitle}-${colorName}`)] = hexToRGB(value as string);
      });
    }
  });
  return properties;
}

// Converts hex values to RGB values if it's not already in RGB format.
function hexToRGB(colorMapping: string, opacity?: string): string {
  if (colorMapping.startsWith('#')) {
    const r = parseInt(colorMapping.slice(1, 3), 16);
    const g = parseInt(colorMapping.slice(3, 5), 16);
    const b = parseInt(colorMapping.slice(5, 7), 16);
    if (opacity) {
      return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }
    return `rgb(${r}, ${g}, ${b})`;
  }
  return colorMapping;
}

export type ThemeProviderProps = {
  theme?: Theme & { iframe?: IFrameTheme };
  locals?: Locals;
};

export function ThemeProvider({
  children,
  locals,
  theme = {},
}: PropsWithChildren<ThemeProviderProps>) {
  const ctwThemeRef = useRef<HTMLDivElement>(null);
  const [style, setStyle] = useState<string>('');

  // Manually compute our CSS theme string AND the
  // fix for empty tailwind CSS vars.
  // We have to apply this manually instead of `style={style}`
  // as we want to use a string instead of an object where react would
  // drop the empty variables!
  useEffect(() => {
    const styles = {
      ...mapToCSSVar(theme.colors || {}),
    };

    // Convert our styles into a style string.
    setStyle(
      Object.entries(styles)
        .map(([key, value]) => `${key}:${value}`)
        .join(';'),
    );
  }, [ctwThemeRef, theme]);

  // Manually apply our CSS theme string to the theme class.
  useEffect(() => {
    ctwThemeRef.current?.setAttribute('style', style);
  }, [ctwThemeRef, style]);

  // Workaround for https://github.com/tailwindlabs/headlessui/discussions/666
  // Note: We want the portal root to:
  //    1. Be a child of body.
  //    2. Have our theme styles.
  useEffect(() => {
    // Remove any existing portal root.
    document.getElementById('headlessui-portal-root')?.remove();

    // Create a new portal root.
    const el = document.createElement('div');
    el.id = 'headlessui-portal-root';

    // It needs at least one child, so that HeadlessUI doesn't remove this portal root workaround
    // https://github.com/tailwindlabs/headlessui/blob/main/packages/@headlessui-react/src/components/portal/portal.tsx#L84
    el.innerHTML = '<div/>';

    // Apply our theme styles and add the portal root to the body.
    el.setAttribute('style', style);
    document.body.appendChild(el);
  }, [style]);

  // Overwrite our i18next resources with any provided to ThemeProvider.
  useEffect(() => {
    if (locals) {
      Object.entries(locals).forEach(([lang, namespaces]) => {
        Object.entries(namespaces).forEach(([namespace, resources]) => {
          i18next.addResourceBundle(lang, namespace, resources);
        });
      });
    }
  }, [locals]);

  const contextValue = useMemo(() => {
    const { iframe, ...tailwindTheme } = theme;
    return {
      // Set our context theme to our default theme merged
      // with any of the provided theme overwrites.
      // This way consumers of useTheme can get access to
      // the full true theme being applied.
      theme: merge({}, defaultTheme, tailwindTheme),
      iframeTheme: iframe,
      ctwThemeRef,
      locals,
    };
  }, [theme, locals, ctwThemeRef]);

  return (
    <div ref={ctwThemeRef} className={tw`theme scrollable-pass-through-height`}>
      <ThemeContext.Provider value={contextValue}>{children}</ThemeContext.Provider>
    </div>
  );
}
