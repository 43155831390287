import xpath, { SelectedValue } from 'xpath';

export const wrappedInDiv = (content: string): string =>
  `<div xmlns="http://www.w3.org/1999/xhtml">${content}</div>`;

export const parseNestedElement = (element: Document): string => {
  const childNodes = xpath.select('child::node()', element);
  const names = childNodes
    .map((itemChildNode: SelectedValue) =>
      xpath.select1('string(name())', itemChildNode as Node | undefined),
    )
    .filter(Boolean);

  const documents = childNodes as Document[];

  if (names.length !== 0) {
    return documents.map(parseElement).join('');
  }
  return '';
};

export const parseElement = (element: Document) => {
  const textElementToParseName = xpath.select1('string(name())', element) as string;

  switch (textElementToParseName) {
    case 'br':
      return '<br />';
    case 'caption':
      return parseCaptionElement(element);
    case 'content':
      return parseContentElement(element);
    case 'list':
      return parseListElement(element);
    case 'paragraph':
      return parseParagraphElement(element);
    case 'table':
      return parseTableElement(element);
    // Text nodes have no node name
    case '':
      return parseTextNode(element);
    default:
      return undefined;
  }
};

export const parseTableElement = (tableElementXml: Document | undefined): string | undefined => {
  if (!tableElementXml) return '';

  const theadElementXml = xpath.select1("*[name()='thead']", tableElementXml) as
    | Document
    | undefined;
  const tbodyElementXml = xpath.select1("*[name()='tbody']", tableElementXml) as
    | Document
    | undefined;

  const tableContent = [];

  if (theadElementXml) {
    const parsedTheadElement = parseTheadElement(theadElementXml);
    if (parsedTheadElement) tableContent.push(parsedTheadElement);
  }

  if (tbodyElementXml) {
    const parsedTbodyElement = parseTbodyElement(tbodyElementXml);
    if (parsedTbodyElement) tableContent.push(parsedTbodyElement);
  }

  if (tableContent.length > 0) return `<table>${tableContent.join('')}</table>`;

  return undefined;
};

export const parseTdElement = (tdElementXml: Document | undefined): string | undefined => {
  if (!tdElementXml) return '';

  const nestedData = parseNestedElement(tdElementXml);
  if (nestedData) return `<td>${nestedData}</td>`;

  const tdElementText = parseTextNode(tdElementXml);
  if (tdElementText) return `<td>${tdElementText}</td>`;

  return undefined;
};

export const parseTextNode = (textNodeXml: Document | undefined): string => {
  if (!textNodeXml) return '';

  return (
    (xpath.select1('string()', textNodeXml) as string)
      .trim()
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/&/g, '&amp;') || ''
  );
};

export const parseParagraphElement = (
  paragraphElementXml: Document | undefined,
): string | undefined => {
  if (!paragraphElementXml) return '';

  const nestedData = parseNestedElement(paragraphElementXml);
  if (nestedData) return `<p>${nestedData}</p>`;

  const paragraphText = parseTextNode(paragraphElementXml);

  if (paragraphText) return `<p>${paragraphText}</p>`;

  return undefined;
};

export const parseListElement = (listElelementXml: Document | undefined): string => {
  if (!listElelementXml) return '';

  const listType = xpath.select1('string(@listType)', listElelementXml) as string;

  const listItems = xpath.select("*[name()='item']", listElelementXml) as Document[];

  const parsedListItems = listItems
    .map((listItem: Document) => {
      const nestedData = parseNestedElement(listItem);
      if (nestedData) return `<li>${nestedData}</li>`;

      const textData = parseTextNode(listItem);
      if (textData) return `<li>${textData}</li>`;

      return undefined;
    })
    .join('');

  if (listType === 'ordered') {
    return `<ol>${parsedListItems}</ol>`;
  }

  return `<ul>${parsedListItems}</ul>`;
};

export const parseContentElement = (
  contentElementXml: Document | undefined,
): string | undefined => {
  if (!contentElementXml) return undefined;

  const nestedData = parseNestedElement(contentElementXml);
  if (nestedData) return nestedData;

  const contentText = parseTextNode(contentElementXml);
  if (contentText) return `<span>${contentText}</span>`;

  return undefined;
};

export const parseCaptionElement = (
  captionElementXml: Document | undefined,
): string | undefined => {
  if (!captionElementXml) return undefined;

  const nestedData = parseNestedElement(captionElementXml);
  if (nestedData) return nestedData;

  const captionText = parseTextNode(captionElementXml);
  if (captionText) return `<h2>${captionText}</h2>`;

  return undefined;
};

export const parseTheadElement = (theadElementXml: Document | undefined): string | undefined => {
  if (!theadElementXml) return '';

  const trElementXml = xpath.select1("*[name()='tr']", theadElementXml) as Document | undefined;

  if (trElementXml) {
    const parsedTrElement = parseTrElement(trElementXml);
    if (parsedTrElement) return `<thead>${parsedTrElement}</thead>`;
  }

  return undefined;
};

export const parseTbodyElement = (theadElementXml: Document | undefined): string | undefined => {
  if (!theadElementXml) return '';

  const trElementsXml = xpath.select("*[name()='tr']", theadElementXml) as Document[];
  if (trElementsXml.length > 0) {
    const parsedTrElements = trElementsXml.map((trElementXml: Document) =>
      parseTrElement(trElementXml),
    );
    if (parsedTrElements.length > 0) {
      return `<tbody>${parsedTrElements.join('')}</tbody>`;
    }
  }

  return undefined;
};

export const parseThElement = (thElementXml: Document | undefined): string | undefined => {
  if (!thElementXml) return '';

  const nestedData = parseNestedElement(thElementXml);
  if (nestedData) return `<th>${nestedData}</th>`;

  const thElementText = parseTextNode(thElementXml);
  if (thElementText) return `<th>${thElementText}</th>`;

  return undefined;
};

export const parseTrElement = (trElementXml: Document | undefined): string | undefined => {
  if (!trElementXml) return '';

  const thElements = xpath.select("*[name()='th']", trElementXml) as Document[];

  if (thElements.length > 0) {
    const parsedThElements = thElements.map((thElement) => parseThElement(thElement));
    return `<tr>${parsedThElements.join('')}</tr>`;
  }

  const tdElements = xpath.select("*[name()='td']", trElementXml) as Document[];

  if (tdElements.length > 0) {
    const parsedTdElements = tdElements.map((tdElement) => parseTdElement(tdElement));
    return `<tr>${parsedTdElements.join('')}</tr>`;
  }

  return undefined;
};
