// Client-side in order to use useSearchParams

'use client';

import { ButtonGroup } from '@ctw/shared/components/button-group';
import { Button } from '@ctw/shared/components/button';

export type DemographicsTabName = 'Contact Information' | 'Sources';

export function PatientInformationTabs({
  selectedTab,
  onTabSelected,
}: {
  selectedTab: DemographicsTabName;
  onTabSelected: (tabName: DemographicsTabName) => void;
}) {
  return (
    <ButtonGroup>
      <Button
        testId="contact-information-subtab"
        type="button"
        variant={selectedTab === 'Contact Information' ? 'primary' : 'default'}
        onClick={() => onTabSelected('Contact Information')}
      >
        Contact Information
      </Button>
      <Button
        testId="sources-subtab"
        type="button"
        variant={selectedTab === 'Sources' ? 'primary' : 'default'}
        onClick={() => onTabSelected('Sources')}
      >
        Sources
      </Button>
    </ButtonGroup>
  );
}
