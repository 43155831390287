import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { tw } from '@ctw/shared/utils/tailwind';

export type MenuItemProps = {
  children: ReactNode;
  icon?: IconDefinition;
};

export function MenuItem({ children, icon }: MenuItemProps) {
  return (
    <div className={tw`flex items-center space-x-3`}>
      {icon && (
        <FontAwesomeIcon
          icon={icon}
          // The 2px bottom margin helps vertically center icon with text.
          // This issue comes up as text and icons have different whitespace
          // and visually look different when vertically
          // centered normally (items-center).
          className={tw`mb-[2px] w-4 text-content-lighter`}
        />
      )}
      <div>{children}</div>
    </div>
  );
}
