import { isEqual, orderBy, uniqWith } from 'lodash';
import { CareTeamModel } from './models/careteam';
import { CareTeamPractitionerModel } from './models/careteam-practitioner';
import { PatientModel } from '@ctw/shared/api/fhir/models';
import { SYSTEM_SUMMARY } from '@ctw/shared/api/fhir/system-urls';
import { createGraphqlClient, fqsRequest } from '@ctw/shared/api/fqs/client';
import { CareTeamGraphqlResponse, careTeamsQuery } from '@ctw/shared/api/fqs/queries/care-teams';
import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { useTimingQueryWithPatient } from '@ctw/shared/context/patient-provider';
import { QUERY_KEY_CARETEAM } from '@ctw/shared/utils/query-keys';

export function usePatientCareTeamMembers() {
  return useTimingQueryWithPatient(QUERY_KEY_CARETEAM, [], getCareTeamMembersFromFQS);
}

export async function getCareTeamMembersByIdFromFQS(
  requestContext: CTWRequestContext,
  patient: PatientModel,
  ids: string[],
) {
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequest<CareTeamGraphqlResponse>(graphClient, careTeamsQuery, {
    upid: patient.UPID,
    cursor: '',
    first: 1000,
    sort: {
      lastUpdated: 'DESC',
    },
    filter: {
      ids: { anymatch: ids },
      tag: { nonematch: [SYSTEM_SUMMARY] },
    },
  });

  const nodes = data.CareTeamConnection.edges.map((x) => x.node);
  const results = getFilteredCareTeamMembers(nodes);
  return results;
}

async function getCareTeamMembersFromFQS(requestContext: CTWRequestContext, patient: PatientModel) {
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequest<CareTeamGraphqlResponse>(graphClient, careTeamsQuery, {
    upid: patient.UPID,
    cursor: '',
    first: 1000,
    sort: {
      lastUpdated: 'DESC',
    },
    filter: {
      tag: { nonematch: [SYSTEM_SUMMARY] },
    },
  });

  const nodes = data.CareTeamConnection.edges.map((x) => x.node);
  const results = getFilteredCareTeamMembers(nodes);
  return results;
}

export const getFilteredCareTeamMembers = (data: fhir4.CareTeam[]) => {
  const careTeams = data.map((careteam) => new CareTeamModel(careteam));

  const careTeamMembers: CareTeamPractitionerModel[] = [];
  careTeams.forEach(
    (careTeam) =>
      careTeam.resource.participant?.forEach((participant) => {
        const practitioner = careTeam.getPractitionerByID(participant.member);
        if (practitioner?.id && participant.member?.reference) {
          careTeamMembers.push(new CareTeamPractitionerModel(careTeam, practitioner));
        }
      }),
  );

  const filteredCareTeamMembers = careTeamMembers.filter((ct) => ct.practitionerName);

  const sortedCareTeamPractitionerModels = orderBy(
    filteredCareTeamMembers,
    ['effectiveStartDate', 'id'],
    ['desc', 'desc'],
  );

  return uniqWith(sortedCareTeamPractitionerModels, (a, b) =>
    isEqual(valuesToDedupeOn(a), valuesToDedupeOn(b)),
  );
};

const valuesToDedupeOn = (careTeam: CareTeamPractitionerModel) => [
  careTeam.practitionerName.toLocaleUpperCase(),
];
