import { curry } from 'lodash';
import { ConditionModel } from '@ctw/shared/api/fhir/models';
import { getNewCondition } from '@ctw/shared/api/fhir/models/condition';
import { DrawerFormWithFields } from '@ctw/shared/components/form/drawer-form-with-fields';
import { notify } from '@ctw/shared/components/toast';
import {
  createOrEditCondition,
  getAddConditionWithDefaults,
} from '@ctw/shared/content/forms/actions/conditions';
import {
  conditionAddSchema,
  getAddConditionData,
} from '@ctw/shared/content/forms/schemas/condition-schema';
import { useDrawer } from '@ctw/shared/context/drawer-hooks';
import { usePatientContext } from '@ctw/shared/context/patient-provider';
import { useBaseTranslations } from '@ctw/shared/utils/i18n';

export function useAddConditionForm(
  onWritebackStart: (id: string) => void,
  onWritebackEnd: (id: string, isError: boolean, errorMessage?: string) => void,
) {
  const { t } = useBaseTranslations();
  const { openDrawer } = useDrawer();
  const { patient } = usePatientContext();
  const patientId = patient.data?.id ?? '';

  return (referenceCondition?: ConditionModel) => {
    const condition = new ConditionModel(
      referenceCondition ?
        getAddConditionWithDefaults(referenceCondition.resource)
      : getNewCondition(patientId),
    );

    openDrawer({
      component: (props) => (
        <DrawerFormWithFields
          title={t('resource.add', { resource: t('glossary:condition_one') })}
          schema={conditionAddSchema}
          errorHeader="Unable to add condition to chart"
          closeOnSubmit
          onSubmit={() => {
            if (referenceCondition?.id) onWritebackStart(referenceCondition.id);
          }}
          onSubmitSuccess={() => {
            if (referenceCondition?.id) {
              onWritebackEnd(referenceCondition.id, false);
            }
          }}
          onSubmitError={(errorMessage) => {
            if (referenceCondition?.id) {
              onWritebackEnd(referenceCondition.id, true, errorMessage);
            }
            notify({
              type: 'error',
              title: `Error adding ${referenceCondition?.display} to chart.`,
              body: errorMessage,
            });
          }}
          action={curry(createOrEditCondition)(condition, patientId)}
          data={getAddConditionData({ condition })}
          trackingMetadata={{
            action: 'create_resource',
            resourceType: 'condition',
          }}
          {...props}
        />
      ),
    });
  };
}
