import { UseQueryResult } from '@tanstack/react-query';
import { PatientModel } from './models';
import { filterByTags } from './resource-helper';
import {
  SYSTEM_SUMMARY,
  SYSTEM_ZUS_OWNER,
  SYSTEM_ZUS_SUMMARY,
  SYSTEM_ZUS_UPI_RECORD_TYPE,
} from './system-urls';
import { Tag } from './types';
import {
  createGraphqlClient,
  FQSFilter,
  fqsRequest,
  GraphqlPageInfo,
  MAX_OBJECTS_PER_REQUEST,
} from '@ctw/shared/api/fqs/client';
import {
  PatientGraphqlResponse,
  patientsForBuilderQuery,
  patientsForUPIDQuery,
} from '@ctw/shared/api/fqs/queries/patients';
import {
  fetchResource,
  fqsRequestContext,
  searchBuilderRecords,
  searchRecords,
} from '@ctw/shared/api/fqs-rest/search-helpers';
import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { useTimingQueryWithPatient } from '@ctw/shared/context/patient-provider';
import { useTimerQueryWithCTW } from '@ctw/shared/context/use-query-with-ctw';
import {
  QUERY_KEY_BUILDER_PATIENTS_BY_UPID,
  QUERY_KEY_MATCHED_PATIENTS,
  QUERY_KEY_MATCHED_PATIENTS_INCLUDING_CURRENT_BUILDER,
  QUERY_KEY_PATIENTS_LIST_FQS,
} from '@ctw/shared/utils/query-keys';
import { sort } from '@ctw/shared/utils/sort';
import { Telemetry } from '@ctw/shared/utils/telemetry';
import { hasNumber } from '@ctw/shared/utils/types';

// As of 1/9/24, if all of the patients for the given UPID are inactive, getPatientsForUPIDFQS will throw an
// unauthorized error so we return the patient used to initiate the query.
export function useBuilderPatientsByUPID(): UseQueryResult<PatientModel[]> {
  return useTimingQueryWithPatient(
    QUERY_KEY_BUILDER_PATIENTS_BY_UPID,
    [],
    async (requestContext: CTWRequestContext, patient: PatientModel) =>
      getPatientsForUPIDFQS(requestContext, patient, {
        tag: {
          allmatch: [`${SYSTEM_ZUS_OWNER}|builder/${requestContext.builderId}`],
        },
      }).catch(() => [patient]),
  );
}

export function useMatchedPatients(
  options: { includeCurrentBuilder?: boolean } = {
    includeCurrentBuilder: false,
  },
) {
  const filterFn =
    options.includeCurrentBuilder ? undefined : (
      (requestContext: CTWRequestContext) => ({
        tag: {
          nonematch: [`${SYSTEM_ZUS_OWNER}|builder/${requestContext.builderId}`],
        },
      })
    );

  return useTimingQueryWithPatient(
    options.includeCurrentBuilder ?
      QUERY_KEY_MATCHED_PATIENTS_INCLUDING_CURRENT_BUILDER
    : QUERY_KEY_MATCHED_PATIENTS,
    [],
    async (requestContext: CTWRequestContext, patient: PatientModel) =>
      getPatientsForUPIDFQS(requestContext, patient, filterFn && filterFn(requestContext)),
  );
}

export async function getPatientsForUPIDFQS(
  requestContext: CTWRequestContext,
  patient: PatientModel,
  filter: FQSFilter = {},
) {
  const graphClient = createGraphqlClient(requestContext);
  const tagHasAllmatch = filter.tag?.allmatch ?? false;
  const { data } = await fqsRequest<PatientGraphqlResponse>(graphClient, patientsForUPIDQuery, {
    upid: patient.UPID,
    cursor: '',
    first: MAX_OBJECTS_PER_REQUEST,
    sort: {
      lastUpdated: 'DESC',
    },
    filter:
      tagHasAllmatch ? filter : (
        {
          ...filter,
          tag: {
            ...filter.tag,
            nonematch: [SYSTEM_SUMMARY, ...(filter.tag?.nonematch ?? [])],
          },
        }
      ),
  });
  let nodes = data.PatientConnection.edges.map((x) => x.node);

  // TODO: There's a bug in FQS that doesn't allow filtering with nonematch AND allmatch.
  // Once https://zeushealth.atlassian.net/browse/DRT-249 is resolved,
  // remove the below filter and use the filter in the query.
  if (tagHasAllmatch) {
    nodes = nodes.filter((node) => !node.meta?.tag?.some((t) => t.system === SYSTEM_ZUS_SUMMARY));
  }

  return nodes
    .filter(
      (node) =>
        !node.meta?.tag?.some(
          (t) => t.system === SYSTEM_ZUS_UPI_RECORD_TYPE && t.code === 'universal',
        ),
    )
    .map((node) => new PatientModel(node));
}

// Returns a single FHIR patient given a patientID and systemURL.
// If multiple patients are found then it returns the last updated.
export async function getBuilderFhirPatientByIdentifier(
  requestContext: CTWRequestContext,
  patientID: string,
  systemURL: string,
  tags?: Tag[],
): Promise<PatientModel> {
  const params = {
    identifier: `${systemURL}|${patientID}`,
    _count: '50',
  };

  const patients = await searchBuilderRecords(fqsRequestContext(requestContext), 'Patient', params);

  const filteredPatients = filterByTags(patients, tags);

  if (filteredPatients.length === 0) {
    throw new Error(
      `Failed to find patient information for patient from patientID ${patientID} with system ${systemURL}`,
    );
  }

  // sort by lastUpdated in descending order to prefer more recent patients
  const sortedPatients = sort(filteredPatients, 'meta.lastUpdated', 'desc', true);
  // prefer patients with a name so we avoid using a stub/duplicate patient
  const patient = sortedPatients.find((x) => (x.name?.length ?? 0) > 0) ?? sortedPatients[0];
  const patientModel = new PatientModel(patient);

  // Set the patient UPID globally so that any log events can be associated with the patient
  Telemetry.setPatientUPID(patientModel.UPID);

  return patientModel;
}

export async function getPatientByID(
  requestContext: CTWRequestContext,
  patientID: string,
): Promise<PatientModel> {
  const response = await fetchResource(fqsRequestContext(requestContext), 'Patient', patientID);

  const patientModel = new PatientModel(response as fhir4.Patient);
  // Set the patient UPID globally so that any log events can be associated with the patient
  Telemetry.setPatientUPID(patientModel.UPID);
  return patientModel;
}

export function usePatientsList(pageSize: number, cursor: string) {
  return useTimerQueryWithCTW(
    QUERY_KEY_PATIENTS_LIST_FQS,
    [pageSize, cursor],
    getBuilderPatientsList,
  );
}

type GetPatientsTableResults = {
  patients: PatientModel[];
  pageInfo: GraphqlPageInfo;
};

export async function getBuilderPatientListWithSearch(
  requestContext: CTWRequestContext,
  queryKeys: (string | undefined | boolean)[] = [],
  _?: boolean,
) {
  const [searchValue] = queryKeys;
  if (!searchValue) {
    return [];
  }
  const params = {
    _count: '100',
    builderID: requestContext.builderId,
    [hasNumber(searchValue as string) ? 'identifier' : 'name']: searchValue as string,
  };

  const patients = await searchRecords(fqsRequestContext(requestContext), 'Patient', params, true);

  return patients.map((patient) => new PatientModel(patient));
}

export async function getBuilderPatientsList(
  requestContext: CTWRequestContext,
  paginationOptions: (number | string | undefined)[] = [],
): Promise<GetPatientsTableResults> {
  const [pageSize, cursor] = paginationOptions;
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequest<PatientGraphqlResponse>(graphClient, patientsForBuilderQuery, {
    builderID: requestContext.builderId,
    cursor,
    first: pageSize,
    filter: {
      tag: {
        nonematch: ['https://zusapi.com/thirdparty/source', SYSTEM_SUMMARY],
      },
    },
  });
  const models = data.PatientConnection.edges.map((x) => new PatientModel(x.node));
  return {
    patients: models,
    pageInfo: data.PatientConnection.pageInfo,
  };
}
