import { differenceInDays } from 'date-fns';
import { ViewOption } from './view-button';

// Return data within "days" or that don't have a date if allowNullDates is true.
function dateFilter<T extends object>(field: keyof T, days: number, allowNullDates: boolean) {
  return (data: T[]) => data.filter((d) => datePredicate(d, field, days, allowNullDates));
}

export function datePredicate<T extends object>(
  d: T,
  field: keyof T,
  days: number,
  allowNullDates: boolean,
): boolean {
  return (
    (allowNullDates && !d[field]) ||
    differenceInDays(new Date(), new Date(String(d[field]))) <= days
  );
}

export function getDateRangeView<T extends object>(
  field: Extract<keyof T, string>,
  allowNullDates = true,
) {
  const viewOptions: ViewOption<T>[] = [
    {
      display: 'Past 7 days',
      filters: [dateFilter<T>(field, 7, allowNullDates)],
    },
    {
      display: 'Past 30 days',
      filters: [dateFilter<T>(field, 30, allowNullDates)],
    },
    {
      display: 'Past 3 months',
      filters: [dateFilter<T>(field, 90, allowNullDates)],
    },
    {
      display: 'Past 6 months',
      filters: [dateFilter<T>(field, 180, allowNullDates)],
    },
    {
      display: 'Past year',
      filters: [dateFilter<T>(field, 365, allowNullDates)],
    },
    {
      display: 'All time',
      filters: [],
    },
  ];
  return {
    viewOptions,
    past7days: viewOptions[0],
    past30days: viewOptions[1],
    past3months: viewOptions[2],
    past6Months: viewOptions[3],
    pastYear: viewOptions[4],
    allTime: viewOptions[5],
  };
}
