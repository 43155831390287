import fhir4 from 'fhir/r4';
import { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import { Badge } from '@ctw/shared/components/badge';
import { FeedbackForm } from '@ctw/shared/content/patient-record-search/helpers/feedback-form';
import { tw } from '@ctw/shared/utils/tailwind';
import { Button } from '@ctw/shared/components/button';

type SearchResultProps<T extends FHIRModel<fhir4.Resource>> = {
  resource: T;
  heading?: string;
  label: string;
  openDetails?: (record: T) => void;
  text?: string | JSX.Element;
  details?: { label: string; value?: string }[];
};

export function SearchResult<T extends FHIRModel<fhir4.Resource>>({
  resource,
  heading,
  openDetails,
  label,
  text,
  details,
}: SearchResultProps<T>) {
  return (
    <div className={tw`patient-record-search-result`}>
      <div className={tw`flex items-end justify-between`}>
        <span>
          <Button
            type="button"
            variant="unstyled"
            className={tw`patient-record-search-result-btn`}
            onClick={() => openDetails?.(resource)}
          >
            {heading}
          </Button>
          <Badge color="primary" content={label} />
        </span>
        <FeedbackForm name={`${resource.resourceType}/${resource.id}`} />
      </div>
      <div className={tw`patient-record-search-text`}>{text}</div>

      <div className={tw`patient-record-search-details`}>
        {details?.map((detail) => (
          <div key={detail.label} data-label={detail.label}>
            {detail.value}
          </div>
        ))}
      </div>
    </div>
  );
}
