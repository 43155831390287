import { Combobox } from '@headlessui/react';
import { debounce, isEmpty, isObject } from 'lodash';
import { Fragment, useCallback, useMemo, useState } from 'react';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { isMouseEvent, VoidValue } from '@ctw/shared/utils/types';
import { Button } from '@ctw/shared/components/button';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type ComboxboxFieldOption = {
  value: unknown;
  label: string;
  key?: string;
};

export type ComboboxFieldProps<T> = {
  options: ComboxboxFieldOption[];
  isLoading: boolean;
  name: string;
  defaultValue?: T;
  defaultSearchTerm?: string;
  onSearchChange: (searchTerm: string) => VoidValue;
  readonly: boolean | undefined;
  enableSearchIcon?: boolean;
  onCustomSelectChange?: (e: unknown) => VoidValue;
  renderCustomOption?: (e: unknown) => JSX.Element;
  placeholder?: string;
  minInputLength?: number;
  usesSearchButton?: boolean;
};

export const ComboboxField = <T,>({
  options,
  isLoading,
  name,
  defaultValue = {} as T,
  defaultSearchTerm = '',
  onSearchChange,
  readonly,
  onCustomSelectChange,
  renderCustomOption,
  placeholder = 'Type to search',
  minInputLength = 3,
  enableSearchIcon = false,
  usesSearchButton = false,
}: ComboboxFieldProps<T>) => {
  const [searchTerm, setSearchTerm] = useState(defaultSearchTerm || '');
  const [isSearchSubmitted, setIsSearchSubmitted] = useState(false);
  const [inputValue, setInputValue] = useState<unknown>({});
  const inputState = isEmpty(inputValue) ? defaultValue : inputValue;

  // Check if inputState is an object to determine if we should JSON.stringify.
  const inputValueParsed = isObject(inputState) ? JSON.stringify(inputState) : inputState;

  const handleSearch = useCallback(
    (newSearchTerm: string) => {
      if (newSearchTerm.length >= minInputLength) {
        void onSearchChange(newSearchTerm);
        setSearchTerm(newSearchTerm);
        setIsSearchSubmitted(true);
      }

      if (options.filter((item) => item.label === newSearchTerm).length === 0) {
        setInputValue({});
      }
    },
    [onSearchChange, minInputLength, options],
  );

  // Delay handle search input so that we don't fire a bunch of events until the user has had time to type.
  const debouncedSearchInputChange = useMemo(() => debounce(handleSearch, 300), [handleSearch]);

  const onSelectChange = (e: unknown) => {
    const option = e as ComboxboxFieldOption | undefined;

    if (option?.value) {
      setSearchTerm(option.label);
      setInputValue(option.value);
      void onCustomSelectChange?.(option.value);
    }
  };

  return (
    <Combobox onChange={onSelectChange} value={searchTerm} disabled={readonly}>
      {({ open }) => (
        <div className={tw`relative text-left`}>
          <Combobox.Button
            as="div"
            onClick={(e: unknown) => {
              // Prevent options from flashing upon re-click.
              if (open || searchTerm.length === 0) {
                if (isMouseEvent(e)) {
                  e.preventDefault();
                }
              }
            }}
          >
            <div className={tw`flex space-x-2`}>
              <div className={tw`relative grow`}>
                {enableSearchIcon && (
                  <div className={tw`search-icon-wrapper`}>
                    <FontAwesomeIcon icon={faSearch} className={tw`search-icon`} />
                  </div>
                )}
                <Combobox.Input
                  className={twx(`listbox-input w-full`, {
                    'pl-10': enableSearchIcon,
                  })}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                      if (options.length === 0 || isLoading) {
                        e.preventDefault();
                      }
                      if (usesSearchButton && !isSearchSubmitted) {
                        e.preventDefault();
                        handleSearch(searchTerm);
                      }
                    }
                  }}
                  onChange={(e) => {
                    if (usesSearchButton) {
                      setSearchTerm(e.target.value);
                      setIsSearchSubmitted(false);
                    } else {
                      // Due to debounce, we have to persist the event.
                      // https://reactjs.org/docs/legacy-event-pooling.html
                      e.persist();
                      debouncedSearchInputChange(e.target.value);
                    }
                  }}
                  placeholder={placeholder}
                />
              </div>
              {usesSearchButton && (
                <Button
                  type="button"
                  variant="primary"
                  onClick={() => {
                    handleSearch(searchTerm);
                  }}
                  disabled={searchTerm.length < minInputLength || isSearchSubmitted}
                >
                  Search
                </Button>
              )}
            </div>
          </Combobox.Button>
          <input hidden name={name} value={inputValueParsed as string} readOnly />
          <Combobox.Options
            className={tw`listbox absolute z-10 m-0 mt-1 max-h-60 w-full list-none overflow-auto rounded-md bg-white p-0 py-1 text-base shadow-lg ring-1 ring-opacity-5 focus:outline-none sm:text-sm`}
          >
            <ComboboxOptions
              options={options}
              query={searchTerm}
              isLoading={isLoading}
              renderCustomOption={renderCustomOption}
              minInputLength={minInputLength}
              usesSearchButton={usesSearchButton}
              isSearchSubmitted={isSearchSubmitted}
            />
          </Combobox.Options>
        </div>
      )}
    </Combobox>
  );
};

type RenderCorrectOptionsProps = {
  isLoading: boolean;
  options: ComboxboxFieldOption[];
  query: string;
  renderCustomOption?: (e: unknown) => JSX.Element;
  minInputLength: number;
  usesSearchButton?: boolean;
  isSearchSubmitted?: boolean;
};

const ComboboxOptions = ({
  options,
  query,
  isLoading,
  renderCustomOption,
  minInputLength,
  usesSearchButton = false,
  isSearchSubmitted = true,
}: RenderCorrectOptionsProps) => {
  if (query.length === 0) {
    return <ComboboxOption option={{ value: '', label: 'Type to search' }} />;
  }

  if (isLoading) {
    return <ComboboxOption option={{ value: '', label: 'Loading...' }} />;
  }

  if (query.length < minInputLength) {
    return (
      <ComboboxOption option={{ value: '', label: 'Please type at least three characters' }} />
    );
  }

  if (usesSearchButton && !isSearchSubmitted) {
    return (
      <ComboboxOption
        option={{
          value: '',
          label: `Type at least ${minInputLength} letters and click the Search button or Enter key`,
        }}
      />
    );
  }

  if (options.length === 0) {
    return (
      <ComboboxOption
        option={{
          value: ``,
          label: `No results found for search term '${query}'`,
        }}
      />
    );
  }

  return (
    <>
      {options.map((option, index) =>
        renderCustomOption ?
          <Fragment key={option.key ?? `option-${index}`}>{renderCustomOption(option)} </Fragment>
        : <ComboboxOption option={option} key={option.key ?? `option-${index}`} />,
      )}
    </>
  );
};

const ComboboxOption = ({ option }: { option: ComboxboxFieldOption }) => (
  <Combobox.Option
    value={option}
    // eslint-disable-next-line no-restricted-syntax
    className={({ active }) =>
      `relative cursor-default select-none py-2 pl-4 pr-4 ${
        active ? 'bg-primary-light text-primary-dark' : 'text-content-black'
      }`
    }
  >
    {option.label}
  </Combobox.Option>
);
