import { faHeartPulse } from '@fortawesome/free-solid-svg-icons';
import { usePatientVitalsOverview } from './helpers/use-patient-overview-vitals';
import { OverviewCard } from '../../components/overview-card';
import { usePatientVitals } from '@ctw/shared/api/fhir/vitals';
import { withErrorBoundaryResourceCard } from '@ctw/shared/components/overview-error-boundary';
import { useAnalytics } from '@ctw/shared/context/analytics/use-analytics';
import { tw } from '@ctw/shared/utils/tailwind';
import { RenderSyncedWithRecordIcon } from '@ctw/shared/content/resource/helpers/resource-title-column';

export type PatientVitalsOverviewProps = {
  onSeeAllResources: () => void;
};

export const PatientVitalsOverviewComponent = ({
  onSeeAllResources,
}: PatientVitalsOverviewProps) => {
  const { trackInteraction } = useAnalytics();
  const { data, isLoading } = usePatientVitals();
  const vitals = usePatientVitalsOverview();

  return (
    <OverviewCard
      headerIcon={faHeartPulse}
      loading={isLoading}
      title="Most Recent Vitals"
      helpText="Displays most recent available recorded vitals"
      emptyStateMessage="No vitals records found."
      empty={data?.length === 0}
      footerCTA={{
        label: 'All Vitals',
        onClick: () => {
          trackInteraction('see_all_of_resource', { target: 'vitals_overview' });
          onSeeAllResources();
        },
      }}
      testId="patient-vitals-overview"
      fullbleed
    >
      {vitals.data.map((vital) => (
        <div
          className={tw`flex space-x-1.5 px-2 py-1.5`}
          data-testid="overview-row"
          key={vital.vital}
        >
          <div className={tw`flex justify-between`}>
            <div className={tw`w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem]`}>
              {RenderSyncedWithRecordIcon(vital.firstParty ?? false)}
            </div>
            <div className={tw`w-14 font-medium`}>{vital.vital}</div>
          </div>
          <div className={tw`w-[7.25rem] text-right`}>{vital.value}</div>
          <div className={tw`w-[5.25rem] text-right`}>
            {vital.date ? vital.date : <span className={tw`text-content-lighter`}>N/A</span>}
          </div>
        </div>
      ))}
    </OverviewCard>
  );
};

export const PatientVitalsOverview = withErrorBoundaryResourceCard({
  component: PatientVitalsOverviewComponent,
  name: 'PatientVitalsOverview',
  title: 'Most Recent Vitals',
  footerTitle: 'All Vitals',
  tabToNavigateTo: 'vitals',
});
