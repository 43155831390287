import { orderBy } from 'lodash';
import { fqsRequestContext, searchRecords } from './search-helpers';
import { BinaryModel } from '@ctw/shared/api/fhir/models/binary';
import { useTimerQueryWithCTW } from '@ctw/shared/context/use-query-with-ctw';
import { QUERY_KEY_BINARIES_BY_ID } from '@ctw/shared/utils/query-keys';

// Returns BinaryModel[], sorted by createdAt (newest first).
export function useBinaries(binaryIds: string[]) {
  return useTimerQueryWithCTW(QUERY_KEY_BINARIES_BY_ID, [binaryIds], async (requestContext) => {
    if (!binaryIds.length) {
      return [];
    }

    const binaries = await searchRecords(
      fqsRequestContext(requestContext),
      'Binary',
      {
        _id: binaryIds.join(','),
      },
      false,
    );
    return orderBy(
      binaries.map((binary) => new BinaryModel(binary)),
      'createdAt',
      'desc',
    );
  });
}
