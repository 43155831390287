export const EmptyNotes = [
  'not available',
  'no known',
  'no information',
  'none',
  'no reason',
  'no active',
  'no clinical',
  'not on file',
  'no data',
  'no instructions',
  'no medical',
  'no assessment',
  'no physical',
  'no history',
  'no review',
  'no goals',
  'no health',
  'no discharge',
  'no transcribed',
];
