import { BubbleIcon } from './bubble';
import { ObservationTrends } from './trends';
import { ObservationModel } from '@ctw/shared/api/fhir/models';
import { TableColumn } from '@ctw/shared/components/table/table-helpers';
import { tw, twx } from '@ctw/shared/utils/tailwind';

export const ObservationsColumns = (): TableColumn<ObservationModel>[] => [
  {
    title: 'Component',
    render: (model) => <div className={tw`flex flex-wrap text-base`}>{model.display}</div>,
  },
  {
    title: 'Result',
    render: (model) => (
      <div className={tw`flex`}>
        <div className={tw`text-sm font-normal`}>
          Result:{' '}
          <BubbleIcon
            interpretation={model.interpretation}
            result={model.value}
            className={twx(model.acceptedInterpretations)}
          />
        </div>
      </div>
    ),
  },
  {
    title: 'Reference Range',
    render: (model) => (
      <div className={tw`flex text-sm font-normal`}>
        {model.referenceRange && (
          <div className={tw`font-normal`}>
            Reference Range: {model.referenceRange} {model.unit}
          </div>
        )}
      </div>
    ),
  },
  {
    title: 'Trends',
    render: (model) => <ObservationTrends model={model} />,
  },
];
