import { patientImmunizationsColumns } from './helpers/columns';
import { immunizationsFilter } from './helpers/filters';
import { defaultImmunizationSort, immunizationSortOptions } from './helpers/sort';
import { PatientResourceTable } from '../resource/patient-resource-table';
import { useResourceDetailsDrawer } from '../resource/resource-details-drawer';
import { ResourceTableActions } from '../resource/resource-table-actions';
import { usePatientImmunizations } from '@ctw/shared/api/fhir/immunizations';
import { ImmunizationModel } from '@ctw/shared/api/fhir/models/immunization';
import { entryFromArray } from '@ctw/shared/utils/entry-from-array';
import { EmptyPatientTable } from '@ctw/shared/components/table/empty-table';
import { withErrorBoundary } from '@ctw/shared/content/error-boundary';
import { AnalyticsProvider } from '@ctw/shared/context/analytics/analytics-provider';
import { useUserBuilderId } from '@ctw/shared/context/user-builder-id';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { twx } from '@ctw/shared/utils/tailwind';

export type PatientImmunizationsProps = {
  className?: string;
};

function PatientImmunizationsComponent({ className }: PatientImmunizationsProps) {
  const userBuilderId = useUserBuilderId();
  const patientImmunizationsQuery = usePatientImmunizations();
  const { data, filters, setFilters, setSort, defaultSort } = useFilteredSortedData({
    cacheKey: 'patient-immunizations',
    defaultSort: defaultImmunizationSort,
    records: patientImmunizationsQuery.data,
  });

  const isEmptyQuery = patientImmunizationsQuery.data?.length === 0;
  const hasZeroFilteredRecords = !isEmptyQuery && data.length === 0;

  const openDetails = useResourceDetailsDrawer({
    header: (m) => m.description,
    details: immunizationData,
    getSourceDocument: true,
    enableDismissAndReadActions: true,
    deepLinkResourceType: 'Immunization',
  });

  return (
    <AnalyticsProvider componentName="PatientImmunizations">
      <div className={twx(className, 'scrollable-pass-through-height')}>
        <ResourceTableActions
          filterOptions={{
            onChange: setFilters,
            filters: immunizationsFilter(),
            selected: filters,
          }}
          sortOptions={{
            defaultSort,
            options: immunizationSortOptions,
            onChange: setSort,
          }}
        />
        <PatientResourceTable
          showTableHead
          isLoading={patientImmunizationsQuery.isLoading}
          data={data}
          columns={patientImmunizationsColumns(userBuilderId)}
          onRowClick={openDetails}
          enableDismissAndReadActions
          emptyMessage={
            <EmptyPatientTable
              hasZeroFilteredRecords={hasZeroFilteredRecords}
              resourceName="immunizations"
            />
          }
        />
      </div>
    </AnalyticsProvider>
  );
}

export const PatientImmunizations = withErrorBoundary(
  PatientImmunizationsComponent,
  'PatientImmunizations',
);

const immunizationData = (immunization: ImmunizationModel) => [
  { label: 'Date Given', value: immunization.occurrence },
  { label: 'Recorded By', value: immunization.managingOrganization },
  { label: 'Status', value: immunization.status },
  { label: 'Dose Quantity', value: immunization.doseQuantity },
  { label: 'Route', value: immunization.route },
  { label: 'Site', value: immunization.site },
  { label: 'Lot Number', value: immunization.resource.lotNumber },
  ...entryFromArray('Note', immunization.notesDisplay),
];
