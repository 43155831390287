import { CitationText } from '../citations/citation-text';
import { ChartReviewResponse } from '@ctw/shared/api/ai/types';
import { NotFoundSummary } from '@ctw/shared/api/ai/use-precompute-summary';
import { formatDateToLocalString } from '@ctw/shared/api/fhir/formatters';
import { withErrorBoundary } from '@ctw/shared/content/error-boundary';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { tw } from '@ctw/shared/utils/tailwind';
import { ContentError } from '@ctw/shared/components/errors/content-error';
import { SupportFormLink } from '@ctw/shared/content/support-form-link';

interface PrecomputedSummaryProps {
  document?: ChartReviewResponse;
  onGenerateSummary: () => void;
}

export const PrecomputedSummaryComponent = ({ document }: PrecomputedSummaryProps) => {
  if (!document) {
    return <LoadingSpinner message="Loading..." />;
  }

  if (document === NotFoundSummary) {
    return (
      <ContentError title="A summary is not available for this patient.">
        <span>If this problem persists </span>
        <SupportFormLink buttonText="contact support" className={tw`link text-base`} />.
      </ContentError>
    );
  }

  return (
    <div className={tw`rounded-lg bg-bg-lighter p-4`}>
      <span className={tw`text-xs font-medium uppercase text-content-light`}>
        Summary of all available information - {formatDateToLocalString(document.createdAt)}
      </span>
      <CitationText citations={document.citations} content={document.hpi} />
      <div className={tw`pt-2 text-xs italic`}>
        This health record summary is generated using AI technology and is intended for
        informational purposes only. While efforts have been made to ensure accuracy, the
        information provided may not capture all relevant details or updates.
      </div>
    </div>
  );
};

export const PrecomputedSummary = withErrorBoundary(
  PrecomputedSummaryComponent,
  'PrecomputedSummary',
);
