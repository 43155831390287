import { twx } from '@ctw/shared/utils/tailwind';

export type BubbleIconProps = {
  interpretation?: string;
  result: string | number;
  className?: string;
};

export const BubbleIcon = ({ className, result, interpretation }: BubbleIconProps) =>
  interpretation ?
    <div className={twx(className)}>
      {result} - {interpretation}
    </div>
  : <div className={twx(className)}>{result}</div>;
