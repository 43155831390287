import { pickBy } from 'lodash';
import {
  Component,
  ComponentType,
  createElement,
  ErrorInfo,
  ForwardedRef,
  forwardRef,
  ForwardRefExoticComponent,
  PropsWithoutRef,
  ReactNode,
  RefAttributes,
} from 'react';
import { tw } from '@ctw/shared/utils/tailwind';
import { Telemetry } from '@ctw/shared/utils/telemetry';
import { ContentError } from '@ctw/shared/components/errors/content-error';
import { SupportFormLink } from '@ctw/shared/content/support-form-link';

export interface Props {
  children?: ReactNode;
  name?: string;
}

export interface State {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
}

/**
 * Error Boundary for internal use.
 */
export class ErrorBoundary extends Component<Props, State> {
  static getDerivedStateFromError(error: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  state: State = {
    hasError: false,
  };

  componentDidMount() {
    const { props } = this;
    if (props.name) {
      Telemetry.logger.info(`Loaded component ${props.name}`);
    }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { props } = this;
    Telemetry.logger.error(
      `Component failed to load: ${props.name}`,
      pickBy({
        ...errorInfo,
        componentName: props.name,
        error,
      }),
    );
  }

  public render() {
    const { state, props } = this;

    if (state.hasError) {
      return (
        <div className={tw`py-3`}>
          <ContentError title="An unexpected error occurred" message="Try reloading the ZAP.">
            <span>If this problem persists </span>
            <SupportFormLink buttonText="contact support" className={tw`link text-base`} />.
          </ContentError>
        </div>
      );
    }

    return props.children;
  }
}

export function withErrorBoundary<CProps extends object>(
  component: ComponentType<CProps>,
  name?: string,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): ForwardRefExoticComponent<PropsWithoutRef<CProps> & RefAttributes<any>> {
  const Wrapped = forwardRef<ComponentType<CProps>, CProps>(
    (props, ref: ForwardedRef<ComponentType<CProps>>) =>
      createElement(ErrorBoundary, { name }, createElement(component, { ...props, ref } as CProps)),
  );

  // Format for display in DevTools
  Wrapped.displayName = `withErrorBoundary(${name})`;

  return Wrapped;
}
