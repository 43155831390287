import { MinRecordItem, TableColumn } from './table-helpers';
import { tw } from '@ctw/shared/utils/tailwind';

export type TableHeadProps<T extends MinRecordItem> = {
  columns: TableColumn<T>[];
};

export const TableHead = <T extends MinRecordItem>({ columns }: TableHeadProps<T>) => (
  <thead>
    <tr>
      {columns.map((column, index) => (
        <th className={tw`group`} key={column.title ?? index} scope="col">
          <div className={tw`flex items-center space-x-2`}>
            <div>{column.title}</div>
          </div>
        </th>
      ))}
    </tr>
  </thead>
);
