import { useEffect, useMemo, useState } from 'react';
import { usePatientDocuments } from '../api/fhir/document';
import { getBuilderEncountersFQS, getSummaryEncountersFQS } from '../api/fhir/encounters';
import { EncounterModel } from '../api/fhir/models';
import { getLensSummariesWithSyncedWithRecord } from '../api/fhir/summary';
import { useTimingQueryWithPatient } from '../context/patient-provider';
import {
  QUERY_KEY_PATIENT_BUILDER_ENCOUNTERS,
  QUERY_KEY_PATIENT_SUMMARY_ENCOUNTERS,
} from '../utils/query-keys';
import { MAX_OBJECTS_PER_REQUEST } from '@ctw/shared/api/fqs/client';

export function usePatientEncounters(limit = MAX_OBJECTS_PER_REQUEST, enabled = true) {
  const queryForSummary = useTimingQueryWithPatient(
    QUERY_KEY_PATIENT_SUMMARY_ENCOUNTERS,
    [limit],
    getSummaryEncountersFQS(limit),
    enabled,
  );
  const queryForBuilder = useTimingQueryWithPatient(
    QUERY_KEY_PATIENT_BUILDER_ENCOUNTERS,
    [limit],
    getBuilderEncountersFQS(limit),
    enabled,
  );

  const encounters = useMemo(
    () =>
      getLensSummariesWithSyncedWithRecord(queryForSummary.data ?? [], queryForBuilder.data ?? []),
    [queryForSummary.data, queryForBuilder.data],
  );

  return {
    ...queryForSummary,
    isLoading: queryForSummary.isLoading && queryForBuilder.isLoading,
    data: encounters,
  };
}

// Gets patient encounters along with clinical notes from any documents associated with each encounter.
export function usePatientEncountersWithClinicalNotes(limit = MAX_OBJECTS_PER_REQUEST) {
  const documentsQuery = usePatientDocuments();
  const encounterQuery = usePatientEncounters(limit);
  const [encountersWithClinicalNotes, setEncountersWithClinicalNotes] = useState<EncounterModel[]>(
    [],
  );

  useEffect(() => {
    const documents = documentsQuery.data ?? [];
    const encounters = encounterQuery.data;

    if (documents.length > 0) {
      encounters.forEach((encounter) => encounter.setClinicalNotesFromDocumentPool(documents));
    }
    setEncountersWithClinicalNotes([...encounters]);
  }, [documentsQuery.data, encounterQuery.data]);

  const isLoading = documentsQuery.isLoading || encounterQuery.isLoading;
  const isError = documentsQuery.isError || encounterQuery.isError;
  const isFetching = documentsQuery.isFetching || encounterQuery.isFetching;

  return {
    isLoading,
    isError,
    isFetching,
    data: encountersWithClinicalNotes,
  };
}
