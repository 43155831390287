import { EmptyNotes } from './empty-notes';
import { DocumentModel } from '@ctw/shared/api/fhir/models/document';
import { FilterItem } from '@ctw/shared/components/filter-bar/filter-bar-types';
import { dismissFilter } from '@ctw/shared/content/resource/filters';

export const ZUS_CREATION_DATE_URL = 'https://zusapi.com/created-at';

// DA creates document references for sections of a CDA and the full CDA.
// Sections will have at most 1 category.
export function isSectionDocument(document: DocumentModel) {
  return document.category.length < 2;
}

// isEmptyClinicalNote is used to filter out empty clinical notes
// where the note text is null or empty string or
// is less than 500 chars and contains one of the substrings in EmptyNotes
export function isEmptyClinicalNote(document: DocumentModel) {
  const noteText = document.displayContent;
  if (!noteText) {
    return true;
  }

  return EmptyNotes.some(
    (emptyNoteStr) => noteText.length < 500 && noteText.toLowerCase().includes(emptyNoteStr),
  );
}

export function documentsFilter(): FilterItem[] {
  return [
    dismissFilter,
    {
      key: 'contentType',
      display: 'Type',
      type: 'checkbox',
      values: [
        { name: 'CDA', key: 'application/xml' },
        { name: 'PDF', key: 'application/pdf' },
        { name: 'PNG', key: 'image/png' },
        { name: 'JPEG', key: 'image/jpeg' },
        { name: 'TIFF', key: 'image/tiff' },
      ],
    },
  ];
}
