import { capitalize } from 'lodash/fp';
import { EncounterModel } from '@ctw/shared/api/fhir/models/encounter';
import { CodingList } from '@ctw/shared/content/coding-list';
import { useResourceDetailsDrawer } from '@ctw/shared/content/resource/resource-details-drawer';

export function usePatientEncounterDetailsDrawer() {
  return useResourceDetailsDrawer({
    header: (m) => `${m.periodStart} - ${m.periodEnd}`,
    subHeader: (m) => m.typeDisplay,
    getSourceDocument: true,
    details: encounterData,
  });
}

export const encounterData = (encounter: EncounterModel) => [
  { label: 'Period Start', value: encounter.periodStart },
  { label: 'Period End', value: encounter.periodEnd },
  { label: 'Status', value: capitalize(encounter.status) },
  { label: 'Class', value: encounter.classDisplay },
  {
    label: 'Type',
    value:
      encounter.typeCodings.length ? <CodingList codings={encounter.typeCodings} /> : undefined,
  },
  { label: 'Location', value: encounter.location },
  { label: 'Participants', value: encounter.practitionersDisplay },
  { label: 'Reason', value: encounter.reason },
  { label: 'Diagnosis', value: encounter.diagnoses.join(', ') },
  { label: 'Discharge Disposition', value: encounter.dischargeDisposition },
];
