import { faArrowTrendUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DiagnosticReportModel } from '@ctw/shared/api/fhir/models';
import { TableColumn } from '@ctw/shared/components/table/table-helpers';
import {
  RenderSyncedWithRecordIcon,
  ResourceTitleColumn,
} from '@ctw/shared/content/resource/helpers/resource-title-column';

export const patientDiagnosticReportsColumns = (
  builderId: string,
): TableColumn<DiagnosticReportModel>[] => [
  {
    title: 'Diagnostic',
    widthPercent: 40,
    minWidth: 200,
    render: (diagnostic) => (
      <ResourceTitleColumn
        title={diagnostic.displayName}
        renderIcon={RenderSyncedWithRecordIcon(diagnostic.ownedByBuilder(builderId))}
        capitalizeTitle={false}
      />
    ),
  },
  {
    title: 'Date',
    dataIndex: 'effectiveStart',
    widthPercent: 20,
    minWidth: 128,
  },
  {
    widthPercent: 1,
    minWidth: 32,
    className: 'trend-indicator-cell',
    render: (diagnostic) =>
      diagnostic.hasTrends ? <FontAwesomeIcon icon={faArrowTrendUp} /> : null,
  },
  {
    title: 'Details',
    dataIndex: 'details',
    widthPercent: 39,
    minWidth: 200,
    className: 'details-cell',
  },
];
