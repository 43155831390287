import { ZusAggregatedProfile } from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile';
import { CTWProvider } from '@ctw/shared/context/ctw-provider';
import { PatientProvider } from '@ctw/shared/context/patient-provider';
import {
  SalesforceEnvironmentProvider,
  useSalesforceEnvironment,
} from '../salesforce-context/salesforce-environment-context';
import { useZusToken, ZusTokenProvider } from '../salesforce-context/zus-token-context';
import { getPageEnv } from '../utils/get-page-env';
import { SalesforceUserProvider } from '../salesforce-context/salesforce-user-context';

function SalesforceV1Content() {
  const salesforceEnvironment = useSalesforceEnvironment();
  const zusToken = useZusToken();
  const zusEnvironment = getPageEnv();

  if (!zusToken.zusAccessToken) {
    throw new Error('Missing Zus access token');
  }

  if (!salesforceEnvironment.environment?.accountId) {
    throw new Error('Missing Salesforce context');
  }

  return (
    <CTWProvider env={zusEnvironment} authToken={zusToken.zusAccessToken} ehr="salesforce">
      <PatientProvider
        systemURL="https://salesforce.com/account-id"
        patientID={salesforceEnvironment.environment.accountId}
      >
        <ZusAggregatedProfile />
      </PatientProvider>
    </CTWProvider>
  );
}

export function SalesforceV1() {
  return (
    <SalesforceUserProvider>
      <SalesforceEnvironmentProvider>
        <ZusTokenProvider>
          <SalesforceV1Content />
        </ZusTokenProvider>
      </SalesforceEnvironmentProvider>
    </SalesforceUserProvider>
  );
}
