import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useMemo, type ReactNode } from 'react';
import { uniqueId } from 'lodash';
import { tw } from '@ctw/shared/utils/tailwind';

export interface TooltipProps {
  children: ReactNode;
  content: string;
}

export function Tooltip({ children, content }: TooltipProps) {
  const id = useMemo(() => `tooltip-${uniqueId()}`, []);

  return (
    <div>
      <button className={tw`cursor-default`} type="button" data-tooltip-id={id}>
        {children}
      </button>
      <ReactTooltip
        positionStrategy="absolute"
        place="bottom"
        id={id}
        content={content}
        className={tw`z-[10000]`}
      />
    </div>
  );
}
