import { ReactElement, ReactNode, useRef } from 'react';
import { useAdditionalResourceActions } from './use-additional-resource-actions';
import { useToggleRead } from '../hooks/use-toggle-read';
import { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import { Table, TableProps } from '@ctw/shared/components/table/table';
import { MinRecordItem } from '@ctw/shared/components/table/table-helpers';
import { useUserBuilderId } from '@ctw/shared/context/user-builder-id';
import { useBreakpoints } from '@ctw/shared/hooks/use-breakpoints';
import { twx } from '@ctw/shared/utils/tailwind';
import './resource-table.scss';

export type ResourceTableProps<T extends MinRecordItem> = {
  className?: string;
  columns: TableProps<T>['columns'];
  data: T[];
  emptyMessage?: string | ReactElement;
  isLoading?: boolean;
  onRowClick?: TableProps<T>['handleRowClick'];
  rowActions?: (r: T) => TableProps<T>['rowActions'];
  onRightClick?: TableProps<T>['onRightClick'];
  showTableHead?: boolean;
  enableDismissAndReadActions?: boolean;
  hidePagination?: boolean;
  children?: ReactNode;
};

export const ResourceTable = <T extends fhir4.Resource, M extends FHIRModel<T>>({
  className,
  columns,
  data,
  emptyMessage,
  isLoading = false,
  onRowClick,
  onRightClick,
  rowActions,
  showTableHead,
  enableDismissAndReadActions,
  hidePagination = false,
  children,
}: ResourceTableProps<M>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const breakpoints = useBreakpoints(containerRef);
  const userBuilderId = useUserBuilderId();

  const shouldShowTableHead = typeof showTableHead === 'boolean' ? showTableHead : !breakpoints.sm;

  const { toggleRead } = useToggleRead();

  // Create the RowActions react node
  const RowActionsWithAdditions = useAdditionalResourceActions({
    rowActions,
    enableDismissAndReadActions,
  });

  const onRowClickWithRead =
    onRowClick ?
      async (record: M) => {
        if (
          !record.isRead &&
          enableDismissAndReadActions &&
          !record.ownedByBuilder(userBuilderId)
        ) {
          void toggleRead(record);
        }
        onRowClick(record);
      }
    : undefined;

  return (
    <div
      ref={containerRef}
      className={twx(className, 'scrollable-pass-through-height resource-table')}
    >
      <Table
        getRowClassName={(record) =>
          twx({
            'tr-dismissed': record.isDismissed,
            'tr-unread':
              enableDismissAndReadActions &&
              !record.ownedByBuilder(userBuilderId) &&
              !record.isRead,
          })
        }
        showTableHead={shouldShowTableHead}
        stacked={breakpoints.sm}
        emptyMessage={emptyMessage}
        isLoading={isLoading}
        records={data}
        RowActions={RowActionsWithAdditions}
        columns={columns}
        handleRowClick={(resource) => {
          if (onRowClickWithRead) {
            void onRowClickWithRead(resource);
          }
        }}
        onRightClick={onRightClick}
        hidePagination={hidePagination}
      >
        {children}
      </Table>
    </div>
  );
};
