import { DocumentReference } from 'fhir/r4';
import { groupBy, isEqual, orderBy, uniqWith } from 'lodash';
import { useMemo } from 'react';
import { DocumentModel, PatientModel } from '@ctw/shared/api/fhir/models';
import { SYSTEM_SUMMARY } from '@ctw/shared/api/fhir/system-urls';
import {
  createGraphqlClient,
  fqsRequest,
  fqsRequestAll,
  MAX_OBJECTS_PER_REQUEST,
} from '@ctw/shared/api/fqs/client';
import {
  DocumentReferenceGraphqlResponse,
  documentsQuery,
} from '@ctw/shared/api/fqs/queries/documents';
import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { useTimingQueryWithPatient } from '@ctw/shared/context/patient-provider';
import { QUERY_KEY_PATIENT_DOCUMENTS } from '@ctw/shared/utils/query-keys';

// AN IMPORTANT NOTE ON HOW DA GENERATES DOCUMENTS!
// DA creates document references for sections of a CDA (eg. "Assessments") and the full CDA (eg. "Summary of Care").
// These "section documents" are not what a user would consider a "document" that would be rendered in our documents list.
// However, they contain free text content that we do want to display, often as an "encounter note".

export function usePatientRenderableDocuments(limit = MAX_OBJECTS_PER_REQUEST, enabled = true) {
  const { data, isError, isFetching, isLoading } = usePatientDocuments(limit, enabled);

  const topLevelDocuments = useMemo(
    () =>
      orderBy(filterToRenderableDocuments(data ?? []), 'resource.content[0].attachment.creation', [
        'desc',
      ]),
    [data],
  );

  return {
    data: topLevelDocuments,
    isError,
    isFetching,
    isLoading,
  };
}

export function usePatientDocuments(limit = MAX_OBJECTS_PER_REQUEST, enabled = true) {
  return useTimingQueryWithPatient(
    QUERY_KEY_PATIENT_DOCUMENTS,
    [limit],
    getDocumentsFromFQS,
    enabled,
  );
}

export async function getDocumentsFromFQS(
  requestContext: CTWRequestContext,
  patient: PatientModel,
  keys: number[] = [],
) {
  const limit = keys[0];
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequestAll<DocumentReferenceGraphqlResponse>(
    graphClient,
    documentsQuery,
    'DocumentReferenceConnection',
    {
      upid: patient.UPID,
      cursor: '',
      first: limit,
    },
  );
  return data.DocumentReferenceConnection.edges.map(
    (x) => new DocumentModel(x.node, undefined, x.node.BasicList),
  );
}

export async function getDocumentsByIdFromFQS(
  requestContext: CTWRequestContext,
  patient: PatientModel,
  ids: string[] = [],
) {
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequest<DocumentReferenceGraphqlResponse>(graphClient, documentsQuery, {
    upid: patient.UPID,
    cursor: '',
    first: 1000,
    filter: {
      ids: { anymatch: ids },
      tag: { nonematch: [SYSTEM_SUMMARY] },
    },
    sort: {
      lastUpdated: 'DESC',
    },
  });
  return data.DocumentReferenceConnection.edges.map((x) => new DocumentModel(x.node));
}

export const filterToRenderableDocuments = (data: DocumentModel[]) => {
  const highestLevelDocs = filterOutSectionDocuments(data);
  const isHighestLevelDocument = (docRef: DocumentReference) =>
    highestLevelDocs.find((docModel) => docModel.id === docRef.id);

  const documentModels = data.filter(
    (doc) =>
      doc.isImage ||
      doc.isPdf ||
      (doc.isCDA && isHighestLevelDocument(doc.resource) && !doc.isZusGeneratedReciprocityDocument),
  );

  return uniqWith(documentModels, (a, b) => isEqual(valuesToDedupeOn(a), valuesToDedupeOn(b)));
};

const filterOutSectionDocuments = (docs: DocumentModel[]) => {
  const highestLevelDocs: DocumentModel[] = [];
  const documentGroups = groupBy(docs, (d) => d.binaryId);

  // If multiple documents share the same binaryId, we want to keep the one with highest number of categories
  // as that is currently the most reliable way to determine the "top level" document.
  Object.keys(documentGroups).forEach((binaryId) => {
    if (binaryId) {
      highestLevelDocs.push(orderBy(documentGroups[binaryId], 'category.length', 'desc')[0]);
    } else {
      highestLevelDocs.push(...documentGroups[binaryId]);
    }
  });

  return highestLevelDocs;
};

const valuesToDedupeOn = (document: DocumentModel) => [
  document.encounterDate,
  document.dateCreated,
  document.custodian,
  document.title,
];
