import type { FormEntry } from '@ctw/shared/components/form/drawer-form-with-fields';
import Zod, { RefinementCtx, z } from 'zod';
import { ConditionsAutoComplete } from '../conditions-autocomplete';
import { ConditionModel } from '@ctw/shared/api/fhir/models/condition';
import i18next from '@ctw/shared/utils/i18n';

export const getAddConditionData = ({ condition }: { condition: ConditionModel }): FormEntry[] => [
  {
    label: i18next.t('glossary:condition_one'),
    field: 'condition',
    value: condition.display,
    readonly: true,
    render: (readonly: boolean | undefined, inputProps) => (
      <ConditionsAutoComplete
        readonly={readonly}
        {...inputProps}
        defaultCoding={condition.preferredCoding ?? {}}
      />
    ),
  },
  ...sharedFields(condition),
];

const sharedFields = (condition: ConditionModel) => [
  {
    label: 'subject',
    value: condition.subjectID,
    field: 'subjectID',
    readonly: true,
    hidden: true,
  },
  {
    label: 'Status',
    value: condition.displayStatus === 'Unknown' ? 'Select One' : condition.displayStatus,
    field: 'status',
  },
  {
    label: 'Type',
    value: condition.type,
    field: 'type',
  },
  {
    label: 'Onset',
    value: condition.onset,
    field: 'onset',
  },
  {
    label: 'Abatement',
    value: condition.abatement,
    field: 'abatement',
  },
  {
    label: 'Note',
    value: condition.notes,
    lines: 3,
    field: 'note',
  },
];

const conditionSchema = z.object({
  id: z.string().optional(),
  subjectID: z.string({
    required_error: 'Condition subjectID must be specified.',
  }),
  status: z.enum(['Active', 'Pending', 'Inactive', 'Refuted', 'Entered In Error']),
  type: z.enum(['Chronic', 'Acute', 'Chronic and acute', 'Unknown']),
  onset: z.date().max(new Date(), { message: 'Onset cannot be a future date.' }).optional(),
  abatement: z.date().max(new Date(), { message: 'Abatement cannot be a future date.' }).optional(),
  note: z.string().optional(),
});

const conditionRefinement = (condition: Zod.infer<typeof conditionSchema>, ctx: RefinementCtx) => {
  if (condition.abatement && condition.status !== 'Inactive') {
    ctx.addIssue({
      code: Zod.ZodIssueCode.custom,
      message: 'Status must be inactive.',
      path: ['abatement'],
    });
  }
  if (condition.abatement && condition.onset && condition.abatement < condition.onset) {
    ctx.addIssue({
      code: Zod.ZodIssueCode.custom,
      message: 'Abatement date must be after onset date.',
      path: ['abatement'],
    });
  }
};

export const conditionAddSchema = conditionSchema
  .extend({
    condition: z.object({
      code: z.string({
        required_error: 'Please choose a condition.',
      }),
      // These are technically required but we mark them
      // as optional to avoid duplicative error messages.
      // The condition autocomplete will set us up so that
      // all three of these values are set.
      display: z.string().optional(),
      system: z.string().optional(),
    }),
  })
  .superRefine((condition, refinementCtx) => conditionRefinement(condition, refinementCtx));
