import { OverviewCard } from '../../components/overview-card';
import { PatientSubscriptionPackage } from './patient-subscription-package';
import { PatientHistoryWithAction } from '../patient-history/patient-history-with-action';
import { SupportFormLink } from '../support-form-link';
import ZusSVG from '@ctw/shared/assets/zus-logo.svg';
import { withErrorBoundaryResourceCard } from '@ctw/shared/components/overview-error-boundary';
import { usePatientHistory } from '@ctw/shared/content/patient-history/use-patient-history';
import { tw } from '@ctw/shared/utils/tailwind';

export type ZusInformationCardProps = {
  hideRequestRecords?: boolean;
};

export const ZusInformationCardComponent = ({
  hideRequestRecords = false,
}: ZusInformationCardProps) => {
  const patientHistory = usePatientHistory();

  return (
    <OverviewCard
      headerIcon={<img src={ZusSVG} alt="Zus" className={tw`h-5`} />}
      title="Records Provided by Zus Health"
      loading={patientHistory.isLoading}
      testId="zus-information-card"
      empty={patientHistory.isError}
      emptyStateMessage="Unable to load patient history information"
    >
      <div className={tw`space-y-2`}>
        <div className={tw`space-y-1.5 px-3`}>
          <PatientSubscriptionPackage />
          <PatientHistoryWithAction hideRequestRecords={hideRequestRecords} />
        </div>

        <div className={tw`h-px bg-divider-light`} />

        <div className={tw`space-y-1.5 px-3 font-medium`}>
          <div className={tw`flex justify-between`}>
            <div>Have feedback or need support?</div>
            <SupportFormLink
              buttonText="Let us know"
              className={tw`link block whitespace-nowrap text-sm`}
            />
          </div>

          <div className={tw`flex justify-between`}>
            <div>Learn more about the ZAP</div>
            <a
              href="https://clinicalguide.zushealth.com/docs/getting-started"
              className={tw`link block whitespace-nowrap text-sm`}
              target="_blank"
              rel="noreferrer noopener"
            >
              Clinical Guide
            </a>
          </div>
        </div>
      </div>
    </OverviewCard>
  );
};

export const ZusInformationCard = withErrorBoundaryResourceCard({
  component: ZusInformationCardComponent,
  name: 'ZusInformationCard',
  title: 'Records Provided by Zus Health',
  footerTitle: 'Zus Health',
});
