import { IFrameTheme } from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile-iframe';
import { useEffect, useState } from 'react';

type AllowedFont = IFrameTheme['fontFamily'];

type FontMap = {
  [key in NonNullable<AllowedFont>]: {
    cssImport: string;
    cssFontFamily: string;
  };
};

const fontMap: FontMap = {
  Roboto: {
    cssImport:
      "url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;0,900;1,100;1,400;1,700;1,900&display=swap')",
    cssFontFamily: "'Roboto', sans-serif",
  },
  Avenir: {
    cssImport: "url('https://fonts.cdnfonts.com/css/avenir')",
    cssFontFamily: 'Avenir, sans-serif',
  },
};

// useFont takes an IFrame theme object and applies any rules to a style element in the body of document
export function useFont({
  fontFamily,
  headerSize,
  lineHeight,
  letterSpacing,
  fontWeight,
}: IFrameTheme = {}) {
  const [currentStyleElem, setCurrentStyleElem] = useState<HTMLStyleElement>();
  const [styleInnerHTML, setStyleInnerHTML] = useState('');

  useEffect(() => {
    let style = '';
    const rules: string[] = [];

    if (fontFamily) {
      const { cssImport, cssFontFamily } = fontMap[fontFamily];
      style += `@import ${cssImport};\n\n`;
      rules.push(`font-family: ${cssFontFamily};`);
    }

    if (headerSize || letterSpacing || fontWeight) {
      style += `\n.ctw-zus-aggregated-profile > .ctw-title-container h3 {`;
      if (headerSize) {
        style += `\nfont-size: ${headerSize};`;
      }
      if (letterSpacing) {
        style += `\nletter-spacing: ${letterSpacing};`;
      }
      if (fontWeight) {
        style += `\nfont-weight: ${fontWeight};`;
      }
      style += `\n}`;
    }

    style += `body {${rules.join(';')}}`;

    setStyleInnerHTML(style);
  }, [fontFamily, headerSize, lineHeight, letterSpacing, fontWeight]);

  useEffect(() => {
    const id = 'ctw-zap-iframe-theme';
    const styleElem: HTMLStyleElement = document.createElement('style');

    styleElem.id = id;
    styleElem.innerHTML = styleInnerHTML;

    // Remove stale element
    currentStyleElem?.parentElement?.removeChild(currentStyleElem);
    // Append new element
    document.body.prepend(styleElem);
    // Set newly created element as current
    setCurrentStyleElem(styleElem);

    return () => {
      // Remove element on cleanup
      document.body.removeChild(styleElem);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [styleInnerHTML]);
}
