import { Component, ErrorInfo, ReactNode } from 'react';
import { tw } from '@ctw/shared/utils/tailwind';

interface Props {
  title?: string;
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  errorInfo?: ErrorInfo;
  error?: unknown;
}

export class ErrorBoundary extends Component<Props, State> {
  public static getDerivedStateFromError(error: unknown): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  public state: State = {
    hasError: false,
  };

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error('Uncaught error:', error, errorInfo);
  }

  public render() {
    const { hasError, error } = this.state;
    const { children, title } = this.props;

    if (!hasError) {
      return children;
    }

    const errorMessage = error instanceof Error ? error.message : 'Unknown error';

    return (
      <div className={tw`lex-wrap ml-10 mt-20 flex flex-col space-y-12 sm:ml-20 lg:ml-80`}>
        <div className={tw`space-y-6 p-3 align-middle`}>
          <div className={tw`text-2xl font-medium`}>{title || 'Error Boundary'}</div>
          <div className={tw`text-base font-medium`}>Uncaught Error</div>
          <div className={tw`text-xs font-medium text-gray-500`}>{errorMessage}</div>
          <div>
            <a
              className={tw`btn case-normal w-fit rounded-md border border-transparent bg-primary-main px-4 py-2 text-sm font-medium normal-case text-white shadow-sm hover:bg-primary-dark`}
              href="mailto:support@zushealth.com?subject=Uncaught%20Error%20Zus%20Aggregated%20Profile"
            >
              Contact support@zushealth.com
            </a>
          </div>
        </div>
      </div>
    );
  }
}
