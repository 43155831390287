import { FHIRModel } from './fhir-model';

export class CareGapModel extends FHIRModel<fhir4.MeasureReport> {
  kind = 'CareGap' as const;

  private readonly measure: fhir4.Measure;

  constructor(careGap: fhir4.MeasureReport, measure: fhir4.Measure | undefined) {
    super(careGap);
    this.measure = measure || {
      title: 'Unknown Measure',
      resourceType: 'Measure',
      status: 'unknown',
    };
  }

  get resourceTypeTitle(): string {
    return 'Care Gap';
  }

  // This presumes that our MeasureReport resources are individual reports and  will just
  // have one group.  If that changes, this will need to be updated.
  get inDenominator(): boolean {
    const denomPop = this.resource.group?.[0].population?.find(
      (pop) => pop.code?.coding?.some((c) => c.code === 'denominator'),
    );
    return (denomPop?.count ?? 0) > 0;
  }

  // This presumes that our MeasureReport resources are individual reports and  will just
  // have one group.  If that changes, this will need to be updated.
  get inNumerator(): boolean {
    const numPop = this.resource.group?.[0].population?.find(
      (pop) => pop.code?.coding?.some((c) => c.code === 'numerator'),
    );
    return (numPop?.count ?? 0) > 0;
  }

  // The title of the measure that this gap belongs to (e.g. "Controlling Blood Pressure").  This value
  // comes from the attached Measure resource.
  get title(): string {
    return this.measure.title || 'Unnamed Measure';
  }

  get updatedDate(): string {
    if (!this.resource.date) return '';

    const updatedDate = new Date(this.resource.date);

    return updatedDate.toLocaleDateString('en-US', { timeZone: 'UTC' });
  }

  get closed(): boolean {
    return this.inDenominator && this.inNumerator;
  }
}
