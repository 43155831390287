export function saveToLocalStorage<T extends Record<string, unknown>>(
  cacheKey: string | undefined,
  data: T,
) {
  if (!cacheKey || typeof window === 'undefined') {
    return;
  }

  localStorage.setItem(cacheKey, JSON.stringify(data));
}

export function getDefaultsFromLocalStorage<T extends Record<string, unknown>>(
  cacheKey: string | undefined,
  defaults: T,
): T {
  if (!cacheKey || typeof window === 'undefined') {
    return defaults;
  }

  const cache = localStorage.getItem(cacheKey);
  if (!cache) {
    return defaults;
  }

  try {
    const cachedDefaults = JSON.parse(cache);
    return { ...defaults, ...cachedDefaults };
  } catch (e) {
    return defaults;
  }
}
