import { CodeableConcept, Condition } from 'fhir/r4';
import { gql } from 'graphql-request';
import { GraphqlConnectionNode, GraphqlPageInfo } from '../client';
import { fragmentCondition } from './fragments/condition';
import { ResourceBuilderInfo } from '@ctw/shared/api/fhir/summary';

interface ConditionConnection {
  pageInfo: GraphqlPageInfo;
  edges: GraphqlConnectionNode<ConditionWithBasics>[];
}

export type ConditionWithBasics = Condition & {
  BasicList?: fhir4.Basic[];
};

export interface ConditionGraphqlResponse {
  ConditionConnection: ConditionConnection;
}

export interface ConditionBuilderDataGraphqlResponse {
  ConditionConnection: {
    pageInfo: GraphqlPageInfo;
    edges: GraphqlConnectionNode<ConditionBuilderInfo>[];
  };
}

export interface ConditionBuilderInfo extends ResourceBuilderInfo {
  code?: CodeableConcept;
}

export const conditionsQuery = gql`
  ${fragmentCondition}
  query SummaryConditions(
    $upid: ID!
    $cursor: String!
    $filter: ConditionFilterParams! = {}
    $sort: ConditionSortParams!
    $first: Int!
  ) {
    ConditionConnection(upid: $upid, after: $cursor, filter: $filter, sort: $sort, first: $first) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          ...Condition
        }
      }
    }
  }
`;

const fragmentConditionsBuilderChecker = gql`
  fragment Condition on Condition {
    id
    resourceType
    meta {
      tag {
        system
        code
      }
    }
    code {
      text
      coding {
        system
        code
        display
      }
    }
  }
`;

export const conditionsBuilderQuery = gql`
  ${fragmentConditionsBuilderChecker}
  query FirstPartyConditions(
    $upid: ID!
    $cursor: String!
    $filter: ConditionFilterParams! = {}
    $sort: ConditionSortParams!
    $first: Int!
  ) {
    ConditionConnection(upid: $upid, after: $cursor, filter: $filter, sort: $sort, first: $first) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          ...Condition
        }
      }
    }
  }
`;
