import { ViewOption } from '../resource/helpers/view-button';
import { getDateRangeView } from '../resource/helpers/view-date-range';
import { VitalsBucket } from '@ctw/shared/api/fhir/models/vitals-bucket';
import { sort } from '@ctw/shared/utils/sort';

const { past30days, past3months, past6Months, pastYear, allTime } =
  getDateRangeView<VitalsBucket>('date');

// Sorts the data by most recent and then takes the first 5.
// Note: This sort happens before the sort options sorting. So
//       the 5 most recent can still be sorted old to new.
const mostRecent5: ViewOption<VitalsBucket> = {
  display: '5 Most Recent',
  filters: [(data) => sort(data, 'date', 'desc', true).slice(0, 5)],
};

export const vitalViewOptions = [
  mostRecent5,
  past30days,
  past3months,
  past6Months,
  pastYear,
  allTime,
];

export const defaultVitalView = mostRecent5;
