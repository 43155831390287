import { faNoteSticky } from '@fortawesome/free-regular-svg-icons';
import { faCircleH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { encounterClassPredicate } from './helpers/filters';
import { usePatientEncounterDetailsDrawer } from './helpers/modal-hooks';
import { defaultEncounterSort } from './helpers/sorts';
import { applyFilters, OverviewFilter } from '../overview/filters';
import { ResourceOverviewCard } from '../overview/resource-overview-card';
import {
  RenderSyncedWithRecordIcon,
  ResourceTitleColumn,
} from '../resource/helpers/resource-title-column';
import { datePredicate } from '../resource/helpers/view-date-range';
import { EncounterModel } from '@ctw/shared/api/fhir/models';
import { withErrorBoundaryResourceCard } from '@ctw/shared/components/overview-error-boundary';
import { useUserBuilderId } from '@ctw/shared/context/user-builder-id';
import { usePatientEncountersWithClinicalNotes } from '@ctw/shared/hooks/use-encounters';
import { applySorts } from '@ctw/shared/utils/sort';
import { tw } from '@ctw/shared/utils/tailwind';

const TITLE = 'ED & IP Visits';
const FOOTER_TITLE = 'All Encounters';

type EncountersOverviewCardProps = {
  onSeeAllResources: () => void;
};

export const EncountersOverviewCardComponent = ({
  onSeeAllResources,
}: EncountersOverviewCardProps) => {
  const userBuilderId = useUserBuilderId();
  const encountersQuery = usePatientEncountersWithClinicalNotes();
  const openEncounterDetails = usePatientEncounterDetailsDrawer();

  const sortedData = applySorts(encountersQuery.data, defaultEncounterSort.sorts);
  const filteredData = applyFilters(sortedData, getEncounterOverviewFilters());

  return (
    <ResourceOverviewCard
      headerIcon={faCircleH}
      title={TITLE}
      data={filteredData}
      emptyStateMessage={
        encountersQuery.data.length === 0 ?
          "We didn't find any encounter records for this patient."
        : 'There are no emergency or inpatient visits within the past year for this patient.'
      }
      onRowClick={(e) => {
        openEncounterDetails(e, { target: 'overview' });
      }}
      footerCTA={{
        label: FOOTER_TITLE,
        onClick: onSeeAllResources,
      }}
      loading={encountersQuery.isLoading}
      helpText="Emergency and inpatient visits from the past year."
      telemetryTargetName="encounters_overview"
      testId="encounters-overview"
      renderResource={(e: EncounterModel) => (
        <ResourceTitleColumn
          title={`${e.dateDisplay} - ${e.typeDisplay}`}
          subTitle={
            <div className={tw`space-y-0.5`}>
              {e.location.length && <div className={tw`font-normal`}>{e.location.join(', ')}</div>}
              {e.clinicalNotes.length > 0 ?
                <div className={tw`flex items-center space-x-1.5 pt-1 text-sm font-normal`}>
                  <FontAwesomeIcon className={tw`w-4 text-content-lighter`} icon={faNoteSticky} />
                  <div>
                    {e.clinicalNotes.length} {e.clinicalNotes.length === 1 ? 'note' : 'notes'}
                  </div>
                </div>
              : undefined}
            </div>
          }
          renderIcon={RenderSyncedWithRecordIcon(
            e.ownedByBuilder(userBuilderId) || e.syncedWithRecord,
          )}
        />
      )}
    />
  );
};

const getEncounterOverviewFilters = (): OverviewFilter<EncounterModel>[] => [
  {
    key: 'emergency',
    label: 'Filter for emergency encounters',
    description: 'Is an emergency or a hospitalization.',
    predicate: (e: EncounterModel) => encounterClassPredicate(['Emergency', 'Inpatient'], e),
  },
  {
    key: 'recent',
    label: 'Filter for recent encounters',
    description: 'Began within the past year.',
    predicate: (e: EncounterModel) => datePredicate(e, 'periodStart', 365, false),
  },
];

export const EncountersOverviewCard = withErrorBoundaryResourceCard({
  component: EncountersOverviewCardComponent,
  name: 'EncountersOverviewCard',
  title: TITLE,
  footerTitle: FOOTER_TITLE,
  tabToNavigateTo: 'encounters',
});
