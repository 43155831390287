import { UseQueryResult } from '@tanstack/react-query';
import { merge } from 'lodash';
import { getCTWBaseUrl } from '@ctw/shared/api/urls';
import { useTimerQueryWithCTW } from '@ctw/shared/context/use-query-with-ctw';
import { useUserBuilderId } from '@ctw/shared/context/user-builder-id';
import { QUERY_KEY_BUILDER_CONFIG } from '@ctw/shared/utils/query-keys';
import { ctwFetch } from '@ctw/shared/utils/request';

const DEFAULT_CONFIG = {
  overviewCards: {
    conditions: { enabled: true },
    referrals: { enabled: true },
    medications: { enabled: true },
    diagnostics: { enabled: true },
    encounters: { enabled: true },
    episodesOfCare: { enabled: false },
    vitals: { enabled: true },
    packages: { enabled: true },
    careGaps: { enabled: true },
  },
};

export type BuilderConfig = {
  builderId: string;
  overviewCards?: Record<string, { enabled: boolean } | undefined>;
  systemMRN?: string;
  hospitalizationExtensionFilters: { systemURL: string; displayName: string } | undefined;
};

export function useBuilderConfig(): UseQueryResult<BuilderConfig> {
  const builderId = useUserBuilderId();

  return useTimerQueryWithCTW(
    QUERY_KEY_BUILDER_CONFIG,
    [builderId],
    async (requestContext) => {
      try {
        const { data: config } = await ctwFetch(
          `${getCTWBaseUrl(requestContext.env)}/api/builders/${requestContext.builderId}/config`,
          {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${requestContext.authToken}`,
            },
            mode: 'cors',
          },
        );
        return merge({}, DEFAULT_CONFIG, config);
      } catch {
        return DEFAULT_CONFIG;
      }
    },
    !!builderId,
  );
}
