import './rotated-table.scss';

import { ReactElement, useRef } from 'react';
import { LoadingTable } from '../table/loading-table';
import { DataIndexSpecified, MinRecordItem, RenderSpecified } from '../table/table-helpers';
import { useBreakpoints } from '@ctw/shared/hooks/use-breakpoints';
import { tw, twx } from '@ctw/shared/utils/tailwind';

export type RotatedTableRows<T extends MinRecordItem> = {
  title: string;
  shortTitle?: string;
  greyed?: boolean;
} & (DataIndexSpecified<T> | RenderSpecified<T>);

export type RotatedTableProps<T extends MinRecordItem> = {
  className?: string;
  rows: RotatedTableRows<T>[];
  records: T[];
  isLoading?: boolean;
  emptyMessage?: string | ReactElement;
};

export const RotatedTable = <T extends MinRecordItem>({
  className,
  rows,
  records,
  isLoading = false,
  emptyMessage: message = 'No records found',
}: RotatedTableProps<T>) => {
  const tableRef = useRef<HTMLTableElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const breakpoints = useBreakpoints(tableRef);

  if (isLoading) {
    return <LoadingTable />;
  }

  if (Object.entries(records).length === 0) {
    return <div className={tw`flex justify-center`}>{message}</div>;
  }

  return (
    <div className={twx('scrollable-pass-through-height space-y-4')}>
      <div
        ref={tableRef}
        className={twx('rotated-table-container scrollable-pass-through-height', className, {
          'rotated-table-small': breakpoints.md,
        })}
      >
        <div className={twx('scrollbar scrollable-content')} ref={scrollContainerRef}>
          <table>
            <colgroup>
              <col />
              {records.map((record) => (
                <col key={record.key} />
              ))}
            </colgroup>
            <tbody>
              {rows.map((row) => (
                <tr key={row.title} className={twx(row.greyed && `bg-gray-50`)}>
                  <td className={twx(row.greyed && `bg-gray-50`, `title-column`)}>
                    {breakpoints.md ? row.shortTitle ?? row.title : row.title}
                  </td>
                  {records.map((record) => {
                    if (row.dataIndex) {
                      const value = record[row.dataIndex] as string | undefined;
                      return (
                        <td key={record.key}>
                          <div className={tw`flex h-full items-center`}>
                            <div title={value} className={tw`rotated-table-truncate`}>
                              {value}
                            </div>
                          </div>
                        </td>
                      );
                    }

                    return (
                      <td key={record.key}>
                        <div className={tw`flex h-full items-center`}>
                          <div className={tw`rotated-table-truncate`}>{row.render?.(record)}</div>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
