import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Resource } from 'fhir/r4';
import { useState } from 'react';
import { useToggleDismiss } from '../hooks/use-toggle-dismiss';
import { useToggleRead } from '../hooks/use-toggle-read';
import { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import { DropdownMenuSimple } from '@ctw/shared/components/dropdown/simple';
import { RowActionsProps, TableProps } from '@ctw/shared/components/table/table';
import { MinRecordItem } from '@ctw/shared/components/table/table-helpers';
import { RowActionsConfigProp, WritebackAction } from '@ctw/shared/components/table/table-rows';
import { ViewFHIR } from '@ctw/shared/content/view-fhir';
import { useAnalytics } from '@ctw/shared/context/analytics/use-analytics';
import { useCTW } from '@ctw/shared/context/use-ctw';
import { useRequestContext } from '@ctw/shared/context/use-request-context';
import { useUserBuilderId } from '@ctw/shared/context/user-builder-id';
import { useBaseTranslations } from '@ctw/shared/utils/i18n';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { Button } from '@ctw/shared/components/button';

export type AdditionalResourceActionsProps<T extends MinRecordItem> = {
  onWritebackStart?: (action: WritebackAction, id: string) => void;
  onWritebackEnd?: (
    action: WritebackAction,
    id: string,
    isError: boolean,
    errorMessage?: string,
  ) => void;
  rowActions?: (r: T) => TableProps<T>['rowActions'];
  enableDismissAndReadActions?: boolean;
  // Will adjust the way the dropdown menu appears if the content will be rendered in a footer
  isInFooter?: boolean;
};

export const useAdditionalResourceActions = <T extends Resource, M extends FHIRModel<T>>({
  onWritebackStart,
  onWritebackEnd,
  rowActions,
  enableDismissAndReadActions,
  isInFooter = false,
}: AdditionalResourceActionsProps<M>) => {
  const { featureFlags } = useCTW();
  const [selectedAction, setSelectedAction] = useState('card');
  const dismissAndReadActions = useDismissAndReadActions(
    onWritebackStart ?? (() => {}),
    onWritebackEnd ?? (() => {}),
    enableDismissAndReadActions,
  );

  return ({ record, onSuccess, stacked = false }: RowActionsProps<M>) => {
    const extraActions = dismissAndReadActions(record) ?? [];
    const actions = rowActions?.(record) ?? [];
    const combinedActions = [...extraActions, ...actions];
    if (!combinedActions.length && !featureFlags?.enableViewFhirButton) {
      return null;
    }

    if (stacked) {
      return (
        <div className={tw`flex space-x-2`}>
          {featureFlags?.enableViewFhirButton && <ViewFHIR resource={record.resource} />}
          {combinedActions.length > 0 && (
            <DropdownMenuSimple
              align="end"
              buttonClassName={twx('bg-blue border-none bg-transparent p-0')}
              onItemSelect={(item) => {
                const selectedOption = combinedActions.filter(
                  (action) => action.text === item.key,
                )[0];
                setSelectedAction(selectedOption.text);
                setTimeout(() => {
                  // This is a hack to make sure the dropdown menu closes before the action is executed to avoid
                  // react state updates on unmounted components
                  void selectedOption.onClick(record, onSuccess);
                }, 1);
              }}
              items={combinedActions.map((item) => ({
                key: item.text,
                name: item.text,
                display: item.render?.({ record, onSuccess, stacked }) ?? item.text,
                isSelected: selectedAction === item.text,
                disabled: item.disabled,
              }))}
            >
              <div
                className={tw`btn flex items-center space-x-2 rounded-md border border-transparent bg-primary-main !py-0 px-4 py-2 text-sm font-medium font-normal text-white shadow-sm hover:bg-primary-dark`}
              >
                <span
                  className={tw`!border-r-1 mr-1.5 divide-bg-light border-0 border-solid py-2 pr-4 !text-white`}
                >
                  select action
                </span>
                <FontAwesomeIcon
                  icon={isInFooter ? faChevronUp : faChevronDown}
                  className={tw`w-2 !text-white`}
                />
              </div>
            </DropdownMenuSimple>
          )}
        </div>
      );
    }

    return (
      <div className={tw`flex space-x-2`}>
        {featureFlags?.enableViewFhirButton && <ViewFHIR resource={record.resource} />}
        {combinedActions.map(({ text, testId, onClick, disabled = false, className, render }) => (
          <Button
            key={text}
            type="button"
            disabled={disabled}
            variant="unstyled"
            className={twx(className, 'row-action')}
            testId={testId}
            onClick={() => {
              void onClick(record, onSuccess);
            }}
          >
            {render?.({ record, onSuccess, stacked }) ?? text}
          </Button>
        ))}
      </div>
    );
  };
};

function useDismissAndReadActions(
  onWritebackStart: (action: WritebackAction, id: string) => void,
  onWritebackEnd: (
    action: WritebackAction,
    id: string,
    isError: boolean,
    errorMessage?: string,
  ) => void,
  enableDismissAndReadActions = false,
) {
  const userBuilderId = useUserBuilderId();
  const { t } = useBaseTranslations();
  const requestContext = useRequestContext();
  const { trackInteraction } = useAnalytics();

  const { isLoading: isToggleDismissLoading, toggleDismiss } = useToggleDismiss();
  const { isLoading: isToggleReadLoading, toggleRead } = useToggleRead();
  return (record: FHIRModel<Resource>): RowActionsConfigProp<FHIRModel<Resource>> => {
    const archiveLabel =
      record.isDismissed ? t('resourceTable.restore') : t('resourceTable.dismiss');

    const readLabel = record.isRead ? t('resourceTable.unread') : t('resourceTable.read');

    // In production, we want to avoid non-builder users from inadvertently marking records as read
    // In lower environments this is allowed for demos/testing
    const disableReadButton =
      requestContext?.env === 'production' && requestContext.userType !== 'builder';
    if (!enableDismissAndReadActions || record.ownedByBuilder(userBuilderId)) {
      return [];
    }
    return [
      {
        className:
          'rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm border border-solid border-divider-main bg-white capitalize text-content-light hover:bg-bg-lighter',
        disabled: isToggleDismissLoading || isToggleReadLoading,
        text: archiveLabel,
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick: async () => {
          trackInteraction('toggle_record_archive', {
            action: record.isDismissed ? 'restore' : 'dismiss',
          });
          try {
            const action = record.isDismissed ? 'restore' : 'dismiss-single';
            onWritebackStart(action, record.id);
            await toggleDismiss(record);
            onWritebackEnd(action, record.id, false);
          } catch (error) {
            onWritebackEnd(
              'dismiss-single',
              record.id,
              true,
              error instanceof Error ? error.message : String(error),
            );
            return;
          }
          if (!record.isRead) {
            await toggleRead(record);
          }
        },
        render() {
          return (
            <div className={tw`flex`}>
              {isToggleDismissLoading && (
                <LoadingSpinner message="Loading..." className={tw`mx-4 align-middle`} />
              )}
              {!isToggleDismissLoading && archiveLabel}
            </div>
          );
        },
      },
      {
        className:
          'rounded-md border border-transparent px-4 py-2 text-sm font-medium shadow-sm border border-solid border-divider-main bg-white capitalize text-content-light hover:bg-bg-lighter',
        disabled: disableReadButton || isToggleDismissLoading || isToggleReadLoading,
        text: readLabel,
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onClick: async () => {
          if (record.isRead) {
            trackInteraction('toggle_record_read', {
              action: 'mark_as_unread',
            });
          } else {
            trackInteraction('toggle_record_read', { action: 'mark_as_read' });
          }
          await toggleRead(record);
        },
        render() {
          return (
            <div className={tw`flex`}>
              {isToggleReadLoading && (
                <LoadingSpinner message="Loading..." className={tw`mx-4 align-middle`} />
              )}
              {!isToggleReadLoading && readLabel}
            </div>
          );
        },
      },
    ];
  };
}
