import { twx } from '@ctw/shared/utils/tailwind';

export const RefreshIcon = ({ className }: { className?: string }) => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={twx(className)}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.75 8.5C15.0139 8.5 5.5 18.0139 5.5 29.75C5.5 41.486 15.0139 51 26.75 51C36.8532 51 45.3095 43.9493 47.4671 34.5H44.6876C43.6438 34.5 42.7098 33.8514 42.3451 32.8733C41.9805 31.8952 42.2619 30.7935 43.051 30.1102L49.1132 24.8602C50.0526 24.0466 51.447 24.0466 52.3864 24.8602L58.4486 30.1102C59.2377 30.7935 59.5191 31.8952 59.1545 32.8733C58.7898 33.8514 57.8558 34.5 56.812 34.5H53.0795C50.8381 47.0077 39.9026 56.5 26.75 56.5C11.9764 56.5 0 44.5236 0 29.75C0 14.9764 11.9764 3 26.75 3C33.9573 3 40.5038 5.85401 45.3115 10.4877C46.405 11.5417 46.4371 13.2826 45.3831 14.3761C44.3291 15.4697 42.5882 15.5017 41.4947 14.4478C37.6708 10.7622 32.4774 8.5 26.75 8.5Z"
      fill="#9CA3AF"
    />
    <path
      d="M26.25 16.75C27.6307 16.75 28.75 17.8693 28.75 19.25V29.7145L36.0178 36.9822C36.9941 37.9585 36.9941 39.5415 36.0178 40.5178C35.0415 41.4941 33.4585 41.4941 32.4822 40.5178L24.4822 32.5178C24.0134 32.0489 23.75 31.413 23.75 30.75V19.25C23.75 17.8693 24.8693 16.75 26.25 16.75Z"
      fill="#9CA3AF"
    />
  </svg>
);
