import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { useGetAuthToken } from '@ctw/shared/context/authentication/use-get-auth-token';
import { TelemetryContext } from '@ctw/shared/context/telemetry/context';
import { Env } from '@ctw/shared/context/types';
import { Telemetry } from '@ctw/shared/utils/telemetry';

type TelemetryProviderProps = {
  env: Env;
  builderId?: string;
  enableTelemetry?: boolean;
  ehr?: string;
  datadogConfig?: {
    applicationId?: string;
    clientToken?: string;
    service?: string;
    version: string;
  };
};

const INITIALIZATION_TIMEOUT = 2000; // 2 seconds

export function TelemetryProvider({
  children,
  env,
  builderId,
  enableTelemetry = false,
  datadogConfig,
  ehr,
}: PropsWithChildren<TelemetryProviderProps>) {
  const getAuthToken = useGetAuthToken();
  const [isInitialized, setIsInitialized] = useState(false);
  const [initTimeoutExceeded, setInitTimeoutExceeded] = useState(false);

  const contextValue = useMemo(
    () => ({
      builderId,
      ehr,
      enableTelemetry,
      datadogConfig,
    }),
    [builderId, ehr, enableTelemetry, datadogConfig],
  );

  useEffect(() => {
    void (async () => {
      try {
        Telemetry.init(env, ehr, enableTelemetry, datadogConfig);
        Telemetry.setBuilder(builderId);
        const accessToken = await getAuthToken();
        Telemetry.setUser(accessToken);
      } catch (error) {
        Telemetry.logger.error(
          'Telemetry initialization failed',
          error instanceof Error ? error : new Error(String(error)),
        );
      } finally {
        setIsInitialized(true); // proceed to render children regardless of initialization success
      }
    })();
  }, [builderId, env, ehr, enableTelemetry, getAuthToken, datadogConfig]);

  // don't want to keep showing loading screen if telemetry initialization takes too long
  useEffect(() => {
    const timeout = setTimeout(() => {
      setInitTimeoutExceeded(true);
    }, INITIALIZATION_TIMEOUT);

    return () => clearTimeout(timeout); // Clean up the timeout if the component unmounts
  }, []);

  if (!isInitialized && !initTimeoutExceeded) {
    return <LoadingSpinner message="Loading..." />;
  }

  return <TelemetryContext.Provider value={contextValue}>{children}</TelemetryContext.Provider>;
}
