import { Condition } from 'fhir/r4';
import { cloneDeep, isUndefined, omitBy } from 'lodash';
import { ConditionType } from '../types';
import { createOrEditFhirResource } from '@ctw/shared/api/fhir/action-helper';
import { dateToISO } from '@ctw/shared/api/fhir/formatters';
import { ConditionModel, ConditionStatuses } from '@ctw/shared/api/fhir/models/condition';
import { getUsersPractitionerReference } from '@ctw/shared/api/fhir/practitioner';
import {
  SYSTEM_CCI,
  SYSTEM_CONDITION_CATEGORY,
  SYSTEM_CONDITION_CLINICAL,
  SYSTEM_CONDITION_VERIFICATION_STATUS,
} from '@ctw/shared/api/fhir/system-urls';
import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import {
  QUERY_KEY_PATIENT_BUILDER_CONDITIONS,
  QUERY_KEY_PATIENT_SUMMARY_CONDITIONS,
} from '@ctw/shared/utils/query-keys';
import { queryClient } from '@ctw/shared/utils/request';

// Sets any autofill values that apply when a user adds a condition, whether creating or confirming.
export function getAddConditionWithDefaults(condition: Condition): Condition {
  return cloneDeep(condition);
}

export const getClincalAndVerificationStatus = (status: ConditionStatuses) => {
  let verificationStatus = '';
  let clinicalStatus = '';

  switch (status) {
    case 'Active':
      verificationStatus = 'confirmed';
      clinicalStatus = 'active';
      break;
    case 'Inactive':
      verificationStatus = 'confirmed';
      clinicalStatus = 'inactive';
      break;
    case 'Pending':
      verificationStatus = 'unconfirmed';
      clinicalStatus = 'active';
      break;
    case 'Refuted':
      verificationStatus = 'refuted';
      clinicalStatus = 'inactive';
      break;
    case 'Entered in Error':
      verificationStatus = 'entered-in-error';
      break;
    default:
      throw Error('status is should be of type ConditionStatus');
  }

  return omitBy(
    {
      verificationStatus: {
        coding: [
          {
            system: SYSTEM_CONDITION_VERIFICATION_STATUS,
            code: verificationStatus,
          },
        ],
      },
      ...(clinicalStatus && {
        clinicalStatus: {
          coding: [{ system: SYSTEM_CONDITION_CLINICAL, code: clinicalStatus }],
        },
      }),
    },
    isUndefined,
  );
};

export const createOrEditCondition = async (
  condition: ConditionModel | undefined,
  patientID: string,
  data: CreateOrEditConditionFormData,
  getRequestContext: () => Promise<CTWRequestContext>,
): Promise<unknown> => {
  const requestContext = await getRequestContext();

  // Defines the properties of the condition based on the form.
  // The autofill values that apply to both edits and creates are here; including Practitioner, Recorder, Patient, and Recorded date.
  const fhirCondition: fhir4.Condition = {
    resourceType: 'Condition',
    id: data.id,
    recorder: await getUsersPractitionerReference(requestContext),
    ...getClincalAndVerificationStatus(data.status),
    // Set category to problem list when creating a condition.
    category:
      data.id && condition ?
        condition.resource.category
      : [
          {
            coding: [
              {
                system: SYSTEM_CONDITION_CATEGORY,
                code: 'problem-list-item',
                display: 'Problem List Item',
              },
            ],
          },
        ],
    // Keep all existing codings when editing a condition.
    code:
      data.id && condition ?
        condition.codings
      : {
          coding: [
            {
              system: data.condition.system,
              code: data.condition.code,
              display: data.condition.display,
            },
          ],
          text: data.condition.display,
        },
    ...(data.abatement && {
      abatementDateTime: dateToISO(data.abatement),
    }),
    ...(data.onset && { onsetDateTime: dateToISO(data.onset) }),
    recordedDate: dateToISO(new Date()),
    subject: { type: 'Patient', reference: `Patient/${patientID}` },
    note: data.note ? [{ text: data.note }] : undefined,
  };

  if (data.type === 'Chronic') {
    fhirCondition.code?.coding?.push({
      system: SYSTEM_CCI,
      code: 'C',
      display: 'Chronic',
    });
  } else if (data.type === 'Acute') {
    fhirCondition.code?.coding?.push({
      system: SYSTEM_CCI,
      code: 'A',
      display: 'Acute',
    });
  } else if (data.type === 'Chronic and acute') {
    fhirCondition.code?.coding?.push({
      system: SYSTEM_CCI,
      code: 'B',
      display: 'Both',
    });
  }

  const response = await createOrEditFhirResource(fhirCondition, requestContext);

  await queryClient.invalidateQueries([
    QUERY_KEY_PATIENT_SUMMARY_CONDITIONS,
    QUERY_KEY_PATIENT_BUILDER_CONDITIONS,
  ]);

  return response;
};

export type CreateOrEditConditionFormData = {
  id?: string;
  status: ConditionStatuses;
  type: ConditionType;
  condition: fhir4.Coding;
  abatement?: Date;
  onset?: Date;
  note?: string;
};
