import { usePatientHistory } from './use-patient-history';
import { usePatientHistoryStatus } from './use-patient-history-status';
import { SupportFormLink } from '../support-form-link';
import { formatISODateStringToDate } from '@ctw/shared/api/fhir/formatters';
import { Modal, ModalProps } from '@ctw/shared/components/modal';
import { useModal } from '@ctw/shared/context/modal-hooks';
import { tw } from '@ctw/shared/utils/tailwind';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { Button } from '@ctw/shared/components/button';

export function useRequestRecordsModal() {
  const { openModal } = useModal();

  return () => {
    openModal({
      component: (props) => <RequestRecordsModal {...props} />,
    });
  };
}

type RequestRecordsModalProps = {
  onClose: () => void;
} & Omit<ModalProps, 'title' | 'children' | 'onAfterClosed'>;

const RequestRecordsModal = ({ onClose, ...modalProps }: RequestRecordsModalProps) => {
  const { openHistoryRequestDrawer } = usePatientHistory();
  const { isLoading, lastCompletedDate } = usePatientHistoryStatus();

  let header = (
    <div>
      <LoadingSpinner message="Loading" />
    </div>
  );

  // Because of query caching, it is unlikely for users to ever see the above loading state.
  if (!isLoading) {
    if (lastCompletedDate) {
      header = (
        <span>
          Records last updated {formatISODateStringToDate(lastCompletedDate.toISOString())}
        </span>
      );
    } else {
      header = <span>Records never requested</span>;
    }
  }

  return (
    <Modal {...modalProps} onClose={onClose} showCloseIcon>
      <div className={tw`w-[372px] space-y-6`}>
        <div className={tw`space-y-2`}>
          <div className={tw`font-medium`}>{header}</div>
          <div className={tw`text-sm`}>
            Patient records are automatically updated when Zus thinks new information is available.
            If you feel that something is missing please let us know.
          </div>
        </div>

        <div className={tw`flex justify-between`}>
          <Button
            type="button"
            onClick={() => {
              onClose();
            }}
            variant="link"
          >
            Cancel
          </Button>

          <div className={tw`flex space-x-3`}>
            <Button
              type="button"
              onClick={() => {
                onClose();
                void openHistoryRequestDrawer();
              }}
              variant="default"
              className={tw`!normal-case`}
            >
              Request records
            </Button>
            <SupportFormLink
              buttonText="Report an issue"
              className={tw`btn rounded-md border border-transparent bg-primary-main px-4 py-2 text-sm font-medium !normal-case text-white shadow-sm hover:bg-primary-dark`}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
