import { faCalendar, faFileLines, faUser } from '@fortawesome/free-regular-svg-icons';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReferralModel } from '@ctw/shared/api/fhir/models/referral';
import { withErrorBoundaryResourceCard } from '@ctw/shared/components/overview-error-boundary';
import { ResourceOverviewCardGrouped } from '@ctw/shared/content/overview/resource-overview-card-grouped';
import {
  useReferralDetailsDrawer,
  useReferralRelatedDocuments,
} from '@ctw/shared/content/patient-referrals/helpers/details';
import { usePatientReferrals } from '@ctw/shared/hooks/use-patient-referrals';
import { tw } from '@ctw/shared/utils/tailwind';

const TITLE = 'Referrals';
const FOOTER_TITLE = 'All Referrals';

const RelatedDocumentsSummary = ({ referral }: { referral: ReferralModel }) => {
  const relatedDocumentsQuery = useReferralRelatedDocuments(referral);
  const relatedDocuments = relatedDocumentsQuery.isLoading ? [] : relatedDocumentsQuery.data;

  if (relatedDocuments.length === 0 && ['fulfilled', 'acknowledged'].includes(referral.status)) {
    return (
      <div className={tw`flex items-center gap-1`}>
        <FontAwesomeIcon className={tw`w-4 text-content-lighter`} icon={faFileLines} />
        <div className={tw`text-content-lighter`}>No documents available</div>
      </div>
    );
  }

  if (relatedDocuments.length > 0) {
    return (
      <div className={tw`ctems-center flex gap-1`}>
        <FontAwesomeIcon className={tw`w-4 text-content-lighter`} icon={faFileLines} />
        <div>Documents available</div>
      </div>
    );
  }

  return <></>;
};

type PatientReferralsOverviewProps = {
  onSeeAllResources: () => void;
};

export const PatientReferralsOverviewComponent = ({
  onSeeAllResources,
}: PatientReferralsOverviewProps) => {
  const referralsQuery = usePatientReferrals();
  const openDetails = useReferralDetailsDrawer();

  return (
    <ResourceOverviewCardGrouped
      groupBy={(referral) => (referral.status === 'acknowledged' ? 'past' : 'current')}
      groupByKeyOrder={['open', 'closed']}
      footerCTA={{
        label: FOOTER_TITLE,
        onClick: onSeeAllResources,
      }}
      onRowClick={openDetails}
      data={referralsQuery.data}
      emptyStateMessage="We didn't find any referrals for this patient."
      title={TITLE}
      helpText="Referrals for the patient"
      loading={referralsQuery.isLoading}
      headerIcon={faUsers}
      telemetryTargetName="referrals_overview"
      testId="referrals-overview"
      renderResource={(referral: ReferralModel) => (
        <div className={tw`border-t px-[1.5rem] pt-2`}>
          <div className={tw`font-medium`}>{referral.referredPractitionerName}</div>
          <div>
            {referral.referredPracticeName} {referral.referredPracticePhoneNumber || ''}
          </div>
          <hr className={tw`my-1`} />
          <div className={tw`flex gap-1`}>
            <FontAwesomeIcon className={tw`w-4 text-content-lighter`} icon={faUser} />
            <div>
              Ordered {referral.referredAt?.toLocaleDateString()} by{' '}
              {referral.referringPractitionerName || 'Unknown'}
            </div>
          </div>
          {['scheduled', 'pending-acknowledgement', 'acknowledged'].includes(referral.status) && (
            <div className={tw`flex gap-1`}>
              <FontAwesomeIcon className={tw`w-4 text-content-lighter`} icon={faCalendar} />
              <div>
                Scheduled {referral.scheduledAt?.toLocaleDateString()} by{' '}
                {referral.referredPracticeName || 'Unknown'}
              </div>
            </div>
          )}
          {['pending-acknowledgement', 'acknowledged'].includes(referral.status) && (
            <div className={tw`flex gap-1`}>
              <FontAwesomeIcon className={tw`w-4 text-content-lighter`} icon={faCalendar} />
              <div>
                Completed {referral.completedAt?.toLocaleDateString()} by{' '}
                {referral.referredPracticeName || 'Unknown'}
              </div>
            </div>
          )}
          <RelatedDocumentsSummary referral={referral} />
        </div>
      )}
    />
  );
};

export const PatientReferralsOverview = withErrorBoundaryResourceCard({
  component: PatientReferralsOverviewComponent,
  name: 'PatientReferralsOverview',
  title: TITLE,
  footerTitle: FOOTER_TITLE,
  tabToNavigateTo: 'referrals',
});
