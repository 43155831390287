import { useState } from 'react';
import { ChartReviewResponse } from './types';
import { getAiEndpoint, translateUnixBreaksToHtml } from './utils';
import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { usePatientContext, useTimingQueryWithPatient } from '@ctw/shared/context/patient-provider';
import { useCTW } from '@ctw/shared/context/use-ctw';
import { RequestError } from '@ctw/shared/utils/error';
import { QUERY_KEY_AI_CHART_REVIEW_SUMMARY } from '@ctw/shared/utils/query-keys';
import { ctwFetch, retryWithExponentialBackoff } from '@ctw/shared/utils/request';
import { Telemetry } from '@ctw/shared/utils/telemetry';

const MAX_RETRIES = 5;
const BASE_DELAY = 2000;

const generatePrecomputedSummary = async (
  requestContext: CTWRequestContext,
  patientId: string,
  summaryType: string,
): Promise<ChartReviewResponse> => {
  const endpoint = getAiEndpoint(
    requestContext.env,
    `/patients/${patientId}/summaries/${summaryType}`,
  );
  const resp = await ctwFetch(endpoint, {
    headers: {
      Authorization: `Bearer ${requestContext.authToken}`,
      'Content-Type': 'application/json',
    },
    method: 'PUT',
  });

  resp.data.hpi = translateUnixBreaksToHtml(resp.data.hpi);
  return resp.data;
};

const fetchPrecomputedSummary = async (
  requestContext: CTWRequestContext,
  patientId: string,
  summaryType: string,
): Promise<ChartReviewResponse> => {
  const endpoint = getAiEndpoint(
    requestContext.env,
    `/patients/${patientId}/summaries/${summaryType}`,
  );
  const response = await ctwFetch(endpoint, {
    headers: {
      Authorization: `Bearer ${requestContext.authToken}`,
      'Content-Type': 'application/json',
    },
    method: 'GET',
  });

  response.data.hpi = translateUnixBreaksToHtml(response.data.hpi);
  return response.data;
};

export const ErrorSummary: ChartReviewResponse = {
  hpi: 'An error occurred while fetching the summary',
  citations: [],
};

export const NotFoundSummary: ChartReviewResponse = {
  hpi: 'Summary not found',
  citations: [],
};

export const usePrecomputedNewPatientSummaryForPatient = (summaryType: string) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const { patient } = usePatientContext();
  const { getRequestContext } = useCTW();

  const {
    data: document,
    isLoading,
    error,
    refetch,
  } = useTimingQueryWithPatient<ChartReviewResponse, string>(
    QUERY_KEY_AI_CHART_REVIEW_SUMMARY,
    [summaryType],
    async (reqContext, patientModel) => {
      try {
        if (!patientModel.UPID) {
          return { hpi: '', citations: [] };
        }

        const resp = await fetchPrecomputedSummary(reqContext, patientModel.UPID, summaryType);
        resp.upid = patientModel.UPID;
        return resp;
      } catch (e: unknown) {
        if (e instanceof RequestError && e.statusCode === 404) {
          return NotFoundSummary;
        }
        return ErrorSummary;
      }
    },
  );

  const generateDocument = async () => {
    setIsGenerating(true);
    try {
      const reqContext = await getRequestContext();
      const upid = patient.data?.UPID || 'unknown-upid';
      await generatePrecomputedSummary(reqContext, upid, summaryType);

      // Wait for the summary to be generated
      return retryWithExponentialBackoff(
        async () => {
          const resp = await refetch();
          if (resp.data === NotFoundSummary) {
            throw new Error('Summary not found');
          }
          return resp;
        },
        MAX_RETRIES,
        BASE_DELAY,
      );
    } catch (e) {
      Telemetry.logger.error(
        'Error generating summary',
        error instanceof Error ? error : new Error(String(error)),
      );
      throw new Error('Error generating summary');
    } finally {
      setIsGenerating(false);
    }
  };

  return {
    document,
    isLoading,
    isGenerating,
    error,
    generateDocument,
  };
};
