import { useState } from 'react';
import { FeedbackItem } from './feedback-item';
import { tw } from '@ctw/shared/utils/tailwind';
import { Heading } from '@ctw/shared/components/heading';

export type FeedbackGroupProps = {
  title: string;
  radioGroupName: string;
  options: string[];
  onChange: (score: number) => void;
};

export const FeedbackGroup: React.FC<FeedbackGroupProps> = ({
  title,
  radioGroupName,
  options,
  onChange,
}) => {
  const [currentValue, setCurrentValue] = useState<number>(0);

  const handleChange = (score: number) => {
    onChange(score);
    setCurrentValue(score);
  };

  const items = options
    .map((option, index) => (
      <FeedbackItem
        key={`${radioGroupName}-${option}`}
        score={index + 1}
        radioGroupName={radioGroupName}
        itemText={option}
        selectedScore={currentValue === index + 1}
        onChange={handleChange}
        className={tw`p-4 ${index === 0 ? 'rounded-b-lg' : ''} ${
          index === options.length - 1 ? 'rounded-t-lg' : ''
        }`}
      />
    ))
    .reverse(); // Reverse the order so that the highest score is at the top.

  return (
    <>
      <Heading level="h3">{title}</Heading>
      <div>{items}</div>
    </>
  );
};
