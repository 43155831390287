import { orderBy } from 'lodash';
import { useState } from 'react';
import { RelatedDocumentEntry, RelatedDocumentEntryProps } from './related-document-entry';
import { ReferralModel } from '@ctw/shared/api/fhir/models/referral';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { UseQueryResultBasic } from '@ctw/shared/utils/request';
import { tw } from '@ctw/shared/utils/tailwind';
import { Button } from '@ctw/shared/components/button';

export type RelatedDocumentEntries = RelatedDocumentEntryProps[];

type RelatedDocumentsProps = {
  limit?: number;
  getRelatedDocuments?: (
    referral: ReferralModel,
  ) => UseQueryResultBasic<RelatedDocumentEntries | undefined>;
  referral: ReferralModel;
};

const DEFAULT_RELATED_DOCUMENTS_PAGE_LIMIT = 20;

export function RelatedDocuments({
  limit = DEFAULT_RELATED_DOCUMENTS_PAGE_LIMIT,
  getRelatedDocuments,
  referral,
}: RelatedDocumentsProps) {
  const relatedDocuments = getRelatedDocuments && getRelatedDocuments(referral);
  const entries: RelatedDocumentEntries = relatedDocuments?.data ?? [];
  const [showAll, setShowAll] = useState(!limit || entries.length <= limit);
  const { resourceTypeTitle } = referral;

  // Sort entries by:
  //  1. date descending
  //  2. has a source document (binaryId present or not)
  //  3. title ascending
  //  4. subtitle ascending
  //  5. versionId descending (this is to ensure consistent ordering)
  const sortedEntries = orderBy(
    entries,
    [
      // Convert the date string to a Date object so that it sorts correctly.
      (e) => (e.date ? new Date(e.date) : ''), // desc
      // We want to promote entries with a source document to the top.
      (e) => !!e.binaryId, // desc
      'title', // asc
      'subtitle', // asc
      'versionId', // desc
    ],
    ['desc', 'desc', 'asc', 'asc', 'desc'],
  );

  const displayedEntries = showAll || !limit ? sortedEntries : sortedEntries.slice(0, limit);

  if (!relatedDocuments) {
    return <></>;
  }

  if (relatedDocuments.isLoading) {
    return <LoadingSpinner message="Loading documents..." />;
  }

  if (displayedEntries.length === 0) {
    return <></>;
  }

  return (
    <div className={tw`space-y-4`}>
      <div className={tw`text-lg font-semibold`}>Documents</div>
      {displayedEntries.map((entry) => (
        <div key={entry.id}>
          <RelatedDocumentEntry
            id={entry.id}
            date={entry.date}
            title={entry.title}
            subtitle={entry.subtitle}
            details={entry.details}
            hideEmpty={entry.hideEmpty}
            binaryId={entry.binaryId}
            resourceTypeTitle={resourceTypeTitle}
          />
        </div>
      ))}
      {!showAll && (
        <div className={tw`text-center`}>
          <Button type="button" variant="primary" onClick={() => setShowAll(true)}>
            {/* We know limit must be set if showAll is false. */}
            {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
            Load {sortedEntries.length - limit!} More
          </Button>
        </div>
      )}
    </div>
  );
}
