import { useContext, useMemo } from 'react';
import { AnalyticsContext } from './context';
import { TrackingMetadata } from './tracking-metadata';
import { usePatientContext } from '../patient-provider';
import { useTelemetry } from '@ctw/shared/context/telemetry/use-telemetry';

// Tracked events indicate the effect the user initiated.
// They don't indicate details like patient, overarching component, or cause, which belong in metadata.
export type Action =
  // App-level
  | 'logout'
  | 'toggle_ai_search'
  | 'search'
  | 'clear_search'
  // Documents
  | 'open_source_document'
  | 'close_document'
  | 'toggle_document_section'
  | 'download_document'
  | 'add_to_record'
  | 'select_section'
  // Related Documents
  | 'toggle_related_document_entry'
  // History
  | 'toggle_history_entry'
  // Notes
  | 'toggle_note'
  // Drawers
  | 'open_drawer'
  | 'close_drawer'
  // Forms
  | 'submit_form' // actions include "create_resource", "edit_resource"
  | 'cancel_delete_request'
  | 'confirm_delete_request'
  | 'request_records'
  | 'open_diagnostic_report'
  // Trends
  | 'toggle_trend'
  // Tables
  | 'change_view'
  | 'change_filter'
  | 'sort'
  | 'open_tab'
  | 'empty_table'
  // Records
  | 'toggle_record_archive'
  | 'toggle_record_read'
  | 'click_row'
  | 'change_page'
  // TOC Table
  | 'view_adt_details'
  | 'view_adt_discharge_summary'
  // Overview
  | 'see_all_of_resource'
  // GPS Summary
  | 'open_gps_summary'
  | 'open_gps_citation';

export function useAnalytics() {
  const analytics = useContext(AnalyticsContext);
  const telemetry = useTelemetry();
  const { patient } = usePatientContext();

  return useMemo(
    () => ({
      // Helper to incl ude patient UPID and optionally also the component name
      // with metadata when tracking interactions
      trackInteraction(action: Action, trackingMetadata: TrackingMetadata = {}) {
        const { customMetadata = {}, ...metadata } = trackingMetadata;
        const eventMetadata: Record<string, unknown> = {
          ...customMetadata,
          ...metadata,
        };

        if (analytics.componentName) {
          eventMetadata.componentName = analytics.componentName;
        }
        if (patient.data?.UPID) {
          eventMetadata.upid = patient.data.UPID;
        }

        return telemetry.Telemetry.trackInteraction(action, eventMetadata);
      },

      analyticsEvent(action: string, eventMetadata = {}) {
        return telemetry.Telemetry.analyticsEvent(action, eventMetadata);
      },
    }),
    [analytics.componentName, patient.data?.UPID, telemetry.Telemetry],
  );
}
