import { patientReferralsColumns } from './helpers/columns';
import { useReferralDetailsDrawer } from './helpers/details';
import { PatientResourceTable } from '../resource/patient-resource-table';
import { EmptyPatientTable } from '@ctw/shared/components/table/empty-table';
import { withErrorBoundary } from '@ctw/shared/content/error-boundary';
import { AnalyticsProvider } from '@ctw/shared/context/analytics/analytics-provider';
import { useUserBuilderId } from '@ctw/shared/context/user-builder-id';
import { usePatientReferrals } from '@ctw/shared/hooks/use-patient-referrals';
import { twx } from '@ctw/shared/utils/tailwind';

export type PatientReferralsProps = {
  className?: string;
};

const PatientReferralsComponent = ({ className }: PatientReferralsProps) => {
  const userBuilderId = useUserBuilderId();
  const referralsQuery = usePatientReferrals();
  const openDetails = useReferralDetailsDrawer();

  return (
    <AnalyticsProvider componentName="PatientReferrals">
      <div
        data-testid="referrals-table"
        className={twx(className, 'scrollable-pass-through-height')}
      >
        <PatientResourceTable
          showTableHead
          isLoading={referralsQuery.isLoading}
          data={referralsQuery.data}
          columns={patientReferralsColumns(userBuilderId)}
          onRowClick={openDetails}
          enableDismissAndReadActions
          emptyMessage={
            <EmptyPatientTable hasZeroFilteredRecords={false} resourceName="referrals" />
          }
        />
      </div>
    </AnalyticsProvider>
  );
};

export const PatientReferrals = withErrorBoundary(PatientReferralsComponent, 'Referrals');
