import { useCallback, useState } from 'react';
import { FeedbackRequest, FeedbackResponse } from './types';
import { getAiEndpoint } from './utils';
import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { useTimingQueryWithPatient } from '@ctw/shared/context/patient-provider';
import { claimsAuthEmail } from '@ctw/shared/utils/auth';
import { QUERY_KEY_AI_FEEDBACK } from '@ctw/shared/utils/query-keys';
import { ctwFetch } from '@ctw/shared/utils/request';

const submitFeedback = async (requestContext: CTWRequestContext, requestData: FeedbackRequest) => {
  const endpoint = getAiEndpoint(requestContext.env, `/feedback`);
  const createdAt = new Date().toISOString();
  const userId = claimsAuthEmail(requestContext.authToken);

  const finalRequestData = {
    ...requestData,
    createdAt,
    userId,
  };

  const response = await ctwFetch(endpoint, {
    headers: {
      Authorization: `Bearer ${requestContext.authToken}`,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(finalRequestData),
  });
  return response.data as FeedbackResponse;
};

export const useFeedback = (req: FeedbackRequest) => {
  const [isEnabled, setIsEnabled] = useState(false);

  const query = useTimingQueryWithPatient<FeedbackResponse, string>(
    QUERY_KEY_AI_FEEDBACK,
    [JSON.stringify(req)],
    async (reqContext) => {
      try {
        return await submitFeedback(reqContext, req);
      } catch (e) {
        throw new Error(`Failed to fetch feedback: ${e}`);
      }
    },
    isEnabled,
  );

  const triggerFeedback = useCallback(() => {
    setIsEnabled(true);
    return query.refetch();
  }, [query]);

  return { ...query, triggerFeedback };
};
