import { capitalize } from 'lodash/fp';
import { EncounterNotes } from './notes';
import { EncounterModel } from '@ctw/shared/api/fhir/models/encounter';
import { useResourceDetailsDrawer } from '@ctw/shared/content/resource/resource-details-drawer';
import { tw } from '@ctw/shared/utils/tailwind';

export function usePatientEncounterDetailsDrawer() {
  return useResourceDetailsDrawer({
    header: (encounter) => encounter.dateDisplay,
    getSourceDocument: true,
    details: encounterData,
    enableDismissAndReadActions: true,
    deepLinkResourceType: 'Encounter',
    RenderChild: ({ model }) =>
      model.docsAndNotes.length > 0 && <EncounterNotes entries={model.docsAndNotes} />,
  });
}

export const encounterData = (encounter: EncounterModel) => [
  { label: 'Status', value: capitalize(encounter.status) },
  { label: 'Class', value: capitalize(encounter.classDisplay) },
  { label: 'Type', value: capitalize(encounter.typeDisplay) },
  { label: 'Location', value: encounter.location.join(', ') },
  {
    label: 'Providers',
    value: encounter.practitioners.length > 0 && (
      <ul className={tw`m-0 list-disc pl-4`}>
        {encounter.practitioners.map((p) => (
          <li key={p}>{p}</li>
        ))}
      </ul>
    ),
  },
  {
    label: 'Location Type',
    value: (() => {
      const locationType = encounter.locationType.filter(
        (value) => value.toLowerCase() !== 'unknown',
      );
      if (locationType.length > 0) {
        return (
          <ul className={tw`m-0 list-disc pl-4`}>
            {locationType
              .filter((value) => value.toLowerCase() !== 'unknown')
              .map((s) => (
                <li key={s}>{s}</li>
              ))}
          </ul>
        );
      }
      return null;
    })(),
  },
  { label: 'Reason', value: encounter.reason },
  {
    label: 'Diagnoses',
    value: encounter.diagnoses.length > 0 && (
      <ul className={tw`m-0 list-disc pl-4`}>
        {encounter.diagnoses.map((d) => (
          <li key={d}>{d}</li>
        ))}
      </ul>
    ),
  },
  { label: 'Discharge Disposition', value: encounter.dischargeDisposition },
];
