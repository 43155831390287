import { useEffect, useState } from 'react';
import { useCTW } from './use-ctw';
import { AUTH_BUILDER_ID, AUTH_BUILDER_NAME, AUTH_EMAIL, getClaims } from '@ctw/shared/utils/auth';

interface User {
  builderId: string;
  builderName: string;
  email: string;
  userId: string;
}

export function useUser() {
  const { getRequestContext } = useCTW();
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    async function load() {
      const requestContext = await getRequestContext();
      const claims = getClaims(requestContext.authToken);

      setUser({
        builderId: claims[AUTH_BUILDER_ID],
        builderName: claims[AUTH_BUILDER_NAME],
        email: claims[AUTH_EMAIL],
        userId: claims.sub,
      });
    }

    void load();
  }, [getRequestContext]);

  return user;
}
