import { Provenance } from 'fhir/r4';
import { find, some } from 'lodash';
import { DocumentModel, PatientModel } from './models';
import { FHIRModel } from './models/fhir-model';
import { getBuilderFhirPatientByIdentifier } from './patient-helper';
import { searchProvenances } from './provenance';
import { SYSTEM_ZUS_UNIVERSAL_ID } from './system-urls';
import { isRenderableBinary } from '@ctw/shared/content/resource/helpers/filters';
import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { useTimingQueryWithPatient } from '@ctw/shared/context/patient-provider';
import { useTimerQueryWithCTW } from '@ctw/shared/context/use-query-with-ctw';
import {
  QUERY_KEY_BINARY,
  QUERY_KEY_BINARY_ID,
  QUERY_KEY_CCDA_PATIENT,
} from '@ctw/shared/utils/query-keys';
import { withTimerMetric } from '@ctw/shared/utils/telemetry';

// Get the binary ID from provenance, if document is renderable
export function getBinaryId(provenances: Provenance[], targetId: string): string | undefined {
  for (let i = 0; i < provenances.length; i += 1) {
    const provenance = provenances[i];

    const hasTarget = some(provenance.target, (t) => t.reference?.includes(targetId));

    if (hasTarget && isRenderableBinary(provenance)) {
      const source = find(provenance.entity, { role: 'source' });
      if (source?.what.reference) {
        // Return the ID portion of the reference.
        return source.what.reference.split('/')[1];
      }
    }
  }

  return undefined;
}

async function fetchBinaryId<T extends fhir4.Resource, M extends FHIRModel<T>>(
  requestContext: CTWRequestContext,
  model: M | undefined,
  getSourceDocument?: boolean,
) {
  if (model instanceof DocumentModel) {
    return model.binaryId;
  }

  if (!model) {
    return null;
  }

  if (model instanceof DocumentModel) {
    // Special handling for document models which already have a binaryID.
    return model.binaryId ?? null;
  }
  if (getSourceDocument) {
    if (!model.isSummaryResource) {
      const provenances = await searchProvenances(requestContext, [model]);
      const binaryData = getBinaryId(provenances, model.id) ?? null;
      return binaryData;
    }
  }

  return null;
}

// We optionally look for any associated binary CCDAs
// if getSourceDocument is true.
export function useBinaryId<T extends fhir4.Resource, M extends FHIRModel<T>>(
  model: M | undefined,
  getSourceDocument?: boolean,
  shouldFetchBinaryDocument?: boolean,
) {
  return useTimingQueryWithPatient(
    QUERY_KEY_BINARY_ID,
    [model, getSourceDocument],
    (requestContext: CTWRequestContext) => fetchBinaryId(requestContext, model, getSourceDocument),
    shouldFetchBinaryDocument,
  );
}

async function blobToBase64(blob: Blob): Promise<string> {
  const reader = new FileReader();

  return new Promise((resolve, reject) => {
    reader.readAsDataURL(blob);

    reader.onload = () => {
      const base64String = reader.result?.toString().split(',')[1] ?? ''; // Remove data: prefix
      const binaryString = atob(base64String);
      const bytes = new Uint8Array(binaryString.length);
      for (let i = 0; i < binaryString.length; i += 1) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      const text = new TextDecoder().decode(bytes);
      resolve(text);
    };

    reader.onerror = (error) => {
      reject(error);
    };
  });
}

export type BinaryData = {
  binary: fhir4.Binary & {
    blob: Blob;
  };
};

export type FQSBinaryJSON = {
  data: {
    contentType: string;
    id: string;
    meta: fhir4.Binary['meta'];
    resourceType: 'Binary';
    securityContext: {
      reference: string;
      type: string;
    };
  };
};

async function getBinaryDocumentReq(
  requestContext: CTWRequestContext,
  binaryId: string,
): Promise<BinaryData> {
  const { data, headers } = await requestContext.fetchFromFqs(`Binary/${binaryId}`, {
    method: 'GET',
  });

  if (data instanceof Blob) {
    // The Smile response is a base64 string but FQS returns a blob.
    // The blob can easily convert to base64 string, but is flaky converting
    // back from base64 blob because we strip off any header information the
    // blob had when converting to base64 decoded string. So we add `blob` to
    // the BinaryData object so that we have it later as-is.

    return {
      binary: {
        blob: data,
        data: await blobToBase64(data),
        contentType: headers.get('Content-Type') || 'unknown',
        resourceType: 'Binary',
      },
    };
  }
  throw new Error('Response from FQS was not a Blob');
}

export const useGetBinaryDocument = (binaryId: string) =>
  useTimerQueryWithCTW(
    QUERY_KEY_BINARY,
    [binaryId],
    async (requestContext) => getBinaryDocument(requestContext, binaryId),
    true,
  );

export const getBinaryDocument = withTimerMetric(getBinaryDocumentReq, 'query_request_timing', [
  `query_key:${QUERY_KEY_BINARY}`,
]);

async function getPatientFromBinaryId(
  requestContext: CTWRequestContext,
  binaryId: string,
): Promise<PatientModel | undefined> {
  const binaryToUpidRequest = (await requestContext.fetchFromFqs(`rest/Binary/${binaryId}`, {
    method: 'GET',
    headers: {
      Accept: 'application/fhir+json',
    },
  })) as FQSBinaryJSON;

  const upid = find(binaryToUpidRequest.data.meta?.extension, {
    url: SYSTEM_ZUS_UNIVERSAL_ID,
  })?.valueString;

  if (upid) {
    return getBuilderFhirPatientByIdentifier(requestContext, upid, SYSTEM_ZUS_UNIVERSAL_ID);
  }

  return undefined;
}

export function useGetPatientFromBinaryId(binaryId: string, enabled: boolean) {
  return useTimerQueryWithCTW(
    QUERY_KEY_CCDA_PATIENT,
    [binaryId],
    async (requestContext) => getPatientFromBinaryId(requestContext, binaryId),
    enabled,
  );
}
