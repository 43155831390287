import { ServiceRequest } from 'fhir/r4';
import { PatientModel } from './models';
import { fetchServiceRequests } from '@ctw/shared/api/fhir/service-requests';
import { SYSTEM_LOINC, SYSTEM_ZUS_OWNER } from '@ctw/shared/api/fhir/system-urls';
import {
  createGraphqlClient,
  fqsRequest,
  MAX_OBJECTS_PER_REQUEST,
} from '@ctw/shared/api/fqs/client';
import {
  DataIndicatorGraphqlResponse,
  generateDataIndicatorQuery,
} from '@ctw/shared/api/fqs/queries/data-indicator';
import { vitalLoincCodes } from '@ctw/shared/content/vitals/vital-loinc-codes';
import { ZAPTabName } from '@ctw/shared/content/zus-aggregated-profile/zus-aggregated-profile';
import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { useTimingQueryWithPatient } from '@ctw/shared/context/patient-provider';
import { QUERY_KEY_DATA_INDICATORS } from '@ctw/shared/utils/query-keys';

export function useDataIndicators(
  limit = MAX_OBJECTS_PER_REQUEST,
  enabled = true,
  resources: ZAPTabName[] = [],
) {
  return useTimingQueryWithPatient(
    QUERY_KEY_DATA_INDICATORS,
    [limit, resources],
    async (requestContext, patient) => getDataIndicators(requestContext, patient, limit, resources),
    enabled,
  );
}

export async function getDataIndicators(
  requestContext: CTWRequestContext,
  patient: PatientModel,
  limit: number = MAX_OBJECTS_PER_REQUEST,
  resources: ZAPTabName[] = [],
): Promise<
  DataIndicatorGraphqlResponse & {
    serviceRequests: ServiceRequest[];
  }
> {
  const vitalCodes = vitalLoincCodes.map((loincCode) => `${SYSTEM_LOINC}|${loincCode}`);
  const graphClient = createGraphqlClient(requestContext);
  const dataIndicatorQuery = generateDataIndicatorQuery(resources);
  const serviceRequests = await fetchServiceRequests(requestContext, patient);
  const { data } = await fqsRequest<DataIndicatorGraphqlResponse>(graphClient, dataIndicatorQuery, {
    upid: patient.UPID,
    cursor: '',
    first: limit,
    PatientFirst: MAX_OBJECTS_PER_REQUEST,
    PatientFilter: {
      tag: {
        nonematch: [`${SYSTEM_ZUS_OWNER}|builder/${requestContext.builderId}`],
      },
    },
    ObservationFilter: {
      code: {
        anymatch: vitalCodes,
      },
    },
    ImmunizationFilter: {
      status: {
        eq: 'completed',
      },
    },
  });

  return { ...data, serviceRequests };
}
