import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum-slim';
import { compact, isEmpty, snakeCase } from 'lodash';
import { RequestError } from './error';
import { ReactQueryError } from './react-query-error';
import { Session } from './session';
import { FhirError, fhirErrorResponse } from '@ctw/shared/api/fhir/errors';
import { getCTWBaseUrl } from '@ctw/shared/api/urls';
import { CTWProviderProps } from '@ctw/shared/context/ctw-provider';
import {
  AUTH_BUILDER_ID,
  AUTH_BUILDER_NAME,
  AUTH_EMAIL,
  AUTH_IS_SUPER_ORG,
  AUTH_PRACTITIONER_ID,
  AUTH_USER_ID,
  AUTH_USER_TYPE,
  getClaims,
  hasUnexpiredToken,
} from '@ctw/shared/utils/auth';
import { ctwFetch } from '@ctw/shared/utils/request';

type TelemetryEventKey = 'zusTelemetryClick' | 'zusTelemetryFocus';

const prodDatadogConfig = {
  service: 'ctw-component-library',
  clientToken: 'pub7f1b01887ceb412fd989f5e08cf60d9a',
  applicationId: '44011d8b-3aa4-4672-9c7b-ee23ddac16b5',
};
const devDatadogConfig = {
  service: 'ctw-component-library',
  clientToken: 'pub29b659b1cd402a88d57c4f8c923c1eea',
  applicationId: '48fec1f8-b187-492a-afdd-e809cc6b3b82',
};

let origin = '';
let body: HTMLElement | undefined;
if (typeof window !== 'undefined') {
  origin = window.location.origin;
  body = window.document.body;
}

const FLUSH_DEFERRED_TIMEOUT_MS = 200;
// Assume local development if origin is localhost or just an IP address
const isLocalDevelopment = /https?:\/\/(localhost|\d+\.\d+\.\d+\.\d+)/i.test(origin);
// Avoid initializing telemetry or event listeners multiple times
let listenersHaveBeenAdded = false;

/**
 * Bootstrap APM Tracing
 *
 * @description Starts up DataDog RUM and tracing. Depending on the configuration the
 * RUM client will decorate requests to allowed origins with x-datadog-trace-id
 * headers, profile the in-browser app and optionally even record sessions.
 * Visit the docs for important configuration options.
 *
 * https://docs.datadoghq.com/real_user_monitoring/browser/#configuration
 */

export class Telemetry {
  private static accessToken = '';

  private static get telemetryIsAvailable() {
    return Boolean(this.environment && this.isInitialized);
  }

  private static datadogLoggingEnabled = false;

  private static unauthorizedAnalyticBuffer: {
    name: string;
    properties: Record<string, unknown>;
  }[] = [];

  private static unauthorizedMetricBuffer: {
    type: string;
    metric: string;
    value: number;
    additionalTags: string[];
  }[] = [];

  static logger = datadogLogs.logger;

  static environment = '';

  static ehr = 'unknown';

  static ddConfig: CTWProviderProps['datadogConfig'] = {
    version: 'unknown',
  };

  static isInitialized = false;

  /**
   * We need to normalize environment name in order to effectively use template
   * variables on dashboards in Datadog. Otherwise, users of the dashboard would
   * need to know to select all variations of an environment.
   */
  static setEnv(environment: string) {
    this.environment = environment.toLowerCase();
    if (['dev', 'development'].includes(this.environment)) {
      this.environment = 'dev';
    }
    if (['prod', 'production'].includes(this.environment)) {
      this.environment = 'prod';
    }
  }

  static init(
    environment: string,
    ehr = 'unknown',
    allowDataDogLogging = false,
    datadogConfig = {
      version: 'unknown',
    },
  ) {
    this.datadogLoggingEnabled = allowDataDogLogging;
    this.setEnv(environment);
    this.setEHR(ehr);
    this.setDDConfig(datadogConfig);

    // Turning on Datadog Logging is conditional. However, the event handlers
    // that explicitly send internal /report/metrics to ctw are not optional.
    if (!this.telemetryIsAvailable && allowDataDogLogging) {
      datadogLogs.init({
        ...(isLocalDevelopment ? devDatadogConfig : prodDatadogConfig),
        env: this.environment,
        forwardConsoleLogs: [], // No console logs to datadog.
        forwardErrorsToLogs: true,
        site: 'datadoghq.com',
        ...datadogConfig,
      });

      if (!this.shouldSkipSendingMetrics()) {
        datadogRum.init({
          ...(isLocalDevelopment ? devDatadogConfig : prodDatadogConfig),
          site: 'datadoghq.com',
          allowedTracingUrls: [], // No allowed tracing urls
          defaultPrivacyLevel: 'mask',
          env: this.environment,
          sessionReplaySampleRate: 20,
          sessionSampleRate: 100,
          trackLongTasks: true,
          trackResources: true,
          trackUserInteractions: true,
          ...datadogConfig,
        });
      }
    }

    // We need to ensure that this will run in browser context, not just server.
    if (!listenersHaveBeenAdded && body) {
      // We are listening to click events propagating to the document body as that
      // is the lowest level HTMLElement we actually know will both exist at this
      // time and which won't be removed from the DOM by React causing a small
      // memory leak.
      // Additionally, because we aren't listening directly to events on elements
      // that have `data-zus-telemetry-*` attributes, we don't have the luxury of
      // knowing whether an event triggered from inside one of these telemetry
      // elements, so we'll have to traverse the DOM tree ourselves. To minimize
      // this work we'll put a `depth` level and adjust it over time if needed.
      // For example, imagine the user clicked an element with CSS selector path
      // of "button[data-zus-telemetry-click="submit"] > span > span". Because we
      // are listening on body and not button, we would have to walk up 2 parent
      // nodes of the DOM before knowing whether this event was relevant to us.
      body.addEventListener('click', (event: MouseEvent) => {
        const { target, isTrusted } = event;
        if (!(isTrusted && target instanceof Element)) {
          return;
        }
        const htmlElement = this.closestHTMLElement(target);
        if (htmlElement instanceof HTMLElement) {
          this.processHTMLEvent(htmlElement, 'zusTelemetryClick');
        }
      });
      body.addEventListener('focusin', (event) => {
        const { target, isTrusted } = event;
        if (isTrusted && target instanceof HTMLElement) {
          this.processHTMLEvent(target, 'zusTelemetryFocus');
        }
      });
      listenersHaveBeenAdded = true;
    }

    this.isInitialized = true;
    if (!this.accessToken) {
      setTimeout(() => {
        void this.flushUnauthorizedAnalyticBuffer();
      }, FLUSH_DEFERRED_TIMEOUT_MS);
    }
  }

  static setDDConfig(config: CTWProviderProps['datadogConfig']) {
    this.ddConfig = config;
  }

  static setEHR(ehr: string) {
    this.setGlobalContextProperty('ehr', ehr);

    this.ehr = ehr;
  }

  static setBuilder(builderId?: string) {
    this.setGlobalContextProperty('builderId', builderId);
  }

  static setPatientID(patientId?: string, systemUrl = '') {
    this.setGlobalContextProperty('patientContext', {
      patientId,
      systemUrl,
    });
  }

  static setPatientUPID(upid?: string) {
    this.setGlobalContextProperty('patientUPID', upid);
  }

  static setUser(accessToken?: string) {
    if (accessToken) {
      this.accessToken = accessToken;
      this.validateAccessToken();
    }

    if (this.accessToken) {
      const user = getClaims(this.accessToken);
      Session.setSessionUserId(user[AUTH_USER_ID]);
      const decodedUser = {
        id: user[AUTH_USER_ID],
        type: user[AUTH_USER_TYPE],
        email: user[AUTH_EMAIL],
        practitionerId: user[AUTH_PRACTITIONER_ID],
        builderName: user[AUTH_BUILDER_NAME],
        builderId: user[AUTH_BUILDER_ID],
        isSuperOrg: user[AUTH_IS_SUPER_ORG],
      };

      if (this.datadogLoggingEnabled) {
        datadogLogs.setUser(decodedUser);
        datadogRum.setUser(decodedUser);
      }
    }
  }

  static clearUser() {
    this.accessToken = '';
    datadogLogs.setUser({});
  }

  // Validate the access token and clear it if it has expired
  static validateAccessToken(): boolean {
    if (hasUnexpiredToken(this.accessToken)) {
      return true;
    }
    if (this.accessToken) {
      this.logger.warn('Clearing Telemetry.accessToken because it has expired');
      this.accessToken = '';
      // Need to start up the flush process again
      void this.flushUnauthorizedAnalyticBuffer();
    }
    return false;
  }

  static logError(error: Error, additionalInfo?: string | Record<string, unknown>): Error {
    let jsonifiedError;
    try {
      jsonifiedError = JSON.stringify(error.stack);
    } catch (e) {
      jsonifiedError = 'Cannot stringify error';
    }

    const baseError = {
      stack: error.stack,
      message: error.message,
      jsonifiedError,
      additionalInfo,
    };

    if (error instanceof RequestError) {
      this.logger.error(error.message, {
        error: {
          ...baseError,
          statusCode: error.statusCode,
        },
      });
      datadogRum.addError(error);
      return error;
    }

    if (error instanceof ReactQueryError) {
      this.logger.error(error.message, {
        error: {
          ...baseError,
          queryKeys: error.otherQueryKeys,
          identifier: error.identifier,
          requestErrors: error.requestErrors,
          responseErrors: error.responseErrors,
        },
      });
      datadogRum.addError(error);
      return error;
    }

    const err = new Error(error.message);
    this.logger.error(err.message, {
      error: {
        ...baseError,
      },
    });
    datadogRum.addError(error);
    return err;
  }

  static logFhirError(error: FhirError, message: string) {
    const context = fhirErrorResponse(message, error);
    this.logger.error(message, context);
  }

  static trackInteraction(action: string, metadata: Record<string, unknown> = {}) {
    // We report an active session to CTW
    this.reportActiveSession().catch((error) => Telemetry.logError(error as Error));
    this.analyticsEvent(action, metadata).catch((error) => Telemetry.logError(error as Error));
  }

  static setGlobalContextProperty(key: string, value: unknown) {
    if (this.datadogLoggingEnabled) {
      datadogLogs.setGlobalContextProperty(key, value);
      datadogRum.setGlobalContextProperty(key, value);
    }
  }

  /**
   * Metrics names are alphanumeric, lowercase, seperated by only "." or "_"
   */
  private static normalizeMetricName(metric: string) {
    return metric
      .split('.')
      .map(snakeCase)
      .join('.')
      .replace(/[^a-z0-9_.]/gi, '');
  }

  /*
   * In dev/test environments we should skip sending any metrics
   */
  static shouldSkipSendingMetrics() {
    return !this.environment || (process.env.NODE_ENV !== 'test' && this.isLocalHost());
  }

  static isLocalHost() {
    return /(localhost|127\.0\.0\.1)/.test(window.location.origin);
  }

  private static deferAnalyticEvent(name: string, properties: Record<string, unknown> = {}) {
    this.unauthorizedAnalyticBuffer.push({ name, properties });
  }

  private static deferMetricEvent(
    type: string,
    metric: string,
    value: number,
    additionalTags: string[] = [],
  ) {
    this.unauthorizedMetricBuffer.push({ type, metric, value, additionalTags });
  }

  private static async flushUnauthorizedAnalyticBuffer(): Promise<void> {
    if (!this.validateAccessToken()) {
      setTimeout(() => {
        void this.flushUnauthorizedAnalyticBuffer();
      }, FLUSH_DEFERRED_TIMEOUT_MS);
      return;
    }

    try {
      if (this.unauthorizedMetricBuffer.length) {
        await Promise.all(
          this.unauthorizedMetricBuffer.map(({ type, metric, value, additionalTags }) =>
            this.reportMetric(type, metric, value, additionalTags),
          ),
        );
      }
    } catch {
      // eslint-disable-next-line no-console
      console.warn('[Telemetry] Failed to flush unauthorized metric buffer');
    }

    try {
      if (this.unauthorizedAnalyticBuffer.length) {
        await Promise.all(
          this.unauthorizedAnalyticBuffer.map(({ name, properties }) =>
            this.analyticsEvent(name, properties),
          ),
        );
      }
    } catch {
      // eslint-disable-next-line no-console
      console.warn('[Telemetry] Failed to flush unauthorized analytics buffer');
    }

    // We don't retry failed metrics or analytics flushes. Just clear the buffer.
    this.unauthorizedMetricBuffer = [];
    this.unauthorizedAnalyticBuffer = [];
  }

  /**
   * Report a metric to CTW
   */
  static async reportMetric(
    type: string,
    metric: string,
    value: number,
    additionalTags: string[] = [],
  ) {
    if (this.shouldSkipSendingMetrics()) {
      return;
    }

    // Validate token before checking that an access token exists
    this.validateAccessToken();

    if (!this.accessToken) {
      this.deferMetricEvent(type, metric, value, additionalTags);
      return;
    }

    const name = this.normalizeMetricName(metric);
    const tags = compact([
      `service:${this.ddConfig?.service ? this.ddConfig.service : 'ctw-component-library'}`,
      `env:${this.environment}`,
      this.ehr ? `ehr:${this.ehr}` : undefined,
      ...additionalTags,
    ]);

    try {
      await ctwFetch(`${getCTWBaseUrl(this.environment)}/report/metric`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
        body: JSON.stringify({ name, type, tags, value }),
        mode: 'cors',
      });
    } catch (error) {
      Telemetry.logError(error as Error, {
        errorMessage: 'error reporting metric',
        metric: {
          type,
          tags,
          value,
        },
      });
    }
  }

  /**
   * Report User analytic events
   */
  static async analyticsEvent(eventName: string, eventProperties: Record<string, unknown> = {}) {
    if (this.shouldSkipSendingMetrics()) {
      return;
    }

    if (!this.accessToken) {
      this.deferAnalyticEvent(eventName, eventProperties);
      return;
    }

    try {
      const analyticEvent = {
        event: eventName,
        metadata: {
          ...eventProperties,
          ehr: this.ehr || undefined,
          version: this.ddConfig?.version,
        },
      };

      await ctwFetch(`${getCTWBaseUrl(this.environment)}/report/analytic`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.accessToken}`,
        },
        // Base64 encode the event name and user information
        body: JSON.stringify(analyticEvent),
        mode: 'cors',
      });
    } catch {
      // Nothing to do here.
    }
  }

  static countMetric(name: string, value = 1, tags: string[] = []) {
    return Telemetry.reportMetric('increment', name, value, tags);
  }

  static histogramMetric(name: string, value: number, tags: string[] = []) {
    return Telemetry.reportMetric('histogram', name, value, tags);
  }

  static timeMetric(metric: string, tags: string[] = []) {
    const start = new Date().getTime();

    // Callback should not return a promise or throw errors to the consumer
    return function endTiming() {
      const end = new Date().getTime();
      return Telemetry.reportMetric('timing', metric, end - start, tags);
    };
  }

  static processHTMLEvent(
    target: HTMLElement,
    telemetryKey: TelemetryEventKey,
    explicitTargetName?: string,
  ) {
    let eventTarget: HTMLElement | null = null;

    if (explicitTargetName) {
      eventTarget = target;
    } else {
      let nextTarget: HTMLElement | null = target;
      let depth = 5;
      while (!eventTarget && depth > 0 && nextTarget) {
        if (nextTarget.dataset[telemetryKey]) {
          eventTarget = nextTarget;
        }
        nextTarget = nextTarget.parentElement;
        depth -= 1;
      }
    }

    const targetName = eventTarget?.dataset[telemetryKey] ?? explicitTargetName;
    if (eventTarget && targetName) {
      this.trackInteraction(targetName);
    }
  }

  private static closestHTMLElement(target: Element | Node | null): HTMLElement | null {
    if (!target || target instanceof HTMLElement) {
      return target;
    }
    if (target.parentElement instanceof HTMLElement) {
      return target.parentElement;
    }
    return this.closestHTMLElement(target.parentNode);
  }

  static async reportActiveSession() {
    // Return if the session is already active or we don't need to report
    if (Session.isActive() || this.shouldSkipSendingMetrics()) {
      return;
    }

    // Set last active timestamp, so we don't report the same session twice
    const user = getClaims(this.accessToken);
    if (!isEmpty(user)) {
      Session.setSessionUserId(user[AUTH_USER_ID]);
      Session.setSessionLastActiveTimestamp();
    } else {
      // Clear the session last active timestamp because we didn't report it
      Session.clearSessionLastActiveTimestamp();
    }
  }
}

/**
 * Wrapper to time a function
 *
 * @example
 * ```
 * withTimerMetric(async (requestContext, patient) => {
 *   const data = await getData(requestContext, patient);
 *   return applyTransform(data);
 * }, "req.data_transform")
 * ```
 */
export const withTimerMetric =
  <Args extends unknown[], Return>(
    fn: (...args: Args) => Promise<Return>,
    name: string,
    tags: string[] = [],
  ) =>
  async (...args: Args) => {
    let sendMetric;
    try {
      sendMetric = Telemetry.timeMetric(name, tags);
      return await fn(...args);
    } catch (e) {
      sendMetric = undefined;
      throw e;
    } finally {
      void sendMetric?.();
    }
  };
