import { tw } from '@ctw/shared/utils/tailwind';
import './pagination';
import { Button } from '@ctw/shared/components/button';

export const DEFAULT_PAGE_SIZE = 10;

export type ExpandListProps = {
  total: number;
  count: number;
  changeCount: (amount: number) => void;
};

export const ExpandList = ({ total, count, changeCount }: ExpandListProps) => {
  const allShown = count >= total || total === 0;
  const hasPages = total > DEFAULT_PAGE_SIZE;

  return (
    <div className={tw`pagination !mt-1 sm:!mt-2`}>
      <div className={tw`text-sm text-gray-600`}>
        Showing <span className={tw`font-medium`}>{Math.min(count, total)}</span> of{' '}
        <span className={tw`font-medium`}>{total}</span> records
      </div>

      {(!allShown || hasPages) && (
        <div className={tw`leading-5`}>
          {!allShown && (
            <Button
              type="button"
              variant="clear-link"
              className={tw`whitespace-nowrap`}
              onClick={() => changeCount(total)}
            >
              Show All
            </Button>
          )}

          {allShown && hasPages && (
            <Button
              type="button"
              variant="clear-link"
              className={tw`whitespace-nowrap`}
              onClick={() => changeCount(DEFAULT_PAGE_SIZE)}
            >
              Clear
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
