import { Query, QueryCache, QueryClient } from '@tanstack/react-query';
import { RequestError } from './error';
import { ReactQueryError } from './react-query-error';
import { sleep } from './sleep';
import { Telemetry } from './telemetry';

export type UseQueryResultBasic<T> = {
  data: T;
  isLoading: boolean;
};

function logOnError(err: unknown, query: Query) {
  const error = new ReactQueryError(query, err);
  Telemetry.logError(error);
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      // NOTE: Stale time must be kept less than what we'd expect auth token
      // expiration to be. This ensures that if the token expires and we go to
      // fetch a fresh one, we don't get a stale token from react-query cache.
      staleTime: 1000 * 30, // 30s.
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: logOnError,
  }),
});

export const CTW_REQUEST_HEADER = { 'Zus-Request-Source': 'component-library' };

export type CTWRequestInit = {
  headers?: Record<string, string>;
} & Omit<RequestInit, 'headers'>;

const xmlTypes = ['/xml', '/xhtml+xml', 'application/xml', 'text/xml'];
const pdfTypes = ['/pdf'];
const tiffTypes = ['/tif', '/tiff'];
const webImageTypes = ['/jpg', '/jpeg', '/png', '/apng', '/avif', '/gif', '/webp', '/svg+xml'];

export async function ctwFetch(input: RequestInfo | URL, init?: CTWRequestInit) {
  const headers = init?.headers ?? {};

  const newInit: CTWRequestInit = init ?? {};
  newInit.headers = {
    ...CTW_REQUEST_HEADER,
    ...headers,
  };

  const fetchResponse = await fetch(input, newInit);
  await assertResponseOK(fetchResponse);

  return {
    data: await getResponseContent(fetchResponse),
    headers: fetchResponse.headers,
  };
}

export const getResponseContent = async (response: Response) => {
  const contentType = response.headers.get('content-type');
  if (contentType?.includes('json')) {
    return response.json();
  }
  if (contentType?.includes('text')) {
    return response.text();
  }
  if (
    [...xmlTypes, ...pdfTypes, ...tiffTypes, ...webImageTypes].some(
      (type) => contentType?.includes(type),
    )
  ) {
    return response.blob();
  }
  return response.json();
};

export async function assertResponseOK(response: Awaited<Response>) {
  if (!response.ok) {
    let data;
    let errorMsg = response.statusText;
    const contentType = response.headers.get('content-type');
    if (contentType?.includes('json')) {
      data = await response.json();
      errorMsg = JSON.stringify(data);
    }

    // try to get error message directly from DA response
    if (data && 'detail' in data) {
      throw new Error(data.detail);
    }

    switch (response.status) {
      case 401:
        throw new RequestError(response, errorMsg);
      case 403:
        throw new RequestError(response, errorMsg);
      case 404:
        throw new RequestError(response, errorMsg);
      default:
        throw new RequestError(response, response.statusText);
    }
  }
}

export const retryWithExponentialBackoff = async <T>(
  fn: () => Promise<T>,
  retries: number,
  baseDelay: number,
  attempt = 1,
): Promise<T> => {
  try {
    return await fn();
  } catch (error) {
    if (attempt > retries) {
      throw error;
    }
    await sleep(baseDelay * 2 ** attempt);
    return retryWithExponentialBackoff(fn, retries, baseDelay, attempt + 1);
  }
};
