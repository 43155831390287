import { ReactNode } from 'react';
import { DrawerContext } from './drawer-context';
import { useDrawerState } from './drawer-hooks';
import { ModalContext } from './modal-context';
import { useModalState } from './modal-hooks';

// NOTE: This is a single provider that provides both drawer and modal providers.
// We use a seperate providers for modals & drawers for two reasons:
//  1. Allows us to have both a drawer and a modal open at
//     the same time via the providers.
//  2. Allows modals and drawer interfaces to diverge a bit if needed.
// We use this single provider to provide both so that the actual
// drawer and modal components are both nested underneath the providers,
// this allows modals to open drawers and drawers to open modals.

interface ProviderProps {
  children: ReactNode;
}

export function OverlayProvider({ children }: ProviderProps) {
  const { modalComponent, modalState } = useModalState();
  const { drawerComponent, drawerState } = useDrawerState();

  return (
    <ModalContext.Provider value={modalState}>
      <DrawerContext.Provider value={drawerState}>
        {modalComponent}
        {drawerComponent}
        {children}
      </DrawerContext.Provider>
    </ModalContext.Provider>
  );
}
