import { compact, flatten, some } from 'lodash';
import { useEffect, useState } from 'react';
import { usePatientDiagnosticReports } from './diagnostic-report';
import { TimelineEventModel } from './models/timeline-event';
import { usePatientEncounters } from '@ctw/shared/hooks/use-encounters';
import { applySorts } from '@ctw/shared/utils/sort';

export function useTimelineEvents() {
  const [timelineEvents, setTimelineEvents] = useState<TimelineEventModel[]>();
  const patientEncountersQuery = usePatientEncounters();
  const diagnosticReportQuery = usePatientDiagnosticReports();

  const queries = [patientEncountersQuery, diagnosticReportQuery];

  useEffect(() => {
    const models = compact(
      flatten(queries.map((query) => query.data?.map((m) => new TimelineEventModel(m)))),
    );

    setTimelineEvents(
      applySorts(models, [
        { dir: 'desc', key: 'date', isDate: true },
        { dir: 'desc', key: 'type' },
      ]),
    );
    // Disabling because including queries will cause infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientEncountersQuery.data, diagnosticReportQuery.data]);

  const isLoading = some(queries, 'isLoading');
  const isFetching = some(queries, 'isFetching');
  const isError = some(queries, 'isError');

  return {
    isFetching,
    isLoading,
    isError,
    data: timelineEvents ?? [],
  };
}
