import { IVariant, useFlagsStatus, useUnleashClient } from '@unleash/proxy-client-react';
import { z, ZodRawShape } from 'zod';

export type FeatureVariant = Partial<IVariant> & {
  ready: boolean;
};

export function useFeatureVariant(name: string): FeatureVariant {
  const client = useUnleashClient();
  const status = useFlagsStatus();

  // return "ready" if unleash failed to load flags
  if (status.flagsError) {
    return { ready: true };
  }

  // return "not ready" if flags are still being fetched
  if (!status.flagsReady && !status.flagsError) {
    return { ready: false };
  }

  // return "ready" /w a variant
  const variant = client.getVariant(name);
  return { ...variant, ready: true };
}

export function processVariantPayload<K extends ZodRawShape, T extends z.ZodObject<K>>(
  variant: FeatureVariant,
  payloadSchema: T,
): z.infer<T> | undefined {
  if (
    variant.ready &&
    variant.enabled &&
    variant.payload?.type === 'json' &&
    variant.payload.value
  ) {
    try {
      const result = payloadSchema.safeParse(JSON.parse(variant.payload.value));
      if (!result.success) {
        // eslint-disable-next-line no-console
        console.error(result.error);
      } else {
        return result.data;
      }
    } catch (e) {
      // This guards the case where JSON.parse throws an error. This shouldn't happen as unleash form requires valid json.
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }

  return undefined;
}
