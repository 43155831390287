import { twx } from '@ctw/shared/utils/tailwind';

export function PdfIcon({ className }: { className?: string }) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="3.75 1.5 12.5 17.5"
      xmlns="http://www.w3.org/2000/svg"
      className={twx(className)}
    >
      <path d="M6 2H11V6C11 6.5625 11.4375 7 12 7H16V16C16 17.125 15.0938 18 14 18H6C4.875 18 4 17.125 4 16V4C4 2.90625 4.875 2 6 2ZM12 2L16 6H12V2ZM6 9C5.71875 9 5.5 9.25 5.5 9.5V12V13.5C5.5 13.7812 5.71875 14 6 14C6.25 14 6.5 13.7812 6.5 13.5V12.5H6.75C7.6875 12.5 8.5 11.7188 8.5 10.75C8.5 9.8125 7.6875 9 6.75 9H6ZM6.75 11.5H6.5V10H6.75C7.15625 10 7.5 10.3438 7.5 10.75C7.5 11.1875 7.15625 11.5 6.75 11.5ZM9 9.5V13.5C9 13.7812 9.21875 14 9.5 14H10.25C11.0625 14 11.75 13.3438 11.75 12.5V10.5C11.75 9.6875 11.0625 9 10.25 9H9.5C9.21875 9 9 9.25 9 9.5ZM10 13V10H10.25C10.5 10 10.75 10.25 10.75 10.5V12.5C10.75 12.7812 10.5 13 10.25 13H10ZM13 9C12.7188 9 12.5 9.25 12.5 9.5V11.5V13.5C12.5 13.7812 12.7188 14 13 14C13.25 14 13.5 13.7812 13.5 13.5V12H14.5C14.75 12 15 11.7812 15 11.5C15 11.25 14.75 11 14.5 11H13.5V10H14.5C14.75 10 15 9.78125 15 9.5C15 9.25 14.75 9 14.5 9H13Z" />
    </svg>
  );
}
