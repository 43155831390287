import { faClipboardList } from '@fortawesome/free-solid-svg-icons';
import { isEmpty, orderBy } from 'lodash';
import { useConditionDetailsDrawer } from './helpers/details';
import { lastUpdatedMostRecentConditionSort } from './helpers/sorts';
import { statusView } from './helpers/views';
import { applyFilters, OverviewFilter } from '../overview/filters';
import {
  RenderInfoBadge,
  RenderSyncedWithRecordIcon,
  ResourceTitleColumn,
} from '../resource/helpers/resource-title-column';
import { usePatientConditionsAll } from '@ctw/shared/api/fhir/conditions';
import { formatDate } from '@ctw/shared/api/fhir/formatters';
import { ConditionModel } from '@ctw/shared/api/fhir/models';
import { withErrorBoundaryResourceCard } from '@ctw/shared/components/overview-error-boundary';
import { ResourceOverviewCardGrouped } from '@ctw/shared/content/overview/resource-overview-card-grouped';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { tw } from '@ctw/shared/utils/tailwind';

const TITLE = 'Conditions';
const FOOTER_TITLE = 'All Conditions';

export const filters = (): OverviewFilter<ConditionModel>[] => [
  {
    key: 'conditions',
    label: 'chronic conditions or acute conditions with hcc',
    description: 'chronic conditions or acute conditions with hcc',
    predicate: (r: ConditionModel) =>
      r.chronic?.code === 'C' || (r.chronic?.code === 'A' && !isEmpty(r.hccEnrichment)),
  },
];

export type PatientConditionOverviewProps = {
  onSeeAllResources: () => void;
  onAfterDetailsDrawerClosed?: () => void;
  conditionIdForDetailsDrawer?: string;
  onOpenDetailsDrawer?: (condition: ConditionModel) => void;
};

export const PatientConditionOverviewComponent = ({
  onSeeAllResources,
  onAfterDetailsDrawerClosed,
  onOpenDetailsDrawer,
}: PatientConditionOverviewProps) => {
  const { current } = statusView;
  const openDetails = useConditionDetailsDrawer({
    enableDismissAndReadActions: false,
    onAfterClosed: onAfterDetailsDrawerClosed,
    onOpen: onOpenDetailsDrawer,
  });

  const { data, isLoading } = usePatientConditionsAll();

  const { data: filteredData } = useFilteredSortedData({
    defaultSort: lastUpdatedMostRecentConditionSort,
    viewOptions: [current],
    records: data,
    defaultView: current.display,
  });

  const filteredSortedData = orderBy(
    applyFilters(filteredData, filters()),
    (d) => d.display,
    'asc',
  );

  return (
    <ResourceOverviewCardGrouped
      groupBy={(record) => String(record.ccsChapter)}
      onRowClick={openDetails}
      data={filteredSortedData}
      emptyStateMessage={
        data.length === 0 ?
          "We didn't find any condition records for this patient."
        : 'There are no chronic conditions or acute HCCs for this patient.'
      }
      footerCTA={{
        label: FOOTER_TITLE,
        onClick: onSeeAllResources,
      }}
      title={TITLE}
      helpText="Chronic Conditions and acute HCCs"
      loading={isLoading}
      headerIcon={faClipboardList}
      telemetryTargetName="conditions_overview"
      testId="conditions-overview"
      renderResource={(record: ConditionModel) => (
        <div className={tw`flex justify-between text-left`}>
          <div className={tw`w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] text-sm`}>
            {RenderSyncedWithRecordIcon(record.syncedWithRecord)}
          </div>
          <div className={tw`w-full flex-grow text-sm`}>
            <ResourceTitleColumn
              className={tw`w-full`}
              title={
                <>
                  {record.display}{' '}
                  {record.recordedDate && (
                    <span className={tw`whitespace-nowrap text-xs font-normal text-content-light`}>
                      [Last Dx {formatDate(record.recordedDate, 'yyyy')}]
                    </span>
                  )}
                </>
              }
              renderIcon={RenderInfoBadge(
                !isEmpty(record.hccEnrichment) ? 'HCC' : undefined,
                '',
                !isEmpty(record.hccEnrichment) ? 'Risk-adjusting condition' : undefined,
              )}
            />
          </div>
        </div>
      )}
    />
  );
};

export const PatientConditionOverview = withErrorBoundaryResourceCard({
  component: PatientConditionOverviewComponent,
  name: 'PatientConditionOverview',
  title: TITLE,
  footerTitle: FOOTER_TITLE,
  tabToNavigateTo: 'conditions-all',
});
