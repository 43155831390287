import { VitalsBucket } from '@ctw/shared/api/fhir/models/vitals-bucket';
import { RotatedTableRows } from '@ctw/shared/components/rotated-table/rotated-table';
import { ResponsiveSourceDocumentLink } from '@ctw/shared/content/CCDA/responsive-source-document-link';
import { RenderSyncedWithRecordIcon } from '@ctw/shared/content/resource/helpers/resource-title-column';
import { tw } from '@ctw/shared/utils/tailwind';

export function getVitalRows(vitals: VitalsBucket[] = []): RotatedTableRows<VitalsBucket>[] {
  const rows: RotatedTableRows<VitalsBucket>[] = [
    { title: 'Vital', dataIndex: 'date' },
    {
      title: 'Blood Pressure',
      shortTitle: 'BP',
      render: (bucket) =>
        bucket.bloodPressure &&
        renderVitalWithCheckmark(bucket.bloodPressure, bucket.hasAnyFirstParty),
    },
    {
      title: 'Pulse',
      shortTitle: 'HR',
      render: (bucket) =>
        bucket.pulse && renderVitalWithCheckmark(bucket.pulse, bucket.hasAnyFirstParty),
    },
    {
      title: 'Temperature',
      shortTitle: 'Temp',
      render: (bucket) =>
        bucket.temperature && renderVitalWithCheckmark(bucket.temperature, bucket.hasAnyFirstParty),
    },
    {
      title: 'Respiratory Rate',
      shortTitle: 'RR',
      render: (bucket) =>
        bucket.respiratoryRate &&
        renderVitalWithCheckmark(bucket.respiratoryRate, bucket.hasAnyFirstParty),
    },
    {
      title: 'Oxygen Saturation',
      shortTitle: 'SpO2',
      render: (bucket) =>
        bucket.oxygenSaturation &&
        renderVitalWithCheckmark(bucket.oxygenSaturation, bucket.hasAnyFirstParty),
    },
    {
      title: 'Height/Length',
      shortTitle: 'Ht/Lt',
      render: (bucket) =>
        bucket.height && renderVitalWithCheckmark(bucket.height, bucket.hasAnyFirstParty),
    },
    {
      title: 'Weight',
      shortTitle: 'Wt',
      render: (bucket) =>
        bucket.weight && renderVitalWithCheckmark(bucket.weight, bucket.hasAnyFirstParty),
    },
    {
      title: 'BMI',
      render: (bucket) =>
        bucket.bmi && renderVitalWithCheckmark(bucket.bmi, bucket.hasAnyFirstParty),
    },
  ];

  // Only add these if they are present in any of the vitals.
  if (vitals.some((vital) => vital.headCircumference !== undefined)) {
    rows.push({
      title: 'Head Circumference',
      shortTitle: 'HC',
      render: (bucket) =>
        bucket.headCircumference &&
        renderVitalWithCheckmark(bucket.headCircumference, bucket.hasAnyFirstParty),
    });
  }
  if (vitals.some((vital) => vital.lmp !== undefined)) {
    rows.push({
      title: 'LMP',
      render: (bucket) =>
        bucket.lmp && renderVitalWithCheckmark(bucket.lmp, bucket.hasAnyFirstParty),
    });
  }
  if (vitals.some((vital) => vital.pain !== undefined)) {
    rows.push({
      title: 'Pain',
      render: (bucket) =>
        bucket.pain && renderVitalWithCheckmark(bucket.pain, bucket.hasAnyFirstParty),
    });
  }

  rows.push(
    {
      title: 'Source Document',
      shortTitle: 'Source Doc',
      render: (vitalBucket: VitalsBucket) => (
        <ResponsiveSourceDocumentLink
          binaryId={vitalBucket.binaryId}
          telemetryTarget="vital"
          text="Source Doc"
          showContentTypeIcon={false}
        />
      ),
      greyed: true,
    },
    {
      title: 'Encounter Type',
      shortTitle: 'Enc Type',
      dataIndex: 'typeDisplay',
      greyed: true,
    },
    {
      title: 'Location',
      dataIndex: 'location',
      greyed: true,
    },
  );

  return rows;
}

const renderVitalWithCheckmark = (
  vitalDisplay: {
    display: string;
    firstParty: boolean;
  },
  columnHasCheckmarks: boolean,
) => (
  <div className={tw`flex justify-between space-x-1.5`}>
    {columnHasCheckmarks && (
      <div className={tw`w-[1.5rem] min-w-[1.5rem] max-w-[1.5rem] text-sm`}>
        {RenderSyncedWithRecordIcon(vitalDisplay.firstParty)}
      </div>
    )}
    <span>{vitalDisplay.display}</span>
  </div>
);
