import { cloneDeep } from 'lodash';
import { FHIRModel } from './models/fhir-model';

export interface Summary extends FHIRModel<fhir4.Resource> {
  syncedWithRecord: boolean;
}

export type ResourceBuilderInfo = {
  id?: string;
  meta?: { tag?: { system?: string; code?: string }[] };
};

export function getLensSummariesWithSyncedWithRecord<
  T extends Summary,
  U extends ResourceBuilderInfo,
>(
  summaries: T[],
  builderItems: U[],
  backupSyncedWithRecordCheck?: (summary: T, builderResources: U[]) => boolean, // Fallback to match resources that haven't been aggregated yet.
): T[] {
  const summaryItems = cloneDeep(summaries);
  const idsInBuilder = builderItems.map((builder) => builder.id);

  summaryItems.forEach((summary) => {
    const hasMatch: boolean =
      // Check if aggregated from ids match any builder ids.
      summary.aggregatedFromIds.some((aggregatedFromId) =>
        idsInBuilder.find((idInBuilder) => idInBuilder === aggregatedFromId),
      ) ||
      (backupSyncedWithRecordCheck ? backupSyncedWithRecordCheck(summary, builderItems) : false);
    // eslint-disable-next-line no-param-reassign
    summary.syncedWithRecord = hasMatch;
  });

  return summaryItems;
}
