import { faClock } from '@fortawesome/free-regular-svg-icons';
import {
  faClipboardCheck,
  faClipboardList,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { intersectionWith } from 'lodash/fp';
import { ConditionViewOptions } from './views';
import {
  ConditionModel,
  ConditionStatuses,
  conditionStatuses,
  outsideConditionStatuses,
} from '@ctw/shared/api/fhir/models/condition';
import { FilterItem, FilterOptionTag } from '@ctw/shared/components/filter-bar/filter-bar-types';
import { dismissFilter } from '@ctw/shared/content/resource/filters';
import { PredicateMap } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { uniqueValues } from '@ctw/shared/utils/filters';

const conditionHccPredicate = (selected: boolean, item: ConditionModel): boolean =>
  !selected || Boolean(item.hccEnrichment);

export const conditionFilterPredicates: PredicateMap<FilterOptionTag['predicate']> = {
  showOnlyHcc: (selected: boolean, item: object): boolean =>
    conditionHccPredicate(selected, item as ConditionModel),
};

export function conditionFilters(
  conditions: ConditionModel[],
  showAllStatuses: boolean,
  onlyShowOptionsForStatus?: ConditionViewOptions,
): FilterItem[] {
  const filters: FilterItem[] = [
    dismissFilter,
    {
      key: 'showOnlyHcc',
      type: 'tag',
      belowDivider: true,
      display: ({ listView }) => (listView ? 'Show HCCs Only' : 'HCCs Only'),
      predicate: conditionFilterPredicates.showOnlyHcc,
      icon: faEye,
      toggleDisplay: 'show all',
      toggleIcon: faEyeSlash,
    },
  ];

  let statusesToShow: ConditionStatuses[] =
    showAllStatuses ?
      [...conditionStatuses.filter((status) => status !== 'Entered in Error')]
    : [...outsideConditionStatuses];

  if (onlyShowOptionsForStatus) {
    switch (onlyShowOptionsForStatus) {
      case 'Current':
        statusesToShow = intersectionWith((a, b) => a === b, statusesToShow, [
          'Active',
          'Pending',
          'Unknown',
        ]);
        break;
      case 'Past':
        statusesToShow = intersectionWith((a, b) => a === b, statusesToShow, [
          'Inactive',
          'Refuted',
        ]);
        break;
      default:
        break;
    }
  }

  filters.push(
    {
      key: 'displayStatus',
      type: 'checkbox',
      icon: faClipboardCheck,
      display: 'Status',
      // Create new array as these other ones are readonly.
      values: statusesToShow,
    },
    {
      key: 'ccsChapter',
      type: 'checkbox',
      icon: faClipboardList,
      display: 'Category',
      values: uniqueValues(conditions, 'ccsChapter'),
    },
    {
      key: 'typeDisplay',
      type: 'checkbox',
      icon: faClock,
      display: 'Type',
      values: uniqueValues(conditions, 'typeDisplay').sort((a, b) => {
        // We have to sort these filters in a specific order. Acute, Chronic, Chronic and Acute...
        const scores: Record<string, number | undefined> = {
          acute: 4,
          chronic: 3,
          'chronic and acute': 2,
        };
        const aScore = scores[a.toLowerCase()] ?? 0;
        const bScore = scores[b.toLowerCase()] ?? 0;
        if (aScore > bScore) {
          return -1;
        }
        if (bScore > aScore) {
          return 1;
        }
        return 0;
      }),
    },
  );

  return filters;
}
