import { ServiceRequest } from 'fhir/r4';
import { gql } from 'graphql-request';
import { fragmentServiceRequest } from './fragments';
import { GraphqlConnectionNode, GraphqlPageInfo } from '@ctw/shared/api/fqs/client';

export interface ServiceRequestConnection {
  pageInfo: GraphqlPageInfo;
  edges: GraphqlConnectionNode<ServiceRequest>[];
}

export interface ServiceRequestGraphqlResponse {
  ServiceRequestConnection: ServiceRequestConnection;
}

export const ServiceRequestQuery = gql`
  ${fragmentServiceRequest}

  query ServiceRequests($upid: ID!) {
    ServiceRequestConnection(upid: $upid) {
      edges {
        node {
          ...ServiceRequest
        }
      }
    }
  }
`;
