import { compact, find, isArray, uniq } from 'lodash/';
import { Filter } from '@ctw/shared/components/filter-bar/filter-bar-types';

export type FilterFunc<T extends object> = (data: T[]) => T[];
export type FilterEntry<T extends object> = Filter | FilterFunc<T>;

// By default filters out entries with isHidden set to true
// Unless showHidden filter is present.
export const filterHidden = <T extends object>(data: T[], filters?: (Filter | FilterFunc<T>)[]) => {
  if (!filters) return data;

  const filterItems = filters.filter((f) => typeof f !== 'function') as Filter[];
  const showHidden = find(filterItems, { key: 'showHidden' })?.selected;

  return data.filter((entry) => {
    const isHidden = Boolean(entry['isHidden' as keyof T]);

    return showHidden || !isHidden;
  });
};

export const applyFilters = <T extends object>(data: T[], filters?: (Filter | FilterFunc<T>)[]) => {
  if (!filters) return data;
  const filterFuncs = filters.filter((f) => typeof f === 'function') as FilterFunc<T>[];
  const filterItems = filters.filter((f) => typeof f !== 'function') as Filter[];

  const filteredData = filterFuncs.reduce((acc, filterFunc) => filterFunc(acc), data);

  return filteredData.filter((entry) =>
    Object.entries(filterItems).every(([_, filterItem]) => {
      const targetFilter = String(entry[filterItem.key as keyof T]);

      switch (filterItem.type) {
        case 'checkbox':
          if (isArray(filterItem.selected)) {
            if (filterItem.predicate) {
              if (filterItem.selected.length === 0) {
                return true;
              }
              return filterItem.predicate(filterItem.selected, entry);
            }
            const filteredList = filterItem.selected.filter((item) =>
              compact(uniq(data.map((c) => c[filterItem.key as keyof T]))).includes(
                item as T[keyof T],
              ),
            );
            return filteredList.includes(targetFilter);
          }
          break;
        case 'select':
          return targetFilter === filterItem.selected;
        case 'tag':
          if (filterItem.predicate) {
            return filterItem.predicate(filterItem.selected, entry);
          }
          break;
        default:
      }
      return true;
    }),
  );
};

export function uniqueValues<T extends object>(data: T[], key: keyof T): string[] {
  return compact(uniq(data.map((d) => String(d[key]))));
}
