import { faHandHoldingMedical } from '@fortawesome/free-solid-svg-icons';
import { mostRecentGaps, usePatientCareGaps } from '@ctw/shared/api/fhir/care-gaps';
import { withErrorBoundaryResourceCard } from '@ctw/shared/components/overview-error-boundary';
import { OverviewCard } from '@ctw/shared/components/overview-card';
import { tw } from '@ctw/shared/utils/tailwind';
import { CareGap } from '@ctw/shared/content/care-gaps/care-gap-summary';

const CareGapsOverviewCardComponent = () => {
  const { data: allGaps, isLoading } = usePatientCareGaps();

  const uniqueGaps = mostRecentGaps(allGaps || []);
  return (
    <OverviewCard
      headerIcon={faHandHoldingMedical}
      title="Care Gaps"
      testId="care-gaps-card"
      loading={isLoading}
      empty={uniqueGaps.length === 0}
      emptyStateMessage="This patient has no currently open gaps in care."
    >
      {uniqueGaps.map((gap, i) => (
        // We're using a bottom border as a divider between gaps, but we don't need it on the last one.
        <div className={tw`border-b border-divider-main py-3 last:border-none`} key={gap.title}>
          <CareGap gap={gap} testId={`care-gap-${i}`} />
        </div>
      ))}
    </OverviewCard>
  );
};

export const CareGapsCard = withErrorBoundaryResourceCard({
  component: CareGapsOverviewCardComponent,
  name: 'CareGapsCard',
  title: 'Care Gaps',
  footerTitle: 'Care Gaps',
});
