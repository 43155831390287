import { Coding } from 'fhir/r4';
import { compact, find, toUpper, uniq } from 'lodash';
import { uniqBy, capitalize, toLower } from 'lodash/fp';
import { DocumentModel } from './document';
import { FHIRModel } from './fhir-model';
import { PatientModel } from './patient';
import { PractitionerModel } from './practitioner';
import { codeableConceptLabel } from '../codeable-concept';
import { formatDateISOToLocal } from '../formatters';
import { getBinaryIDFromProvenance } from '../provenance';
import { findReference } from '../resource-helper';
import { LENS_EXTENSION_RELATED_BINARIES, SYSTEM_ZUS_CREATED_AT } from '../system-urls';
import { ResourceMap } from '../types';
import { isNullFlavorSystem } from '@ctw/shared/api/fhir/mappings/null-flavor';
import { isSectionDocument } from '@ctw/shared/content/document/helpers/filters';
import { isRenderableBinary } from '@ctw/shared/content/resource/helpers/filters';
import { ConditionModel } from '@ctw/shared/api/fhir/models/condition.ts';

export class EncounterModel extends FHIRModel<fhir4.Encounter> {
  kind = 'Encounter' as const;

  public docsAndNotes: DocumentModel[] = [];

  public provenance: fhir4.Provenance[];

  public relatedEncounter: EncounterModel | undefined;

  // Used by summary resources to indicate there's a matching
  // builder condition for this summary.
  public syncedWithRecord = false;

  constructor(
    resource: fhir4.Encounter,
    provenance: fhir4.Provenance[],
    includedResources?: ResourceMap,
    basics?: fhir4.Basic[],
  ) {
    super(resource, includedResources, basics);
    this.provenance = provenance;
  }

  get binaryIds(): string[] {
    const preLensBinaryId = getBinaryIDFromProvenance(this.provenance);
    const lensRelatedBinariesExt = find(this.resource.extension, {
      url: LENS_EXTENSION_RELATED_BINARIES,
    });
    const lensBinaryIds = lensRelatedBinariesExt?.extension?.map(
      (binaryExtension) => binaryExtension.valueReference?.reference?.split('/').pop(),
    );
    return compact([preLensBinaryId].concat(lensBinaryIds));
  }

  setClinicalNotesFromDocumentPool(pool: DocumentModel[]) {
    this.docsAndNotes = this.findNotesFromDocumentPool(pool);
  }

  // Get docs and notes from a pool of all documents for that patient (we can't query by encounter)
  private findNotesFromDocumentPool(documentPool: DocumentModel[] = []) {
    const binaryToDocs = new Map<string, DocumentModel[]>();
    documentPool
      .filter((d) => {
        // If the doc is linked with this encounter or is in our list of binaryIds, add it to our list
        const docReferencesEncounter =
          d.resource.context?.encounter?.[0]?.reference === `Encounter/${this.id}`;
        return (
          (d.binaryId && isRenderableBinary(d.resource) && this.binaryIds.includes(d.binaryId)) ||
          docReferencesEncounter
        );
      })
      .forEach((d) => {
        binaryToDocs.set(
          d.binaryId as string,
          (binaryToDocs.get(d.binaryId as string) ?? []).concat(d),
        );
      });

    const docsAndNotes: DocumentModel[] = [];
    binaryToDocs.forEach((docs, binaryId) => {
      if (!binaryId) return;

      const summaryDocs = docs.filter((d) => !isSectionDocument(d));

      const summaryDocOrTitleDoc =
        summaryDocs.length === 1 ? summaryDocs[0] : docs.find((d) => d.title) ?? docs[0];
      docsAndNotes.push(
        new DocumentModel(
          summaryDocOrTitleDoc.resource,
          docs.filter((d) => d.id !== summaryDocOrTitleDoc.id),
        ),
      );
    });
    return docsAndNotes;
  }

  get lastUpdated(): string | undefined {
    return (
      this.resource.meta?.lastUpdated ||
      this.resource.meta?.extension?.find((e) => e.url === SYSTEM_ZUS_CREATED_AT)?.valueDateTime
    );
  }

  get patient(): PatientModel | undefined {
    const patient = findReference('Patient', undefined, undefined, this.resource.subject);
    return patient ? new PatientModel(patient) : undefined;
  }

  // Get the notes, excluding summary documents (sections only)
  get clinicalNotes(): DocumentModel[] {
    return this.docsAndNotes.flatMap((d) => d.sectionDocuments ?? [d]);
  }

  get diagnoses(): string[] {
    // Get unique list of condition coding unique by icd-10-cm code, falling back to display text.
    const diagnosesCodings = uniqBy(
      (coding: Coding = {}) => toUpper(coding.code || coding.display),
      this.resource.diagnosis?.map((encounterDiagnosis): Coding | undefined => {
        const { condition: reference } = encounterDiagnosis;
        const conditionResource = findReference('Condition', undefined, undefined, reference);

        // If the condition resource was not found, use the diagnosis reference display.
        if (typeof conditionResource === 'undefined') {
          return reference.display ? { display: reference.display } : undefined;
        }

        // Prefer the icd-10-cm coding, falling back to `condition.code.text` for display.
        const coding = new ConditionModel(conditionResource).icd10CMEnrichments[0] ?? {};
        const display = (coding.display || conditionResource.code?.text) ?? '';

        return { ...coding, display };
      }),
    );

    // Return the display values of the unique codings.
    return compact(diagnosesCodings.map((coding) => coding?.display));
  }

  get dischargeDisposition(): string | undefined {
    return codeableConceptLabel(this.resource.hospitalization?.dischargeDisposition);
  }

  get isHospitalEncounter(): boolean {
    return this.typeCodings.some((c) => c.code === 'IMP' || c.code === 'EMER');
  }

  get location(): string[] {
    return compact(
      this.resource.location?.map((encounterLocation) => {
        const locationResource = findReference(
          'Location',
          undefined,
          undefined,
          encounterLocation.location,
        );
        return locationResource ? locationResource.name : encounterLocation.location.display;
      }),
    );
  }

  get practitionersDisplay(): string | undefined {
    return this.practitioners.join(', ');
  }

  get practitioners(): string[] {
    return compact(
      uniq(
        this.resource.participant?.map((encounterParticipant) => {
          const practitionerResource = findReference(
            'Practitioner',
            undefined,
            undefined,
            encounterParticipant.individual,
          );
          return practitionerResource ?
              new PractitionerModel(practitionerResource).fullName
            : encounterParticipant.individual?.display;
        }),
      ),
    );
  }

  get periodEnd() {
    return formatDateISOToLocal(this.resource.period?.end);
  }

  get periodStart() {
    return formatDateISOToLocal(this.resource.period?.start);
  }

  get dateDisplay() {
    if (this.periodStart !== this.periodEnd && this.periodEnd) {
      return `${this.periodStart} - ${this.periodEnd}`;
    }
    return this.periodStart;
  }

  get reason(): string | undefined {
    const reasons = compact(this.resource.reasonCode?.map((d) => codeableConceptLabel(d)));

    return reasons.length ? reasons.filter((reason) => reason !== 'unknown').join(', ') : undefined;
  }

  get status() {
    return this.resource.status;
  }

  get typeCodings(): Coding[] {
    return compact(this.resource.type?.flatMap((t) => t.coding));
  }

  get locationType() {
    const locations = this.resource.location
      ?.flatMap((l) => {
        const location = findReference('Location', undefined, undefined, l.location);
        return location?.type?.map((t) => codeableConceptLabel(t));
      })
      .map((l) => {
        if (['not indicated', 'unknown', 'noinformation', 'null'].includes(toLower(String(l)))) {
          return 'unknown';
        }
        return l;
      });

    return compact(uniq(locations));
  }

  get locationPhysicalType() {
    const locations = compact(
      this.resource.location?.map((l) => {
        const location = findReference('Location', undefined, undefined, l.location);
        return codeableConceptLabel(location?.physicalType);
      }),
    );

    const uniqueLocations = uniq(locations).filter((l) => {
      switch (l) {
        case 'URGENT_CARE':
          return 'Urgent Care';
        case 'Not Indicated':
        case 'Unknown':
        case 'NoInformation':
        case 'No Information':
        case 'Building':
        case undefined:
          return 'Unknown';
        default:
          return l;
      }
    });

    return uniqueLocations.length ? uniqueLocations[0] : 'Unknown';
  }

  get title() {
    return this.typeDisplay;
  }

  get classDisplay(): string {
    const { system, code = '', display = '' } = this.resource.class;
    if (isNullFlavorSystem(system)) {
      return '';
    }
    const mappings: Record<string, string | undefined> = {
      AMB: 'Ambulatory',
      IMP: 'Inpatient',
      EMER: 'Emergency',
      HH: 'Home Health',
      OBSENC: 'Observation',
      VR: 'Virtual',
      PRENC: 'Pre-Admission',
    };
    return mappings[code.toUpperCase()] ?? capitalize(display);
  }

  get typeDisplay(): string {
    const { text, coding = [] } = this.resource.type?.[0] ?? {};
    const codingDisplays = coding.map((c) => c.display);
    return [text, ...codingDisplays].filter((t) => !!t).join(', ');
  }
}
