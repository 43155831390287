import { PatientModel } from '@ctw/shared/api/fhir/models';
import { useBuilderConfig } from '@ctw/shared/hooks/use-builder-config';
import { twx } from '@ctw/shared/utils/tailwind';

export type PatientMRNProps = {
  patient?: PatientModel;
  className?: string;
};

export function PatientInfoLine({ patient, className }: PatientMRNProps) {
  const { data } = useBuilderConfig();

  if (!patient) return null;

  const mrn = patient.findIdentifier(data?.systemMRN);

  return (
    <div className={twx(className)}>
      {patient.dob}
      {patient.age !== undefined && ` (${patient.age})`}
      {mrn && <span>, {mrn}</span>}
    </div>
  );
}
