import { format } from 'date-fns';
import type { MedicationStatement } from 'fhir/r4';
import { ReactElement } from 'react';
import { MedicationStatementModel } from '@ctw/shared/api/fhir/models/medication-statement';
import { DrawerFormWithFields } from '@ctw/shared/components/form/drawer-form-with-fields';
import { createMedicationStatement } from '@ctw/shared/content/forms/actions/medications';
import {
  getMedicationFormData,
  medicationStatementSchema,
} from '@ctw/shared/content/forms/schemas/medication-schema';
import { useDrawer } from '@ctw/shared/context/drawer-hooks';
import { usePatientContext } from '@ctw/shared/context/patient-provider';

export function useAddMedicationForm() {
  const { openDrawer } = useDrawer();

  return (medication: MedicationStatementModel) => {
    openDrawer({
      trackingMetadata: { action: 'add_medication' },
      component: (props) => <AddNewMedDrawer medication={medication.resource} {...props} />,
    });
  };
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
  children?: ReactElement;
  medication?: MedicationStatement;
};

const AddNewMedDrawer = ({ isOpen, onClose, medication }: Props) => {
  const { patient } = usePatientContext();

  const patientSubjectRef = {
    reference: `Patient/${patient.data?.id}`,
    display: patient.data?.display,
  };

  // Create a MedicationStatementModel that can be used to pre-populate the form appropriately.
  let medStatementModelForFormPopulation: MedicationStatementModel;
  // If we're starting from an existing medication, make sure the subject is set correctly
  if (medication) {
    medStatementModelForFormPopulation = new MedicationStatementModel({
      ...medication,
      subject: patientSubjectRef,
      dateAsserted: format(new Date(), 'yyyy-MM-dd'),
    });
  } else {
    medStatementModelForFormPopulation = new MedicationStatementModel({
      resourceType: 'MedicationStatement',
      status: 'active',
      subject: patientSubjectRef,
      dateAsserted: format(new Date(), 'yyyy-MM-dd'),
    });
  }

  const createMedData = getMedicationFormData(medStatementModelForFormPopulation);

  return (
    <DrawerFormWithFields
      title="Add Medication"
      action={createMedicationStatement}
      errorHeader="Unable to add medication to chart"
      data={createMedData}
      schema={medicationStatementSchema}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
};
