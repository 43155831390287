import { usePatientContext } from '../context/patient-provider';
import { useUser } from '../context/use-user';
import { twx } from '@ctw/shared/utils/tailwind';

export const SUPPORT_FORM_URL = 'https://share.hsforms.com/10NmAPiu7Qd2OXbxAHnseiwcjai8';

export type SupportFormLinkProps = {
  buttonText: string;
  className?: string;
};

export const SupportFormLink = ({
  buttonText,
  className = 'link text-sm',
}: SupportFormLinkProps) => {
  const { patient } = usePatientContext();
  const user = useUser();

  // These property names exactly match the internal names of the HubSpot form fields
  const params = new URLSearchParams();
  if (patient.data && patient.data.id && patient.data.UPID) {
    params.append('patientid', patient.data.id);
    params.append('upid', patient.data.UPID);
  }
  if (user) {
    params.append('builderid', user.builderId);
    params.append('buildername', user.builderName);
    params.append('email', user.email);
    params.append('userid', user.userId);
  }

  return (
    <a
      href={`${SUPPORT_FORM_URL}?${params.toString()}`}
      className={twx(className)}
      target="_blank"
      rel="noreferrer noopener"
    >
      {buttonText}
    </a>
  );
};
