import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode, useRef } from 'react';
import { notify } from '@ctw/shared/components/toast';
import { Tooltip } from '@ctw/shared/components/tooltip';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { Button } from '@ctw/shared/components/button';

export type CopyToClipboardProps = {
  children: ReactNode;
  className?: string;
};

const withHiddenTextArea = <T,>(callback: (textArea: HTMLTextAreaElement) => T): T => {
  const textArea = document.createElement('textarea');
  textArea.style.top = `${document.documentElement.scrollTop}`;
  textArea.style.left = `${document.documentElement.scrollLeft}`;
  textArea.style.position = 'fixed';
  document.body.appendChild(textArea);

  const returnValue = callback(textArea);

  document.body.removeChild(textArea);

  return returnValue;
};

const isFeaturePolicyClipboardWriteEnabled = (): boolean => {
  const doc = document as unknown as {
    featurePolicy?: { allowsFeature: (feature: string) => boolean };
  };

  return doc.featurePolicy ? doc.featurePolicy.allowsFeature('clipboard-write') : false;
};

const isNavigatorClipboardAvailable = (): Promise<boolean> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!navigator.clipboard || !navigator.clipboard.writeText) {
      return Promise.resolve(false);
    }

    return new Promise((resolve, _reject) => {
      navigator.clipboard
        .writeText(' ')
        .then(() => resolve(true))
        .catch(() => resolve(false));
    });
  } catch (_err) {
    return Promise.resolve(false);
  }
};

const isDocumentCopyActionAvailable = (): boolean =>
  withHiddenTextArea((textArea) => {
    // eslint-disable-next-line no-param-reassign
    textArea.value = ' ';
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand('copy');

      if (successful === true) {
        return true;
      }
      return false;
      // eslint-disable-next-line no-empty
    } catch (_err) {
      return false;
    }
  });

const copyToClipboard = async (content: string, afterCopy?: () => void) => {
  if (isFeaturePolicyClipboardWriteEnabled() && (await isNavigatorClipboardAvailable())) {
    try {
      void navigator.clipboard.writeText(content).then(() => afterCopy && afterCopy());
      // eslint-disable-next-line no-empty
    } catch (_err) {}
  } else if (isDocumentCopyActionAvailable()) {
    const success = withHiddenTextArea((textArea) => {
      // eslint-disable-next-line no-param-reassign
      textArea.value = content;
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');

        if (successful === true) {
          return true;
        }
        return false;
        // eslint-disable-next-line no-empty
      } catch (_err) {
        return false;
      }
    });

    if (success && afterCopy) {
      afterCopy();
    }
  } else {
    notify({
      type: 'error',
      title: 'Copy to clipboard is not supported in this web browser',
    });
  }
};

export const CopyToClipboard = ({ children, className }: CopyToClipboardProps) => {
  const content = useRef<HTMLDivElement>(null);

  const onClick = () => {
    const textContent = content.current?.textContent ?? '';

    void copyToClipboard(textContent, () => {
      notify({
        type: 'info',
        title: `Copied to clipboard: '${textContent}'`,
      });
    });
  };

  return (
    <Button
      onClick={onClick}
      type="button"
      variant="unstyled"
      className={twx(className, 'group flex items-center gap-1 px-1')}
    >
      <div ref={content}>{children}</div>
      <Tooltip content="Copy to Clipboard">
        <FontAwesomeIcon
          icon={faCopy}
          className={tw`h-4 w-4 text-content-lighter opacity-0 transition-opacity group-hover:opacity-100`}
        />
      </Tooltip>
    </Button>
  );
};
