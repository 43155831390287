import { faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MouseEvent, useCallback, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { BinaryField, downloadBlob } from './binary-field';
import { CCDAModalBase } from './ccda-modal-base';
import {
  BinaryData,
  useGetBinaryDocument,
  useGetPatientFromBinaryId,
} from '@ctw/shared/api/fhir/binaries';
import { PatientModel } from '@ctw/shared/api/fhir/models';
import { ContentTypeIcon } from '@ctw/shared/components/icons/content-type-icon';
import { Alert } from '@ctw/shared/components/alert';
import { tw } from '@ctw/shared/utils/tailwind';
import { LoadingSpinner } from '@ctw/shared/components/loading-spinner';
import { Button } from '@ctw/shared/components/button';

type ViewDocumentProps = {
  binaryId: string;
  fileName: string | undefined;
  showPrintBtn?: boolean;
  onClose?: () => void;
  patient?: PatientModel | undefined;
};

export const ViewDocument = ({ binaryId, fileName, onClose, patient }: ViewDocumentProps) => {
  const { data, isLoading } = useGetBinaryDocument(binaryId);

  return (
    <BinaryContent
      isLoading={isLoading}
      rawBinary={data?.binary}
      fileName={fileName}
      binaryId={binaryId}
      onClose={onClose}
      patient={patient}
    />
  );
};

export type BinaryContentProps = {
  isLoading: boolean;
  rawBinary: BinaryData['binary'] | undefined;
  fileName: string | undefined;
  onClose?: () => void;
  patient: PatientModel | undefined;
  binaryId: string;
};

const BinaryContent = ({
  isLoading,
  rawBinary,
  fileName,
  onClose,
  patient,
  binaryId,
}: BinaryContentProps) => {
  const response = useGetPatientFromBinaryId(binaryId, !patient);
  const contentToPrint = useRef(null);
  const handlePrint = useReactToPrint({
    documentTitle: fileName,
    removeAfterPrint: true,
  });
  const patientToUse = response.data || patient;

  if (isLoading) {
    return (
      <CCDAModalBase onClose={onClose} patient={patientToUse} binaryId={binaryId}>
        <LoadingSpinner message="Loading..." className={tw`flex justify-center p-12`} />
      </CCDAModalBase>
    );
  }

  if (!rawBinary?.data) {
    return (
      <CCDAModalBase onClose={onClose} patient={patientToUse} binaryId={binaryId}>
        <Alert type="error" header="Unable to view document" className={tw`justify-center`}>
          The document could not be loaded.
        </Alert>
      </CCDAModalBase>
    );
  }

  const downloadButton = (
    <DownloadButtons
      fileName={fileName}
      contentType={rawBinary.contentType}
      blob={rawBinary.blob}
      handlePrint={handlePrint}
      contentToPrint={contentToPrint}
    />
  );

  return (
    <div
      className={tw`ccda-container ccda-styles`}
      ref={contentToPrint}
      data-testid="ccda-viewer"
      id="ccda-viewer"
    >
      <CCDAModalBase
        onClose={onClose}
        actions={downloadButton}
        patient={patientToUse}
        binaryId={binaryId}
      >
        <BinaryField
          data={rawBinary.data}
          blob={rawBinary.blob}
          contentType={rawBinary.contentType}
          fileName={fileName}
          onClose={onClose}
          overridePatient={patientToUse}
        />
      </CCDAModalBase>
    </div>
  );
};

export type DownloadButtonsProps = {
  fileName: string | undefined;
  contentType: string;
  blob: Blob;
  handlePrint: ReturnType<typeof useReactToPrint>;
  contentToPrint: React.MutableRefObject<null>;
};

function DownloadButtons({
  fileName,
  contentType,
  blob,
  handlePrint,
  contentToPrint,
}: DownloadButtonsProps) {
  const downloadDocument = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      // Don't want the click event to bubble up to the parent element (the anchor tag).
      event.stopPropagation();
      // Use the helper function to download the blob.
      downloadBlob(blob, fileName);
    },
    [blob, fileName],
  );

  return (
    <div className={tw`ml-0 flex justify-items-start space-x-2 print:hidden`}>
      <Button
        aria-label="Download"
        type="button"
        variant="clear-link"
        className={tw`flex items-center space-x-2`}
        onClick={downloadDocument}
      >
        <ContentTypeIcon contentType={contentType} className={tw`h-5 w-5 fill-white`} />
      </Button>

      <Button
        aria-label="Print"
        type="button"
        variant="clear-link"
        className={tw`items-center`}
        onClick={() => {
          handlePrint(null, () => contentToPrint.current);
        }}
      >
        <FontAwesomeIcon icon={faPrint} className={tw`h-4 w-4 text-white`} />
      </Button>
    </div>
  );
}
