import React from 'react';
import { CitationLink } from './citation-link';
import { Citation } from '@ctw/shared/api/ai/types';

export type CitationTextProps = {
  className?: string;
  content: string;
  citations?: Citation[];
};

export const CitationText = ({ content, citations }: CitationTextProps) => {
  // break up the content into parts based on the citation references, breaks, etc
  const parts = content.split(/(\[\d+\]|<br>)/g);

  // only happens if there's an error in the API call
  if (!citations || citations.length === 0) {
    return <span>{content}</span>;
  }

  return (
    <div>
      {parts.map((part, index) => {
        const citation = citations.find((c) => `[${c.id}]` === part);
        if (citation) {
          const resourceInfo = {
            resourceID: citation.source_id,
            resourceType: citation.source_resource_type,
          };
          return (
            <CitationLink
              resourceInfo={resourceInfo}
              citation={citation}
              // eslint-disable-next-line react/no-array-index-key
              key={index}
            />
          );
        }

        if (part === '<br>') {
          // eslint-disable-next-line react/no-array-index-key
          return <br key={index} />;
        }
        // eslint-disable-next-line react/no-array-index-key
        return <span key={index}>{part}</span>;
      })}
    </div>
  );
};
