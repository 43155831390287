import { applyAllergyFilters } from '@ctw/shared/content/allergies/helpers/allergies-filter';
import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { useTimingQueryWithPatient } from '@ctw/shared/context/patient-provider';
import {
  createGraphqlClient,
  fqsRequest,
  MAX_OBJECTS_PER_REQUEST,
} from '@ctw/shared/api/fqs/client';
import { AllergyGraphqlResponse, allergyQuery } from '@ctw/shared/api/fqs/queries/allergies';
import { AllergyModel, PatientModel } from './models';
import { SYSTEM_SUMMARY } from './system-urls';
import { QUERY_KEY_PATIENT_ALLERGIES } from '@ctw/shared/utils/query-keys';

export function usePatientAllergies(enabled = true) {
  return useTimingQueryWithPatient(
    QUERY_KEY_PATIENT_ALLERGIES,
    [],
    getAllergyIntoleranceFromFQS,
    enabled,
  );
}

async function getAllergyIntoleranceFromFQS(
  requestContext: CTWRequestContext,
  patient: PatientModel,
) {
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequest<AllergyGraphqlResponse>(graphClient, allergyQuery, {
    upid: patient.UPID,
    cursor: '',
    first: 1000,
    sort: {
      lastUpdated: 'DESC',
    },
    filter: {
      tag: { nonematch: [SYSTEM_SUMMARY] },
    },
  });
  const models = data.AllergyIntoleranceConnection.edges.map(
    (x) => new AllergyModel(x.node, undefined, x.node.BasicList),
  );
  const results = applyAllergyFilters(models, requestContext.builderId);
  return results;
}

export async function getAllergyIntolerancesById(
  requestContext: CTWRequestContext,
  patient: PatientModel,
  ids: string[],
) {
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequest<AllergyGraphqlResponse>(graphClient, allergyQuery, {
    upid: patient.UPID,
    cursor: '',
    first: MAX_OBJECTS_PER_REQUEST,
    filter: {
      ids: { anymatch: ids },
      tag: { nonematch: [SYSTEM_SUMMARY] },
    },
    sort: {},
  });
  return data.AllergyIntoleranceConnection.edges.map((x) => new AllergyModel(x.node));
}
