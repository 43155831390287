import { useEffect, useRef, useState } from 'react';
import { FeedbackComponent } from './feedback/feedback';
import { PatientSummaryDrawerFooter } from './patient-summary-drawer-footer';
import { PatientSummaryDrawerHeader } from './patient-summary-drawer-header';
import { PrecomputedSummaryComponent } from './precomputed-summary';
import { FeedbackRequest, NewBlankFeedbackRequest } from '@ctw/shared/api/ai/types';
import { useFeedback } from '@ctw/shared/api/ai/use-feedback';
import { usePrecomputedNewPatientSummaryForPatient } from '@ctw/shared/api/ai/use-precompute-summary';
import { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import { Drawer } from '@ctw/shared/components/drawer';
import { notifyFromDrawer } from '@ctw/shared/components/toast';
import { TrackingMetadata } from '@ctw/shared/context/analytics/tracking-metadata';
import { useDrawer } from '@ctw/shared/context/drawer-hooks';
import { usePatientContext } from '@ctw/shared/context/patient-provider';
import './patient-summary-drawer.scss';
import { tw, twx } from '@ctw/shared/utils/tailwind';

export type PatientSummaryDrawerProps = {
  onClose: () => void;
  summaryType: string;
  className?: string;
  isOpen: boolean;
};

export const PatientSummaryDrawer = ({
  onClose,
  summaryType,
  className,
  isOpen,
}: PatientSummaryDrawerProps) => {
  const [feedback, setFeedback] = useState<FeedbackRequest>(NewBlankFeedbackRequest());
  const [feedbackSent, setFeedbackSent] = useState(false);
  const [feedbackReady, setFeedbackReady] = useState(false);

  const { patient } = usePatientContext();

  const containerRef = useRef<HTMLDivElement | null>(null);
  const feedbackRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (patient.data?.UPID) {
      setFeedback(
        (prev: FeedbackRequest) =>
          ({
            ...prev,
            upid: patient.data.UPID,
          }) as FeedbackRequest,
      );
    }
  }, [patient.data?.UPID]);

  const { document, generateDocument } = usePrecomputedNewPatientSummaryForPatient(summaryType);

  const { triggerFeedback } = useFeedback(feedback);

  const handleSummaryClick = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleFeedbackClick = () => {
    if (containerRef.current && feedbackRef.current) {
      containerRef.current.scrollTo({
        top: feedbackRef.current.offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const handleFeedbackUpdate = (updatedFeedback: FeedbackRequest) => {
    const newFeedback = {
      ...updatedFeedback,
      content: document?.hpi || '',
      citations: document?.citations || [],
    };
    setFeedback(newFeedback);

    if (newFeedback.usefulnessRating > 0) {
      setFeedbackReady(true);
    }
  };

  const handleFeedbackSubmit = () => {
    notifyFromDrawer({ type: 'success', title: 'Feedback Sent!' });
    void triggerFeedback();
    setFeedbackSent(true);
  };

  const handleClose = () => {
    onClose();
  };

  const handleGenerateDocument = () => {
    void generateDocument();
  };

  // show feedback section if feedback has not been sent
  const feedbackSection =
    feedbackSent ? undefined : (
      <FeedbackComponent initialFeedback={feedback} onFeedbackUpdate={handleFeedbackUpdate} />
    );

  return (
    <div>
      <Drawer
        className={twx(className)}
        isOpen={isOpen}
        onClose={onClose}
        renderHeader={() => (
          <PatientSummaryDrawerHeader
            onFeedbackClick={handleFeedbackClick}
            onSummaryClick={handleSummaryClick}
            isFeedbackEnabled={feedbackSent}
          />
        )}
        renderFooter={() => (
          <PatientSummaryDrawerFooter
            feedbackEnabled={!feedbackSent && feedbackReady}
            onClose={handleClose}
            onFeedbackClick={handleFeedbackSubmit}
          />
        )}
      >
        <div ref={containerRef} className={tw`ai-summary-drawer-content`}>
          <div>
            <PrecomputedSummaryComponent
              onGenerateSummary={handleGenerateDocument}
              document={document}
            />
          </div>
          <div ref={feedbackRef}>{feedbackSection}</div>
        </div>
      </Drawer>
    </div>
  );
};

export function usePatientSummaryDrawer<T extends fhir4.Resource, M extends FHIRModel<T>>() {
  const { openDrawer } = useDrawer();

  return (model?: M, trackingMetadata?: TrackingMetadata) => {
    openDrawer({
      component: (drawerProps) => (
        <PatientSummaryDrawer
          summaryType="new_patient"
          {...drawerProps}
          onClose={() => {
            drawerProps.onClose();
          }}
        />
      ),
      trackingMetadata: {
        resourceType: model?.resourceType,
        ...trackingMetadata,
      },
    });
  };
}
