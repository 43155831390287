import {
  SYSTEM_SUMMARY,
  SYSTEM_ZUS_OWNER,
  SYSTEM_ZUS_SUMMARY,
  SYSTEM_ZUS_THIRD_PARTY,
} from '@ctw/shared/api/fhir/system-urls';
import { filterResourcesByBuilderId } from '@ctw/shared/api/common';
import {
  createGraphqlClient,
  fqsRequestAll,
  MAX_OBJECTS_PER_REQUEST,
} from '@ctw/shared/api/fqs/client';
import { isAfter, subYears } from 'date-fns';
import { orderBy } from 'lodash';
import { useMemo } from 'react';
import { getLensSummariesWithSyncedWithRecord } from './summary';
import { PatientModel } from '@ctw/shared/api/fhir/models';
import { ConditionModel } from '@ctw/shared/api/fhir/models/condition';
import {
  ConditionBuilderDataGraphqlResponse,
  ConditionBuilderInfo,
  ConditionGraphqlResponse,
  conditionsBuilderQuery,
  conditionsQuery,
  ConditionWithBasics,
} from '@ctw/shared/api/fqs/queries/conditions';
import { getLensBuilderId } from '@ctw/shared/api/urls';
import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { useTimingQueryWithPatient } from '@ctw/shared/context/patient-provider';
import {
  QUERY_KEY_PATIENT_BUILDER_CONDITIONS,
  QUERY_KEY_PATIENT_SUMMARY_CONDITIONS,
} from '@ctw/shared/utils/query-keys';

export function usePatientConditionsAll(enabled = true) {
  const queryForSummary = useTimingQueryWithPatient(
    QUERY_KEY_PATIENT_SUMMARY_CONDITIONS,
    [],
    fetchSummaryConditions,
    enabled,
  );
  const queryForBuilder = useTimingQueryWithPatient(
    QUERY_KEY_PATIENT_BUILDER_CONDITIONS,
    [],
    fetchBuilderConditions,
    enabled,
  );

  const conditions = useMemo(() => {
    const summaries = getLensSummariesWithSyncedWithRecord(
      queryForSummary.data ?? [],
      queryForBuilder.data ?? [],
      (summary, builderResources) =>
        builderResources.some((builderResource) => summary.knownCodingsMatch(builderResource.code)),
    );
    return filterUndesirableConditions(summaries);
  }, [queryForSummary.data, queryForBuilder.data]);

  return {
    ...queryForSummary,
    isLoading: queryForSummary.isLoading || queryForBuilder.isLoading,
    data: conditions,
  };
}

// Filter out conditions that we never want to display
export function filterUndesirableConditions(conditions: ConditionModel[]) {
  const outdatedThreshold = subYears(new Date(), 5);

  return (
    conditions
      // Filter out summaries where CCS Category code starts with FAC or XXX.
      .filter((condition) => !['FAC', 'XXX'].includes(condition.ccsChapterCode ?? ''))
      .filter(
        // Filter out low quality conditions
        (condition) =>
          condition.ccsChapterCode !== 'SYM' ||
          !['A', 'U', 'N'].includes(condition.chronic?.code ?? '') ||
          isAfter(new Date(condition.recordedDate ?? ''), outdatedThreshold),
      )
  );
}

function setupConditionModelsWithFQS(conditionResources: ConditionWithBasics[]): ConditionModel[] {
  return conditionResources.map((c) => new ConditionModel(c, undefined, c.BasicList));
}

function filterAndSort(conditions: ConditionModel[]): ConditionModel[] {
  return orderBy(
    conditions.filter((condition) => condition.resource.asserter?.type !== 'Patient'),
    ['resource.recordedDate', 'display'],
    ['desc'],
  );
}

export async function fetchConditionsByIdFQS(
  requestContext: CTWRequestContext,
  patient: PatientModel,
  ids: string[],
) {
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequestAll<ConditionGraphqlResponse>(
    graphClient,
    conditionsQuery,
    'ConditionConnection',
    {
      upid: patient.UPID,
      cursor: '',
      first: MAX_OBJECTS_PER_REQUEST,
      sort: {},
      filter: {
        ids: {
          anymatch: ids,
        },
      },
    },
  );
  const nodes = data.ConditionConnection.edges.map((x) => x.node);
  return setupConditionModelsWithFQS(nodes);
}

async function fetchBuilderConditions(
  requestContext: CTWRequestContext,
  patient: PatientModel,
): Promise<ConditionBuilderInfo[]> {
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequestAll<ConditionBuilderDataGraphqlResponse>(
    graphClient,
    conditionsBuilderQuery,
    'ConditionConnection',
    {
      upid: patient.UPID,
      cursor: '',
      first: 1000,
      filter: {
        tag: {
          nonematch: [SYSTEM_SUMMARY, SYSTEM_ZUS_THIRD_PARTY],
          // TODO: There's a bug in FQS that doesn't allow filtering with nonematch AND allmatch.
          // Uncomment the line below once https://zeushealth.atlassian.net/browse/DRT-249 is resolved.
          // allmatch: [`${SYSTEM_ZUS_OWNER}|builder/${requestContext.builderId}`],
        },
      },
    },
  );

  let nodes = data.ConditionConnection.edges.map((x) => x.node);
  nodes = filterResourcesByBuilderId(nodes, requestContext.builderId);
  return nodes;
}

async function fetchSummaryConditions(requestContext: CTWRequestContext, patient: PatientModel) {
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequestAll<ConditionGraphqlResponse>(
    graphClient,
    conditionsQuery,
    'ConditionConnection',
    {
      upid: patient.UPID,
      cursor: '',
      first: 1000,
      filter: {
        tag: {
          allmatch: [
            `${SYSTEM_ZUS_SUMMARY}|Common`,
            `${SYSTEM_ZUS_OWNER}|builder/${getLensBuilderId(requestContext.env)}`,
          ],
        },
      },
    },
  );
  const nodes = data.ConditionConnection.edges.map((x) => x.node);
  const conditions = setupConditionModelsWithFQS(nodes);
  const results = filterAndSort(conditions);
  return results;
}
