import { ResourceTable, ResourceTableProps } from './resource-table';
import { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';
import { usePatientContext } from '@ctw/shared/context/patient-provider';
import './resource-table.scss';
import { twx } from '@ctw/shared/utils/tailwind';

// TODO - I think we can probably get rid of this
export const PatientResourceTable = <T extends fhir4.Resource, M extends FHIRModel<T>>({
  className,
  columns,
  data,
  emptyMessage,
  isLoading = false,
  onRowClick,
  rowActions,
  onRightClick,
  showTableHead,
  enableDismissAndReadActions,
  hidePagination = false,
}: ResourceTableProps<M>) => {
  const { patient } = usePatientContext();

  // We're loading, if our patient is loading OR
  // if we have our patient data but the passed in isLoading is true.
  // We have to check for patient.data because most queries are only
  // enabled when we have a patient UPID, without one, those queries
  // will stay in the loading state forever.
  const isLoading2 = patient.isLoading || (!!patient.data && isLoading);

  return (
    <ResourceTable
      className={twx(className)}
      columns={columns}
      data={data}
      emptyMessage={emptyMessage}
      isLoading={isLoading2}
      onRowClick={onRowClick}
      showTableHead={showTableHead}
      rowActions={rowActions}
      onRightClick={onRightClick}
      enableDismissAndReadActions={enableDismissAndReadActions}
      hidePagination={hidePagination}
    />
  );
};
