import { faCheck, faChevronDown, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Listbox } from '@headlessui/react';
import { isFunction } from 'lodash';
import { forwardRef, Fragment, useEffect, useState } from 'react';
import type { ReactNode, Ref } from 'react';
import { MenuItem } from '../_internal/menu-item';
import { FilterOptionSelect } from '@ctw/shared/components/filter-bar/filter-bar-types';
import { ClassName, tw, twx } from '@ctw/shared/utils/tailwind';
import './list-box.scss';

type ListBoxItem = {
  className?: ClassName;
  divider?: false;
  display: string | ((status: ListBoxOptionStatus) => ReactNode);
  icon?: IconDefinition;
  key: string;
};

export type MinListBoxItem = ListBoxItem | { divider: true };

export type ListBoxProps = {
  btnClassName?: ClassName;
  children?: ReactNode;
  className?: ClassName;
  defaultIndex?: number;
  selectedIndex?: number;
  items: MinListBoxItem[];
  onChange: (index: number, item: ListBoxItem) => void;
  optionsClassName?: ClassName;
  useBasicStyles?: boolean;
};

export type ListBoxOptionStatus = {
  active?: boolean;
  filter?: FilterOptionSelect;
  listView?: boolean;
  selected?: boolean;
};

export const ListBox = forwardRef(
  (
    {
      useBasicStyles = false,
      btnClassName,
      children,
      className,
      optionsClassName,
      items,
      defaultIndex = 0,
      selectedIndex,
      onChange,
    }: ListBoxProps,
    ref: Ref<HTMLDivElement>,
  ) => {
    const [selectedTabIndex, setSelectedTabIndex] = useState(defaultIndex);
    const selectedItem = items[selectedTabIndex] ?? {
      display: 'Choose a selection',
    };

    useEffect(() => {
      if (selectedIndex === undefined) return;
      setSelectedTabIndex(selectedIndex);
    }, [selectedIndex]);

    return (
      <div className={twx(className, 'relative inline-block')} ref={ref}>
        <Listbox
          value={selectedTabIndex}
          onChange={(index: number) => {
            const item = items[index];
            if (item.divider) return;
            onChange(index, item);
            setSelectedTabIndex(index);
          }}
        >
          <Listbox.Button
            className={twx(
              btnClassName,
              !useBasicStyles && 'focus-visible:outline-primary-dark',
              'relative cursor-pointer justify-between space-x-2 border-0 border-transparent',
            )}
          >
            {children || renderDisplay(selectedItem, { listView: false })}
            {!useBasicStyles && <FontAwesomeIcon icon={faChevronDown} className={tw`h-4`} />}
          </Listbox.Button>
          <Listbox.Options className={twx(optionsClassName, 'list-box')}>
            {items.map((item, index) => {
              if (item.divider) {
                return <div key="divider" className={tw`border-top`} />;
              }

              return (
                <Listbox.Option key={item.key} value={index} as={Fragment}>
                  {({ active, selected }) => (
                    <li
                      className={twx(
                        'flex cursor-pointer justify-between px-3 py-2',
                        'hover:bg-bg-lighter',
                        item.className,
                        {
                          'text-medium': active || selected,
                          'text-content-black': !(active || selected),
                          'bg-bg-lighter': active,
                        },
                      )}
                    >
                      <span
                        className={twx('inline-flex whitespace-nowrap align-middle', {
                          'font-semibold': selected && !useBasicStyles,
                        })}
                      >
                        {renderDisplay(item, {
                          active,
                          selected,
                          listView: true,
                        })}
                      </span>
                      {!useBasicStyles && selected && !item.key.startsWith('_') && (
                        <span className={tw`inline-flex pb-0.5`}>
                          <FontAwesomeIcon
                            icon={faCheck}
                            className={tw`inline-block h-4 stroke-0 align-middle text-primary-dark`}
                          />
                        </span>
                      )}
                    </li>
                  )}
                </Listbox.Option>
              );
            })}
          </Listbox.Options>
        </Listbox>
      </div>
    );
  },
);

// A helper function to render items for both the ListBox button and options.
function renderDisplay<T extends MinListBoxItem>(item: T, status: ListBoxOptionStatus) {
  if (item.divider) return null;
  return (
    <MenuItem icon={item.icon}>
      {isFunction(item.display) ? item.display(status) : item.display}
    </MenuItem>
  );
}
