import { VitalsBucket } from '@ctw/shared/api/fhir/models/vitals-bucket';
import { SortOption } from '@ctw/shared/content/resource/resource-table-actions';

export const defaultVitalSort: SortOption<VitalsBucket> = {
  display: 'Date (New to Old)',
  sorts: [{ key: 'dateTime', dir: 'desc' }],
};

export const vitalSortOptions: SortOption<VitalsBucket>[] = [
  defaultVitalSort,
  {
    display: 'Date (Old to New)',
    sorts: [{ key: 'dateTime', dir: 'asc' }],
  },
];
