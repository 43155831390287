import defaultTheme from 'tailwindcss/defaultTheme';

/*
 * WARNING: Do NOT add `plugins` to this file - they fail to compile correctly.
 * Instead, add plugins to each project's individual `tailwind.config.js` file.
 */
export default {
  content: [
    '../../packages/shared/api/**/*.{js,ts,jsx,tsx,scss,mdx}',
    '../../packages/shared/assets/**/*.{js,ts,jsx,tsx,scss,mdx}',
    '../../packages/shared/components/**/*.{js,ts,jsx,tsx,scss,mdx}',
    '../../packages/shared/content/**/*.{js,ts,jsx,tsx,scss,mdx}',
    '../../packages/shared/context/**/*.{js,ts,jsx,tsx,scss,mdx}',
    '../../packages/shared/hooks/**/*.{js,ts,jsx,tsx,scss,mdx}',
    '../../packages/shared/styles/**/*.{js,ts,jsx,tsx,scss,mdx}',
    '../../packages/shared/test/**/*.{js,ts,jsx,tsx,scss,mdx}',
    '../../packages/shared/utils/**/*.{js,ts,jsx,tsx,scss,mdx}',
    '../../packages/shared/vendor/**/*.{js,ts,jsx,tsx,scss,mdx}',
    '../../packages/shared/stories/**/*.{js,ts,jsx,tsx,scss,mdx}',
  ],
  theme: {
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1280px',
      '2xl': '1536px',
      '3xl': '1920px',
    },
    colors: {
      transparent: 'transparent',
      white: '#ffffff',
      black: '#000000',
      icon: {
        default: '#374151',
        light: '#D1D5DB',
        active: '#A855F7',
      },
      divider: {
        main: '#D1D5DB',
        light: '#E5E7EB',
      },
      content: {
        black: '#111827',
        light: '#6B7280',
        lighter: '#9CA3AF',
      },
      error: {
        main: '#EF4444',
        light: '#FEE2E2',
        dark: '#7F1D1D',
        text: '#991B1B',
        message: '#B91C1B',
        bg: '#FEF2F2',
        icon: '#F97171',
      },
      success: {
        main: '#10B981',
        light: '#D1FAE5',
        dark: '#065F46',
      },
      caution: {
        bg: '#FFFBEB',
        icon: '#F59E0B',
        light: '#FEF3C7',
        text: '#92400E',
        textHover: '#D4753C',
        50: '#FFFBEB',
        100: '#FEF3C7',
        200: '#FDE68A',
        300: '#FCD34D',
        400: '#FBBF24',
        500: '#F59E0B',
        600: '#D97706',
        700: '#B45309',
        800: '#92400E',
        900: '#78350F',
      },
      notification: {
        light: '#F3E8FF',
        main: '#A855F7',
        icon: '#EF4444',
        dark: '#4B5563',
      },
      info: {
        bg: '#F0F9FF',
        light: '#E0F2FE',
        icon: '#0EA5E9',
        text: '#075985',
        textHover: '#0979B6',
        50: '#F0F9FF',
        100: '#E0F2FE',
        200: '#BAE6FD',
        300: '#7DD3FC',
        400: '#38BDF8',
        500: '#0EA5E9',
        600: '#0284C7',
        700: '#0369A1',
        800: '#075985',
        900: '#0C4A6E',
      },
      bg: {
        white: '#FFFFFF',
        lighter: '#F9FAFB',
        light: '#F3F4F6',
        dark: '#E5E7EB',
        black: '#111827',
        zusLogoBlue: '#71B2C9',
      },
      zap: {
        headerBg: '#ffffff',
      },
      // Ideally this would be named "primary" and uses of the
      // one above would be completely refactored.
      primary: {
        lighter: '#EBFEF4',
        light: '#D0FBE2',
        main: '#008052',
        dark: '#036240',
      },
      gray: {
        50: '#F9FAFB',
        100: '#F3F4F6',
        200: '#E5E7EB',
        300: '#D1D5DB',
        400: '#9CA3AF',
        500: '#6B7280',
        600: '#4B5563',
        700: '#374151',
        800: '#1F2937',
        900: '#111827',
      },
      good: {
        50: '#ECFDF5',
        100: '#D1FAE5',
        200: '#A7F3D0',
        300: '#6EE7B7',
        400: '#34D399',
        500: '#10B981',
        600: '#059669',
        700: '#047857',
        800: '#065F46',
        900: '#064E3B',
      },
      alert: {
        50: '#FEF2F2',
        100: '#FEE2E2',
        200: '#FECACA',
        300: '#FCA5A5',
        400: '#F87171',
        500: '#EF4444',
        600: '#DC2626',
        700: '#B91C1C',
        800: '#991B1B',
        900: '#7F1D1D',
      },
    },
    extend: {
      fontFamily: {
        sans: ['Inter var', 'Inter', ...defaultTheme.fontFamily.sans],
      },
      spacing: {
        38: '9.5rem',
        70: '17.5rem',
      },
    },
    corePlugins: {
      preflight: false,
    },
  },
};
