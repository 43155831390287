import { capitalize } from 'lodash';
import { CitationComponent } from './citation';
import { allergyData, allergyHistory } from '../allergies/patient-allergies';
import { useCCDAModal } from '../CCDA/modal-ccda';
import { useConditionDetailsDrawer } from '../conditions/helpers/details';
import { useDocumentDetailsDrawer } from '../document/helpers/details';
import { useMedicationDetailsDrawer } from '../medications/helpers/details';
import { useResourceDetailsDrawer } from '../resource/resource-details-drawer';
import { Citation } from '@ctw/shared/api/ai/types';
import { getAllergyIntolerancesById } from '@ctw/shared/api/fhir/allergies';
import { useBinaryId } from '@ctw/shared/api/fhir/binaries';
import { fetchConditionsByIdFQS } from '@ctw/shared/api/fhir/conditions';
import { getDocumentsByIdFromFQS } from '@ctw/shared/api/fhir/document';
import { getMedicationStatementsByIdFQS } from '@ctw/shared/api/fhir/medications';
import {
  AllergyModel,
  ConditionModel,
  DocumentModel,
  MedicationStatementModel,
  ObservationModel,
} from '@ctw/shared/api/fhir/models';
import { fetchObservationsById } from '@ctw/shared/api/fhir/observations';
import { useTimingQueryWithPatient } from '@ctw/shared/context/patient-provider';

export type SupportedCitationLinkResourceType =
  | AllergyModel[]
  | ConditionModel[]
  | DocumentModel[]
  | MedicationStatementModel[]
  | ObservationModel[]
  | null;

export type CitationLinkProps = {
  resourceInfo: { resourceID: string; resourceType: string };
  citation: Citation;
};

export const CitationLink = ({ resourceInfo, citation }: CitationLinkProps) => {
  const { isLoading, isError, data } = useTimingQueryWithPatient<
    SupportedCitationLinkResourceType,
    string
  >(
    'ResourceByTypeAndIDQuery',
    [resourceInfo.resourceID, resourceInfo.resourceType],
    (context, patient) => {
      switch (resourceInfo.resourceType) {
        case 'AllergyIntolerance':
          return getAllergyIntolerancesById(context, patient, [resourceInfo.resourceID]);
        case 'Condition':
          return fetchConditionsByIdFQS(context, patient, [resourceInfo.resourceID]);
        case 'DocumentReference':
          return getDocumentsByIdFromFQS(context, patient, [resourceInfo.resourceID]);
        case 'MedicationStatement':
          return getMedicationStatementsByIdFQS(context, patient, [resourceInfo.resourceID]);
        case 'Observation':
          return fetchObservationsById(context, patient, [resourceInfo.resourceID]);
        default:
          return Promise.resolve(null);
      }
    },
  );

  const binaryContent = useBinaryId(data?.[0], !isLoading, true);
  const openCCDAModal = useCCDAModal();

  const openAllergyDetails = useResourceDetailsDrawer({
    header: (m) => capitalize(m.display),
    details: allergyData,
    RenderChild: allergyHistory,
    getSourceDocument: true,
  });
  const openConditionDetails = useConditionDetailsDrawer({});
  const openDocumentDetails = useDocumentDetailsDrawer({});
  const openMedicationDetails = useMedicationDetailsDrawer({});
  const openObservationDetails = useResourceDetailsDrawer({
    header: (obs: ObservationModel) => capitalize(obs.display),
    details: (obs: ObservationModel) => [
      { label: 'Value', value: obs.value },
      { label: 'Effective Date', value: obs.effectiveStart },
    ],

    getSourceDocument: true,
  });

  if (isLoading || isError || !data) {
    return null;
  }

  // if it's not an array, it's not a valid resource
  if (!Array.isArray(data)) {
    return null;
  }

  if (data.length === 0) {
    return null;
  }

  const resource = data[0];
  const titleOfCCDAModal = 'Source Document';

  switch (resource.resourceType) {
    case 'AllergyIntolerance': {
      const allergy = resource as AllergyModel;

      return (
        <CitationComponent
          citation={citation}
          openDetails={() => {
            if (binaryContent.data) {
              return openCCDAModal(binaryContent.data, titleOfCCDAModal);
            }
            return openAllergyDetails(allergy);
          }}
        />
      );
    }
    case 'Condition': {
      const condition = resource as ConditionModel;
      return (
        <CitationComponent
          citation={citation}
          openDetails={() => {
            if (binaryContent.data) {
              return openCCDAModal(binaryContent.data, titleOfCCDAModal);
            }
            return openConditionDetails(condition);
          }}
        />
      );
    }
    case 'DocumentReference': {
      const document = resource as DocumentModel;
      return (
        <CitationComponent
          citation={citation}
          openDetails={() => {
            if (document.binaryId) {
              return openCCDAModal(document.binaryId, titleOfCCDAModal);
            }
            return openDocumentDetails(document);
          }}
        />
      );
    }
    case 'MedicationStatement': {
      const medication = resource as MedicationStatementModel;
      return (
        <CitationComponent
          citation={citation}
          openDetails={() => {
            if (binaryContent.data) {
              return openCCDAModal(binaryContent.data, titleOfCCDAModal);
            }
            return openMedicationDetails(medication);
          }}
        />
      );
    }
    case 'Observation': {
      const observation = resource as ObservationModel;
      return (
        <CitationComponent
          citation={citation}
          openDetails={() => {
            if (binaryContent.data) {
              return openCCDAModal(binaryContent.data, titleOfCCDAModal);
            }
            return openObservationDetails(observation);
          }}
        />
      );
    }
    default:
      return null;
  }
};
