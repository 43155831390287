import { DocumentModel } from '@ctw/shared/api/fhir/models/document';
import { SortOption } from '@ctw/shared/content/resource/resource-table-actions';

export const documentSortOptions: SortOption<DocumentModel>[] = [
  {
    display: 'Name (A-Z)',
    sorts: [{ key: 'title', dir: 'asc' }],
  },
  {
    display: 'Name (Z-A)',
    sorts: [{ key: 'title', dir: 'desc' }],
  },
  {
    display: 'Date (Old to New)',
    sorts: [
      { key: 'contextPeriodStartDate', dir: 'asc', isDate: true },
      { key: 'dateCreated', dir: 'asc', isDate: true },
      { key: 'title', dir: 'asc' },
    ],
  },
  {
    display: 'Date (New To Old)',
    sorts: [
      { key: 'contextPeriodStartDate', dir: 'desc', isDate: true },
      { key: 'dateCreated', dir: 'desc', isDate: true },
      { key: 'title', dir: 'asc' },
    ],
  },
];

export const defaultDocumentSort = documentSortOptions[3];
