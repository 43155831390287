import { medicationFilters } from './helpers/filters';
import { PatientMedicationsBase } from './helpers/patient-medications-base';
import { defaultMedicationView, medicationViews } from './helpers/views';
import { withErrorBoundary } from '@ctw/shared/content/error-boundary';
import { useQueryAllPatientMedications } from '@ctw/shared/hooks/use-medications';
import { twx } from '@ctw/shared/utils/tailwind';

export type PatientMedicationsProps = {
  className?: string;
  onOpenHistoryDrawer?: () => void;
};

const PatientMedicationsComponent = ({
  className,
  onOpenHistoryDrawer,
}: PatientMedicationsProps) => {
  const { builderMedications, isLoading } = useQueryAllPatientMedications();

  return (
    <PatientMedicationsBase
      className={twx(className)}
      query={{ data: builderMedications, isLoading }}
      filters={medicationFilters(builderMedications, false)}
      views={medicationViews}
      defaultView={defaultMedicationView}
      onOpenHistoryDrawer={onOpenHistoryDrawer}
    />
  );
};

export const PatientMedications = withErrorBoundary(
  PatientMedicationsComponent,
  'PatientMedications',
);
