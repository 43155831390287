import { Citation } from '@ctw/shared/api/ai/types';
import { useAnalytics } from '@ctw/shared/context/analytics/use-analytics';
import { tw } from '@ctw/shared/utils/tailwind';

export type CitationComponentProps = {
  citation: Citation;
  openDetails?: () => void;
};

export const CitationComponent = ({ citation, openDetails }: CitationComponentProps) => {
  const { trackInteraction } = useAnalytics();

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      openDetails?.();
    }
  };

  return (
    <span
      className={tw`flex-shrink-0 cursor-pointer align-super text-xs text-primary-dark`}
      onClick={() => {
        openDetails?.();
        trackInteraction('open_gps_citation');
      }}
      onKeyDown={handleKeyDown}
    >
      [{citation.id}]
    </span>
  );
};
