import { Env } from '@ctw/shared/context/types';

export const getZusApiBaseUrl = (env: Env) =>
  env === 'production' ? `https://api.zusapi.com` : `https://api.${env}.zusapi.com`;

export const getZusServiceUrl = (env: Env, service: string) => {
  if (env === 'phitest' && ['fqs', 'patient-history', 'zap-data-subscriptions'].includes(service)) {
    return `https://${service}.phitest.zusapi.com`;
  }
  return env === 'production' ?
      `https://${service}.zusapi.com`
    : `https://${service}.${env}.zusapi.com`;
};

export const getZapIFrameUrl = (env: Env) =>
  env === 'production' ? 'https://zap.zushealth.com' : `https://zap.${env}.zushealth.com`;

export const getEHRIntegrationServiceBaseUrl = (env: Env) =>
  env === 'production' ? `https://ehr-hooks.zusapi.com` : `https://ehr-hooks.${env}.zusapi.com`;

export const getZusFhirBaseUrl = (env: Env) => `${getZusApiBaseUrl(env)}/fhir`;

export const getFormsConditionsUrl = (env: Env) =>
  `${getZusApiBaseUrl(env)}/forms-data/terminology/conditions`;

export const getFormsMedicationsUrl = (env: Env) =>
  `${getZusApiBaseUrl(env)}/forms-data/terminology/dosages`;

export const getCTWBaseUrl = (env: string) => {
  if (window.location.hostname === 'localhost') {
    // Locally we just want to send to 3000. This provides 2 outcomes:
    // - If CTW is running locally on 3000, we can test with to dev Datadog
    // - Else, the requests fail silently
    return 'http://localhost:3000';
  }

  return ['prod', 'production'].includes(env) ? `https://app.zushealth.com` : (
      `https://app.${env}.zushealth.com`
    );
};

export const getLensBuilderId = (env: Env) => {
  switch (env) {
    case 'dev':
      return 'bc1c791a-581c-4117-8b17-f9c59f39caf8';
    case 'phitest':
      return '186d5446-46ec-4beb-8699-9e42903a3dd2';
    case 'production':
      return '5e0f86eb-bb0b-4543-8489-262fcbf661da';
    case 'sandbox':
      return 'd8ab3e86-7ff2-482d-bbed-b30df2dd7ec7';
    default:
      throw new Error('Unknown environment');
  }
};
