export type OverviewFilter<T> = {
  key: string;
  label: string;
  description: string;
  predicate: (r: T) => boolean;
};

export function applyFilters<T>(data: T[], filters: { predicate: (item: T) => boolean }[]): T[] {
  return data.filter((item) => filters.every((filter) => filter.predicate(item)));
}
