import { faArrowRight, faArrowTrendUp, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { BubbleIcon } from './bubble';
import { useObservationsDetailsDrawer } from './drawer';
import { ObservationModel } from '@ctw/shared/api/fhir/models';
import { AnalyticsProvider } from '@ctw/shared/context/analytics/analytics-provider';
import { useAnalytics } from '@ctw/shared/context/analytics/use-analytics';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { Button } from '@ctw/shared/components/button';

export type ObservationTrendsProps = {
  model: ObservationModel;
};

export const ObservationTrends = ({ model }: ObservationTrendsProps) => {
  const [isTrendsShown, setIsTrendsShown] = useState(false);
  const openDiagnosticReportDetails = useObservationsDetailsDrawer();
  const { trackInteraction } = useAnalytics();

  return (
    <AnalyticsProvider componentName="ObservationTrends">
      <div className={tw`text-sm font-normal`}>
        {!model.isIncorrectlyCodedGlucose && model.trends && model.trends.length > 1 && (
          <div className={tw`pt-2`}>
            <Button
              aria-label="trends"
              variant="clear"
              className={tw`space-x-3`}
              type="button"
              onClick={() => {
                trackInteraction('toggle_trend', {
                  action: isTrendsShown ? 'collapse_trend' : 'expand_trend',
                });
                setIsTrendsShown(!isTrendsShown);
              }}
            >
              <FontAwesomeIcon icon={faArrowTrendUp} />
              <span>Trends</span>
              <FontAwesomeIcon
                icon={faChevronRight}
                className={twx('h-3 w-3 pr-1', {
                  'rotate-90': isTrendsShown,
                })}
              />
            </Button>
            {isTrendsShown &&
              model.trends.map(
                (trend) =>
                  !trend.isIncorrectlyCodedGlucose && (
                    <div
                      tabIndex={0}
                      role="button"
                      key={trend.id}
                      className={twx('group/trends grid grid-cols-3 py-1 pl-4', {
                        'cursor-pointer hover:bg-bg-lighter': trend.diagnosticReport,
                      })}
                      onClick={() => {
                        if (trend.diagnosticReport) {
                          trackInteraction('open_diagnostic_report', {
                            target: 'btn',
                          });
                          openDiagnosticReportDetails(trend.diagnosticReport);
                        }
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && trend.diagnosticReport) {
                          trackInteraction('open_diagnostic_report', {
                            target: 'enter_key',
                          });
                          openDiagnosticReportDetails(trend.diagnosticReport);
                        }
                      }}
                    >
                      <div
                        className={twx('relative w-24 text-sm', {
                          'font-semibold': trend.id === model.id,
                          'group-hover/trends:underline': trend.diagnosticReport,
                        })}
                      >
                        {trend.effectiveStart}
                      </div>
                      <BubbleIcon
                        result={trend.value}
                        interpretation={trend.interpretation}
                        className={twx(`${trend.acceptedInterpretations} w-fit`, {
                          '!font-semibold': trend.id === model.id,
                        })}
                      />

                      {trend.diagnosticReport && (
                        <div className={tw`justify-self-end`}>
                          <FontAwesomeIcon
                            icon={faArrowRight}
                            className={tw`invisible pr-2 text-content-lighter group-hover/trends:visible`}
                          />
                        </div>
                      )}
                    </div>
                  ),
              )}
          </div>
        )}
      </div>
    </AnalyticsProvider>
  );
};
