import { tw } from '@ctw/shared/utils/tailwind';

export type CodingListProps = {
  codings: fhir4.Coding[];
};

export const CodingList = ({ codings }: CodingListProps) => (
  <div className={tw`flex flex-col space-y-4`}>
    {codings.map((coding, idx) => (
      // eslint-disable-next-line react/no-array-index-key
      <div key={`${coding.system}-${coding.code}-${idx}`} className={tw`space-x-1`}>
        <span className={tw`font-semibold`}>{coding.code}</span>
        <span>{coding.display}</span>
      </div>
    ))}
  </div>
);
