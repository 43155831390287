import { faClipboardCheck } from '@fortawesome/free-solid-svg-icons';
import { compact, isEqual, uniqWith } from 'lodash/fp';
import { TimelineEventModel } from '@ctw/shared/api/fhir/models/timeline-event';
import { FilterChangeEvent, FilterItem } from '@ctw/shared/components/filter-bar/filter-bar-types';

export function timelineFilters(timelineEvents: TimelineEventModel[]): FilterItem[] {
  const filters: FilterItem[] = [];

  filters.push({
    key: 'type',
    type: 'checkbox',
    icon: faClipboardCheck,
    display: 'Type',
    values: compact(
      uniqWith(
        isEqual,
        timelineEvents.map((te) =>
          te.type ?
            {
              key: te.type,
              name: te.type,
            }
          : undefined,
        ),
      ),
    ),
  });

  return filters;
}

export const defaultTimelineFilters: FilterChangeEvent = {
  type: {
    key: 'type',
    selected: ['Diagnostic Report', 'Encounter'],
    type: 'checkbox',
  },
};
