import { PatientModel } from '@ctw/shared/api/fhir/models';
import {
  MAX_OBJECTS_PER_REQUEST,
  createGraphqlClient,
  fqsRequestAll,
} from '@ctw/shared/api/fqs/client';
import {
  ServiceRequestGraphqlResponse,
  ServiceRequestQuery,
} from '@ctw/shared/api/fqs/queries/service-requests';
import { CTWRequestContext } from '@ctw/shared/context/ctw-context';

export async function fetchServiceRequests(
  requestContext: CTWRequestContext,
  patient: PatientModel,
) {
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequestAll<ServiceRequestGraphqlResponse>(
    graphClient,
    ServiceRequestQuery,
    'ServiceRequestConnection',
    {
      upid: patient.UPID,
      cursor: '',
      first: MAX_OBJECTS_PER_REQUEST,
    },
  );

  return data.ServiceRequestConnection.edges.map(({ node }) => node);
}
