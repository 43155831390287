import { faChevronRight, faFileLines } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Action, useAnalytics } from '@ctw/shared/context/analytics/use-analytics';
import { tw, twx } from '@ctw/shared/utils/tailwind';
import { Button } from '@ctw/shared/components/button';

interface ResourceDetailSummaryProps {
  date?: string;
  title?: string;
  subtitle?: string;
  isDetailShown: boolean;
  hasDocument?: boolean;
  analyticsInteraction: Action;
  analyticsActionExpand: string;
  analyticsActionCollapse: string;
  setIsDetailShown: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ResourceDetailSummary = ({
  date,
  title,
  subtitle,
  isDetailShown,
  analyticsInteraction,
  analyticsActionExpand,
  analyticsActionCollapse,
  hasDocument = false,
  setIsDetailShown,
}: ResourceDetailSummaryProps) => {
  const { trackInteraction } = useAnalytics();

  return (
    <Button
      type="button"
      aria-label="details"
      onClick={() => {
        setIsDetailShown(!isDetailShown);
        trackInteraction(analyticsInteraction, {
          action: isDetailShown ? analyticsActionCollapse : analyticsActionExpand,
        });
      }}
      variant="clean"
    >
      <div
        className={tw`flex items-center justify-between rounded-lg bg-bg-white p-3 text-left outline outline-1 outline-bg-dark`}
      >
        <div className={tw`flex space-x-3`}>
          <div className={tw`min-w-[6rem]`}>
            {date ?? <span className={tw`text-content-lighter`}>Unknown</span>}
          </div>
          {(title || subtitle) && (
            <div>
              <div className={tw`font-semibold text-content-black`}>{title}</div>
              <div className={tw`text-content-light`}>{subtitle}</div>
            </div>
          )}
          {/* Show unknown in the title spot if we don't have one AND if we aren't already
              showing "Unknown" in the date spot. */}
          {!title && !subtitle && date && <div className={tw`text-content-lighter`}>Unknown</div>}
        </div>
        <div className={tw`flex items-center space-x-3`}>
          {hasDocument && (
            <FontAwesomeIcon icon={faFileLines} className={tw`h-5 text-content-light`} />
          )}
          <div className={tw`justify-right flex`}>
            <FontAwesomeIcon
              icon={faChevronRight}
              className={twx('h-3 w-3 text-content-lighter', {
                'rotate-90': isDetailShown,
              })}
            />
          </div>
        </div>
      </div>
    </Button>
  );
};
