import { twx } from '@ctw/shared/utils/tailwind';
import { ReactNode } from 'react';

export type ButtonType = 'button' | 'submit' | 'reset';
export type ButtonVariant =
  | 'clean'
  | 'clear'
  | 'clear-link'
  | 'default'
  | 'primary'
  | 'link'
  | 'primary-link'
  | 'unstyled';

export type ButtonProps = {
  className?: string;
  children: ReactNode;
  type: ButtonType;
  variant: ButtonVariant;
  name?: string;
  ariaLabel?: string;
  value?: string;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  testId?: string;
};

export const Button = ({
  className,
  children,
  type,
  variant,
  name,
  ariaLabel,
  value,
  onClick,
  testId,
  disabled,
}: ButtonProps) => (
  <button
    // eslint-disable-next-line react/button-has-type
    type={type}
    name={name}
    value={value}
    onClick={onClick}
    data-testid={testId}
    aria-label={ariaLabel}
    disabled={disabled}
    className={twx(
      variant === 'unstyled' ? '' : (
        'cursor-pointer rounded-md px-4 py-2 font-sans text-sm font-medium shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-main disabled:pointer-events-none'
      ),
      {
        'w-full cursor-pointer border-none bg-transparent p-0 text-base outline-none':
          variant === 'clean',
        'cursor-pointer border-0 bg-transparent p-0 leading-none text-primary-dark outline-none hover:text-primary-main':
          variant === 'clear',
        'border border-solid border-divider-main bg-white capitalize text-content-light hover:bg-bg-lighter disabled:bg-bg-light':
          variant === 'default',
        'bg-primary-main capitalize text-white hover:bg-primary-dark disabled:bg-content-lighter':
          variant === 'primary',
        'cursor-pointer fill-primary-dark text-sm font-medium text-primary-dark no-underline hover:fill-primary-main hover:text-primary-main hover:underline':
          variant === 'link',
        'cursor-pointer text-primary-dark hover:text-primary-main hover:underline':
          variant === 'primary-link',
        'cursor-pointer border-0 bg-transparent fill-primary-dark p-0 text-sm font-medium leading-none text-primary-dark no-underline outline-none hover:fill-primary-main hover:text-primary-main hover:underline':
          variant === 'clear-link',
      },
      className,
    )}
  >
    {children}
  </button>
);
