import { tw } from '@ctw/shared/utils/tailwind';

export const FormFieldLabel = ({
  label,
  required,
  name,
}: {
  label: string;
  name: string;
  required: boolean | undefined;
}) => (
  <div className={tw`flex items-center justify-between`}>
    <label htmlFor={name} className={tw`capitalize`}>
      {label}
    </label>
    {!required && (
      <span className={tw`right-0 inline-block text-xs text-content-light`}>Optional</span>
    )}
    {required && (
      <>
        <div className={tw`flex-grow text-icon-default`}>*</div>
      </>
    )}
  </div>
);
