import { capitalize } from 'lodash';
import { allergyFilter } from './helpers/filters';
import { useAllergiesHistory } from './helpers/history';
import { allergySortOptions, defaultAllergySort } from './helpers/sort';
import { History } from '../resource/helpers/history';
import { PatientResourceTable } from '../resource/patient-resource-table';
import { useResourceDetailsDrawer } from '../resource/resource-details-drawer';
import { ResourceTableActions } from '../resource/resource-table-actions';
import { usePatientAllergies } from '@ctw/shared/api/fhir/allergies';
import { AllergyModel } from '@ctw/shared/api/fhir/models/allergies';
import { EmptyPatientTable } from '@ctw/shared/components/table/empty-table';
import { withErrorBoundary } from '@ctw/shared/content/error-boundary';
import { patientAllergiesColumns } from '@ctw/shared/content/allergies/helpers/column';
import { AnalyticsProvider } from '@ctw/shared/context/analytics/analytics-provider';
import { useUserBuilderId } from '@ctw/shared/context/user-builder-id';
import { useFilteredSortedData } from '@ctw/shared/hooks/use-filtered-sorted-data';
import { twx } from '@ctw/shared/utils/tailwind';

export type PatientAllergiesProps = {
  className?: string;
};

export const allergyHistory = ({ model }: { model: AllergyModel }) => (
  <History getHistory={useAllergiesHistory} model={model} />
);

function PatientAllergiesComponent({ className }: PatientAllergiesProps) {
  const patientAllergiesQuery = usePatientAllergies();

  const { data, filters, setFilters, setSort, defaultSort } = useFilteredSortedData({
    cacheKey: 'patient-allergies',
    defaultSort: defaultAllergySort,
    records: patientAllergiesQuery.data,
  });

  const userBuilderId = useUserBuilderId();
  const isEmptyQuery = patientAllergiesQuery.data?.length === 0;
  const hasZeroFilteredRecords = !isEmptyQuery && data.length === 0;

  const openDetails = useResourceDetailsDrawer({
    header: (m) => capitalize(m.display),
    details: allergyData,
    RenderChild: allergyHistory,
    getSourceDocument: true,
    enableDismissAndReadActions: true,
    deepLinkResourceType: 'AllergyIntolerance',
  });

  return (
    <AnalyticsProvider componentName="PatientAllergies">
      <div className={twx(className, 'scrollable-pass-through-height')}>
        <ResourceTableActions
          filterOptions={{
            onChange: setFilters,
            filters: allergyFilter(),
            selected: filters,
          }}
          sortOptions={{
            defaultSort,
            options: allergySortOptions,
            onChange: setSort,
          }}
        />
        <PatientResourceTable
          showTableHead
          isLoading={patientAllergiesQuery.isLoading}
          data={data}
          columns={patientAllergiesColumns(userBuilderId)}
          onRowClick={openDetails}
          enableDismissAndReadActions
          emptyMessage={
            <EmptyPatientTable
              hasZeroFilteredRecords={hasZeroFilteredRecords}
              resourceName="allergies"
            />
          }
        />
      </div>
    </AnalyticsProvider>
  );
}

export const PatientAllergies = withErrorBoundary(PatientAllergiesComponent, 'PatientAllergies');

export const allergyData = (allergy: AllergyModel) => [
  { label: 'Recorded Date', value: allergy.recordedDate },
  { label: 'Recording Organization', value: allergy.patientOrganizationName },
  { label: 'Status', value: allergy.clinicalStatus },
  { label: 'Type', value: capitalize(allergy.type) },
  { label: 'Onset', value: allergy.onset },
  { label: 'Reaction', value: capitalize(allergy.manifestations) },
  { label: 'Severity', value: capitalize(allergy.severity) },
  { label: 'Note', value: allergy.note },
];
