import { EpisodeOfCareModel, PatientModel } from './models';
import {
  createGraphqlClient,
  fqsRequest,
  MAX_OBJECTS_PER_REQUEST,
} from '@ctw/shared/api/fqs/client';
import {
  EpisodeOfCareGraphqlResponse,
  episodeOfCareQuery,
} from '@ctw/shared/api/fqs/queries/episode-of-care';
import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { useTimingQueryWithPatient } from '@ctw/shared/context/patient-provider';
import { QUERY_KEY_PATIENT_EPISODES_OF_CARE } from '@ctw/shared/utils/query-keys';

export function usePatientEpisodesOfCare() {
  return useTimingQueryWithPatient(
    QUERY_KEY_PATIENT_EPISODES_OF_CARE,
    [],
    async (requestContext: CTWRequestContext, patient: PatientModel) => {
      const graphClient = createGraphqlClient(requestContext);
      const { data } = await fqsRequest<EpisodeOfCareGraphqlResponse>(
        graphClient,
        episodeOfCareQuery,
        {
          upid: patient.UPID,
          cursor: '',
          first: MAX_OBJECTS_PER_REQUEST,
          sort: {
            lastUpdated: 'DESC',
          },
          filter: {
            tag: {
              allmatch: ['https://zusapi.com/summary|TransitionsOfCare'],
            },
          },
        },
      );
      const nodes = data.EpisodeOfCareConnection.edges.map((x) => x.node);
      const results = nodes.map((c) => new EpisodeOfCareModel(c, []));
      return results;
    },
  );
}
