import { Bundle } from 'fhir/r4';
import { SYSTEM_ZUS_THIRD_PARTY } from '@ctw/shared/api/fhir/system-urls';
import { ResourceType, ResourceTypeString } from '@ctw/shared/api/fhir/types';
import { getZusServiceUrl } from '@ctw/shared/api/urls';
import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { ctwFetch } from '@ctw/shared/utils/request';

export type FQSRequestContext = {
  // Added type to prevent normal CTWRequestContext from working.
  // This forces callers to use the fqsRequestContext converter function
  // which properly handles contextBuilderId overwrites.
  type: 'fqs';
  env: string;
  builderId: string;
  authToken: string;
};

export function fqsRequestContext(ctwContext: CTWRequestContext): FQSRequestContext {
  return {
    type: 'fqs',
    env: ctwContext.env,
    builderId: ctwContext.builderId,
    authToken: ctwContext.authToken,
  };
}

export async function searchRecords<T extends ResourceTypeString>(
  context: FQSRequestContext,
  resourceString: T,
  params?: Record<string, string>,
  filterOutThirdParty = true,
): Promise<ResourceType<T>[]> {
  const baseUrl = `${getZusServiceUrl(context.env, 'fqs')}/rest`;
  const urlParams = new URLSearchParams(params);

  const { data } = await ctwFetch(`${baseUrl}/${resourceString}?${urlParams.toString()}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${context.authToken}`,
    },
  });

  const resources = ((data as Bundle).entry ?? []).map((e) => e.resource) as ResourceType<T>[];

  if (!filterOutThirdParty) {
    return resources;
  }

  // Filter out 3rd party resources (TODO: Can remove when fully post-kludge).
  return resources.filter(
    (resource) => !resource.meta?.tag?.some((t) => t.system === SYSTEM_ZUS_THIRD_PARTY),
  );
}

export async function searchBuilderRecords<T extends ResourceTypeString>(
  context: FQSRequestContext,
  resourceString: T,
  params?: Record<string, string>,
): Promise<ResourceType<T>[]> {
  return searchRecords(context, resourceString, {
    ...params,
    builderID: context.builderId,
  });
}

export async function fetchResource<T extends ResourceTypeString>(
  context: FQSRequestContext,
  resourceString: T,
  id: string,
) {
  const baseUrl = `${getZusServiceUrl(context.env, 'fqs')}/rest`;
  const { data } = await ctwFetch(`${baseUrl}/${resourceString}/${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${context.authToken}`,
    },
  });

  return data as ResourceType<T>;
}
