export type SalesforceCanvasEnvironment = {
  targetOrigin: string;
  instanceId: string;
  clientId: string;
};

export function getSalesforceCanvasEnvironment(): SalesforceCanvasEnvironment {
  // Check if the values are already in session storage
  const targetOrigin = sessionStorage.getItem('targetOrigin') || window.location.ancestorOrigins[0];
  const instanceId =
    sessionStorage.getItem('instanceId') ||
    new URLSearchParams(window.location.hash.substring(1)).get('instance_id');
  const clientId =
    sessionStorage.getItem('clientId') ||
    new URLSearchParams(window.location.search).get('clientId');

  // Validate that all required values are available
  if (!targetOrigin) {
    throw new Error('Could not determine targetOrigin');
  }
  if (!instanceId) {
    throw new Error('Could not determine instanceId');
  }
  if (!clientId) {
    throw new Error('Could not determine clientId');
  }

  // Save values to session storage if they were not already set
  if (!sessionStorage.getItem('targetOrigin')) {
    sessionStorage.setItem('targetOrigin', targetOrigin);
  }
  if (!sessionStorage.getItem('instanceId')) {
    sessionStorage.setItem('instanceId', instanceId);
  }
  if (!sessionStorage.getItem('clientId')) {
    sessionStorage.setItem('clientId', clientId);
  }

  // Return the SalesforceCanvasEnvironment object
  return { targetOrigin, instanceId, clientId };
}
