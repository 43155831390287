import jwt_decode from 'jwt-decode';
import { notify } from '@ctw/shared/components/toast';

export const AUTH_BUILDER_ID = 'https://zusapi.com/builder_id';
export const AUTH_BUILDER_NAME = 'https://zusapi.com/builder_name';
export const AUTH_PRACTITIONER_ID = 'https://zusapi.com/practitioner_id';
export const AUTH_IS_SUPER_ORG = 'https://zusapi.com/is_super_org';
export const AUTH_PERMISSION_TOKEN = 'https://zusapi.com/permissions_token';
export const AUTH_EMAIL = 'https://zusapi.com/email';
export const AUTH_USER_ID = 'https://zusapi.com/user_id';
export const AUTH_APP_CLIENT_ID = 'https://zusapi.com/app_client_id';
export const AUTH_USER_TYPE = 'https://zusapi.com/user_type';
export const AUTH_PATIENT_ID = 'https://zusapi.com/patient_id';
export const AUTH_AUTHENTICATED_BY = 'https://zusapi.com/authenticated_by';
export const BUFFER_FOR_TOKEN_EXPIRATION = 500;

export type ZusJWT = {
  [AUTH_BUILDER_ID]: string;
  [AUTH_BUILDER_NAME]: string;
  [AUTH_PRACTITIONER_ID]: string;
  [AUTH_IS_SUPER_ORG]: string;
  [AUTH_PERMISSION_TOKEN]: string;
  [AUTH_EMAIL]: string;
  [AUTH_USER_ID]: string;
  [AUTH_APP_CLIENT_ID]: string;
  [AUTH_USER_TYPE]: string;
  [AUTH_PATIENT_ID]: string;
  [AUTH_AUTHENTICATED_BY]: string;
  iss: string;
  sub: string;
  aud: string[];
  iat: number;
  exp: number;
  azp: string;
  scope: string;
};

export function getClaims(authToken: string): ZusJWT {
  try {
    return jwt_decode(authToken);
  } catch {
    return {} as ZusJWT;
  }
}

export function claimsUserType(authToken: string): string | undefined {
  return getClaims(authToken)[AUTH_USER_TYPE];
}

export function claimsBuilderId(authToken: string): string | undefined {
  return getClaims(authToken)[AUTH_BUILDER_ID];
}

export function claimsBuilderName(authToken: string): string | undefined {
  return getClaims(authToken)[AUTH_BUILDER_NAME];
}

export function claimsExp(authToken: string): number {
  const expires = getClaims(authToken).exp;

  if (!expires || Number.isNaN(expires)) {
    // Return 0 if the token does not contain an 'exp' claim because undefined
    // will cast to NaN which always will be false in comparisons.
    return 0;
  }

  return expires;
}

export function claimsPractitionerId(authToken: string): string | undefined {
  return getClaims(authToken)[AUTH_PRACTITIONER_ID];
}

export function claimsAuthEmail(authToken: string): string | undefined {
  return getClaims(authToken)[AUTH_EMAIL];
}

export function hasUnexpiredToken(authToken?: string): boolean {
  if (!authToken) {
    return false;
  }
  // Consider token expired if it will expire in the next 500ms
  return claimsExp(authToken) * 1000 > Date.now() + BUFFER_FOR_TOKEN_EXPIRATION;
}

export function hasValidClaimsAuthEmail(authToken = ''): boolean {
  return hasUnexpiredToken(authToken) && !!claimsAuthEmail(authToken);
}

export function sendInvalidTokenNotification(authToken: string, toastId: string) {
  const message =
    hasUnexpiredToken(authToken) ? 'Only user tokens are allowed' : 'This token has expired';
  notify({
    type: 'error',
    title: 'Warning',
    body: `Invalid auth token: ${message}`,
    options: {
      toastId,
    },
  });
}
