import { ObservationsColumns } from './columns';
import { ObservationModel } from '@ctw/shared/api/fhir/models';
import { PatientResourceTable } from '@ctw/shared/content/resource/patient-resource-table';
import { tw, twx } from '@ctw/shared/utils/tailwind';

export type ObservationsTableProps = {
  className?: string;
  data: ObservationModel[];
};

export const ObservationsTable = ({ className, data }: ObservationsTableProps) => (
  <div className={twx(className)}>
    <div className={tw`text-base font-medium uppercase text-content-black`}>Results</div>
    <PatientResourceTable
      className={tw`patient-observation-details`}
      columns={ObservationsColumns()}
      data={data}
      emptyMessage="There are no observations available."
      hidePagination
    />
  </div>
);
