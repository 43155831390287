import { createContext } from 'react';

export interface AuthenticationContextValue {
  getAuthToken: () => Promise<string>;
  isExpired: boolean;
}

export const AuthenticationContext = createContext<AuthenticationContextValue>({
  getAuthToken: () => Promise.resolve(''),
  isExpired: false,
});
