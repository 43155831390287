import { z } from 'zod';
import { stateCode } from './validations';
import { PatientModel } from '@ctw/shared/api/fhir/models';
import { FormEntry } from '@ctw/shared/components/form/drawer-form-with-fields';
import { tw } from '@ctw/shared/utils/tailwind';

export const getRequestData = (
  patient: PatientModel | undefined,
  includePatientDemographicsForm: boolean,
): FormEntry[] => [
  {
    label: 'treating-provider',
    render: () => (
      <div className={tw`font-medium`}>Who is the treating provider for this patient?</div>
    ),
  },
  {
    label: 'Practitioner Name',
    field: 'name',
    value: '',
    readonly: false,
  },
  {
    label: 'NPI',
    field: 'npi',
    value: '',
    readonly: false,
  },
  [
    {
      label: 'Zus patient ID',
      hidden: true,
      field: 'id',
      value: patient?.id ?? '',
      readonly: true,
    },
    {
      label: 'Role',
      field: 'role',
      value: 'Medical practitioner (SNOMED 158965000)',
      hidden: true,
    },
  ],
  ...(!includePatientDemographicsForm ?
    []
  : [
      {
        label: 'patient-information',
        render: () => (
          <div>
            <div className={tw`font-medium`}>
              Is the patient information below correct and up-to-date?
            </div>
            <div>Complete as many fields as possible to increase matching results.</div>
          </div>
        ),
      },
      {
        label: 'First Name',
        field: 'firstName',
        value: patient?.firstName ?? '',
        readonly: false,
      },
      {
        label: 'Last Name',
        field: 'lastName',
        value: patient?.lastName ?? '',
        readonly: false,
      },
      [
        {
          label: 'Date of Birth',
          field: 'dateOfBirth',
          value: patient?.dob ?? '',
          readonly: false,
        },
        {
          label: 'Gender',
          field: 'gender',
          value: patient?.gender ?? '',
          readonly: false,
        },
      ],
      {
        label: 'Address',
        field: 'address',
        value: patient?.homeAddress?.line?.join(', ') ?? '',
        readonly: false,
      },
      {
        label: 'City',
        field: 'city',
        value: patient?.homeAddress?.city ?? '',
        readonly: false,
      },
      [
        {
          label: 'State',
          field: 'state',
          value: stateCode(patient?.homeAddress?.state),
          readonly: false,
        },
        {
          label: 'Zip',
          field: 'zipCode',
          value: patient?.homeAddress?.postalCode ?? '',
          readonly: false,
        },
      ],
      {
        label: 'Phone',
        field: 'phone',
        value: patient?.phoneNumber ?? '',
        readonly: false,
      },
      {
        label: 'Email',
        field: 'email',
        value: patient?.email ?? '',
        readonly: false,
      },
    ]),
];

export const requestHistorySchema = z.object({
  name: z.string({
    required_error: 'Practitioner name must be specified.',
  }),
  npi: z
    .string({
      required_error: 'NPI must be specified.',
    })
    .length(10),
  role: z.enum(['Medical practitioner (SNOMED 158965000)']).optional(),
  id: z.string().optional(), // patient id (hidden field on form)
});
