import { ReactElement, ReactNode, useRef } from 'react';
import { useBreakpoints } from '@ctw/shared/hooks/use-breakpoints';
import { tw, twx } from '@ctw/shared/utils/tailwind';

type ContainerProps = {
  className?: string;
  children?: ReactNode;
};

type BodyContainerProps = ContainerProps & {
  title?: string;
  titleChildren?: ReactElement;
};

type TitleContainerProps = ContainerProps & {
  title?: string;
};

type HeadingContainerProps = TitleContainerProps;

export function Wrapper({ className, children }: ContainerProps) {
  return <div className={twx('container', className)}>{children}</div>;
}

export function StackedWrapper({ className, children, ...htmlProps }: ContainerProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const breakpoints = useBreakpoints(containerRef);
  const classNames = twx(className, {
    stacked: breakpoints.sm,
  });

  return (
    <div className={twx(classNames)} ref={containerRef} {...htmlProps}>
      {children}
    </div>
  );
}

export function Heading({ className, title, children }: HeadingContainerProps) {
  return (
    <div className={twx('heading-container', className)}>
      {title && <div className={tw`title`}>{title}</div>}
      {children}
    </div>
  );
}

export function Title({ className, title, children }: TitleContainerProps) {
  return (
    <div className={twx('title-container', className)}>
      {title && <div className={tw`title`}>{title}</div>}
      {children}
    </div>
  );
}

export function Body({ className, title, titleChildren, children }: BodyContainerProps) {
  const showTitleContainer = !!(title || titleChildren);
  return (
    <div className={twx('body-container', className)}>
      <div className={tw`space-y-3`}>
        {showTitleContainer && <Title title={title}>{titleChildren}</Title>}
        {children}
      </div>
    </div>
  );
}
