import { useMemo } from 'react';
import { FHIRModel } from '@ctw/shared/api/fhir/models/fhir-model';

export function useGroupedBy<T extends fhir4.Resource, M extends FHIRModel<T>>(
  groupBy: (record: M) => string,
  data: M[] = [],
  groupByKeyOrder?: string[],
) {
  // Group the records using the groupBy function
  const groupedRecords = useMemo(
    () =>
      data.reduce(
        (result, record) => {
          const groupKey = groupBy(record) as string;
          const group = result[groupKey] ?? [];
          return {
            ...result,
            [groupKey]: group.concat(record),
          };
        },
        {} as Record<string, M[] | undefined>,
      ),
    [data, groupBy],
  );

  const sortedKeys = useMemo(
    () =>
      Object.keys(groupedRecords).sort((a, b) =>
        groupByKeyOrder ?
          groupByKeyOrder.indexOf(a) - groupByKeyOrder.indexOf(b)
        : a.localeCompare(b),
      ),
    [groupedRecords, groupByKeyOrder],
  );

  return { groupedRecords, sortedKeys };
}
