import { CTWRequestContext } from '@ctw/shared/context/ctw-context';
import { useTimingQueryWithPatient } from '@ctw/shared/context/patient-provider';
import { MedicationModel } from '@ctw/shared/api/fhir/models/medication';
import { MedicationStatementModel } from '@ctw/shared/api/fhir/models/medication-statement';
import { PatientModel } from '@ctw/shared/api/fhir/models/patient';
import { filterResourcesByBuilderId } from '@ctw/shared/api/common';
import {
  createGraphqlClient,
  fqsRequest,
  MAX_OBJECTS_PER_REQUEST,
} from '@ctw/shared/api/fqs/client';
import type {
  FhirResource,
  MedicationAdministration,
  MedicationDispense,
  MedicationRequest,
  MedicationStatement,
} from 'fhir/r4';
import { cloneDeep, compact, groupBy, last, mapValues, sortBy, uniqWith } from 'lodash';
import { find, compact as fpCompact } from 'lodash/fp';
import { bundleToResourceMap } from './bundle';
import { getIdentifyingRxNormCode } from './medication';
import {
  CTW_EXTENSION_LENS_AGGREGATED_FROM,
  LENS_EXTENSION_AGGREGATED_FROM,
  LENS_EXTENSION_MEDICATION_DAYS_SUPPLY,
  LENS_EXTENSION_MEDICATION_LAST_FILL_DATE,
  LENS_EXTENSION_MEDICATION_LAST_PRESCRIBED_DATE,
  LENS_EXTENSION_MEDICATION_LAST_PRESCRIBER,
  LENS_EXTENSION_MEDICATION_QUANTITY,
  LENS_EXTENSION_MEDICATION_REFILLS,
  SYSTEM_SUMMARY,
  SYSTEM_ZUS_OWNER,
  SYSTEM_ZUS_SUMMARY,
  SYSTEM_ZUS_THIRD_PARTY,
} from './system-urls';
import { ResourceMap } from './types';
import {
  MedicationAdministrationGraphqlResponse,
  medicationAdministrationQuery,
} from '@ctw/shared/api/fqs/queries/medication-administration';
import {
  MedicationDispenseGraphqlResponse,
  medicationDispenseQuery,
} from '@ctw/shared/api/fqs/queries/medication-dispense';
import {
  MedicationRequestGraphqlResponse,
  medicationRequestQuery,
} from '@ctw/shared/api/fqs/queries/medication-request';
import {
  MedicationStatementGraphqlResponse,
  medicationStatementQuery,
} from '@ctw/shared/api/fqs/queries/medication-statements';
import { getLensBuilderId } from '@ctw/shared/api/urls';
import { QUERY_KEY_MEDICATION_HISTORY } from '@ctw/shared/utils/query-keys';
import { sort } from '@ctw/shared/utils/sort';

export type InformationSource =
  | 'Patient'
  | 'Practitioner'
  | 'PractitionerRole'
  | 'RelatedPerson'
  | 'Organization';

type MedicationFilter = {
  status?: fhir4.MedicationStatement['status'];
  informationSource?: InformationSource;
  informationSourceNot?: InformationSource;
};

export type MedicationResults = {
  bundle: fhir4.Bundle | undefined;
  medications: fhir4.MedicationStatement[];
  basic: fhir4.Basic[];
};

export async function getBuilderMedicationsFQS(
  requestContext: CTWRequestContext,
  patient: PatientModel,
) {
  const searchFilters = {
    informationSourceNot: 'Patient', // exclude medication statements where the patient is the information source
  } as MedicationFilter;
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequest<MedicationStatementGraphqlResponse>(
    graphClient,
    medicationStatementQuery,
    {
      upid: patient.UPID,
      cursor: '',
      first: 1000,
      sort: {
        lastUpdated: 'DESC',
      },
      filter: {
        tag: {
          nonematch: [SYSTEM_SUMMARY, `${SYSTEM_ZUS_THIRD_PARTY}`],
          // TODO: There's a bug in FQS that doesn't allow filtering with nonematch AND allmatch.
          // Uncomment the line below once https://zeushealth.atlassian.net/browse/DRT-249 is resolved.
          // allmatch: [`${SYSTEM_ZUS_OWNER}|builder/${requestContext.builderId}`],
        },
      },
    },
  );
  let nodes = data.MedicationStatementConnection.edges.map((x) => x.node);
  nodes = filterResourcesByBuilderId(nodes, requestContext.builderId);
  const medStatements = nodes.map((n) => new MedicationStatementModel(n, undefined, n.BasicList));
  const models = applySearchFiltersToFQSResponse(medStatements, searchFilters, false);
  return models;
}

export async function getMedicationStatementsForPatientByIdFQS(
  requestContext: CTWRequestContext,
  patient: PatientModel,
  resourceIds: string[] | undefined,
): Promise<MedicationResults> {
  if (resourceIds === undefined || resourceIds.length === 0) {
    return { bundle: undefined, medications: [], basic: [] };
  }
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequest<MedicationStatementGraphqlResponse>(
    graphClient,
    medicationStatementQuery,
    {
      upid: patient.UPID,
      cursor: '',
      first: 1000,
      sort: {
        lastUpdated: 'DESC',
      },
      filter: {
        ids: {
          anymatch: resourceIds,
        },
      },
    },
  );
  const nodes = data.MedicationStatementConnection.edges.map((x) => x.node);
  return { bundle: undefined, medications: nodes, basic: [] };
}

export async function getMedicationAdministrationsForPatientByIdFQS(
  requestContext: CTWRequestContext,
  patient: PatientModel,
  resourceIds: string[] | undefined,
): Promise<MedicationAdministration[]> {
  if (resourceIds === undefined || resourceIds.length === 0) {
    return [];
  }
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequest<MedicationAdministrationGraphqlResponse>(
    graphClient,
    medicationAdministrationQuery,
    {
      upid: patient.UPID,
      cursor: '',
      first: 1000,
      sort: {
        lastUpdated: 'DESC',
      },
      filter: {
        ids: {
          anymatch: resourceIds,
        },
      },
    },
  );
  return data.MedicationAdministrationConnection.edges.map((x) => x.node);
}

export async function getMedicationDispensesForPatientByIdFQS(
  requestContext: CTWRequestContext,
  patient: PatientModel,
  resourceIds: string[] | undefined,
): Promise<MedicationDispense[]> {
  if (resourceIds === undefined || resourceIds.length === 0) {
    return [];
  }
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequest<MedicationDispenseGraphqlResponse>(
    graphClient,
    medicationDispenseQuery,
    {
      upid: patient.UPID,
      cursor: '',
      first: 1000,
      sort: {
        lastUpdated: 'DESC',
      },
      filter: {
        ids: {
          anymatch: resourceIds,
        },
      },
    },
  );

  return data.MedicationDispenseConnection.edges.map((x) => x.node);
}

export async function getMedicationRequestsForPatientByIdFQS(
  requestContext: CTWRequestContext,
  patient: PatientModel,
  resourceIds: string[] | undefined,
): Promise<MedicationRequest[]> {
  if (resourceIds === undefined || resourceIds.length === 0) {
    return [];
  }
  const graphClient = createGraphqlClient(requestContext);

  const { data } = await fqsRequest<MedicationRequestGraphqlResponse>(
    graphClient,
    medicationRequestQuery,
    {
      upid: patient.UPID,
      cursor: '',
      first: 1000,
      sort: {
        lastUpdated: 'DESC',
      },
      filter: {
        ids: {
          anymatch: resourceIds,
        },
      },
    },
  );
  return data.MedicationRequestConnection.edges.map((x) => x.node);
}

function applySearchFiltersToFQSResponse(
  medicationStatements: MedicationStatementModel[],
  searchFilters: MedicationFilter = {},
  removeMedsWithNoRxNorm = false,
) {
  let medications = medicationStatements;
  if (removeMedsWithNoRxNorm) {
    medications = medications.filter((medication) => medication.rxNorm !== undefined);
  }

  if (searchFilters.informationSource) {
    medications = medications.filter(
      (medication) => medication.informationSource?.type === searchFilters.informationSource,
    );
  }

  if (searchFilters.informationSourceNot) {
    medications = medications.filter(
      (medication) => medication.informationSource?.type !== searchFilters.informationSourceNot,
    );
  }

  return medications;
}

/* Note when filtering the bundle may contain data that will no longer 
be in the returned medications, such as medications with no RxNorm code. */
export async function getSummaryMedicationsFQS(
  requestContext: CTWRequestContext,
  patient: PatientModel,
) {
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequest<MedicationStatementGraphqlResponse>(
    graphClient,
    medicationStatementQuery,
    {
      upid: patient.UPID,
      cursor: '',
      first: 1000,
      sort: {
        lastUpdated: 'DESC',
      },
      filter: {
        tag: {
          allmatch: [
            `${SYSTEM_ZUS_SUMMARY}|Common`,
            `${SYSTEM_ZUS_OWNER}|builder/${getLensBuilderId(requestContext.env)}`,
          ],
        },
      },
    },
  );
  const nodes = data.MedicationStatementConnection.edges.map((x) => x.node);
  return nodes.map((n) => new MedicationStatementModel(n, undefined, n.BasicList));
}

export async function getMedicationStatementsByIdFQS(
  requestContext: CTWRequestContext,
  patient: PatientModel,
  medicationStatementIds: string[] = [],
) {
  const graphClient = createGraphqlClient(requestContext);
  const { data } = await fqsRequest<MedicationStatementGraphqlResponse>(
    graphClient,
    medicationStatementQuery,
    {
      upid: patient.UPID,
      cursor: '',
      sort: {},
      first: MAX_OBJECTS_PER_REQUEST,
      filter: {
        ids: {
          anymatch: medicationStatementIds,
        },
      },
    },
  );

  return data.MedicationStatementConnection.edges.map(
    (x) => new MedicationStatementModel(x.node, undefined, x.node.BasicList),
  );
}

// Helper function to filter out medications missing RxNorm codes.
export function filterMedicationsWithNoRxNorms(
  medications: MedicationStatement[],
  bundle: FhirResource,
) {
  const resourceMap = bundleToResourceMap(bundle);
  return medications.filter((m) => getIdentifyingRxNormCode(m, resourceMap) !== undefined);
}

// Splits medications into those that the builder already knows about ("Provider Medications"),
// those that they do not know about ("Other Provider Medications"), and those they didn't know
// about originally and then dismissed ("Dismissed Other Provider Medications").
export function splitMedications(
  summarizedMedications: MedicationStatementModel[],
  builderOwnedMedications: MedicationStatementModel[],
) {
  // Get active medications where there does not exist a matching builder owned record.
  const otherProviderMedications = summarizedMedications.filter(
    (medication) =>
      !builderOwnedMedications.some((builderMed) => builderMed.rxNorm === medication.rxNorm),
  );

  // Get builder owned medications and splash in some data from lens meds if available.
  const builderMedications = builderOwnedMedications.map((m) => {
    const summarizedMed = find((a) => a.rxNorm === m.rxNorm, summarizedMedications);

    if (!summarizedMed) {
      return m;
    }

    // If we did find an active med then copy the builder med and add in the lens extensions.
    const builderMedResource = cloneDeep(m.resource);

    const LENS_MEDICATION_EXTENSIONS = [
      LENS_EXTENSION_MEDICATION_LAST_FILL_DATE,
      LENS_EXTENSION_MEDICATION_LAST_PRESCRIBED_DATE,
      LENS_EXTENSION_MEDICATION_QUANTITY,
      LENS_EXTENSION_MEDICATION_DAYS_SUPPLY,
      LENS_EXTENSION_MEDICATION_REFILLS,
      LENS_EXTENSION_MEDICATION_LAST_PRESCRIBER,
    ];

    builderMedResource.extension = summarizedMed.resource.extension?.filter((x) =>
      LENS_MEDICATION_EXTENSIONS.includes(x.url),
    );

    const medHistory = cloneDeep(
      find({ url: LENS_EXTENSION_AGGREGATED_FROM }, summarizedMed.resource.extension),
    );
    if (medHistory) {
      // To avoid confusion about the lens extension (since "aggregated from" doesn't really
      // make sense on this builder record), use a different extension URL.
      medHistory.url = CTW_EXTENSION_LENS_AGGREGATED_FROM;
      builderMedResource.extension?.push(medHistory);
    }

    return new MedicationStatementModel(builderMedResource, m.includedResources, m.basics);
  });

  return {
    builderMedications,
    otherProviderMedications,
  };
}

export function useMedicationHistory(medication?: fhir4.MedicationStatement) {
  return useTimingQueryWithPatient(
    QUERY_KEY_MEDICATION_HISTORY,
    [medication?.id],
    getMedicationHistoryFQS(medication),
  );
}

function getMedicationHistoryFQS(medication?: fhir4.MedicationStatement) {
  return async (requestContext: CTWRequestContext, patient: PatientModel) => {
    if (!medication) {
      return { medications: [], includedResources: {} as ResourceMap };
    }
    try {
      const aggregatedFromReferences = new MedicationStatementModel(medication).aggregatedFrom;

      const groups = groupBy(aggregatedFromReferences, 'type');
      const resources = mapValues(groups, (group) =>
        compact(group.map((g) => last(g.reference?.split('/')))),
      );

      const [
        medicationStatementResponse,
        medicationAdministrationResponse,
        medicationRequestResponse,
        medicationDispenseResponse,
      ] = await Promise.all([
        getMedicationStatementsForPatientByIdFQS(
          requestContext,
          patient,
          resources.MedicationStatement,
        ),
        getMedicationAdministrationsForPatientByIdFQS(
          requestContext,
          patient,
          resources.MedicationAdministration,
        ),
        getMedicationRequestsForPatientByIdFQS(
          requestContext,
          patient,
          resources.MedicationRequest,
        ),
        getMedicationDispensesForPatientByIdFQS(
          requestContext,
          patient,
          resources.MedicationDispense,
        ),
      ]);
      let medicationResources = fpCompact([
        ...medicationStatementResponse.medications,
        ...medicationAdministrationResponse,
        ...medicationRequestResponse,
        ...medicationDispenseResponse,
      ]).map((m) => new MedicationModel(m));

      // force FQS results to be sorted by id just like ODS results to ensure both functions return the same output.
      // TODO: Remove once the ODS code path no longer exists.
      medicationResources = sortBy(medicationResources, (a) => a.resource.id);

      const medications = sort(
        uniqWith(
          medicationResources,
          (a, b) => a.date === b.date && a.resource.resourceType === b.resource.resourceType,
        ),
        'date',
        'desc',
        true,
      );
      return { medications, includedResources: {} as ResourceMap };
    } catch (e) {
      throw new Error(`Failed fetching medication history for medication ${medication.id}: ${e}`);
    }
  };
}
